import React, { useState } from "react";
import styled from "styled-components";
import Typography from "carbon-react/lib/components/typography";
import useTheme from "../hooks/useTheme";
import Icon from "carbon-react/lib/components/icon";

// si besoin voir FacetButton pour d'autres cas
const getOnClick =
  ({ value, control }) =>
  (e) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (value?.button?.call)
      return control.callAction(value.button.call.name, value.button.call);
    if (value?.button?.callBack) return value.button.callBack();
    if (value?.button?.location) {
      const location = value.button.location;
      if (location.newTab) window.open(location.url, "_blank");
      else window.location = location.url;
      return;
    }
    return;
  };

const getOnPointerEnter = (setHovered) => () => {
  setHovered(true);
};

const getOnPointerLeave = (setHovered) => () => {
  setHovered(false);
};

const getTextColor = ({ hovered, theme }) =>
  hovered ? theme?.customMenu?.hoverColor : theme?.customMenu?.color;

const getIconColor = ({ theme }) => theme?.colors?.white;

const ButtonContainer = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px ${({ shadowTop }) => (shadowTop ? "1px" : "-1px")} 0px
    ${({ theme }) => theme?.customMenu?.borderColor};
  width: 100%;
  height: 46.6px;
  border: none;
  cursor: pointer;
  background-color: ${({ theme, hovered }) =>
    hovered
      ? theme?.customMenu?.hoverBackground
      : theme?.customMenu?.background};
  &:focus {
    outline: 3px solid var(--colorsSemanticFocus500);
  }
`;

const FacetMenuButton = ({ value, control }) => {
  const [hovered, setHovered] = useState(false);
  const theme = useTheme();
  const { title, icon, shadowTop } = value;

  return (
    <ButtonContainer
      className="frp1000-menu-item"
      shadowTop={shadowTop}
      hovered={hovered}
      onPointerEnter={getOnPointerEnter(setHovered)}
      onPointerLeave={getOnPointerLeave(setHovered)}
      onClick={getOnClick({ value, control })}
    >
      <Typography ml="24px" mb="0px" color={getTextColor({ hovered, theme })}>
        {title ?? ""}
      </Typography>
      {icon ? (
        <Icon type={icon} color={getIconColor({ theme })} mr="16px" />
      ) : null}
    </ButtonContainer>
  );
};

export default FacetMenuButton;
