//Credit 
//better-fetch from Swizec
//swizec@swizec.com 
//http://swizec.com
//https://github.com/Swizec/better-fetch

import { env } from '../../env'

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _includeTrailingUrlSeparator = function(s) {
    if (s.substr(-1) !== '/') return s + '/'; else return s;
};

var apiConfiguration = {url:'',  package:'', headers:{'Content-Type': 'application/json'} };

var apiFetch = function apiFetch(methodName) {

    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    params.headers = Object.assign({}, headerDict(params.headers), apiConfiguration.headers);
    params.credentials = 'same-origin';
    params.method = 'POST';

    var aurl = apiConfiguration.url;
    if (methodName.indexOf('/') > -1) 
     aurl = aurl+methodName; 
    else
     aurl = aurl+apiConfiguration.package+'/'+methodName; 

    return fetch(aurl, params);
};

var headerDict = function headerDict(headers) {
    var dict = {};

    if (headers instanceof Headers) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
            for (var _iterator = headers.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var _step$value = _slicedToArray(_step.value, 2),
                    key = _step$value[0],
                    value = _step$value[1];

                dict[key] = value;
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally {
            try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                }
            } finally {
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    } else {
        dict = headers;
    }

    return dict;
};

apiFetch.sendBeacon = function(methodName, payload) {

    var aurl = apiConfiguration.url;
    if (methodName.indexOf('/') > -1) 
     aurl = aurl+methodName; 
    else
     aurl = aurl+apiConfiguration.package+'/'+methodName; 
    
     console.log("sendBeacon",aurl,payload)
     navigator.sendBeacon(aurl, payload);
}

apiFetch.setUrl = function (url) {

    apiConfiguration.url = url;
};
 
apiFetch.setDefaultHeaders = function (headers) {
    apiConfiguration.headers = headerDict(headers);
};

apiFetch.throwErrors = function (response) {
    if (!response.ok) {
        var err = new Error(response.statusText);
        err.response = response;
        throw err;
    }
    return response;
};

apiFetch.initializeFromEnv = function() {

    // REACT_APP_API_URL
    //
    // In development mode:
    //  must be the absolute url to the service
    //  ex : https://myhost/sdata/
    //
    // In production mode
    //  must be the relative path
    //  ex : ../../server/sdata
    
    // REACT_APP_API_PACKAGE
    //
    //  should be the default package name used for this app
    //  If the call doesn't include a package name this package is used. 

    // REACT_APP_API_TOKEN
    // 
    // Use only in development mode
    //  must be a valid authentication token

    apiConfiguration.url = _includeTrailingUrlSeparator(process.env.REACT_APP_API_URL);
    apiConfiguration.package = process.env.REACT_APP_API_PACKAGE;

    if (env.REACT_APP_API_PACKAGE) {
        apiConfiguration.package = env.REACT_APP_API_PACKAGE;
    }

    if (process.env.NODE_ENV === 'development') {
        apiConfiguration.headers['Authorization'] = 'Bearer '+ process.env.REACT_APP_API_TOKEN;
    };

    //console.log('api headers',apiConfiguration.headers);
 };

 export default apiFetch;