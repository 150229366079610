import React, { useState, useEffect, useLayoutEffect } from "react";
import Grid from "sagegrid/components/BaseSageGrid";
import styled from "styled-components";
import eventBus from "../../events/eventBus";

const getGridReadyHandler = ({ setApi, setColumnApi }) => ({
  api,
  columnApi,
}) => {
  setApi(api);
  setColumnApi(columnApi);
};

const getGridOptions = (columnDefs) => ({
  rowModelType: "clientSide",
  domLayout: "autoHeight",
  rowSelection: "multiple",
  rowMultiSelectWithClick: true,
  columnDefs,
});

const getOnSelectionChanged = ({ api, selectCallback,selectCallbackFacetValueMode, control, facet, value, shouldUpdateFacetValue }) => () => {
  const selectedRows = api.getSelectedRows();
  if(shouldUpdateFacetValue) {
    selectCallbackFacetValueMode({ selectedRows, control, facet });
  } else {
    selectCallback(selectedRows);
  }
};

const GridContainer = styled.div`
  & {
    ${({ fixedHeight }) => fixedHeight ? `
      max-height: ${fixedHeight};
      min-height: ${fixedHeight};
      overflow: auto;
      overflow-x: hidden;
    ` : ""}
  }
  & .ag-center-cols-container {
    width: 100% !important;
  }
  ${({ noHeader }) =>
    noHeader &&
    `& .ag-header[role="presentation"] {
       height: 0px !important;
       min-height: 0px !important;
     }
    `}
`;

const SelectableGrid = ({
  columnDefs,
  data,
  noHeader,
  selectedItems,
  selectCallback,
  selectCallbackFacetValueMode,
  fixedHeight,
  control,
  facet,
  value,
  shouldUpdateFacetValue,
  shouldLocallyInitialiseSelectedData
}) => {
  const [api, setApi] = useState(undefined);
  const [columnApi, setColumnApi] = useState(undefined);
  const [forceRerender, setForceRerender] = useState(false);
  const [firstRenderFlag, setFirstRenderFlag] = useState(true);

  useEffect(() => {
    if (api) {
      api.sizeColumnsToFit();
    }
    if (api && shouldLocallyInitialiseSelectedData) {
      api.forEachNode(function(node) {
        if(node.data.selected) {
          node.setSelected(true);
        }
      });
    }
    else if (api && Array.isArray(selectedItems) && !shouldLocallyInitialiseSelectedData) {
      api.forEachNode(function(node) {
        const nodeInSelectedItems = selectedItems.find(
          (item) => item.id === node.data.id
        );
        if (nodeInSelectedItems) {
          node.setSelected(true);
        }
      });
    }
  }, [selectedItems, api, shouldLocallyInitialiseSelectedData]);

  useLayoutEffect(() => {
    if(!firstRenderFlag) setForceRerender(true);
    else setFirstRenderFlag(false);
  }, [data, firstRenderFlag]);

  useLayoutEffect(() => {
    if(forceRerender) {
      setForceRerender(false);
    }
  }, [forceRerender]);

  return (
    <GridContainer noHeader={noHeader} fixedHeight={fixedHeight}>
      {!forceRerender && <Grid
        enableEnterpriseVersion={false}
        headerHeight={noHeader ? 0 : undefined}
        gridOptions={getGridOptions(columnDefs)}
        rowData={data}
        onSelectionChanged={getOnSelectionChanged({ api, selectCallback, selectCallbackFacetValueMode, control, facet, value, shouldUpdateFacetValue })}
        onGridReady={getGridReadyHandler({ setApi, setColumnApi })}
      />}
    </GridContainer>
  );
};

export default SelectableGrid;
