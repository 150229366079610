import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Tab, TabList as ReactTabsTabList } from "react-tabs";
import IconButton from "carbon-react/lib/components/icon-button";
import Icon from "carbon-react/lib/components/icon";
import Typography from "carbon-react/lib/components/typography";
import Tooltip from "carbon-react/lib/components/tooltip";
import PopoverContainer from "../../components/PopoverContainer";
import ButtonAction from "../../facets/FacetResultGrid/ButtonAction";
import ActionsContainer from "./ActionsContainer";
import Slider from "react-slick";
import Ellipsis from "../../components/Ellipsis";
import SelectableList from "../../components/SelectableList";
import { useNavLeftCurrentSizeContext } from "./FacetNavigTabs";
import eventBus from "../../../events/eventBus";
import FacetDialogButton from "../FacetDialogButton";

// home tab + prev/next arrows + dropdown select tab + close all button
const TAB_LIST_BUTTONS_WIDTH = "178px";
const TAB_LIST_BUTTONS_WIDTH_WITHOUT_DROPDOWN_TAB_SELECTION = "154px";

const closeAllTabsCallback = ({ facet, control }) => {
  if (facet && facet.tabs && facet.tabs.onCloseAll && control) {
    control.callAction(facet.tabs.onCloseAll, {});
  }
};

const TabListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PrevArrowContainer = styled.div``;

const TabSliderContainer = styled.div`
  width: calc(
    100vw - ${({ navLeftWidth }) => navLeftWidth} -
      ${({ tabSelectionDropdownDisplayed }) =>
        tabSelectionDropdownDisplayed
          ? TAB_LIST_BUTTONS_WIDTH
          : TAB_LIST_BUTTONS_WIDTH_WITHOUT_DROPDOWN_TAB_SELECTION}
  );
  & .slick-track {
    margin-left: inherit;
  }
  & .slick-arrow {
    height: ${({ theme }) => theme.navigation.tabs.height};
  }
  & .react-tabs__tab:focus {
    border-color: initial;
    box-shadow: initial;
  }

  & .react-tabs__tab--selected {
    background-color: ${({ theme }) => theme?.pod?.tertiaryBackground};
  }

  & .react-tabs__tab:focus:after {
    height: 0px;
    background-color: ${({ theme }) => theme?.pod?.tertiaryBackground};
  }

  & .slick-slider {
    max-height: ${({ theme }) => theme.navigation.tabs.height};
    overflow-y: visible;
  }

  & .slick-list {
    height: ${({ navLeftHeight }) => navLeftHeight};
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: none;
  }

  & .slick-list > * {
    pointer-events: all;
  }
`;

const NextAndDropdownAndCloseAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-left: 8px;

  & [data-element="popover-container-content"] {
    padding: 0;
  }

  & [data-element="popover-container-content"] > :first-child {
    display: none;
  }
`;

const getNavLeftWidthNumber = (navLeftWidth) =>
  parseInt(navLeftWidth.replace("px", ""));

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.theme.navigation.tabs.title.height + "px"};
  padding: 5px 5px 5px 5px;
  overflow: visible;
  & > div:first-child > button {
    visibility: hidden;
    padding-bottom: 5px;
  }
  &:hover > div:first-child > button {
    visibility: visible;
  }
`;

const TabTitle = styled.div`
  color: ${({ theme }) => theme?.text?.color};
  & > h5 {
    font-weight: ${({ theme }) => theme?.text?.weightTitle};
  }
`;

const TitleEllipsisContainer = styled.div`
  width: 85px;
`;

const FavouriteAndTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 8px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FavouritePopoverContainerStyle = styled.div`
  & {
    visibility: ${(props) => (props?.tab?.actions ? "visible" : "hidden")};
  }
  & [data-element="popover-container-content"] {
    min-width: max-content;
    padding: 0;
  }
  & [data-element="popover-container-content"] > :first-child {
    display: none;
  }
`;

///////////////////////////////////////////////
const HomeContainer = styled(TabContainer)`
  width: 64px;
`;

const HomeTabContainer = styled.div`
  & [data-element="popover-container-content"] {
    min-width: max-content;
    padding: 0;
  }
  display: flex;
  width: 50%;
  margin: 0 auto;
`;

const HomeTab = ({
  facet,
  control,
  setSelected,
  isSelected,
  ...otherProps
}) => {
  const tabRef = useRef();

  const onSelectTab = (e) => {
    e?.preventDefault?.();
    setSelected();
  };

  const [isPopoverOpened, setPopover] = useState(false);
  const refButtonAction = useRef();

  const openHomePopover = (e) => {
    if (e) e.preventDefault();
    setPopover(true);
  };
  const closeHomePopover = (e) => {
    if (e) e.preventDefault();
    setPopover(false);
  };
  const switchHomePopover = (e) => {
    if (e) e.preventDefault();
    setPopover(!isPopoverOpened);
  };

  return (
    <Tab
      {...otherProps}
      ref={tabRef}
      onClick={onSelectTab}
      selected={isSelected}
    >
      <HomeContainer role="tab-container">
        <HomeTabContainer role="home-container">
          <Icon type="boxed_shapes" />

          <IconsContainer>
            <PopoverContainer
              noCloseIcon
              open={isPopoverOpened}
              onOpen={openHomePopover}
              onClose={closeHomePopover}
              renderOpenComponent={() => (
                <IconButton ref={refButtonAction} onAction={switchHomePopover}>
                  <Icon type="dropdown" />
                </IconButton>
              )}
            >
              <ActionsContainer
                refButtonAction={refButtonAction}
                setOpen={(opened) => {
                  closeHomePopover();
                }}
              >
                {facet.homeTab?.actions?.map((action, key) => (
                  <ButtonAction
                    facet={facet}
                    control={control}
                    action={action}
                    afterActionCallback={() => {
                      closeHomePopover();
                    }}
                    id={key}
                    key={key}
                    buttonProps={{
                      buttonType: "tertiary",
                      iconType: action.icon || undefined,
                      pl: "0",
                      pr: "0",
                      ml: "0",
                      mr: "0",
                    }}
                  />
                ))}
              </ActionsContainer>
            </PopoverContainer>
          </IconsContainer>
        </HomeTabContainer>
      </HomeContainer>
    </Tab>
  );
};
///////////////////////////////////////////////

const getActionsPopoverPosition = ({
  trackState,
  customTabIndex,
  currentSlideIndex,
}) => {
  if (customTabIndex === 0) return "right";
  if (trackState) {
    const { slidesToShow } = trackState;
    const fixedSlideIndex = currentSlideIndex < 0 ? 0 : currentSlideIndex;
    const lastDisplayedTabIndex = fixedSlideIndex + slidesToShow - 1;
    if (
      customTabIndex === lastDisplayedTabIndex ||
      customTabIndex === lastDisplayedTabIndex - 1
    )
      return "left";
  }
  return "right";
};

const DialogActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
  & [data-component="icon"] {
    color: ${({ theme }) => theme?.navigation?.actions?.textColor};
  }
  &:hover {
    background-color: var(--colorsActionMajor600);
    color: ${({ theme }) => theme?.navigation?.actions?.hoveredTextColor};
  }
  &:hover button {
    color: ${({ theme }) => theme?.navigation?.actions?.hoveredTextColor};
  }
  &:hover > * {
    color: ${({ theme }) => theme?.navigation?.actions?.hoveredTextColor};
  }
`;

const EmptySpanSpacing = styled.span`
  width: 24px;
  height: 24px;
`;

const CustomTab = ({
  id,
  isSelected,
  setSelected,
  switchFavourite,
  openFavouriteModal,
  closeFavouriteModal,
  switchFavouriteModal,
  tab,
  theme,
  rmvTab,
  facet,
  refButtonAction,
  control,
  doDownloadUrl,
  children,
  customTabIndex,
  currentSlideIndex,
  trackState,
  ...otherProps
}) => {
  const tabRef = useRef();

  return (
    <Tab
      {...otherProps}
      ref={tabRef}
      onClick={(e) => {
        e?.preventDefault?.();
        setSelected(id);
      }}
      selected={isSelected}
    >
      <TabContainer role="tab-container">
        <FavouriteAndTitleContainer>
          {tab.controller !== "loadSearchPanel" ? (
            <IconButton onAction={(e) => switchFavourite(tab, e)}>
              <Icon
                type={tab.favourite ? "favourite" : "favourite_lined"}
                color={
                  tab.favourite
                    ? theme?.icon?.color?.favourite
                    : theme?.icon?.color?.default
                }
              />
            </IconButton>
          ) : <EmptySpanSpacing />}
          <TabTitle role="tab-tilte">
            <Typography
              variant="segment-header-small"
              fontWeight="500"
              fontSize="14px"
              lineHeight="16px"
            >
              <TitleEllipsisContainer>
                <Ellipsis text={tab.title} lines={2} />
              </TitleEllipsisContainer>
            </Typography>
          </TabTitle>
        </FavouriteAndTitleContainer>
        <IconsContainer>
          <IconButton
            onAction={(e) => {
              rmvTab(tab, e);
            }}
          >
            <Icon type="cross" />
          </IconButton>
          <FavouritePopoverContainerStyle tab={tab} facet={facet}>
            <PopoverContainer
              noCloseIcon
              open={tab.isFavouriteModalOpen}
              position={getActionsPopoverPosition({
                trackState,
                customTabIndex,
                currentSlideIndex,
              })}
              onOpen={(e) => openFavouriteModal(tab, e)}
              onClose={(e) => closeFavouriteModal(tab, e)}
              renderOpenComponent={() => (
                <IconButton
                  ref={refButtonAction}
                  onAction={(e) => switchFavouriteModal(tab, e)}
                >
                  <Icon type="dropdown" />
                </IconButton>
              )}
            >
              <ActionsContainer
                refButtonAction={refButtonAction}
                setOpen={(opened) => {
                  closeFavouriteModal(tab);
                }}
              >
                {tab?.actions?.map((action, key) =>
                  action.dialog ? (
                    <DialogActionButtonContainer>
                      <Icon type={action?.icon} />
                      <FacetDialogButton
                        tabMode
                        value={action}
                        control={control}
                        facet={facet}
                      />
                    </DialogActionButtonContainer>
                  ) : (
                    <ButtonAction
                      facet={facet}
                      control={control}
                      action={action}
                      afterActionCallback={() => {
                        closeFavouriteModal(tab);
                      }}
                      doDownloadUrl={doDownloadUrl}
                      id={key}
                      key={key}
                      buttonProps={{
                        buttonType: "tertiary",
                        iconType: action.icon || undefined,
                        pl: "0",
                        pr: "0",
                        ml: "0",
                        mr: "0",
                      }}
                    />
                  )
                )}
              </ActionsContainer>
            </PopoverContainer>
          </FavouritePopoverContainerStyle>
        </IconsContainer>
      </TabContainer>
    </Tab>
  );
};

CustomTab.tabsRole = "Tab";

const CustomPrevArrow = ({ sliderRef, trackState, currentSlideIndex }) => {
  const slideCount = trackState?.slideCount;
  const slidesToShow = trackState?.slidesToShow;
  return (
    <div>
      <IconButton
        disabled={slideCount <= slidesToShow || currentSlideIndex === 0}
        onAction={(e) => {
          e.preventDefault();
          e.stopPropagation();
          sliderRef?.current?.slickPrev?.();
        }}
      >
        <Icon type="chevron_left" />
      </IconButton>
    </div>
  );
};

const CustomNextArrow = ({ sliderRef, trackState, currentSlideIndex }) => {
  const slideCount = trackState?.slideCount;
  const slidesToShow = trackState?.slidesToShow;
  return (
    <div>
      <IconButton
        disabled={
          slideCount <= slidesToShow ||
          currentSlideIndex === slideCount - slidesToShow
        }
        onAction={(e) => {
          e.preventDefault();
          e.stopPropagation();
          sliderRef?.current?.slickNext?.();
        }}
      >
        <Icon type="chevron_right" />
      </IconButton>
    </div>
  );
};

const CloseAllTabsButton = ({ facet, control }) => {
  return (
    <Tooltip message="Tout fermer">
      <IconButton
        onAction={(e) => {
          e.preventDefault();
          e.stopPropagation();
          closeAllTabsCallback({ facet, control });
        }}
      >
        <Icon type="cross" />
      </IconButton>
    </Tooltip>
  );
};

const getSelectCallback =
  ({ setTabsPopoverOpen, setTabSelected, trackState, sliderRef }) =>
  (customTab, customTabIndex) => {
    const { slideCount, currentSlide, slidesToShow } = trackState;
    const slideMin = Math.max(customTabIndex - slidesToShow + 1, 0);
    const slideMax = customTabIndex;
    setTabSelected(customTab.id);
    if (currentSlide < slideMin) {
      sliderRef.current?.slickGoTo(slideMin);
    } else if (currentSlide > slideMax) {
      sliderRef.current?.slickGoTo(slideMax);
    }
    setTabsPopoverOpen(false);
  };

const TabList = ({
  facet,
  control,
  state,
  rmvTab,
  //
  openFavouriteModal,
  closeFavouriteModal,
  switchFavouriteModal,
  switchFavourite,
  //
  doDownloadUrl,
  theme,
  selectedTabId,
  setTabSelected,
  hasHome,
  isHomeSelected,
  setHomeSelected,
}) => {
  const refButtonAction = useRef();
  const sliderRef = useRef();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [tabsPopoverOpen, setTabsPopoverOpen] = useState(false);
  const [trackState, setTrackState] = useState(
    sliderRef?.current?.innerSlider?.track?.props
  );
  const { height: navLeftHeight, width: navLeftWidth } =
    useNavLeftCurrentSizeContext();
  const hasCloseAll = facet?.tabs?.onCloseAll;

  useLayoutEffect(() => {
    /* dirty timeout, but necessary because slider
    does not expose callback for each internal change */
    setTimeout(() => {
      setTrackState(sliderRef?.current?.innerSlider?.track?.props);
    }, 100);
  }, [currentSlideIndex, state.tabs, navLeftWidth]);

  useLayoutEffect(() => {
    const slideCount = trackState?.slideCount;
    const slidesToShow = trackState?.slidesToShow;
    const indexToGo = slideCount - slidesToShow;
    if (indexToGo >= 0 && indexToGo < currentSlideIndex) {
      sliderRef?.current?.slickGoTo(indexToGo);
    }
  }, [currentSlideIndex, trackState]);

  useLayoutEffect(() => {
    const memoizedCallback = () => {
      const slideCount = trackState?.slideCount;
      const slidesToShow = trackState?.slidesToShow;
      // +1 because current slideCount doesn't take in account slide just added
      const lastSlideIndex = slideCount - slidesToShow + 1;
      sliderRef?.current?.slickGoTo(lastSlideIndex);
    };
    eventBus.on("navigation-tab-added-in-state", memoizedCallback);
    return () => {
      eventBus.remove("navigation-tab-added-in-state", memoizedCallback);
    };
  }, [trackState]);

  return (
    <ReactTabsTabList>
      <TabListContainer navLeftWidth={navLeftWidth}>
        {hasHome ? (
          <HomeTab
            facet={facet}
            control={control}
            setSelected={setHomeSelected}
            isSelected={isHomeSelected}
          />
        ) : null}
        <PrevArrowContainer role="prev-arrow-container">
          <CustomPrevArrow
            sliderRef={sliderRef}
            trackState={trackState}
            currentSlideIndex={currentSlideIndex}
          />
        </PrevArrowContainer>
        <TabSliderContainer
          tabSelectionDropdownDisplayed={!!state.tabs?.length}
          navLeftHeight={navLeftHeight}
          navLeftWidth={navLeftWidth}
        >
          <Slider
            ref={sliderRef}
            key={navLeftWidth}
            afterChange={(current) => setCurrentSlideIndex(current)}
            slidesToShow={11}
            slidesToScroll={1}
            speed={500}
            infinite={false}
            arrows={false}
            responsive={[
              {
                breakpoint: 1805 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 10,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1650 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 9,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1495 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 8,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1340 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1185 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1030 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 875 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 720 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 565 + getNavLeftWidthNumber(navLeftWidth),
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {state.tabs.map((tab, customTabIndex) => (
              <CustomTab
                id={tab.id}
                setSelected={setTabSelected}
                isSelected={selectedTabId === tab.id}
                switchFavourite={switchFavourite}
                openFavouriteModal={openFavouriteModal}
                closeFavouriteModal={closeFavouriteModal}
                switchFavouriteModal={switchFavouriteModal}
                tab={tab}
                theme={theme}
                rmvTab={rmvTab}
                facet={facet}
                refButtonAction={refButtonAction}
                control={control}
                customTabIndex={customTabIndex}
                trackState={trackState}
                currentSlideIndex={currentSlideIndex}
                doDownloadUrl={doDownloadUrl}
              />
            ))}
          </Slider>
        </TabSliderContainer>
        <NextAndDropdownAndCloseAllContainer>
          <CustomNextArrow
            sliderRef={sliderRef}
            trackState={trackState}
            currentSlideIndex={currentSlideIndex}
          />
          {state.tabs?.length ? (
            <PopoverContainer
              noCloseIcon
              open={tabsPopoverOpen}
              position="left"
              onOpen={() => {
                setTabsPopoverOpen(true);
              }}
              onClose={() => {
                setTabsPopoverOpen(false);
              }}
              renderOpenComponent={() => (
                <IconButton
                  onAction={() => {
                    setTabsPopoverOpen(
                      (previousPopoverOpen) => !previousPopoverOpen
                    );
                  }}
                >
                  <Icon type="dropdown" />
                </IconButton>
              )}
            >
              <SelectableList
                initialData={
                  state.tabs?.map?.((tab) => ({
                    ...tab,
                    selected: selectedTabId === tab.id,
                  })) || []
                }
                selectCallback={getSelectCallback({
                  setTabsPopoverOpen,
                  setTabSelected,
                  trackState,
                  sliderRef,
                })}
                titleProperty="title"
                dynamicSize
              />
            </PopoverContainer>
          ) : null}
          {hasCloseAll ? (
            <CloseAllTabsButton facet={facet} control={control} />
          ) : null}
        </NextAndDropdownAndCloseAllContainer>
      </TabListContainer>
    </ReactTabsTabList>
  );
};

export default TabList;
