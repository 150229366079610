import React, { useState, useReducer, useEffect } from "react";
import Layout from "../../layout/Layout";
import Dialog from "carbon-react/lib/components/dialog";
import { DialogController } from "../../common/Controller";
import eventBus from "../../events/eventBus";

const getOnCancel = (onCancel) => (e) => {
  e.stopPropagation();
  onCancel(false);
};

const FacetDialog = ({
  facet,
  control,
  value,
  isOpen,
  onCancel,
  onDone,
  changeCount,
  initialObject,
  refreshEvents,
  ...props
}) => {
  const defaultLayout = {
    name: "layout",
    css: "dialog",
    children: [
      { name: "topbar", css: "dialog", children: [] },
      {
        name: "body",
        css: "dialog",
        children: [
          {
            name: "main",
            css: "dialog",
            children: [
              { name: "main-header", css: "dialog", children: [] },
              { name: "main-body", css: "dialog", children: [] },
              { name: "main-footer", css: "dialog", children: [] },
            ],
          },
        ],
      },
    ],
  };

  const disableAutoFocus = value.disableAutoFocus ?? false;

  const [state, setState] = useReducer(rstate, {
    meta: {},
    facets: [],
    layout: defaultLayout,
    roundTrip: 0,
    isLoading: true,
    isClosing: false,
  });
  const [refresh, setRefresh] = useState(0);

  function rstate(state, data) {
    //console.log('dialog-setState-old',state,data)
    let newState = {
      meta: {},
      facets: [],
      layout: defaultLayout,
      roundTrip: 0,
      isLoading: false,
      isClosing: false,
    };
    switch (data.action) {
      case "set":
        // set query result
        newState = { ...newState, ...data.data };
        newState.meta = data.data.meta || { itemId: "", itemsPerPage: 10 };
        newState.layout = data.data.layout || defaultLayout;
        newState.facets = data.data.facets || [];
        newState.roundTrip = state.roundTrip + 1;
        newState.itemId = newState.meta.itemId;
        newState.isClosing = newState.meta.result ? true : false;
        break;
      default:
    }
    //console.log("dialog-setState-new",newState,data);
    return newState;
  }

  const getDialogSize = () => {
    if (value.dialog && value.dialog.size) return value.dialog.size;
    if (value.size) return value.size;
    if (state.meta?.dialog?.size) return state.meta.dialog.size;
    if (state.size) return state.size;
    return "medium"; // default size 750px
  };
  const getDialogTitle = () => {
    if (value.dialog && value.dialog.title) return value.dialog.title;
    if (value.title) return value.title;
    if (state.meta?.dialog?.title) return state.meta.dialog.title;
    if (state.title) return state.title;
    return "";
  };
  const getDialogSubTitle = () => {
    if (value.dialog && value.dialog.subTitle) return value.dialog.subTitle;
    if (state.meta?.dialog?.subTitle) return state.meta.dialog.subTitle;
    return "";
  };

  let controller = new DialogController({
    facet,
    control,
    value,
    state,
    setState,
  });

  useEffect(() => {
    // will be called only when isOpen change or changeCount change
    if (isOpen) {
      if (initialObject) {
        controller.selectObject(initialObject);
      }
      controller.fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, initialObject, changeCount]);

  ////////////////////////////////////////////////////////
  // Close dialog on backend response
  ////////////////////////////////////////////////////////
  if (state.meta.result) {
    if (
      typeof state.meta.result.action === "string" &&
      state.meta.result.action === "cancel"
    ) {
      onCancel();
    } else {
      onDone(state.meta.result);
    }
    setTimeout(controller.dispatch({ action: "reset" }, 1));
  }

  const isHidden = () => {
    return state.isLoading || state.isClosing;
  };

  useEffect(() => {
    if (Array.isArray(refreshEvents)) {
      const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
        callback: () => {
          // on rafraichis complètement le dialog lors de ces events
          controller.submit();
        },
        event: refreshEvent,
      }));

      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  });

  return (
    <React.Fragment>
      {isHidden() ? null : (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dialog
            disableAutoFocus={disableAutoFocus}
            open={isOpen}
            onCancel={getOnCancel(onCancel)}
            title={getDialogTitle()}
            subtitle={getDialogSubTitle()}
            size={getDialogSize()}
          >
            <Layout
              isLoading={state.isLoading}
              facets={state.facets}
              control={controller}
              layout={state.layout}
              {...props}
            />
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
};

export default FacetDialog;
