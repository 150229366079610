import React, { useState } from 'react'
import I18n from "i18n-js";
import { cloneDeep, mapValues } from "lodash"
import InputButton from './InputButton'
import FacetDialog from '../facets/FacetDialog'


const IconCodeEditor = ({ facet, control, value, ...props }) => {

  const [code, setCode] = useState(value.value);
  const [messages, setMessages] = useState([]);
  const [changeCount, setChangeCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen(!isOpen)
  }

  const editorCallBack = (req) => {

    if (req.filters) {
      req.filters.forEach((v)=>{
          if (v.id===1) {
            setCode(v.value)
          }
      })
    }

    if ((req.meta) && (req.meta.action)) {
      if (req.meta.action.name==='compile') {

        let r = {};
        r.meta = cloneDeep(control.meta);
        r.meta.action = {name:req.meta.action.name,value:value,param:code};

        control.callMethod('main', r, (resp)=> {
          if (resp.facets) {
            resp.facets.forEach((f) => {
              f.values.forEach((v)=>{
                if (v.class==='messages') {
                  setMessages(v.values);
                  setChangeCount(changeCount+1);
                }
              })
            })
          }
        });
      }

      if (req.meta.action.name==='cancel') {
        setIsOpen(false);
      }
      if (req.meta.action.name==='apply') {
        control.setFacetValue(facet,value,code);
        setIsOpen(false);
      }
    }

    let resp = {
        meta: {id:1},
        facets:[
            {id:0, type:'list', position:'main-body', values:[
              {facet:0, id:0, class:'toolBar', buttons:[{title:I18n.t("codeEditor.compile", { defaultValue: "Compile"}),icon:'compile',action:'compile'},] },
              {facet:0, id:1, class:'codeEditor', autoSubmit:true, editor:{code:code} },
              {facet:0, id:2, class:'messages', values:messages },
          ]},
          {id:1, type:'list', position:'main-footer',  orientation:'horz', values:[
            {facet:1, id:0, class:'button', button:{title:I18n.t("dialog.cancel", { defaultValue: "Cancel"}), action:'cancel'} },
            {facet:2, id:1, class:'button', button:{title:I18n.t("dialog.apply", { defaultValue: "Apply"}), action:'apply'} }
        ]}
      ],
    }
    return resp;
}

  const getDialogValue = () => {
    return {facet:facet.id, id:value.id, dialog:{title:facet.title, callBack:editorCallBack}};
  }

  return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={setOpen} placeHolder={I18n.t("codeEditor.placeHolder", { defaultValue: "Edit..." })} tooltipMessage={I18n.t("codeEditor.tooltipMessage", { defaultValue: "Script has been edited..." })}/>
        <FacetDialog facet={facet} control={control} value={getDialogValue()} isOpen={isOpen} onCancel={setOpen} changeCount={changeCount} {...props}/>
    </React.Fragment>
  )
}

export default IconCodeEditor
