import React, { useState, useEffect, useRef } from "react";
import useTheme from "../hooks/useTheme";
import styled from "styled-components";
import PopoverContainer from "./PopoverContainer";
import Icon from "carbon-react/lib/components/icon";
import LayoutContent from "../../layout/LayoutContent";
import eventBus from "../../events/eventBus";
import Tooltip from "carbon-react/lib/components/tooltip";
import Ellipsis from "./Ellipsis";
import FlagIcon from "./FlagIcon";

const getOnSwitch = (setOpen) => (e) => {
  e.preventDefault();
  setOpen((previousOpen) => !previousOpen);
};

const getPopoverContent = (value) => {
  if (value?.popover?.facets) return value.popover.facets;
  return [];
};
const getPopoverLayout = (value) => {
  if (value?.popover?.layout) return value.popover.layout;
  return null;
};

const getSelectedItemFromValue = (value) =>
  value?.values?.find((item) => item.selected);

export const getTooltipMessageFromItem = ({ item, titleProperty = "title" }) =>
  item.company?.name && item[titleProperty]
    ? `${item[titleProperty]} - ${item.company.name}`
    : item[titleProperty]
    ? item[titleProperty]
    : "";

const SelectContainer = styled.div`
  &:focus {
    outline: 3px solid var(--colorsSemanticFocus500);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, darkMode, isCompanySelect }) =>
    isCompanySelect
      ? theme?.navigation?.topBarSelect?.companySelectBackgroundColor
      : darkMode
      ? theme?.navigation?.topBarSelect?.backgroundColor
      : theme?.pod?.tertiaryBackground};
  min-width: var(--minWidth);
  & .layout-body {
    padding: 0;
    width: 311px;
  }
  div[data-element="popover-container-content"] {
    padding: 0;
    width: 311px;
    min-width: 311px;
  }
  div[data-element="popover-container-content"] > div:first-child {
    margin: 0;
  }
  ul[role="facet-list-container"] {
    padding: 0 !important;
    margin: 0 !important;
    width: 311px;
  }
  ul[role="facet-list-container"] > li {
    margin: 0 !important;
    padding: 0;
  }
`;

const IconAndTitleContainer = styled.div`
  ${({ darkMode, theme }) => (darkMode ? `color: ${theme.colors.white};` : "")}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
`;

const SelectedTitleContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 123px;
`;

const LayoutContentContainer = styled.div`
  background-color: ${({ theme, darkMode }) =>
    darkMode ? theme?.colors?.black : "inherit"};
`;

const PopoverDropdown = styled.div``;

const getHandleKeyDown =
  ({ setOpen, refSelectContainer }) =>
  (e) => {
    const activeElement = document.activeElement;
    switch (e.key) {
      case "Enter":
      case "ArrowDown":
        if (activeElement?.isEqualNode(refSelectContainer?.current)) {
          e.preventDefault?.();
          e.stopPropagation?.();
          setOpen(true);
        }
        break;
      case "Escape":
      case "ArrowUp":
        if (refSelectContainer?.current?.contains(activeElement)) {
          e.preventDefault?.();
          e.stopPropagation?.();
          setOpen(false);
        }
        break;
      default:
    }
  };

const getContainerStyle = (value) => {
  const mw = value.minWidth ? value.minWidth : 0;
  return {
    "--minWidth": mw + "px",
  };
};

const getEllipsisWidth = (value) => {
  return value.minWidth ? value.minWidth : 128;
};

const getOnSelectItem =
  ({ control, facet, value, setSelectedItem }) =>
  (newValue) => {
    control.setFacetValue(facet, value, newValue);
    setSelectedItem(newValue);
  };

const ItemSelect = ({ value, facet, control, ...props }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    getSelectedItemFromValue(value)
  );
  const refButtonAction = useRef();
  const refSelectContainer = useRef();
  const { setCurrentEvent, closeEvent, darkMode, isCompanySelect } = value;
  let { titleProperty } = value;
  if (!titleProperty) titleProperty = "title";

  useEffect(() => {
    if (setCurrentEvent) {
      const memoizedCallback = (dataNewSelectedItem) => {
        const newSelectedItem = dataNewSelectedItem?.values?.[0];
        if (newSelectedItem) setSelectedItem(newSelectedItem);
      };

      eventBus.on(setCurrentEvent, memoizedCallback);

      return () => {
        eventBus.remove(setCurrentEvent, memoizedCallback);
      };
    }
  }, [setCurrentEvent]);

  useEffect(() => {
    if (closeEvent) {
      const memoizedCallback = () => {
        setOpen(false);
      };
      eventBus.on(closeEvent, memoizedCallback);

      return () => {
        eventBus.remove(closeEvent, memoizedCallback);
      };
    }
  }, [closeEvent]);

  // close-item-select is event used to close all items select, not specifically one
  useEffect(() => {
    const closeItemSelectCallback = () => {
      setOpen(false);
    };
    eventBus.on("close-item-select", closeItemSelectCallback);

    return () => {
      eventBus.remove("close-item-select", closeItemSelectCallback);
    };
  }, []);

  return (
    <SelectContainer
      darkMode={darkMode}
      isCompanySelect={isCompanySelect}
      ref={refSelectContainer}
      role="select-container"
      tabIndex="0"
      onKeyDown={getHandleKeyDown({ setOpen, refSelectContainer })}
      style={getContainerStyle(value)}
      theme={theme}
    >
      <IconAndTitleContainer darkMode={darkMode}>
        {selectedItem?.lcid ? (
          <FlagIcon code={`${selectedItem.lcid}`} />
        ) : selectedItem?.icon ? (
          <Icon
            type={selectedItem.icon}
            color={darkMode ? theme.colors.white : undefined}
            fontSize="small"
          />
        ) : null}
        {selectedItem && !selectedItem.lcid ? (
          selectedItem.item ? (
            <Tooltip
              message={getTooltipMessageFromItem({
                item: selectedItem,
                titleProperty,
              })}
              position="bottom"
            >
              <SelectedTitleContainer>
                {selectedItem[titleProperty]}
              </SelectedTitleContainer>
            </Tooltip>
          ) : (
            <Ellipsis
              width={getEllipsisWidth(value)}
              text={selectedItem[titleProperty]}
            />
          )
        ) : (
          ""
        )}
      </IconAndTitleContainer>
      <PopoverContainer
        position="left"
        open={open}
        renderOpenComponent={({ isOpen }) => (
          <PopoverDropdown
            ref={refButtonAction}
            role="icon-dropdown"
            onClick={getOnSwitch(setOpen)}
          >
            <Icon
              color={darkMode ? theme.colors.white : undefined}
              type={isOpen ? "caret_up" : "dropdown"}
            />
          </PopoverDropdown>
        )}
        renderCloseComponent={() => null}
      >
        <LayoutContentContainer darkMode={darkMode}>
          <LayoutContent
            refButtonAction={refButtonAction}
            setOpen={setOpen}
            open={open}
            layout={getPopoverLayout(value)}
            content={getPopoverContent(value)}
            control={control}
            focusSearchAtOpening
            onSelectItem={getOnSelectItem({
              control,
              facet,
              value,
              setSelectedItem,
            })}
            {...props}
          />
        </LayoutContentContainer>
      </PopoverContainer>
    </SelectContainer>
  );
};

export default ItemSelect;
