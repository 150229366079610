import React, { useState, useEffect, useRef } from "react";
import {
  FlatTable,
  FlatTableBody,
  FlatTableRow,
  FlatTableCell,
} from "carbon-react/lib/components/flat-table";
import { isEqual } from "lodash";
import eventBus from "../../events/eventBus";
import Icon from "carbon-react/lib/components/icon";
import styled from "styled-components";
import useTheme from "../hooks/useTheme";
import Tooltip from "carbon-react/lib/components/tooltip";
import Ellipsis from "./Ellipsis";
import FlagIcon from "./FlagIcon";
import { getTooltipMessageFromItem } from "./ItemSelect";
import I18n from "i18n-js";
import Loader from "carbon-react/lib/components/loader";

const handleSelectRow = ({
  item,
  itemIndex,
  setData,
  selectCallback,
  selectPropName,
}) => {
  setData((previousData) => {
    const newData = previousData.map((currentItem) => ({
      ...currentItem,
      [selectPropName]: isEqual(item, currentItem) ? true : undefined,
    }));

    if (!isEqual(previousData, newData)) selectCallback?.(item, itemIndex);

    return newData;
  });
};

const TableContainer = styled.div`
  ${({ dynamicSize }) =>
    dynamicSize ? "max-height: 322px;" : "height: 322px;"}
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid ${({ theme }) => theme?.colors?.divider};
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.divider};
  & [data-element="flat-table-cell"] {
    border-bottom: none;
  }
  & [data-element="flat-table-cell"] > div {
    padding: 0;
    padding-left: 8px;
  }
  & tr[data-element="flat-table-row"]:focus {
    outline: 3px solid var(--colorsSemanticFocus500);
    outline-offset: -2px;
  }
`;

const FlatTableRowContainer = styled.div`
  display: contents;
  & td[data-element="flat-table-cell"] {
    background-color: ${({ theme, selected, darkMode }) =>
      selected
        ? darkMode
          ? theme?.customMenu?.selectedBackground
          : theme?.customMenu?.selectedBackgroundLight
        : darkMode
        ? theme?.customMenu?.background
        : theme?.customMenu?.backgroundLight};
    color: ${({ theme, darkMode }) =>
      darkMode ? theme?.customMenu?.color : theme?.customMenu?.colorLight};
  }
  & td[data-element="flat-table-cell"]:hover {
    background-color: ${({ theme, darkMode }) =>
      darkMode
        ? theme?.customMenu?.hoverBackground
        : theme?.customMenu?.hoverBackgroundLight};
    color: ${({ theme }) => theme?.customMenu?.hoverColor};
  }
`;

const TitleContainer = styled.div`
  margin-left: ${({ haveIcon }) => (haveIcon ? "8px" : "32px")};
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedTitleContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ haveIcon }) => (haveIcon ? "249px" : "273px")};
`;

const SelectableList = ({
  darkMode,
  initialData = [],
  selectCallback,
  selectPropName = "selected",
  refreshEvents,
  loading,
  titleProperty,
  dynamicSize,
  focusFirstAtOpening,
}) => {
  const [data, setData] = useState([...initialData]);
  const theme = useTheme();
  const tableContainerRef = useRef();

  useEffect(() => {
    setData([...initialData]);
  }, [initialData]);

  useEffect(() => {
    if (refreshEvents?.length) {
      const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
        callback: (newData) => {
          if (newData.values) {
            setData((previousData) =>
              isEqual(previousData, newData.values)
                ? previousData
                : newData.values
            );
          }
        },
        event: refreshEvent,
      }));

      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  }, [refreshEvents]);

  /* On rend la div role="region" de carbon non focusable par tab
     Ainsi quand on appuie sur tab lorsque la zone de recherche est en focus,
     le focus passe au premier élément de la liste */
  useEffect(() => {
    if (tableContainerRef.current) {
      const regionDiv =
        tableContainerRef.current.querySelector('[role="region"]');
      regionDiv?.setAttribute?.("tabindex", "-1");
    }
  }, [loading]);

  /* quand la data change,
    on rend le premier élément de la liste focusable par la touche tab,
    et les autres non */
  useEffect(() => {
    if (tableContainerRef.current) {
      const rowTrs = tableContainerRef.current.querySelectorAll(
        '[data-element="flat-table-row"]'
      );
      if (rowTrs?.length) {
        const rowTrsArray = Array.from(rowTrs);
        const firstRow = rowTrsArray[0];
        firstRow?.setAttribute?.("tabindex", "0");
        const allRowsExceptedFirstOne = rowTrsArray.slice(1);
        allRowsExceptedFirstOne.forEach((row) => {
          row.setAttribute?.("tabindex", "-1");
        });
      }
    }
  }, [data]);

  const handleOnKeyDown = (e) => {
    const activeElement = document.activeElement;
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault?.();
        e.stopPropagation?.();
        const previousElement =
          activeElement?.parentElement?.previousSibling?.firstChild;
        if (
          previousElement?.getAttribute?.("data-element") === "flat-table-row"
        ) {
          previousElement.focus?.();
        }
        break;
      case "ArrowDown":
        e.preventDefault?.();
        e.stopPropagation?.();
        const nextElement =
          activeElement?.parentElement?.nextSibling?.firstChild;
        if (nextElement?.getAttribute?.("data-element") === "flat-table-row") {
          nextElement.focus?.();
        }
        break;
      default:
    }
  };

  useEffect(() => {
    if (focusFirstAtOpening) {
      setTimeout(() => {
        const firstTr = tableContainerRef.current?.querySelector("tr");
        if (firstTr) {
          firstTr.focus();
        }
      }, 100);
    }
  }, [focusFirstAtOpening]);

  return (
    <TableContainer
      ref={tableContainerRef}
      onKeyDown={handleOnKeyDown}
      theme={theme}
      tabIndex="-1"
      dynamicSize={dynamicSize}
    >
      {loading ? (
        <Loader />
      ) : (
        <FlatTable>
          <FlatTableBody>
            {data.length ? (
              data.map((item, itemIndex) => {
                const haveIcon = !!(item.icon || item.lcid);
                return (
                  <FlatTableRowContainer
                    darkMode={darkMode}
                    selected={item.selected}
                  >
                    <FlatTableRow
                      onClick={() =>
                        handleSelectRow({
                          item,
                          itemIndex,
                          setData,
                          selectCallback,
                          selectPropName,
                        })
                      }
                      selected={item.selected}
                    >
                      <FlatTableCell id={`ft-row-${itemIndex + 1}-cell-1`}>
                        <IconAndTitleContainer>
                          {item.lcid ? (
                            <FlagIcon code={`${item.lcid}`} />
                          ) : item.icon ? (
                            <Icon
                              type={item.icon}
                              color={theme?.colors?.white}
                              fontSize="small"
                            />
                          ) : null}
                          <TitleContainer haveIcon={haveIcon}>
                            {item.company ? (
                              <Tooltip
                                message={getTooltipMessageFromItem({ item })}
                                position="bottom"
                              >
                                <SelectedTitleContainer haveIcon={haveIcon}>
                                  {item[titleProperty]}
                                </SelectedTitleContainer>
                              </Tooltip>
                            ) : (
                              <Ellipsis
                                width={haveIcon ? 249 : 273}
                                text={item[titleProperty]}
                              />
                            )}
                          </TitleContainer>
                        </IconAndTitleContainer>
                      </FlatTableCell>
                    </FlatTableRow>
                  </FlatTableRowContainer>
                );
              })
            ) : (
              <FlatTableRow>
                <FlatTableCell>{I18n.t("noResult")}</FlatTableCell>
              </FlatTableRow>
            )}
          </FlatTableBody>
        </FlatTable>
      )}
    </TableContainer>
  );
};

export default SelectableList;
