import React, {
  useReducer,
  useEffect,
  useState,
  useCallback,
  useRef,
  Component,
  useContext,
} from "react";
import I18n from "i18n-js";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import eventBus from "../../../events/eventBus";
import NavTabDialog from "./NavTabDialog";
import NavTabContentDefault from "./TabContentDefault";
import NavHomeContent from "./TabHomeContent";
import NavTabContentIFrame from "./TabContentIframe";
import NavTabContentPanel from "./TabContentPanel";
import TabList from "./TabList";
import { navLeftWidth, navLeftHeight } from "../FacetNavigLeft";
import { Tabs, TabPanel } from "react-tabs";
import useTheme from "../../hooks/useTheme";
import Dom from "../../../common/Dom"
import Confirm from "carbon-react/lib/components/confirm";
import useEvents from "./useEvents";

const NavLeftCurrentSizeContext = React.createContext({});
export const useNavLeftCurrentSizeContext = () =>
  useContext(NavLeftCurrentSizeContext);

const TabContentContext = React.createContext({});
export const useTabContentContext = () => useContext(TabContentContext);

const getIFrameHeightFromNavigLeftCurrentHeight = ({
  navigLeftCurrentHeight,
  theme,
}) =>
  `max(calc(100vh - ${theme.navigation.topBar.height} - ${theme.navigation.tabs.height}),calc(${navigLeftCurrentHeight} - ${theme.navigation.tabs.height}))`;

const getSetTabSelected = ({ setTabs, state }) => (id) => {
  setTabs({ action: "setTabId", tabId: id });
};

const getSetHomeSelected = ({ setTabs, state }) => () => {
  setTabs({ action: "setHome" });
};

const NavContentWrapper = styled.div`
  max-width: calc(
    100vw - (100vw - 100%) -
      ${({ navigLeftCurrentWidth }) => navigLeftCurrentWidth}
  );
  height: max(
    calc(100vh - ${({ theme }) => theme?.navigation.topBar.height}),
    ${({ navigLeftCurrentHeight }) => navigLeftCurrentHeight}
  );

  & .react-tabs__tab-panel {
    height: max(
      calc(
        100vh - ${({ theme }) => theme?.navigation.topBar.height} -
          ${({ theme }) => theme?.navigation.tabs.height}
      ),
      calc(
        ${({ navigLeftCurrentHeight }) => navigLeftCurrentHeight} -
          ${({ theme }) => theme?.navigation.tabs.height}
      )
    );
  }
`;

const NavContainer = styled.div`
  width: calc(
    100vw - (100vw - 100%) -
      ${({ navigLeftCurrentWidth }) => navigLeftCurrentWidth}
  );
  max-width: calc(
    100vw - (100vw - 100%) -
      ${({ navigLeftCurrentWidth }) => navigLeftCurrentWidth}
  );
  position: absolute;
  height: max(
    calc(100vh - ${({ theme }) => theme?.navigation.topBar.height}),
    ${({ navigLeftCurrentHeight }) => navigLeftCurrentHeight}
  );
  max-height: max(
    calc(100vh - ${({ theme }) => theme?.navigation.topBar.height}),
    ${({ navigLeftCurrentHeight }) => navigLeftCurrentHeight}
  );
`;

const NavTabsWrapper = styled(NavContainer)`
  & button[data-element="select-tab"] {
    height: auto;
  }
  & .react-tabs {
    height: 100%;
  }
  & .react-tabs__tab-list {
    margin: 0;
    height: ${({ theme }) => theme?.navigation.tabs.height};
    position: relative;
    z-index: 1;
  }
  & .react-tabs__tab {
    top: -0.5px;
    padding: 0;
    max-height: ${({ theme }) => theme?.navigation.tabs.height};
  }
`;

const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const IframeContainer = styled.div`
  height: calc(
    100vh - ${({ theme }) => theme?.navigation.topBar.height} -
      ${({ theme }) => theme?.navigation.tabs.height}
  );
  padding-left: 8px;
  overflow: hidden;
`;

const ConfirmContentWrapper = styled.div`
  padding: 0px 32px 32px;
`;

export const doDownloadUrl = (url, name) => {
    Dom.download(url,name);
};

//////////////////////////////////////////////////////////////////////////

const FacetNavigTabs = ({ facet, control, value, ...props }) => {
  const theme = useTheme();
  const eventHandlersSetter = useEvents();
  const tabsReducer = (state, data) => {
    let newState = cloneDeep(state);
    let cTab = newState.tabId != null ? newState.tabs[newState.tabIndx] : null;
    let aTabId = data.data ? data.data.id : null;

    //console.log('tabs-old',state,data,aTabId,cTab);

    if ((!newState.enable) && (data.action!=="enable"))  {
        // tab action are disabled except change enable state
        return newState;
    }

    //
    switch (data.action) {
      case "enable":
        newState.enable = data.data.enable;
        break;
      case "setTabId":
        if (newState.tabId !== data.tabId) {
          if (cTab) cTab.isFavouriteModalOpen = false;
          newState.tabId = data.tabId;
        }
        break;
      case "set":
        newState.tabs = data.tabs.map((tab) => ({
          ...tab,
          isFavouriteModalOpen: false,
        }));
        if (newState.tabs.length > 0) {
          newState.tabId = newState.tabs[0].id;
        }
        break;
      case "add":
        let tab = cloneDeep(data.data);
        tab.isFavouriteModalOpen = false;
        if (!tab.id) tab.id = newState.tabs.length.toString();
        newState.tabId = tab.id;
        newState.tabs.push(tab);
        eventBus.emit("navigation-tab-added-in-state", {});
        break;
      case "rmv":
        const selectedTabId = newState.tabId;
        const newTabs = newState.tabs.filter((tab) => tab.id !== aTabId);
        const newTabId =
          selectedTabId === aTabId
            ? newTabs.length
              ? newState.tabs[newState.tabIndx - 1]?.id ?? newTabs[0].id
              : null
            : selectedTabId;
        newState.tabs = newTabs;
        newState.tabId = newTabId;
        break;
      case "closeAll":
        newState.tabs = [];
        newState.tabId = null;
        break;
      case "update":
        if (aTabId) {
          newState.tabs = newState.tabs.map((tab) => ({
            ...tab,
            title: tab.id===aTabId?data.data.title:tab.title,
          }));
        }
      break;
      case "setHome":
        newState.tabId = null;
        break;
      //
      case "openFavouriteModal":
        if (aTabId) {
          newState.tabId = aTabId;
          newState.tabs = newState.tabs.map((tab) => ({
            ...tab,
            isFavouriteModalOpen: tab.id === aTabId,
          }));
        }
        break;
      
      case "closeFavouriteModal":
        newState.tabs = newState.tabs.map((tab) => ({
          ...tab,
          isFavouriteModalOpen: false,
        }));
        break;

      case "switchFavouriteModal":
        if (aTabId) {
          if (newState.tabId !== aTabId) {
            newState.tabId = aTabId;
            newState.tabs = newState.tabs.map((tab) => ({
              ...tab,
              isFavouriteModalOpen: tab.id === aTabId,
            }));
          } else {
            if (cTab) cTab.isFavouriteModalOpen = !cTab.isFavouriteModalOpen;
          }
        }
        break;
      //
      case "switchFavourite":
        const switchedTab = data?.data;
        if (switchedTab) {
          newState.tabs = newState.tabs.map((tab) =>
            tab.id === switchedTab.id
              ? { ...tab, favourite: !tab.favourite }
              : tab
          );
        }
        break;
      case "addFavourite":
        if (cTab) cTab.favourite = true;
        break;
      default:
    }
    //
    newState.home = newState.tabId === null ? true : false;
    newState.tabIndx = newState.home
      ? null
      : newState.tabs.findIndex(function (value) {
          return value.id === newState.tabId;
        });
    newState.selectedIndex = newState.home ? 0 : 1 + newState.tabIndx;
    //
    //console.log("tabs-new",newState,data);
    return newState;
  };

  const [navigLeftCurrentWidth, setNavigLeftCurrentWidth] = useState(
    navLeftWidth.current
  );

  const [navigLeftCurrentHeight, setNavigLeftCurrentHeight] = useState(
    navLeftHeight.current
  );
  const [state, setTabs] = useReducer(tabsReducer, {
    home: true,
    tabId: null,
    tabIndx: null,
    selectedIndex: 0,
    enable: true,
    tabs: [],
  });

  ////////////////////////////////////////////////////////////////////////////

  const dialogsReducer = (state,data) => {
    //console.log("dialogs-old",data,state)
    let newState = cloneDeep(state);

    switch (data.action) {
      case "add":
        newState.push(data.data);
        break;
      case "rmv":
        newState = newState.filter((d)=>d.id !== data.data.id)
        break;
      default:
    }
    //console.log("dialogs-new",newState)
    return newState;
  }
  const [dialogs, setDialogs] = useReducer(dialogsReducer, [])

  const openDialog = (data, e) => {
    setDialogs({action:"add",data:data});
  };
  const discardDialog = useCallback((data) => {
    // dialog has been closed
    setDialogs({action:"rmv",data:data});
  }, []);
  const doRemoveDialog = (data) => {
    setDialogs({ action: "rmv", data: data });
    doNotifyRemoved(data);
  }
  const closeQueryDialog = (data) => {
    // user request close dialog
    closeQuery(data,doRemoveDialog)
  }
  ////////////////////////////////////////////////////////////////////////////
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmData, setConfirmData] = useState( { confirm:{}, after:null } );

  const onConfirm = () => {

    setConfirmOpen(false);
    if (confirmData.after) confirmData.after();
    setConfirmData( {confirm:{}, after:null} )
  }
  ////////////////////////////////////////////////////////////////////////////

  const getTabs = useCallback(() => {
    if (facet.tabs) return facet.tabs;
    return {};
  }, [facet.tabs]);

  useEffect(() => {
    const memoizedCallback = () => {
      setNavigLeftCurrentWidth(navLeftWidth.current);
    };

    const memoizedCallbackHeight = () => {
      setNavigLeftCurrentHeight(navLeftHeight.current);
    };

    eventBus.on("navleft-width-changed", memoizedCallback);
    eventBus.on("navleft-height-changed", memoizedCallbackHeight);

    return () => {
      eventBus.remove("navleft-width-changed", memoizedCallback);
      eventBus.remove("navleft-height-changed", memoizedCallbackHeight);
    };
  }, []);

  useEffect(
    () => {
      setTabs({ action: "set", tabs: getTabs().tabs || [] });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const openAction = (data, e) => {
    // Exchange open data with add tab action
    control.callAction("openAction", { openData: data });
  };

  const closeForm = useCallback((data, e) => {
    discardDialog(data);
  }, [discardDialog]);

  const doNotifyRemoved = useCallback((data) => {
    let tabs = getTabs();
    if (tabs.onRemoved) {
      control.callAction(tabs.onRemoved, { tab: data });
    }
  }, [control, getTabs]);

  const doRemoveTab = useCallback((data) => {
    setTabs({ action: "rmv", data: data });
    doNotifyRemoved(data);
  }, [doNotifyRemoved]);

  const closeQuery = useCallback((data, doClose) => {

    let tabs = getTabs();

    if (tabs.onCloseQuery) {
      control.callAction(tabs.onCloseQuery, { tab: data }, (resp)=>{
        if (resp.canClose) {
          doClose(data);
        }
        else {
          // confirm dialog
          setConfirmData( {confirm:resp, after:()=>{ doClose(data) } })
          setConfirmOpen(true);
        }
      });
    }
    else {
      doClose(data);
    }
  }, [control, getTabs]);

  const rmvTab = useCallback((data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    closeQuery(data,doRemoveTab)
  }, [closeQuery, doRemoveTab]);

  const closeTab = useCallback((data, e) => {
    rmvTab(data, e);
  }, [rmvTab]);

  const addTab = (data, e) => {
    if (e) e.preventDefault();
    setTabs({ action: "add", data: data });
    if (getTabs().onAdded) {
      control.callAction(getTabs().onAdded, { tab: data });
    }
  };

  const updateTab = useCallback((data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTabs({ action: "update", data: data });
  }, []);
  const closeAll = (data, e) => {
    if (e) e.preventDefault();
    setTabs({ action: "closeAll", data: null });
  };
  const enableTab = (data, e) => {
    if (e) e.preventDefault();
    setTabs({ action: "enable", data: data });
  };

  const openFavouriteModal = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTabs({ action: "openFavouriteModal", data });
  };

  const switchFavouriteModal = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTabs({ action: "switchFavouriteModal", data });
  };

  const closeFavouriteModal = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTabs({ action: "closeFavouriteModal", data });
  };

  const switchFavourite = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setTabs({ action: "switchFavourite", data });
    if (data.favourite) control.callAction("fav.delete", { tab: data });
    else control.callAction("fav.add", { tab: data });
  };

  const addFavourite = (data, e) => {
    if (e) {
      e.preventDefault();
      setTabs({ action: "addFavourite", data });
      control.callAction("fav.add", { tab: data });
    }
  };

  const getTabContent = (tab) => {
    // IFrame content
    if (tab.url)
      return (
        <IframeContainer>
          <NavTabContentIFrame
            height={getIFrameHeightFromNavigLeftCurrentHeight({
              navigLeftCurrentHeight,
              theme,
            })}
            tab={tab}
            control={control}
            {...props}
          />
        </IframeContainer>
      );
    // Panel content
    if (tab.onLoadMethod||tab.controller)
      return (
        <OverflowContainer>
          <NavTabContentPanel
            facet={facet}
            value={value}
            control={control}
            tab={tab}
            {...props}
          />
        </OverflowContainer>
      );

    return (
      <OverflowContainer>
        <NavTabContentDefault tab={tab} control={control} {...props} />
      </OverflowContainer>
    );
  };

  const homeContent = () => {
    return (
      <TabPanel forceRender key="home">
        <OverflowContainer>
          <NavHomeContent facet={facet} control={control} {...props} />
        </OverflowContainer>
      </TabPanel>
    );
  };

  let tabContentList = [];
  tabContentList.push(homeContent());
  tabContentList.push(
    ...state.tabs.map((tab, index) => {
      return (
        <TabPanel forceRender key={tab.id}>
          {getTabContent(tab)}
        </TabPanel>
      );
    })
  );

  useEffect(
    () => {
      eventBus
        .on("tab.add", addTab)
        .on("tab.remove", rmvTab)
        .on("tab.update", updateTab)
        .on("tab.closeAll", closeAll)
        .on("tab.enable", enableTab)
        .on("openAction", openAction)
        .on("openDialog", openDialog)
        .on("closeForm_", closeForm)
        ;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isEmpty = () => {
    return state.tabs.length === 0 && !state.home;
  };

  useEffect(() => {
    if(eventHandlersSetter) {
      eventHandlersSetter({ closeForm, closeTab, updateTab });
    }
  }, [eventHandlersSetter, closeForm, closeTab, updateTab]);

  const refNavTabsWrapper = useRef();

  const [navTabsWrapperWidth, setNavTabsWrapperWidth] = useState("0");

  useEffect(() => {
    if (refNavTabsWrapper.current) {
      const style = window.getComputedStyle(refNavTabsWrapper.current);
      const width = style["width"];
      setNavTabsWrapperWidth(width);
    }
  }, [navigLeftCurrentWidth]);

  useEffect(() => {
    const handleResize = () => {
      const style = window.getComputedStyle(refNavTabsWrapper.current);
      const width = style["width"];
      setNavTabsWrapperWidth(width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <NavLeftCurrentSizeContext.Provider
      value={{
        width: navigLeftCurrentWidth,
        height: navigLeftCurrentHeight,
      }}
    >
      <TabContentContext.Provider
        value={{
          width: navTabsWrapperWidth,
        }}
      >
        <NavContentWrapper
          navigLeftCurrentWidth={navigLeftCurrentWidth}
          navigLeftCurrentHeight={navigLeftCurrentHeight}
          role="navContent"
        >
          <NavTabsWrapper
            navigLeftCurrentWidth={navigLeftCurrentWidth}
            navigLeftCurrentHeight={navigLeftCurrentHeight}
            role="navTabs"
            ref={refNavTabsWrapper}
          >
            {isEmpty() ? null : (
              <Tabs selectedIndex={state.selectedIndex}>
                <TabList
                  facet={facet}
                  control={control}
                  state={state}
                  rmvTab={rmvTab}
                  openFavouriteModal={openFavouriteModal}
                  closeFavouriteModal={closeFavouriteModal}
                  switchFavouriteModal={switchFavouriteModal}
                  switchFavourite={switchFavourite}
                  doDownloadUrl={doDownloadUrl}
                  theme={theme}
                  selectedTabId={state.tabId}
                  setTabSelected={getSetTabSelected({
                    setTabs,
                    state,
                  })}
                  hasHome={true}
                  isHomeSelected={state.home}
                  setHomeSelected={getSetHomeSelected({
                    setTabs,
                    state,
                  })}
                />
                {tabContentList}
              </Tabs>
            )}
          </NavTabsWrapper>
        </NavContentWrapper>
      </TabContentContext.Provider>
      {dialogs.map((d,index) => (
        <NavTabDialog
        key={d.id}
        facet={facet}
        control={control}
        isOpen={true}
        data={d}
        closeQuery={closeQueryDialog}
        index={index}
        {...props}
        />
      ))}
      <Confirm
        title= {confirmData.confirm.title || I18n.t("confirm.title", { defaultValue: "Confirmation" })}
        subtitle= {confirmData.confirm.subtitle || ""}
        confirmButtonDestructive = {true}
        cancelButtonType="tertiary"
        open={isConfirmOpen}
        onConfirm={onConfirm}
        onCancel={()=>setConfirmOpen(false)}
        confirmLabel = {I18n.t("confirm.yes", { defaultValue: "Yes" })}
        cancelLabel = {I18n.t("confirm.no", { defaultValue: "No" })}
      >
          {confirmData.confirm.content || ""}
      </Confirm>
    </NavLeftCurrentSizeContext.Provider>
  );
};

////////////////////////////////////////////////////////////////////////////////
// Ce genre de wrapper (ou composant inermédiaire) est interdit depuis la mise à jour des hooks
// Je le laisse commenté pour l'exemple A NE PAS FAIRE
// voir le hook useEvents pour voir l'équivalent en hook
// var eventWrappers = (ComposedComponent) =>
//   class extends Component {
//     constructor(props) {
//       super(props);
//       this.state = { closeForm: null, closeTab: null, updateTab: null };
//     }
//
//     componentDidMount() {
//       eventBus
//         .on("closeForm", (data) => {
//           if (this.state.closeForm) this.state.closeForm(data);
//         })
//         .on("tab.close", (data) => {
//           if (this.state.closeTab) this.state.closeTab(data);
//         })
//         .on("tab.update", (data) => {
//           if (this.state.updateTab) this.state.updateTab(data);
//         });
//     }
//
//     render() {
//       return <ComposedComponent {...this.props} eventHandlers={this.state} />;
//     }
//   };

export default FacetNavigTabs;
