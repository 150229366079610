import React, { useState, useEffect } from "react";
import LayoutContent from "../../layout/LayoutContent";
import Button from "carbon-react/lib/components/button";
import IconWithBadge from "../components/IconWithBadge";
import IconButton from "carbon-react/lib/components/icon-button";
import styled from "styled-components";
import FacetPanel from "./FacetPanel";
import eventBus from "../../events/eventBus";

const CustomButtonContainer = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  visibility: ${({ hiddenOnDisable, disabled }) =>
    hiddenOnDisable && disabled ? "hidden" : "visible"};
`;

const ButtonContainer = styled.div`
  visibility: ${({ hiddenOnDisable, disabled }) =>
    hiddenOnDisable && disabled ? "hidden" : "visible"};
`;

const getDisabledState = ({
  disableWhenNoItem,
  itemToValidate,
  customDisablingDataProperty,
}) => {
  if (disableWhenNoItem && !itemToValidate) return true;
  if (itemToValidate && itemToValidate[customDisablingDataProperty])
    return true;
  return false;
};

const getOnClickOpenDialog = (setOpen) => () => {
  setOpen(true);
};

const getDialogValue = (value) => {
  let rslt = { value: 0, dialog: value.dialog };
  return rslt;
};

const getOnCancel = ({ setOpen, eventAtClose }) => () => {
  if (eventAtClose) {
    eventBus.emit(eventAtClose, {});
  }
  setOpen(false);
};

const FacetPanelButton = ({ facet, value, control, ...props }) => {
  const {
    title: buttonTitle,
    disableWhenNoItem,
    hiddenOnDisable,
    ...buttonProps
  } = value?.buttonProps || {};
  const {
    iconProps,
    eventAtClose,
    refreshEvents,
    kind,
    customDisablingDataProperty,
    isMultiItemValidation,
    initialItemToValidate,
  } = value || {};
  const [open, setOpen] = useState(false);
  const [itemToValidate, setItemToValidate] = useState(initialItemToValidate);

  useEffect(() => {
    setItemToValidate(initialItemToValidate);
  }, [initialItemToValidate]);

  useEffect(() => {
    const memoizedCallback = (dataSelection) => {
      if (dataSelection.id === value.groupId) {
        if (isMultiItemValidation) {
          setItemToValidate((previousItem) => ({
            ...(previousItem || {}),
            ...dataSelection.item,
          }));
        } else {
          setItemToValidate(dataSelection.item);
        }
      }
    };

    eventBus.on("SelectDone", memoizedCallback);

    return () => {
      eventBus.remove("SelectDone", memoizedCallback);
    };
  }, [value, isMultiItemValidation]);

  return kind === "custom" ? (
    <CustomButtonContainer
      hiddenOnDisable={hiddenOnDisable}
      disabled={getDisabledState({
        disableWhenNoItem,
        itemToValidate,
        customDisablingDataProperty,
      })}
      onClick={getOnClickOpenDialog(setOpen)}
    >
      <LayoutContent
        open={true}
        layout={null}
        content={value?.facets ?? []}
        control={control}
        {...props}
      />
      {open && (
        <FacetPanel
          initialObject={itemToValidate}
          control={control}
          value={value}
          isOpen={open}
          onCancel={getOnCancel({ setOpen, eventAtClose })}
          facet={facet}
          refreshEvents={refreshEvents}
          {...props}
        />
      )}
    </CustomButtonContainer>
  ) : kind === "icon" ? (
    <IconButton onAction={getOnClickOpenDialog(setOpen)}>
      <ButtonContainer
        hiddenOnDisable={hiddenOnDisable}
        disabled={getDisabledState({
          disableWhenNoItem,
          itemToValidate,
          customDisablingDataProperty,
        })}
      >
        <IconWithBadge
          aria-label={buttonTitle}
          tooltipMessage={buttonTitle}
          tooltipPosition="bottom"
          disabled={getDisabledState({
            disableWhenNoItem,
            itemToValidate,
            customDisablingDataProperty,
          })}
          {...iconProps}
        />
        {open && (
          <FacetPanel
            initialObject={itemToValidate}
            control={control}
            value={value}
            isOpen={open}
            onCancel={getOnCancel({ setOpen, eventAtClose })}
            facet={facet}
            refreshEvents={refreshEvents}
            {...props}
          />
        )}
      </ButtonContainer>
    </IconButton>
  ) : (
    <ButtonContainer
      hiddenOnDisable={hiddenOnDisable}
      disabled={getDisabledState({
        disableWhenNoItem,
        itemToValidate,
        customDisablingDataProperty,
      })}
    >
      <Button
        disabled={getDisabledState({
          disableWhenNoItem,
          itemToValidate,
          customDisablingDataProperty,
        })}
        onClick={getOnClickOpenDialog(setOpen)}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
      {open && (
        <FacetPanel
          initialObject={itemToValidate}
          control={control}
          value={value}
          isOpen={open}
          onCancel={getOnCancel({ setOpen, eventAtClose })}
          facet={facet}
          refreshEvents={refreshEvents}
          {...props}
        />
      )}
    </ButtonContainer>
  );
};

export default FacetPanelButton;
