import React from "react";
import LayoutContent from "../../layout/LayoutContent";
import styled from "styled-components";

const ButtonContainer = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
`;

const FacetCustomButton = ({ facet, value, control, ...props }) => {
  return (
    <ButtonContainer onClick={() => console.log("test")}>
      <LayoutContent
        open={true}
        layout={null}
        content={value?.facets ?? []}
        control={control}
        {...props}
      />
    </ButtonContainer>
  );
};

export default FacetCustomButton;
