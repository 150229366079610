import React, { useState, useEffect } from "react";
import LayoutContent from "../../layout/LayoutContent";
import Button from "carbon-react/lib/components/button";
import styled from "styled-components";
import FacetDialog from "./FacetDialog";
import eventBus from "../../events/eventBus";

const CustomButtonContainer = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  visibility: ${({ hiddenOnDisable, disabled }) =>
    hiddenOnDisable && disabled ? "hidden" : "visible"};
  ${({ buttonExtraStyle }) =>
    buttonExtraStyle ? `& button{${buttonExtraStyle}}` : ""}
  ${({ buttonContainerExtraStyle }) =>
    buttonContainerExtraStyle ? `${buttonContainerExtraStyle}` : ""}
`;

const ButtonContainer = styled.div`
  ${({ tabMode, buttonExtraStyle, buttonContainerExtraStyle }) =>
    tabMode
      ? `
    width: 100%;
    & button {
      border: none;
      font-weight: 500;
      ${buttonExtraStyle ? buttonExtraStyle : ""}
    }
    visibility: ${({ hiddenOnDisable, disabled }) =>
      hiddenOnDisable && disabled ? "hidden" : "visible"};
    ${({ buttonContainerExtraStyle }) =>
      buttonContainerExtraStyle ? `${buttonContainerExtraStyle}` : ""}
     `
      : `
    visibility: ${({ hiddenOnDisable, disabled }) =>
      hiddenOnDisable && disabled ? "hidden" : "visible"};
    ${buttonContainerExtraStyle ? `${buttonContainerExtraStyle}` : ""}
    & button {
      ${buttonExtraStyle ? buttonExtraStyle : ""}
    }
  `}
`;

const getDisabledState = ({
  disableWhenNoItem,
  itemToValidate,
  customDisablingDataProperty,
}) => {
  if (disableWhenNoItem && !itemToValidate) return true;
  if (itemToValidate && itemToValidate[customDisablingDataProperty])
    return true;
  return false;
};

const getOnClickOpenDialog = (setOpen) => () => {
  setOpen(true);
};

const getOnCancel =
  ({ setOpen, eventAtClose }) =>
  () => {
    if (eventAtClose) {
      eventBus.emit(eventAtClose, {});
    }
    setOpen(false);
  };

const getOnDone =
  ({ setOpen, control, value, eventAtClose }) =>
  (item) => {
    if (eventAtClose) {
      eventBus.emit(eventAtClose, {});
    }
    if (item?.action?.param && value.validateAction) {
      control.callAction(value.validateAction, { item: item.action.param });
    }
    setOpen(false);
  };

const FacetDialogButton = ({ facet, value, control, tabMode, ...props }) => {
  const { isMultiItemValidation, initialItemToValidate } = value || {};
  const [open, setOpen] = useState(false);
  const [itemToValidate, setItemToValidate] = useState(initialItemToValidate);
  const {
    title: buttonTitle,
    disableWhenNoItem,
    hiddenOnDisable,
    buttonWidth,
    buttonHeight,
    ...buttonProps
  } = value?.buttonProps || {};
  const {
    eventAtClose,
    refreshEvents,
    kind,
    customDisablingDataProperty,
    buttonExtraStyle,
    buttonContainerExtraStyle,
  } = value || {};

  useEffect(() => {
    setItemToValidate(initialItemToValidate);
  }, [initialItemToValidate]);

  useEffect(() => {
    const memoizedCallback = (dataSelection) => {
      if (dataSelection.id === value.groupId) {
        if (isMultiItemValidation) {
          setItemToValidate((previousItem) => ({
            ...(previousItem || {}),
            ...dataSelection.item,
          }));
        } else {
          setItemToValidate(dataSelection.item);
        }
      }
    };

    eventBus.on("SelectDone", memoizedCallback);

    return () => {
      eventBus.remove("SelectDone", memoizedCallback);
    };
  }, [value, isMultiItemValidation]);

  return kind === "custom" ? (
    <CustomButtonContainer
      buttonContainerExtraStyle={buttonContainerExtraStyle}
      buttonExtraStyle={buttonExtraStyle}
      hiddenOnDisable={hiddenOnDisable}
      disabled={getDisabledState({
        disableWhenNoItem,
        itemToValidate,
        customDisablingDataProperty,
      })}
      onClick={getOnClickOpenDialog(setOpen)}
    >
      <LayoutContent
        open={true}
        layout={null}
        content={value?.facets ?? []}
        control={control}
        {...props}
      />
      {open && (
        <FacetDialog
          initialObject={itemToValidate}
          control={control}
          value={value}
          isOpen={open}
          onCancel={getOnCancel({ setOpen, eventAtClose })}
          onDone={getOnDone({ setOpen, eventAtClose, control, value })}
          facet={facet}
          refreshEvents={refreshEvents}
          {...props}
        />
      )}
    </CustomButtonContainer>
  ) : (
    <ButtonContainer
      tabMode={tabMode}
      buttonContainerExtraStyle={buttonContainerExtraStyle}
      buttonExtraStyle={buttonExtraStyle}
      hiddenOnDisable={hiddenOnDisable}
      disabled={getDisabledState({
        disableWhenNoItem,
        itemToValidate,
        customDisablingDataProperty,
      })}
    >
      <Button
        disabled={getDisabledState({
          disableWhenNoItem,
          itemToValidate,
          customDisablingDataProperty,
        })}
        onClick={getOnClickOpenDialog(setOpen)}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
      {open && (
        <FacetDialog
          initialObject={itemToValidate}
          control={control}
          value={value}
          isOpen={open}
          onCancel={getOnCancel({ setOpen, eventAtClose })}
          onDone={getOnDone({ setOpen, control, value, eventAtClose })}
          facet={facet}
          refreshEvents={refreshEvents}
          {...props}
        />
      )}
    </ButtonContainer>
  );
};

export default FacetDialogButton;
