import styled from "styled-components";
import { padding, margin } from "styled-system";
import Icon from "carbon-react/lib/components/icon";

const StyledDraggableContainer = styled.div`
  ${margin}
`;

const StyledDraggableItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.draggableItem.border};
  ${padding}
  cursor: move;
  opacity: ${({ isDragging }) => (isDragging ? "0" : "1")};
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;


export { StyledDraggableContainer, StyledDraggableItem, StyledIcon };
