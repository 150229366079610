import React, { useEffect } from 'react'
import Typography from "carbon-react/lib/components/typography";


const FacetRedirect = ({ facet }) => {

  const { typographyProps } = facet;

  useEffect(() => {

    if (facet.redirect.url) {
      window.setTimeout( function() {
          window.location.replace(facet.redirect.url);
      }, facet.redirect.delay||3000);
    }

    if (facet.redirect.close) {
      window.setTimeout( function() {
        window.close();
    }, facet.redirect.delay||3000);
  }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Typography {...typographyProps}>{facet.title}</Typography>;
}

export default FacetRedirect
