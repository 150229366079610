import React from "react";
import Icon from "carbon-react/lib/components/icon";

const FacetIcon = ({ value }) => {
  const { iconProps } = value;
  return (
    <Icon {...iconProps} />
  );
};

export default FacetIcon;
