import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import eventBus from "../events/eventBus";

const getHandleClickOutside =
  ({ setOpen, popoverRef, refButtonAction }) =>
  (event) => {
    if (refButtonAction?.current?.contains?.(event?.target)) return;
    if (popoverRef?.current?.contains?.(event?.target) === false)
      setOpen?.(false);
  };

const LayoutContentWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

const LayoutContent = ({
  facet,
  content,
  layout,
  value,
  open,
  setOpen,
  onClose,
  refButtonAction,
  focusSearchAtOpening,
  focusIconPopoverAtOpening,
  ...props
}) => {
  //!!!
  // let facet in parameters even if it's not used so that it's excluded from prop
  // elsewhere the Layout will use facet values instead of content !!
  //!!!
  const popoverRef = useRef(null);

  const onPopoverKeydown = (e) => {
    if (e.key === "Escape" && onClose) onClose();
  };

  window.setTimeout(() => {
    if (open && popoverRef.current) {
      popoverRef.current.setAttribute("tabindex", "-1");
      popoverRef.current.focus();
    }
  });

  const getFacets = () => {
    if (content) return content;
    if (value && value.facets) return value.facets;
    return [];
  };

  const getLayout = () => {
    //
    if (layout) return layout;
    //
    return {
      name: "body",
      style: {
        display: "flex",
        flexDirection: "row",
      },
      children: [],
    };
  };

  useEffect(() => {
    const memoizedCallback = () => {
      if (focusSearchAtOpening) {
        const firstSearch = popoverRef.current?.querySelector?.(
          '[data-component="search"] input'
        );
        firstSearch?.focus?.();
      }
    };
    eventBus.on("debounced-search-loaded", memoizedCallback);
    return () => {
      eventBus.remove("debounced-search-loaded", memoizedCallback);
    };
  }, [focusSearchAtOpening]);

  useEffect(() => {
    setTimeout(() => {
      if (focusIconPopoverAtOpening) {
        const firstIconPopover =
          popoverRef.current?.querySelector?.('[tabindex="0"]');
        firstIconPopover?.focus?.();
      }
    }, 100);
  }, [focusIconPopoverAtOpening]);

  useEffect(() => {
    const memoizedCallback = getHandleClickOutside({
      popoverRef,
      setOpen,
      refButtonAction,
    });
    document.addEventListener("click", memoizedCallback);
    return () => {
      document.removeEventListener("click", memoizedCallback);
    };
  }, [setOpen, refButtonAction]);

  return (
    <LayoutContentWrapper ref={popoverRef} onKeyDown={onPopoverKeydown}>
      <Layout
        isLoading={false}
        facets={getFacets()}
        layout={getLayout()}
        {...props} // SHOULD NOT contains "facet"
      />
    </LayoutContentWrapper>
  );
};

export default LayoutContent;
