import React, { useState, useReducer, useEffect } from "react";
import Sidebar from "carbon-react/lib/components/sidebar";
import Typography from "carbon-react/lib/components/typography";
import Layout from "../../layout/Layout";
import { PanelController } from "../../common/Controller";
import eventBus from "../../events/eventBus";
import styled from "styled-components";
import useTheme from "../hooks/useTheme";

const defaultLayout = {
  name: "layout",
  css: "panel",
  children: [
    { name: "topbar", css: "panel", children: [] },
    {
      name: "body",
      css: "panel",
      children: [
        {
          name: "main",
          css: "panel",
          children: [
            { name: "main-header", css: "panel", children: [] },
            { name: "main-body", css: "panel", children: [] },
            { name: "main-footer", css: "panel", children: [] },
          ],
        },
      ],
    },
  ],
};

const getOnCancel = (onCancel) => (e) => {
  e.stopPropagation();
  onCancel(false);
};

const isHidden = (state) => {
  return state.isLoading || state.isClosing;
};

const getPanelSize = ({ value, state }) => {
  if (value.panel && value.panel.size) return value.panel.size;
  if (value.size) return value.size;
  if (state.size) return state.size;
  return "medium"; // default size 750px
};

const getPanelEnableBackgroundUI = ({ value, state }) => {
  if (value.panel && value.panel.enableBackgroundUI)
    return value.panel.enableBackgroundUI;
  if (value.enableBackgroundUI) return value.enableBackgroundUI;
  if (state.enableBackgroundUI) return state.enableBackgroundUI;
  return false;
};

const getPanelTitle = ({ value, state }) => {
  if (value.panel && value.panel.title) return value.panel.title;
  if (value.title) return value.title;
  if (state.title) return state.title;
  return "";
};

function rstate(state, data) {
  //console.log('panel-setState-old',state,data)
  let newState = {
    meta: {},
    facets: [],
    layout: defaultLayout,
    roundTrip: 0,
    isLoading: false,
    isClosing: false,
  };
  switch (data.action) {
    case "set":
      // set query result
      newState = { ...newState, ...data.data };
      newState.meta = data.data.meta || { itemId: "", itemsPerPage: 10 };
      newState.layout = data.data.layout || defaultLayout;
      newState.facets = data.data.facets || [];
      newState.roundTrip = state.roundTrip + 1;
      newState.itemId = newState.meta.itemId;
      newState.isClosing = newState.meta.result ? true : false;
      break;
    default:
  }
  //console.log("panel-setState-new",newState,data);
  return newState;
}

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme?.sidebar?.header?.backgroundColor};
`;

const FacetPanel = ({
  facet,
  control,
  value,
  isOpen,
  onCancel,
  changeCount,
  initialObject,
  refreshEvents,
  ...props
}) => {
  const { title } = value?.buttonProps || {};
  const { sidebarProps } = value || {};
  const [state, setState] = useReducer(rstate, {
    meta: {},
    facets: [],
    layout: defaultLayout,
    roundTrip: 0,
    isLoading: true,
    isClosing: false,
  });
  const [refresh, setRefresh] = useState(0);
  const theme = useTheme();

  let controller = new PanelController({
    facet,
    control,
    value,
    state,
    setState,
  });

  useEffect(() => {
    // will be called only when isOpen change or changeCount change
    if (isOpen) controller.fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, changeCount]);

  ////////////////////////////////////////////////////////
  // Close panel on backend response cancel
  ////////////////////////////////////////////////////////
  if (state.meta.result) {
    if (
      typeof state.meta.result.action === "string" &&
      state.meta.result.action === "cancel"
    ) {
      onCancel();
    }
    setTimeout(controller.dispatch({ action: "reset" }, 1));
  }

  useEffect(() => {
    if (isOpen && initialObject) {
      controller.selectObject(initialObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialObject, isOpen]);

  useEffect(() => {
    if (Array.isArray(refreshEvents)) {
      const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
        callback: () => {
          // on rafraichis complètement le panel lors de ces events
          controller.submit();
        },
        event: refreshEvent,
      }));

      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  });

  return (
    <React.Fragment>
      {isHidden(state) ? null : (
        <Sidebar
          open={isOpen}
          enableBackgroundUI={getPanelEnableBackgroundUI({ value, state })}
          onCancel={getOnCancel(onCancel)}
          size={getPanelSize({ value, state })}
          header={
            <HeaderContainer theme={theme}>
              <Typography variant="h3">
                {getPanelTitle({ value, state })}
              </Typography>
            </HeaderContainer>
          }
          {...(sidebarProps || {})}
        >
          <Layout
            isLoading={state.isLoading}
            facets={state.facets}
            control={controller}
            layout={state.layout}
            {...props}
          />
        </Sidebar>
      )}
    </React.Fragment>
  );
};

export default FacetPanel;
