import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "carbon-react/lib/components/tabs";
import Facets from "./Facets";
import Pill from "carbon-react/lib/components/pill";
import eventBus from "../../events/eventBus";
import IconAction from "../components/IconAction";

const getCountPill = (count) => (
  <Pill size="S" pillRole="status">
    {count}
  </Pill>
);

const getIconAction = ({ iconAction, control, facet }) => {
  return (
    <IconAction action={iconAction} control={control} facet={facet} />
  );
}

const getOnTabChange = (setSelectedTabId) => (newSelectedTabId) => {
  setSelectedTabId(newSelectedTabId);
};

const FacetTabs = ({ facet, control, ...props }) => {

  const { loadTabContentOnSelection } = facet;
  const [selectedTabId, setSelectedTabId] = useState(facet.tabs?.[0]?.id ?? "");
  const [tabCounts, setTabCounts] = useState(
    facet?.tabs?.map?.((tab) => tab.count) || []
  );

  useEffect(() => {
    setTabCounts(facet?.tabs?.map?.((tab) => tab.count) || []);
  }, [facet?.tabs]);

  useEffect(() => {
    if (Array.isArray(facet?.tabs)) {
      const memoizedCallbacks = facet.tabs.map((tab, tabIndex) => {
        if (
          Array.isArray(tab.facets) &&
          tab.facets[0] &&
          tab.facets[0].updateEvent
        ) {
          const memoizedCallback = (newUpdateEventContent) => {
            if (newUpdateEventContent) {
              setTabCounts((previousTabCounts) => {
                const newTabCounts = [...previousTabCounts];
                newTabCounts[tabIndex] = newUpdateEventContent.title || "";
                return newTabCounts;
              });
            }
          };
          return {
            updateEvent: tab.facets[0].updateEvent,
            callback: memoizedCallback,
          };
        }
        return {};
      });

      memoizedCallbacks.forEach((memoizedCallback) => {
        if (memoizedCallback?.updateEvent && memoizedCallback?.callback) {
          eventBus.on(memoizedCallback.updateEvent, memoizedCallback.callback);
        }
      });

      return () => {
        memoizedCallbacks.forEach((memoizedCallback) => {
          if (memoizedCallback?.updateEvent && memoizedCallback?.callback) {
            eventBus.remove(
              memoizedCallback.updateEvent,
              memoizedCallback.callback
            );
          }
        });
      };
    }
  }, [facet.tabs]);

  const tabList = facet.tabs.map((tab, index) => {
    const siblings = [
      tabCounts?.[index] ? getCountPill(tabCounts?.[index]) : undefined,
      tab.iconAction ? getIconAction({ iconAction: tab.iconAction, control, facet }) : undefined
    ].filter(sibling => sibling !== undefined);
    return (
      <Tab
        errorMessage="error"
        warningMessage="warning"
        infoMessage="info"
        tabId={tab.id}
        title={tab.title}
        key={index}
        siblings={siblings.length ? siblings : undefined}
      >
        {loadTabContentOnSelection ? (
          tab.id === selectedTabId ? (
            <Facets
              facets={tab.facets}
              control={control}
              position={facet.position}
              {...props}
            />
          ) : null
        ) : (
          <Facets
            facets={tab.facets}
            control={control}
            position={facet.position}
            {...props}
          />
        )}
      </Tab>
    );
  });

  let tabTitle = "";
  if (facet.title) {
    tabTitle = <div className="facet-title">{facet.title}</div>;
  }

  return (
    <React.Fragment>
      {tabTitle}
      <Tabs
        selectedTabId={selectedTabId}
        onTabChange={getOnTabChange(setSelectedTabId)}
        align={facet.tabAlign || "left"}
        position={facet.tabPosition || "top"}
      >
        {tabList}
      </Tabs>
    </React.Fragment>
  );
};

export default FacetTabs;
