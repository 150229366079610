import React from 'react'
import ContainerSwitch from './ContainerSwitch'
import Facets from '../ui/facets/Facets'

const Container = ({container, ...props}) => {

    const getClassName = () => {
        let rslt = 'layout-'+container.name;
        if (container.css) rslt = rslt+' '+ container.css;
        return rslt; 
    }
    const getStyle = () => {
        let rslt = {}
        if (container.style) rslt= container.style;
        if (props.theme) rslt = props.theme.translate(rslt);
        return rslt;
    }
    const getChildren = () => {
        if (container.children) return container.children;
        return [];
    }

    return (
        <div className={getClassName()} style={getStyle()}>
            <Facets position={container.name} {...props}/>
            {getChildren().map((child) => (
             <ContainerSwitch key={child.name} container={child} {...props}/>))
            }
        </div>
    )
}

export default Container;
