// taille d'une Card : (taille totale % nombre de colonne) - 24
// taille ellipsis title : taille carte - 16 - 48 (marges et 2x taille icone)
// taille ellipsis link : taille carte - 16 (marges)
export const CONF_3_COLUMNS = {
  slidesToShow: 3,
  sliderWidth: "1032px",
  cardWidth: "320px",
  linkEllipsisWidth: 232,
  titleEllipsisWidth: 304
}

export const CONF_4_COLUMNS = {
  slidesToShow: 4,
  sliderWidth: "1120px",
  cardWidth: "256px",
  linkEllipsisWidth: 168,
  titleEllipsisWidth: 240
}

export const CONF_5_COLUMNS = {
  slidesToShow: 5,
  sliderWidth: "1400px",
  cardWidth: "256px",
  linkEllipsisWidth: 168,
  titleEllipsisWidth: 240
}
