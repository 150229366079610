import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Checkbox } from "carbon-react/lib/components/checkbox";
import DateInput from "carbon-react/lib/components/date";
import Number from "carbon-react/lib/components/number";
import Decimal from "carbon-react/lib/components/decimal";
import Textarea from "carbon-react/lib/components/textarea";
import InputTime from "./InputTime";
import InputToggleButton from "./InputToggleButton";
import InputText from "./InputText";
import InputIcon from "./InputIcon";

const CheckboxWrapper = styled.div`
  & label {
    font-weight: 400;
  }
`;

const getOnExit =
  ({ value, newValue, control, facet }) =>
  () => {
    if (value.value !== newValue) control.setFacetValue(facet, value, newValue);
  };

const InputField = ({ facet, control, value, ...props }) => {
  const [newValue, setNewValue] = useState(null);
  const [inputRef, setInputRef] = useState(null);

  useEffect(() => {
    setNewValue(value.value || "");
  }, [control.roundTrip, value.value]);

  const onValueChange = (event) => {
    setNewValue(event.target.value);
    if(event.target.value !== value.value) {
      control.setFacetValue(facet, value, event.target.value);
    }
  };
  const onCheckValueChange = (event) => {
    let newVal = event.target.checked;
    setNewValue(newVal);
    if (value.value !== newVal) control.setFacetValue(facet, value, newVal);
  };
  const onRawValueChange = (event) => {
    setNewValue(event.target.value.rawValue);
  };
  const onIconChange = (iconValue) => {
    setNewValue(iconValue);
    if (value.value !== iconValue)
      control.setFacetValue(facet, value, iconValue);
  };
  const onViewIconClick = (e) => {
    if (e) e.preventDefault();
    if (inputRef.current) {
      if (inputRef.current.getAttribute("type") === "password") {
        inputRef.current.setAttribute("type", "text");
      } else {
        inputRef.current.setAttribute("type", "password");
      }
    }
  };
  const onExit = getOnExit({ value, newValue, control, facet });
  const getId = () => {
    return value.id.toString();
  };
  const getLabel = () => {
    if (value.title) return value.title;
    return null;
  };
  const getCustomIconList = () =>
    value.customIconList ? value.customIconList : undefined;
  const getPlaceHolder = () => {
    if (value.placeHolder) return value.placeHolder;
    return null;
  };
  const getCodeRows = () => {
    if (value.rows) return value.rows;
    else return "10";
  };
  const getTextRows = () => {
    if (value.rows) return value.rows;
    else return "4";
  };

  const getValueClass = () => {
    if (value.class === "edit") return value.editClass;
    else return value.class;
  };
  const getChecked = () => {
    // using only newValue don't work, need === !
    return newValue === true ? true : false;
  };
  const getMin = (defMin) => {
    if (value.min) return value.min;
    return defMin;
  };
  const getMax = (defMax) => {
    if (value.max) return value.max;
    return defMax;
  };
  const numberLimits = (e) => {
    // can't use Number bc of name conflict with carbon Number component !
    if (
      parseInt(e.target.value, 10) > parseInt(e.target.max, 10) ||
      parseInt(e.target.value, 10) < parseInt(e.target.min, 10)
    ) {
      e.target.value = e.target.value.slice(0, 11);
    }
  };
  const getRequired = () => {
    if (value.required) return true;
    return false;
  };
  const getAutoFocus = () => {
    if (value.autoFocus) return true;
    return false;
  };
  const getDisabled = () => {
    return value.disabled || false;
  };

  const setDateValue = (date) => {
    console.log("setDate", date);
    let iso = date.toISOString();
    console.log("setDate", date, iso);
    setNewValue(iso);
  };
  const getDateValue = () => {
    return new Date();

    // console.log("getDate",newValue)
    // let rslt = Date().parse(new Date());
    // console.log("getDate",rslt,newValue)
    // return rslt;
  };

  useEffect(() => {
    const memoizedCallback = (e) => {
      if (e?.key === "Enter") {
        onExit();
      }
    };
    document.addEventListener("keydown", memoizedCallback);
    return () => {
      document.removeEventListener("keydown", memoizedCallback);
    };
  }, [onExit]);

  switch (getValueClass()) {
    case "code":
    case "input-code":
      return (
        <Textarea
          label={getLabel()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          id={getId()}
          rows={getCodeRows()}
          value={newValue}
          onBlur={onExit}
          onChange={onValueChange}
          disabled={getDisabled()}
        />
      );

    case "memo":
    case "input-memo":
      return (
        <Textarea
          label={getLabel()}
          required={getRequired()}
          expandable
          autoFocus={getAutoFocus()}
          id={getId()}
          rows={getTextRows()}
          value={newValue}
          onBlur={onExit}
          onChange={onValueChange}
          disabled={getDisabled()}
        />
      );
    case "input-icon":
      return (
        <InputIcon
          customIconList={getCustomIconList()}
          label={getLabel()}
          onChange={onIconChange}
          initialIcon={newValue}
        />
      );
    case "delay":
    case "input-delay":
    case "integer":
    case "input-integer":
      return (
        <Number
          type="number"
          min={getMin("-2147483648")}
          max={getMax("2147483647")}
          label={getLabel()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          id={getId()}
          value={newValue}
          onBlur={onExit}
          onChange={onValueChange}
          onInput={numberLimits}
          disabled={getDisabled()}
        />
      );

    case "longint":
    case "input-longint":
      return (
        <Number
          type="number"
          min={getMin("-9223372036854775808")}
          max={getMax("9223372036854775807")}
          label={getLabel()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          id={getId()}
          value={newValue}
          onBlur={onExit}
          onChange={onValueChange}
          disabled={getDisabled()}
        />
      );

    case "number":
    case "input-number":
      return (
        <input
          className="facet-input-integer"
          type="number"
          id={value.id}
          value={newValue}
          onBlur={onExit}
          onChange={onValueChange}
          disabled={getDisabled()}
        />
      );

    case "currency":
    case "input-currency":
    case "decimal":
    case "input-decimal":
      return (
        <Decimal
          label={getLabel()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          id={getId()}
          value={newValue}
          onBlur={onExit}
          onChange={onRawValueChange}
          disabled={getDisabled()}
        />
      );

    case "text":
    case "input-text":
    case "string":
    case "input-string":
      return (
        <InputText
          key={value.name || value.id}
          control={control}
          customChange={onValueChange}
          value={value}
          placeholder={getPlaceHolder()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          initialValue={newValue}
          disabled={getDisabled()}
          facet={facet}
        />
      );

    case "input-password":
      return (
        <InputText
          key={value.name || value.id}
          control={control}
          customChange={onValueChange}
          value={value}
          placeholder={getPlaceHolder()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          initialValue={newValue}
          disabled={getDisabled()}
          inputIcon="view"
          inputRef={setInputRef}
          iconOnClick={onViewIconClick}
          type="password"
          facet={facet}
        />
      );

    case "date":
    case "input-date":
    case "datetime":
    case "input-datetime":
      return (
        <DateInput
          allowEmptyValue
          label={getLabel()}
          required={getRequired()}
          autoFocus={getAutoFocus()}
          id={getId()}
          value={newValue}
          onBlur={onExit}
          onChange={onRawValueChange}
          disabled={getDisabled()}
        />
      );

    case "time":
    case "input-time":
      return (
        <InputTime facet={facet} control={control} value={value} {...props} />
      );

    case "boolean":
    case "input-boolean":
    case "check":
    case "input-check":
      return (
        <CheckboxWrapper>
          <Checkbox
            label={getLabel()}
            required={getRequired()}
            autoFocus={getAutoFocus()}
            name={getId}
            checked={getChecked()}
            onChange={onCheckValueChange}
            disabled={getDisabled()}
          />
        </CheckboxWrapper>
      );

    case "toggleButton":
      return (
        <InputToggleButton
          facet={facet}
          control={control}
          value={value}
          {...props}
        />
      );

    case "":
      return null;

    default:
      return (
        <span className="facet-value-text">
          (IF) Unknow type: {getValueClass()}
        </span>
      );
  }
};

export default InputField;
