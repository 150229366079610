import styled from "styled-components";
import { default as CPopoverContainer } from "carbon-react/lib/components/popover-container";

const PopoverWrapper = styled.div`
  z-index: +1;
  ${({ noCloseIcon }) =>
    noCloseIcon
      ? `& [data-element="popover-container-close-component"] {
    display: none;
  }`
      : ""}
`;

const ProfilePopoverContainerStyle = styled(PopoverWrapper)`
  & .layout-body {
    padding: 0;
  }
  div[data-element="popover-container-content"] {
    padding: 0;
  }
  div[data-element="popover-container-content"] > div:first-child {
    margin: 0;
  }
  ul[role="facet-list-container"] {
    padding: 0 !important;
    margin: 0 !important;
  }
  ul[role="facet-list-container"] > li {
    margin: 0 !important;
    padding: 0;
  }
`;

const PopoverContainer = ({ noCloseIcon, ...props }) => {
  return (
    <PopoverWrapper noCloseIcon>
      <CPopoverContainer {...props} />
    </PopoverWrapper>
  );
};

export const PopoverContainerWithChildren = ({ children, ...props }) => (
  <ProfilePopoverContainerStyle>
    <CPopoverContainer {...props}>{children}</CPopoverContainer>
  </ProfilePopoverContainerStyle>
);

export default PopoverContainer;
