import React, { useState, useEffect } from "react";
import SelectableList from "../components/SelectableList";
import eventBus from "../../events/eventBus";

const getSelectPropsFromValue = (value) => value?.selectProps ?? {};

const getSelectCallback = ({
  callbackName,
  isCallbackFacetAction,
  control,
  facet,
  value,
  eventAtSelect,
  onSelectItem,
}) => (newValue) => {

  if (isCallbackFacetAction) {
    control.doFacetAction(facet, value, {
      name: callbackName,
      item: newValue,
    });
  }
  else
  if (callbackName) {
    control.callAction(callbackName, { item: newValue });
    if(onSelectItem) {
      onSelectItem(newValue);
    }
  }
  else
  if (onSelectItem) {
    onSelectItem(newValue);
  }
  else {
    control.setFacetValue(facet,value,newValue);
  }

  if (eventAtSelect) eventBus.emit(eventAtSelect, { item: newValue });
};

const FacetSelectableList = ({ facet, value, control, onSelectItem }) => {

  const {
    callbackName,
    refreshEvents,
    eventAtSelect,
    selectPropName,
    loadMethod,
    isCallbackFacetAction,
    titleProperty="title",
    dynamicSize,
    focusFirstAtOpening,
    darkMode
  } = getSelectPropsFromValue(value);

  const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    control.callMethod(loadMethod, {}, (data) => {
      Array.isArray(data?.data) && setInitialData(data.data);
      setLoading(false);
    });
  }, [control, loadMethod]);

  return (
    <SelectableList
      darkMode={darkMode}
      focusFirstAtOpening={focusFirstAtOpening}
      dynamicSize={dynamicSize}
      titleProperty={titleProperty}
      loading={loading}
      initialData={initialData}
      refreshEvents={refreshEvents}
      selectPropName={selectPropName}
      selectCallback={getSelectCallback({
        callbackName,
        isCallbackFacetAction,
        control,
        facet,
        value,
        eventAtSelect,
        onSelectItem
      })}
    />
  );
};

export default FacetSelectableList;
