import React, { useState, useEffect, useRef } from "react";
import DraggableContainer from "../components/dragndrop/DraggableContainer";
import DraggableItem from "../components/dragndrop/DraggableItem";
import { Facet } from "./Facet";
import FacetValue from "./FacetValue";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import { isEqual } from "lodash";

const itemHeight = 50;

const StyledDraggableContainer = styled.div`
  width: 320px;
  & [data-element="draggable"] {
    padding: 0;
    border: none;
  }
  & .frp1000-dragging {
    background-color: ${({ theme }) => theme?.colors?.secondary};
    color: ${({ theme }) => theme?.colors?.white};
  }
  & [data-element="draggable"] > span[data-element="drag"]:not(:first-child) {
    display: none;
  }
`;

// Here you can add custom style considering your facet list title
const getCustomStylesMapping = (theme) => ({
  favouriteList: { backgroundColor: theme?.navigation?.panel?.backgroundColor },
});

// Here you can add custom callback at dragend with new reordered list, considering your facet list title
const getCustomDragEndCallbackMapping = () => ({
  favouriteList: ({ reorderedItems, control }) =>
    console.log("test callback: ", reorderedItems, control),
});

// Here you can add custom removing row behavior considering your facet list title
const getCustomRemoveRowCallbackMapping = () => ({
  favouriteList: ({ item, control, setItems }) => () => {
    setItems((previousItems) => {
      const indexToRemove = previousItems.findIndex((currentItem) =>
        isEqual(currentItem, item)
      );
      if (indexToRemove === -1) return previousItems;
      return [
        ...previousItems.slice(0, indexToRemove),
        ...previousItems.slice(indexToRemove + 1)
      ];
    });
    // api callback here using control
  },
});

const getCustomStyleFromFacet = ({ facet, theme }) => {
  const customStylesMapping = getCustomStylesMapping(theme);
  return customStylesMapping[facet?.title];
};

const getStyle = ({ facet, props }) => {
  const customStyle = getCustomStyleFromFacet({ facet, theme: props.theme });
  return { ...facet.style, ...props.style, ...customStyle };
};

const getCustomDragEndCallbackFromFacet = (facet) => {
  const customDragEndCallbackMapping = getCustomDragEndCallbackMapping();
  return customDragEndCallbackMapping[facet?.title];
};

const getOnDragEndContainer = ({
  items,
  setItems,
  dragEndCallback,
  control,
}) => (idsArray) => {
  const reorderedItems = idsArray.map((itemId) => {
    return items.find((item) => item.id === itemId);
  });
  if (!isEqual(reorderedItems, items)) {
    setItems(reorderedItems);
    if (dragEndCallback) dragEndCallback({ reorderedItems, control });
  }
};

const getOnMouseEnter = (draggable) => (e) => {
  if (!e.buttons) draggable.classList.add("frp1000-dragging");
};

const getOnMouseLeave = (draggable) => () => {
  draggable.classList.remove("frp1000-dragging");
};

const getOnDragEnd = (draggable) => (e) => {
  if (e.srcElement === draggable) {
    e.srcElement.classList.add("frp1000-dragging");
  }
};

const FacetDraggableList = ({ facet, control, ...props }) => {
  const [items, setItems] = useState(facet.values);
  const ref = useRef();

  const customRemoveRowCallbackMapping = getCustomRemoveRowCallbackMapping();

  useEffect(() => {
    setItems(facet.values);
  }, [facet]);

  useEffect(() => {
    if (ref.current) {
      const draggables = ref.current.querySelectorAll(
        "[data-element=draggable]"
      );
      if (draggables) {
        const draggablesArray = Array.from(draggables);
        const memoizedCallbacks = draggablesArray.map((draggable) => ({
          onMouseEnter: getOnMouseEnter(draggable),
          onMouseLeave: getOnMouseLeave(draggable),
          onDragEnd: getOnDragEnd(draggable),
        }));
        draggablesArray.forEach((draggable, index) => {
          draggable.addEventListener(
            "mouseenter",
            memoizedCallbacks[index].onMouseEnter
          );
          draggable.addEventListener(
            "mouseleave",
            memoizedCallbacks[index].onMouseLeave
          );
          draggable.addEventListener(
            "dragend",
            memoizedCallbacks[index].onDragEnd
          );
        });

        return () => {
          draggablesArray.forEach((draggable, index) => {
            draggable.removeEventListener(
              "mouseenter",
              memoizedCallbacks[index].onMouseEnter
            );
            draggable.removeEventListener(
              "mouseleave",
              memoizedCallbacks[index].onMouseLeave
            );
            draggable.removeEventListener(
              "dragend",
              memoizedCallbacks[index].onDragEnd
            );
          });
        };
      }
    }
  });

  return (
    <Facet
      autoWidth={facet.autoWidth}
      role="facet-list"
      style={getStyle({ facet, props })}
      ref={ref}
    >
      <StyledDraggableContainer>
        <DraggableContainer
          getOrder={getOnDragEndContainer({
            items,
            setItems,
            dragEndCallback: getCustomDragEndCallbackFromFacet(facet),
            control,
          })}
        >
          {items.map((item, index) => (
            <DraggableItem id={item.id} key={`draggable-item-${item.id}`}>
              <Icon type="drag" />
              <FacetValue
                noWrapping
                role="facet-value"
                key={`facet-${item.id}`}
                facet={facet}
                value={item}
                control={control}
                removeRowCallback={customRemoveRowCallbackMapping[facet?.title]?.({ item, control, setItems })}
                {...props}
              />
            </DraggableItem>
          ))}
        </DraggableContainer>
      </StyledDraggableContainer>
    </Facet>
  );
};

export default FacetDraggableList;
