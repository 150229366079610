import React from "react";
import styled from "styled-components";
import Button from "carbon-react/lib/components/button";
import I18n from 'i18n-js';

const addStep = (setLocalSteps) => {
  setLocalSteps((previousLocalSteps) => {
    const newStep = {
      boxes: [],
      title: "",
      styles: {},
    };
    return [...previousLocalSteps, newStep];
  });
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 64px;
  width: 100%;
  & button {
    color: ${({ theme }) => theme?.text?.color};
    border: 2px dashed ${({ theme }) => theme?.colors?.dashedBorder};
    width: calc(100% - 48px);
  }
  & button:hover {
    color: ${({ theme }) => theme?.text?.color};
    background-color: inherit;
  }
`;

const AddStepButton = ({ setLocalSteps }) => {
  return (
    <ButtonContainer>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          addStep(setLocalSteps);
        }}
        buttonType="tertiary"
      >
        {I18n.t("businessProcess.addNewStep")}
      </Button>
    </ButtonContainer>
  );
};

export default AddStepButton;
