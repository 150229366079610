import React, { useState } from 'react'
import styled from 'styled-components';

const Searchbox = styled.div`
border-radius: 4px;
border: 1px solid #ccc;
padding: 16px;
outline: none;
position: relative;
font-family: inherit;
font-size: 14px;
width: 100%;
`;

const FacetText = ({ facet, control }) => {

  const value = ()=> {
    return facet.values[0];
  }
  const text = () => {
    return value().filter.args[0];
  }

  const onChange = (text) => {
    control.setFacetValueParam(facet,value(),0,text);
  }

  const onKeyPress = async (e) => {
    if (e.key==='Enter') {
      control.changed();
    }
  }

  const divStyle = {
    width: '100%',
  }

  return (
    <div className='facet' style={divStyle}>
      <Searchbox>
          <input
            type='text'
            className='form-control'
            placeholder={facet.title}
            value={text()}
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}
            autoFocus
          />
      </Searchbox>
      </div>
)
}

export default FacetText
