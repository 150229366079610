
const Tree = {

    flatIds: function(tree,rslt) {
        tree.forEach((i)=>{
            rslt.push(i.id);
            if (i.children && i.isExpanded) {
                this.flatIds(i.children,rslt);
            }
        })
    },
    find: function(tree,id) {
        for (let idx=0; idx<tree.length; idx++) {
            let i = tree[idx];
            if (i.id===id) return i;
            if (i.children) {
                let ii = this.find(i.children,id);
                if (ii) return ii;
            }
        }
        return null;
    },
    getFirstSibling: function(tree) {
        if (tree.length>0) return tree[0].id;
        return null;
    },
    getLastSibling: function(tree) {
        let flatten = [];
        this.flatIds(tree,flatten);
        if (flatten.length===0) return null;
        return {id:flatten[flatten.length-1], sibling:false};
    },
    getIncrementSibling: function(tree,currentId,increment) {
        let flatten = [];
        this.flatIds(tree,flatten);
        if (flatten.length===0) return null;

        let idx = increment+flatten.indexOf(currentId);
        if (idx<flatten.length) return {id:flatten[idx], sibling:true};
        return {id:flatten[flatten.length-1], sibling:false};
    },
    getNextSibling: function(tree,currentId) {
        return this.getIncrementSibling(tree,currentId,1);
    },
    getDecrementSibling: function(tree,currentId,decrement) {
        let flatten = [];
        this.flatIds(tree,flatten);
        if (flatten.length===0) return null;

        let idx = flatten.indexOf(currentId)-decrement;
        if (idx>=0) return {id:flatten[idx], sibling:true};
        return {id:flatten[0], sibling:false};
    },
    getPrevSibling: function(tree,currentId) {
        return this.getDecrementSibling(tree,currentId,1);
    },
    flatLevels: function(tree,rslt,thisLevel,wantlevel) {
        tree.forEach((i)=>{
            if (i.children) {
                if (thisLevel===wantlevel) {rslt.push(i);}
                if (thisLevel<=wantlevel) this.flatLevels(i.children,rslt,thisLevel+1,wantlevel);
            }
        })
    },
    collapseLevel: function(tree,level) {
        let flatten = [];
        this.flatLevels(tree,flatten,0,level);
        flatten.forEach((i) => { if (i.isExpanded) i.isExpanded = false; });
    },
    toggle: function(tree,id,level,autoCollapse) {
        let ii=this.find(tree,id);
        if (ii) {
            let newState = ! ii.isExpanded;
            if (newState && autoCollapse) this.collapseLevel(tree,level)
            ii.isExpanded = newState;
        }
    },
    expand: function(tree,id,autoCollapse) {
        let ii=this.find(tree,id);
        if (autoCollapse) this.collapseLevel(tree,ii.level);
        if (ii) ii.isExpanded = true;
    },
    collapse:function(tree,id) {
        let ii=this.find(tree,id);
        if (ii) ii.isExpanded = false;
    },
}

export default Tree;
