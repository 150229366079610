import React from 'react'
import {Facet, FacetTitle} from './Facet'
import styled from 'styled-components';
import { REACT_FLOW_CHART } from "@mrblenny/react-flow-chart";

const Toolbox = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
width: 150px;
`;

const ToolboxCell = styled.img`
flex: 0 0 32%;
width: 50px;
height: 50px;
margin-bottom: 5px;
text-align: center;
display: inline-block;
`;

const FacetToolboxCell = ({ facet, value, control }) => {

    const onDragStart = (event) => {
      event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify( value.node ))
    }

    return (
      <ToolboxCell draggable={value.draggable} onDragStart={onDragStart} src={`${process.env.PUBLIC_URL}/flowcharts/`+value.image} alt={value.title} title={value.title}/>
    )
}

const FacetToolbox = ({ facet, control }) => {

    // 3x3 toolbox grid
    //
    return (
        <Facet role="facet-toolbax">
            <FacetTitle role="facet-toolbox-title" style={{padding:"4px 0"}}>{facet.title}</FacetTitle>
            <Toolbox>
                {facet.values.map((value) => (
                <FacetToolboxCell key={value.id} facet={facet} value={value} control={control} />
                ))}
            </Toolbox>
        </Facet>
    )
}

export default FacetToolbox
