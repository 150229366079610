import React, { useState } from 'react'
import I18n from "i18n-js";
import styled from 'styled-components';
import Icon from 'carbon-react/lib/components/icon'
import IconButton from 'carbon-react/lib/components/icon-button'
import BatchSelection from 'carbon-react/lib/components/batch-selection'
import {FlatTable,FlatTableHead,FlatTableBody,FlatTableRow,FlatTableCell, FlatTableHeader, FlatTableCheckbox} from 'carbon-react/lib/components/flat-table'

const Table = styled.div`
  position: inherit !important;
`;

const IconTable = ({disabled,onAction,iconName,tooltip}) => {

  return (
    <IconButton disabled={disabled} onAction={onAction}>
    <Icon bg='none' disabled={disabled} type={iconName} tooltipMessage={tooltip} tooltipPosition="bottom"/>
   </IconButton>
  );
}

const FacetTable = ({ facet, control, value }) => {

  const [selState, setSelectedState] = useState({all:false, ids:[] });


  const handleEnterEdit = (inserting,editing) => {

    let param = {};
    if (editing) {
      param = {rowId:selState.ids[0], rows:value.rows};
    } else {
      param = {rowId:-1, rows:value.rows};
    }
    control.doFacetAction(facet,value,{name:'enterEdit', param:param});
  };

  const handleSelectAllRows = () => {

      let sel = {all:selState.all, ids:selState.ids, };

      sel.all = ! sel.all;
      sel.ids = [];

      if (sel.all) {
          value.rows.forEach((row,indxrow) => {sel.ids.push(indxrow)}
      )}

      setSelectedState(sel);
  };

  const handleClearSelected = () => {

    let sel = {all:selState.all, ids:[], };
    setSelectedState(sel);
  };

  const handleSelectRow = (id) => {
      let sel = {all:selState.all, ids:selState.ids, };
      //
      const index = sel.ids.indexOf(id);
      if (index > -1) {
        sel.ids.splice(index, 1);
        sel.all = false;
      } else {
        sel.ids.push(id);
      }

      setSelectedState(sel);
  };

  const getOptions = () => {
    return value.options || {
      canInsert: false,
      canEdit: false,
      canDelete: false,
    }
  }
  const hasInsert = () => { return getOptions().canInsert; }
  const hasEdit = () => { return getOptions().canEdit; }
  const hasDelete = ()=> { return getOptions().canDelete; }

  const isSelectedAll = () => { return selState.all;}
  const isSelected = (id) => { return selState.ids.indexOf(id)>-1;}
  const selectedCount = () => { return selState.ids.length; }

  const deleteAction = () => {

    let newRows = [];
    let rowIds = selState.ids;
    value.rows.forEach((r,indx)=>{
      if (!rowIds.includes(indx)) newRows.push(r);
    })
    value.rows = newRows;
    control.doFacetAction(facet,value,{name:'delete',param:{rowid:rowIds, rows:value.rows}});
    handleClearSelected();
   }

   const editAction = () => { handleEnterEdit(false,true); };
   const insertAction = () => { handleEnterEdit(true,false); };

   const getCellTitle = (cell) => {
    let rslt = cell.title;
    if (typeof rslt === "boolean") rslt = rslt?"true":"false";
    return rslt;
   }

  return (
      <Table>

        <BatchSelection selectedCount={selectedCount()}>

        {hasInsert()?
        <IconTable disabled={selectedCount()>0} onAction={insertAction} iconName="add" tooltip={I18n.t("action.add", { defaultValue: "Create" })}/>
        :null
        }


        {hasEdit()?
        <IconTable disabled={selectedCount()!==1} onAction={editAction} iconName="edit" tooltip={I18n.t("action.modify", { defaultValue: "Modify" })}/>
        :null
        }

        {hasDelete()?
        <IconTable disabled={selectedCount()===0} onAction={deleteAction} iconName="bin" tooltip={I18n.t("action.delete", { defaultValue: "Delete" })}/>
          :null
        }
        </BatchSelection>

        <FlatTable>
            <FlatTableHead>
                <FlatTableRow>
                    <FlatTableCheckbox as='th' checked={isSelectedAll()} onChange={() => handleSelectAllRows()} />
                    {value.headers.map((header,indxheader) => (
                    <FlatTableHeader key={indxheader} >{header.title}</FlatTableHeader>
                    ))}
                </FlatTableRow>
            </FlatTableHead>

            <FlatTableBody>
                {value.rows.map((row,indxrow) => (
                <FlatTableRow key={indxrow} selected={isSelected(indxrow)}>
                    <FlatTableCheckbox checked={isSelected(indxrow)} onChange={() => handleSelectRow(indxrow)} />
                    {row.map((cell,indxcell) => (
                        <FlatTableCell key={indxcell}>{getCellTitle(cell)}</FlatTableCell>
                    ) )}
                </FlatTableRow>
                ))}

            </FlatTableBody>
        </FlatTable>
    </Table>
  )
}

export default FacetTable
