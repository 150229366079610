import React, { useState } from 'react'
import InputButton from './InputButton'
import FacetDialog from '../facets/FacetDialog'


const IconLookup = ({ facet, control, value, ...props }) => {

  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen(!isOpen)
  }

  const setDone= (result) => {
      console.log("dialog-done",result)
      control.setFacetValue(facet,value,result.value);
      setIsOpen(false);
  }

  const getController= () => {
    if ((value.lookup)&&(value.lookup.controller)) return value.lookup.controller;
    if (value.controller) return value.controller;
      return 'lookup';
  }

  const getDialogValue = () => {
    return {facet:facet.id, id:value.id, autoSubmit:true, lookup:value.lookup, dialog:{title:value.title, controller:getController()}};
  }

  const getIconLeft = () => {
    if (value.value) return 'cross';
    return null;
  }
  const onDeleteClick = () => {
    control.setFacetValue(facet,value,'');
  }
  const onKeyDown = (e) => {
    // allow user to clear the value with the Del key
    if ((e.key==='Del')||(e.key==='Delete')) {
      control.setFacetValue(facet,value,'');
    }
  }

  return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={setOpen} placeHolder='Sélectionner...' subTitle={value.value} onKeyDown={onKeyDown} rightIcon={getIconLeft()} onRightIconClick={onDeleteClick}/>
        <FacetDialog facet={facet} control={control} value={getDialogValue()} isOpen={isOpen} onCancel={setOpen} onDone={setDone} {...props}/>
    </React.Fragment>
  )
}

export default IconLookup
