export class Mover {
  constructor({ elt, backgroundElt }) {
    this.elt = elt;
    this.backgroundElt = backgroundElt;
    this.down = false;
    this.x = 0;
    this.y = 0;
    this.dx = 0;
    this.dy = 0;
    this._Down = this.onDown.bind(this);
    this.elt.addEventListener("mousedown", this._Down);
  }

  onDown(e) {
    this.x = e.clientX;
    this.y = e.clientY;
    this.down = true;
    this._Move = this.onMove.bind(this);
    this._Exit = this.onExit.bind(this);
    this.backgroundElt.addEventListener("mouseup", this._Exit);
    this.backgroundElt.addEventListener("mouseleave",this._Exit);
    this.backgroundElt.addEventListener("mousemove", this._Move);
  }

  onMove(e) {
    if (this.down) {
      // simple way to avoid iframe hover issues
      // dont forget to set pointerEvents back to "auto" at exit
      // should be done on move and not down, to not disable actions in modal top bar
      this.elt.style.pointerEvents = "none";
      this.dx = e.clientX - this.x;
      this.dy = e.clientY - this.y;
      this.x = e.clientX;
      this.y = e.clientY;
      if (this.dx !== 0 || this.dy !== 0) {
        this.elt.style.top = this.elt.offsetTop + this.dy + "px";
        this.elt.style.left = this.elt.offsetLeft + this.dx + "px";
      }
    }
  }

  onExit(e) {
    if (this.down) {
      this.down = false;
      this.elt.style.pointerEvents = "auto";
      this.backgroundElt.removeEventListener("mouseup", this._Exit);
      this.backgroundElt.removeEventListener("mouseleave", this._Exit);
      this.backgroundElt.removeEventListener("mousemove", this._Move);
    }
  }
}
