import I18n from "i18n-js";
import moment from 'moment/moment.js'
import {fr, moment_fr} from "./fr";
import {en, moment_en} from "./en";
import {de, moment_de} from "./de";
import {it, moment_it} from "./it";
import {es, moment_es} from "./es";


const initLocales = async (locale) => {

    I18n.locale = locale;
    I18n.fallbacks = true;
    if (locale==='fr') {
        I18n.translations = { fr }
        moment.locale('fr', moment_fr);
    }
    if (locale==='en') {
        I18n.translations = { en }
        moment.locale('fr', moment_en);
    }
    if (locale==='es') {
        I18n.translations = { es }
        moment.locale('fr', moment_es);
    }
    if (locale==='it') {
        I18n.translations = { it }
        moment.locale('fr', moment_it);
    }
    if (locale==='de') {
        I18n.translations = { de }
        moment.locale('fr', moment_de);
    }
}

export default initLocales;
