const filterObjectProperties = (originalObject, keyList) => {
  return Object.keys(originalObject)
    .filter((key) => keyList.includes(key))
    .reduce((acc, key) => {
      acc[key] = originalObject[key];
      return acc;
    }, {});
}

const marginPropertyNames = [
  "margin",
  "m",
  "marginLeft",
  "ml",
  "marginRight",
  "mr",
  "marginTop",
  "mt",
  "marginBottom",
  "mb",
  "marginX",
  "mx",
  "marginY",
  "my",
];

const paddingPropertyNames = [
  "padding",
  "p",
  "paddingLeft",
  "pl",
  "paddingRight",
  "pr",
  "paddingTop",
  "pt",
  "paddingBottom",
  "pb",
  "paddingX",
  "px",
  "paddingY",
  "py",
];

export const filterStyledSystemPaddingProps = (originalObject) => {
  return filterObjectProperties(originalObject, paddingPropertyNames);
}

export const filterStyledSystemMarginProps = (originalObject) => {
  return filterObjectProperties(originalObject, marginPropertyNames);
}
