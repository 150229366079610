import React, { useState, useEffect } from "react";
import {
  FlatTable,
  FlatTableBody,
  FlatTableRow,
  FlatTableCell,
  FlatTableCheckbox,
} from "carbon-react/lib/components/flat-table";
import { Accordion } from "carbon-react/lib/components/accordion";
import { isEqual } from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import eventBus from "../../events/eventBus";
import I18n from "i18n-js";

// set selected and expanded to false if not defined, or take existing values
const getSelectableExpandableDataFromData = (data) =>
  data
    .map((item) => ({ ...item, selected: item.selected ?? false }))
    .map((item) => ({ ...item, expanded: item.expanded ?? false }));

const getSelectableExpandableDataFromDataWithPreviousData = ({
  newData,
  firstSelectedItem,
}) =>
  newData.map((item) => ({
    ...item,
    selected: item.id === firstSelectedItem?.id,
  }));

const getHandleSelectRow = ({
  item: selectedItem,
  setData,
  selectCallback,
}) => () => {
  setData((previousData) =>
    previousData.map((item) =>
      isEqual(selectedItem, item)
        ? { ...item, selected: true }
        : { ...item, selected: false }
    )
  );
  selectCallback?.(selectedItem);
};

const getHandleExpandRow = ({ item: expandedItem, setData }) => () => {
  setData((previousData) =>
    previousData.map((item) =>
      isEqual(expandedItem, item)
        ? { ...item, expanded: !item.expanded }
        : { ...item, expanded: false }
    )
  );
};

const getAriaLabelledByValue = (headerProperties) =>
  headerProperties
    .map((_, headerPropertyIndex) => `ft-row-1-cell-${headerPropertyIndex}`)
    .join(" ");

const getHeaderCellsFromHeaderPropertiesAndItem = ({
  headerProperties,
  item,
}) =>
  headerProperties.map((header, headerPropertyIndex) => {
    let text = item[header];
    let align = "left";
    if (typeof header === "object") {
      if (header.text) text = header.text;
      if (header.field) text = item[header.field];
      if (header.align) align = header.align;
    }
    return (
      <FlatTableCell
        id={`ft-row-1-cell-${headerPropertyIndex + 1}`}
        align={align}
      >
        {text}
      </FlatTableCell>
    );
  });

const AccordionsContainer = styled.div`
  & {
    ${({ fixedHeight }) =>
      fixedHeight
        ? `
      max-height: ${fixedHeight};
      min-height: ${fixedHeight};
      overflow: auto;
      overflow-x: hidden;
    `
        : ""}
  }
  & [data-element="accordion-title-container"] {
    padding: 0;
  }

  & [data-element="accordion-headings-container"] {
    display: block;
    width: 100%;
  }

  & [data-element="accordion-content"] {
    padding: 0;
  }

  & tr:not(:hover) td {
    background-color: ${({ theme }) => theme?.menu?.custom?.hover};
  }

  & [data-element="accordion-title-container"]:focus tr td {
    background-color: white;
  }
  & [data-element="accordion-title-container"]:hover tr td {
    background-color: white;
  }

  & [data-element="accordion-title-container"] {
    background-color: ${({ theme }) => theme?.menu?.custom?.hover};
  }

  & [data-element="accordion-title-container"]:focus {
    background-color: white;
  }

  & [data-element="accordion-title-container"]:hover {
    background-color: white;
  }

  & td {
    border: none;
  }
  & td:last-of-type {
    border: none;
  }
  & td[data-element="flat-table-checkbox-cell"] {
    border: none;
  }
`;

const AccordionSelectableList = ({
  data: initialData,
  contentBuilder,
  headerProperties,
  selectCallback,
  refreshEvents,
  fixedHeight,
}) => {
  const [data, setData] = useState(
    getSelectableExpandableDataFromData(initialData)
  );

  useEffect(() => {
    setData(getSelectableExpandableDataFromData(initialData));
  }, [initialData]);

  useEffect(() => {
    if (refreshEvents?.length) {
      const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
        callback: (newData) => {
          if (newData.values) {
            setData((previousData) => {
              const firstSelectedItem = previousData.find(
                (item) => item.selected
              );
              const newSelectableExpandableData = getSelectableExpandableDataFromDataWithPreviousData(
                { newData: newData.values, firstSelectedItem }
              );
              if (!isEqual(previousData, newSelectableExpandableData)) {
                const newFirstSelectedItem = newSelectableExpandableData.find(
                  (item) => item.id === firstSelectedItem?.id
                );
                selectCallback?.(newFirstSelectedItem || null);
                return newSelectableExpandableData;
              }
              return previousData;
            });
          }
        },
        event: refreshEvent,
      }));

      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshEvents]);

  return (
    <AccordionsContainer fixedHeight={fixedHeight}>
      {data.length ? (
        data.map((item, itemIndex) => (
          <Accordion
            onChange={getHandleExpandRow({ item, setData })}
            expanded={item.expanded}
            title={(() => (
              <FlatTable>
                <FlatTableBody>
                  <FlatTableRow>
                    <FlatTableCheckbox
                      ariaLabelledBy={getAriaLabelledByValue(headerProperties)}
                      onClick={(e) => e.stopPropagation()}
                      checked={item.selected}
                      onChange={getHandleSelectRow({
                        item,
                        setData,
                        selectCallback,
                      })}
                    />
                    {getHeaderCellsFromHeaderPropertiesAndItem({
                      headerProperties,
                      item,
                    })}
                  </FlatTableRow>
                </FlatTableBody>
              </FlatTable>
            ))()}
          >
            {item.expanded && contentBuilder(item)}
          </Accordion>
        ))
      ) : (
        <div>{I18n.t("noResult")}</div>
      )}
    </AccordionsContainer>
  );
};

AccordionSelectableList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  contentBuilder: PropTypes.func.isRequired,
  headerProperties: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectCallback: PropTypes.func,
  refreshEvents: PropTypes.array,
};

export default AccordionSelectableList;
