import React, { useEffect, useState, useRef } from "react";
import { useDrop } from "react-dnd";
import { filterStyledSystemMarginProps } from "./utils";
import DraggableItem from "./DraggableItem";
import { StyledIcon, StyledDraggableContainer } from "./DraggableItemStyle";

const DropTarget = ({ children, getOrder, ...rest }) => {
  const [, drop] = useDrop({
    accept: "carbonDraggableItem",
    drop() {
      getOrder();
    },
  });

  return (
    <StyledDraggableContainer ref={drop} {...rest}>
      {children}
    </StyledDraggableContainer>
  );
};

const DraggableContainer = ({ children, getOrder, ...rest }) => {
  const [draggableItems, setDraggableItems] = useState(
    React.Children.toArray(children)
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      setDraggableItems(React.Children.toArray(children));
    } else {
      isFirstRender.current = false;
    }
  }, [children]);

  const findItem = (id) => {
    const draggableItem = draggableItems.filter((item) => {
      return `${item.props.id}` === id;
    })[0];

    return {
      draggableItem,
      index: draggableItems.indexOf(draggableItem),
    };
  };

  const moveItem = (id, atIndex) => {
    const { draggableItem, index } = findItem(id);
    const copyOfDraggableItems = [...draggableItems];

    copyOfDraggableItems.splice(index, 1);
    copyOfDraggableItems.splice(atIndex, 0, draggableItem);
    setDraggableItems(copyOfDraggableItems);
  };

  const getItemsId = () => {
    if (!getOrder) {
      return;
    }

    const draggableItemIds = draggableItems.map(
      (draggableItem) => draggableItem.props.id
    );

    getOrder(draggableItemIds);
  };

  const marginProps = filterStyledSystemMarginProps(rest);

  return (
      <DropTarget getOrder={getItemsId} {...marginProps}>
        {draggableItems.map((item) =>
          React.cloneElement(
            item,
            {
              id: `${item.props.id}`,
              findItem,
              moveItem,
            },
            [
              item.props.children,
              <StyledIcon key={item.props.id} type="drag" />,
            ]
          )
        )}
      </DropTarget>
  );
};

export default DraggableContainer;
