import React from "react";
import styled from "styled-components";
import Link from "carbon-react/lib/components/link";
import {
  useControlContext,
  useSliderConfContext,
} from "./FacetBusinessProcess";
import Ellipsis from "../../components/Ellipsis";

const getOnClick = ({ control, url, title }) => () => {
  control.callAction("openLink", { item: { url, title } });
};

const LinkContainer = styled.div`
  & button {
    text-decoration: none;
  }
  & button:hover {
    text-decoration: underline;
  }
  margin-top: 8px;
`;

const TitleContainer = styled.div`
  & div {
    text-align: start;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const LinkBoxTitle = ({ boxTitle }) => {
  const { title, href } = boxTitle || {};
  const control = useControlContext();
  const sliderConf = useSliderConfContext();

  return (
    <LinkContainer>
      <Link onClick={getOnClick({ control, url: href, title })}>
        <TitleContainer>
          <Ellipsis text={title} width={sliderConf.titleEllipsisWidth} />
        </TitleContainer>
      </Link>
    </LinkContainer>
  );
};

export default LinkBoxTitle;
