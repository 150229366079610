import React, { Component } from 'react';
import I18n from 'i18n-js';
import styled from 'styled-components';
import  {
  SortableTreeWithoutDndContext as SortableTree,
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  getNodeAtPath,
  toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import Button from 'carbon-react/lib/components/button';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import VerticalDivider from 'carbon-react/lib/components/vertical-divider';
import FacetSearchMenuEditor from './FacetSearchMenuEditor';
import MenuEntrySelection from './MenuEntrySelection';
import { ENTRY_TYPE } from './MenuEnums';

import '@nosferatu500/react-sortable-tree/style.css';

const GlobalMenu = styled.div`
  display: flex;
`;

const MenuEditor = styled.div`
  width: 100%;
`;

const Tree = styled.div`
  height: 75vh;
`;

const MenuEditorActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const SortableTreeCSS = styled(SortableTree)`
  /* drag icon */
  .rst__moveHandle:before {
    content: '\\e94c';
    font-family: CarbonIcons;
    font-size: x-large;
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(242, 245, 246, 1);
  }

  /* row matched search text */
  .rst__rowSearchMatch {
    outline: solid 3px #0073c2;
  }
`;

/**
 * Menu editor
 */
class FacetMenuEditor extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.facet.resetFocus && !prevState.resetFocus) {
      return {
        selectedNodeId: '',
        prevSelectedNodeId: prevState.selectedNodeId,
        resetFocus: nextProps.facet.resetFocus,
      };
    }
    if (nextProps.facet.resetFocus && prevState.resetFocus) {
      if (prevState.prevSelectedNodeId === '') {
        return {
          selectedNodeId: prevState.prevSelectedNodeId,
          prevSelectedNodeId: prevState.selectedNodeId,
          resetFocus: nextProps.facet.resetFocus,
        };
      }
      return {
        selectedNodeId: prevState.selectedNodeId,
        prevSelectedNodeId: '',
        resetFocus: nextProps.facet.resetFocus,
      };
    }
    return null;
  }

  state = {
    externalNodeType: 'entrySelection',
    expandedForAll: false,
    // focus
    selectedNodeId: '',
    prevSelectedNodeId: '',
    resetFocus: false,
    // search state
    searchString: '',
    searchFoundCount: 0,
    searchFocusIndex: 0,
  };

  /**
   * Retrieve the node key
   * @param {Object} node - Node of the tree
   */
  getNodeKey = ({ node }) => node.id;

  /**
   * Generate a new node with a unique temporary key
   * @returns Empty default node object
   */
  getNewNode = () => {
    return {
      title: I18n.t('menuEditor.entry.title', { defaultValue: 'New' }),
      custom: {
        caption: I18n.t('menuEditor.entry.caption', { defaultValue: 'New' }),
        menuVisibility: ['mvDesktop', 'mvWebtop'],
      },
      id: uuidv4(),
    };
  };

  /**
   * Generate a new break node with a unique temporary key
   * @returns Break node object { title: '-', custom: { break: 'mbBreak' }}
   */
  getNewBreakNode = () => {
    return {
      title: '-',
      custom: {
        caption: '-',
        break: 'mbBreak',
        menuVisibility: ['mvDesktop', 'mvWebtop'],
      },
      id: uuidv4(),
    };
  };

  /**
   * Add a node to the root
   */
  addNodeToRootHandler = () => {
    const newNode = this.getNewNode();
    this.props.control.updateTree(
      this.props.facet.values[0].tree.tree.concat(newNode),
      [newNode.id]
    );
    this.setState({
      selectedNodeId: newNode.id,
      prevSelectedNodeId: this.state.selectedNodeId,
    });
  };

  /**
   * Add a node under a parent node to the tree
   * @param {string} path - New node's path
   */
  addNodeUnderParentHandler = (event, path) => {
    const newNode = this.getNewNode();
    this.props.control.updateTree(
      addNodeUnderParent({
        treeData: this.props.facet.values[0].tree.tree,
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey: this.getNodeKey,
        newNode: newNode,
        addAsFirstChild: false,
      }).treeData,
      [...path, newNode.id]
    );
    this.setState({
      selectedNodeId: newNode.id,
      prevSelectedNodeId: this.state.selectedNodeId,
    });
    event.stopPropagation();
  };

  /**
   * Add a break node under a parent node to the tree
   * @param {string} path - New node's path
   */
  addBreakNodeUnderParentHandler = (event, path) => {
    const newNode = this.getNewBreakNode();
    this.props.control.updateTree(
      addNodeUnderParent({
        treeData: this.props.facet.values[0].tree.tree,
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey: this.getNodeKey,
        newNode: newNode,
        addAsFirstChild: false,
      }).treeData,
      [...path, newNode.id]
    );
    this.setState({
      selectedNodeId: newNode.id,
      prevSelectedNodeId: this.state.selectedNodeId,
    });
    event.stopPropagation();
  };

  /**
   * Remove a node from the tree
   * @param {string} path - Node's path to be removed
   */
  deleteNodeHandler = (event, path) => {
    this.props.control.updateTree(
      removeNodeAtPath({
        treeData: this.props.facet.values[0].tree.tree,
        path,
        getNodeKey: this.getNodeKey,
      }),
      -1
    );
    this.setState({ selectedNodeId: '', prevSelectedNodeId: '' });
    event.stopPropagation();
  };

  /**
   * onClick event on a node
   */
  clickNodeHandler = (event, node, path) => {
    if (
      event &&
      (event.target.className.includes('collapseButton') ||
        event.target.className.includes('expandButton'))
    ) {
      // ignore the event
    } else {
      this.setState({
        selectedNodeId: node.id,
        prevSelectedNodeId: this.state.selectedNodeId,
      });
      const lightNode = cloneDeep(
        getNodeAtPath({
          treeData: this.props.facet.values[0].tree.tree,
          path,
          getNodeKey: this.getNodeKey,
        }).node
      );
      delete lightNode.children;
      this.props.control.selectNode({ ...lightNode, path: path });
    }
  };

  /**
   * Set default values on an external node and focus it
   * @param { treeData: object[],
   * node: object, nextParentNode: object,
   * prevPath: number[] or string[],
   * prevTreeIndex: number,
   * nextPath: number[] or string[],
   * nextTreeIndex: number } nodeInfo
   */
  onMoveNodeHandler = (nodeInfo) => {
    if (nodeInfo.node.hasOwnProperty('name')) {
      // external node from menu entries selection
      // needs to set default values
      let newNode;
      if (nodeInfo.node.type !== ENTRY_TYPE.OTHER) {
        newNode = this.getNewNode();
        newNode.title = nodeInfo.node.title;
        newNode.custom.caption = nodeInfo.node.title;
        newNode.custom.typeItem = nodeInfo.node.type;
        if (nodeInfo.node.type === ENTRY_TYPE.DFM) {
          newNode.custom.nameDfmFile = nodeInfo.node.name;
        } else if (nodeInfo.node.type === ENTRY_TYPE.MENU) {
          if (nodeInfo.node.name !== '' && nodeInfo.path.length > 1) {
            newNode.title = ':';
            newNode.custom.caption = ':';
          }
          newNode.custom.nameDfmFile = nodeInfo.node.name;
        } else {
          newNode.custom.lnkFile = nodeInfo.node.name;
          if (nodeInfo.node.type === ENTRY_TYPE.PORTAL_EXECUTE) {
            newNode.custom.menuVisibility = ['mvWebtop'];
          }
        }
      } else {
        newNode = this.getNewBreakNode();
      }
      //
      // path contains the old node id
      this.props.control.updateTree(
        changeNodeAtPath({
          treeData: this.props.facet.values[0].tree.tree,
          path: nodeInfo.nextPath,
          getNodeKey: this.getNodeKey,
          newNode: newNode,
        })
      );
      // set selected node
      this.setState({
        selectedNodeId: newNode.id,
        prevSelectedNodeId: this.state.selectedNodeId,
      });
      let lightNode = cloneDeep(newNode);
      delete lightNode.children;
      // replace old node id with new node id in the path
      let newPath = nodeInfo.path.map((path) => {
        if (path.toString().startsWith('{')) return newNode.id;
        return path;
      });
      this.props.control.selectNode({ ...lightNode, path: newPath });
    }
  };

  /**
   * Expand or collaspe all nodes
   */
  toggleExpandedForAll = () => {
    this.props.control.updateTree(
      toggleExpandedForAll({
        treeData: this.props.facet.values[0].tree.tree,
        expanded: !this.state.expandedForAll,
      })
    );
    this.setState({ expandedForAll: !this.state.expandedForAll });
  };

  render() {
    /**
     * Define a custom search method
     * @param {Object, string} { node, searchQuery } - Node's data the search query to compared to
     */
    const customSearchMethod = ({ node, searchQuery }) => {
      return (
        searchQuery &&
        node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
      );
    };

    /**
     * Generate additionnal props for each node (add child & remove buttons for example)
     * @param {string, string} { node, path } - Node's data and its path
     */
    const generateNodePropsHandler = ({ node, path }) => {
      return {
        subtitle: <p>{node.custom ? node.custom.hint : ''}</p>,
        buttons: [
          <IconButton onAction={(event) => this.deleteNodeHandler(event, path)}>
            <Icon
              type='bin'
              aria-label={I18n.t('menuEditor.entry.action.remove', {
                defaultValue: 'Remove',
              })}
              tooltipMessage={I18n.t('menuEditor.entry.action.remove', {
                defaultValue: 'Remove',
              })}
              color='#C7384F' // TODO color
            />
          </IconButton>,
        ],
        onClick: (event) => this.clickNodeHandler(event, node, path),
        style:
          node.id === this.state.selectedNodeId
            ? {
                outline: 'solid 3px #FFB500', // TODO color
              }
            : {},
      };
    };

    const toogleExpandedCaption = this.state.expandedForAll
      ? I18n.t('menuEditor.collapseAll', { defaultValue: 'Collapse all' })
      : I18n.t('menuEditor.expandAll', { defaultValue: 'Expand all' });

    return (
      <GlobalMenu>
        <MenuEntrySelection
          dndType={this.state.externalNodeType}
          getNodeKey={this.getNodeKey}
          data={this.props.facet.values[0].entries}
          control={this.props.control}
        />
        <VerticalDivider />
        <MenuEditor>
          <MenuEditorActions>
            <FacetSearchMenuEditor
              searchString={this.state.searchString}
              searchFoundCount={this.state.searchFoundCount}
              searchFocusIndex={this.state.searchFocusIndex}
              setSearchString={(value) => {
                this.setState({ searchString: value });
              }}
              setSearchFocusIndex={(index) => {
                this.setState({ searchFocusIndex: index });
              }}
            />
            <Button
              size='small'
              buttonType='secondary'
              iconType={
                this.state.expandedForAll ? 'chevron_up' : 'chevron_down'
              }
              onClick={this.toggleExpandedForAll}
            >
              {toogleExpandedCaption}
            </Button>
          </MenuEditorActions>
          <Tree>
            <SortableTreeCSS
              dndType={this.state.externalNodeType}
              treeData={this.props.facet.values[0].tree.tree}
              onChange={(treeData) => this.props.control.updateTree(treeData)}
              getNodeKey={this.getNodeKey}
              onMoveNode={this.onMoveNodeHandler}
              generateNodeProps={({ node, path }) =>
                generateNodePropsHandler({ node, path })
              }
              searchMethod={customSearchMethod}
              searchQuery={this.state.searchString}
              searchFocusOffset={this.state.searchFocusIndex}
              searchFinishCallback={(matches) => {
                this.setState({
                  searchFoundCount: matches.length,
                  searchFocusIndex:
                    matches.length > 0
                      ? this.state.searchFocusIndex % matches.length
                      : 0,
                });
                if (matches.length > 0) {
                  this.clickNodeHandler(
                    undefined,
                    matches[this.state.searchFocusIndex].node,
                    matches[this.state.searchFocusIndex].path
                  );
                }
              }}
            />
          </Tree>
        </MenuEditor>
      </GlobalMenu>
    );
  }
}

export default FacetMenuEditor;
