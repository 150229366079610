import React, {Component, useRef, useState} from 'react';
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styled from 'styled-components';

const EmailEditorWrapper = styled.div`
  font-size: 15px;
  line-height: 1em;
`;

const CustomLinkDiv = styled.div`
  padding: 4px 5px 0 5px;
`;

const getFileBase64 = (file, callback) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  // Since FileReader is asynchronous,
  // we need to pass data back.
  reader.onload = () => callback(reader.result);
  // TODO: catch an error
  reader.onerror = error => {};
};

const imageUploadCallback = file => new Promise(
  (resolve, reject) => getFileBase64(
    file,
    data => resolve({ data: { link: data } })
  )
);

const CustomSelect = ({value,editorState,onChange}) => {

  const [state, setState] = useState({open:false});

  const openPlaceholderDropdown = (e) => {
    if (e) e.preventDefault();
    setState({open: !state.open})
  }

  const getTitle = (item) => {
    if (item.title) return item.title;
    return item.value;
  }

  const onItemClick = (e,item) => {

    if (e) e.preventDefault();
    //
    const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    item.value,
    editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    //
    setState({open:false})
  }

  const placeholderOptions = () => {
    if (value && value.values) return value.values;
    return [];
  }

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return (
    <div class="rdw-block-wrapper" aria-label="rdw-block-control">
    <div class="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown" aria-expanded={state.open}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a class="rdw-dropdown-selectedtext" title={value.hint}>
    <span>{value.title}</span>
    {state.open?
    <div class="rdw-dropdown-carettoclose" onClick={openPlaceholderDropdown}></div>
    :
    <div class="rdw-dropdown-carettoopen" onClick={openPlaceholderDropdown}></div>
    }
    </a>
    {state.open?
            <ul class="rdw-dropdown-optionwrapper">
            {placeholderOptions().map( (item,index) => (
              <li onClick={(e) => onItemClick(e,item)} key={index} class="rdw-dropdownoption-default">{getTitle(item)}</li>
            ))}
            </ul>
           : null
    }
    </div>
    </div>
  );
}

const CustomLink = ({link, editorState, onChange, ...props}) => {

  const onClick = (e) => {
    if (e) e.preventDefault();

    const contentBlock = htmlToDraft(link.html);
    const blockMap = ContentState.createFromBlockArray(contentBlock.contentBlocks).blockMap;

    const newContentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );

    onChange(EditorState.push(editorState, newContentState, 'insert-fragment'));
  }

  return (
    <CustomLinkDiv class="rdw-embedded-wrapper" title={link.hint} onClick={onClick}>{link.title}</CustomLinkDiv>
  );
}

const CustomLinks = ({editor, editorState, onChange, ...props}) => {

  const getLinks = () => {

    if (editor && editor.links) return editor.links;
    return [];
  }

  return (
    <React.Fragment>
      {getLinks().map( (link,index) => (<CustomLink key={index} link={link} editorState={editorState} onChange={onChange} />)) }
    </React.Fragment>
  );
}

const CustomToolbar = ({editor, editorState,onChange, ...props}) => {
  return (
    <React.Fragment>
    {editor.links?<CustomLinks editor={editor} editorState={editorState} onChange={onChange} />:null}
    {editor.variables?<CustomSelect value={editor.variables} editorState={editorState} onChange={onChange} />:null}
    </React.Fragment>
  );
}

class EmailEditor extends Component{
  constructor(props){
    super(props);
    //
    this.editorRef = React.createRef();

    let html = '<p>Hey this <strong>editor</strong> rocks 😀</p>';

    if ((this.props.value) && (this.props.value.editor) && (this.props.value.editor.html)) {
      html = this.props.value.editor.html
    }

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  getEditor = () => {
    if (this.props.value && this.props.value.editor) return this.props.value.editor;
    return {};
  }

  onEditorStateChange = (editorState) => {
    this.setState({editorState,});
    //
    let raw = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(raw);
    this.props.control.setFacetValue(this.props.facet,this.props.value,html);
  };

  getLocale = () => {
    if (this.props.theme) return this.props.theme.locale;
    return 'fr';
  }


  render(){
    const { editorState } = this.state;
    return <EmailEditorWrapper>
      <Editor
        localization={{ locale: this.getLocale()}}
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        ref={this.editorRef}
        toolbar={{
          options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: imageUploadCallback, alt: { present: true, mandatory: false } },
        }}
        toolbarCustomButtons= {[<CustomToolbar editor={this.getEditor()} />]}
        />
    </EmailEditorWrapper>
  }
}

export default EmailEditor;
