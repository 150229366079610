import React, { useState, useEffect } from "react";
import BaseGrid from "../components/BaseGrid";
import AccordionSelectableList from "../components/AccordionSelectableList";
import eventBus from "../../events/eventBus";
/* TODO : -Event name should be given by Api, both here and in ButtonFacet validation.
          -In ButtonFacet, on validate, if eventName given, it should emit on eventBus this event with validate api method in parameter.
          -In FacetAccordionGrid, it should subsribe at this event, and when receive, call validate apiMethod with selectedItem */
const getContentBuilder = ({ columnDefs, dataPropertyName }) => (item) => (
  <BaseGrid noHeader columnDefs={columnDefs} data={item[dataPropertyName]} />
);

const getSelectCallback = ({ value, setSelectedItem }) => item => {
  setSelectedItem(item);
  eventBus.emit("SelectDone", { id: value.groupId, item });
}

const FacetAccordionGrid = ({ value, control }) => {
  const {
    contentGridColumnDefs: columnDefs,
    dataPropertyName,
    accordionHeaderProperties: headerProperties,
    loadMethod,
    refreshEvents,
    fixedHeight
  } = value;
  const [selectedItem, setSelectedItem] = useState();
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    control.callMethod(loadMethod, {}, (data) => {
      if(Array.isArray(data?.data)) {
        const firstSelectedItem = data.data.find(item => item.selected);
        setInitialData(data.data);
        eventBus.emit("SelectDone", { id: value.groupId, item: firstSelectedItem });
        setSelectedItem(firstSelectedItem || null);
      }
    });
  }, [control, loadMethod, value.groupId]);

  return (
    <AccordionSelectableList
      contentBuilder={getContentBuilder({ columnDefs, dataPropertyName })}
      data={initialData}
      refreshEvents={refreshEvents}
      headerProperties={headerProperties}
      selectCallback={getSelectCallback({ value, setSelectedItem })}
      fixedHeight={fixedHeight}
    />
  );
};

export default FacetAccordionGrid;
