import React from "react";
import styled from "styled-components";
import Typography from "carbon-react/lib/components/typography";
import Icon from "carbon-react/lib/components/icon";
import useTheme from "../../hooks/useTheme";
import { useBusinessProcessContext } from "./FacetBusinessProcess";
import I18n from "i18n-js";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TooltipMessage = ({ infos }) => {
  const theme = useTheme();

  return (
    <div>
      {infos?.map?.((info, key) => (
        <Typography key={key} color={theme?.colors?.white}>
          {info}
        </Typography>
      ))}
    </div>
  );
};

const BusinessProcessTitle = ({ infos, title }) => {
  const theme = useTheme();
  const { canEdit } = useBusinessProcessContext();

  return (
    <div>
      <TitleContainer>
        {title && title !== "" ? (
          <Typography
            variant="h1"
            fontSize="24px"
            fontWeight="900"
            lineHeight="31px"
          >
            {title}
          </Typography>
        ) : (
          <Typography
            variant="h1"
            fontSize="24px"
            fontWeight="900"
            lineHeight="31px"
          >
            {I18n.t("businessProcess.businessProcess")}
          </Typography>
        )}
        {infos && infos.length ? (
          <Icon
            mt="4px"
            ml="12px"
            type="info"
            color={theme?.text?.color}
            tooltipMessage={<TooltipMessage infos={infos} />}
            tooltipPosition="right"
          />
        ) : null}
      </TitleContainer>
      {canEdit ? (
        <Typography
          color={theme?.help?.color}
          fontSize="14px"
          fontWeight="400"
          lineHeight="21px"
        >
          {I18n.t("businessProcess.dragAndDropExplanation")}
        </Typography>
      ) : null}
    </div>
  );
};

export default BusinessProcessTitle;
