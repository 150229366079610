import React from "react";
import IconSelection from "./IconSelection";
import styled from "styled-components";
import Typography from "carbon-react/lib/components/typography";

const LabelAndIconSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputIcon = ({ label, onChange, customIconList, initialIcon }) => {
  return (
    <LabelAndIconSelectionContainer>
      <Typography fontWeight="600" fontSize="14px">
        {label}
      </Typography>
      <IconSelection
        customIconList={customIconList}
        setLocalIcon={(newIcon) => {
          onChange(newIcon);
        }}
        icon={initialIcon}
      />
    </LabelAndIconSelectionContainer>
  );
};

export default InputIcon;
