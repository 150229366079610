const workertick = () => {

	var data = {tick:0, tickms:1000};
    let doStart = true;

    /* eslint-disable-next-line no-restricted-globals */
    self.onmessage = function(e) {

        if (e.data && e.data.cmd) {
            switch (e.data.cmd) {
                case "tickms":
                    data.tickms = e.data.param;
                break
								default:
            }
        }
    };

    if (doStart) {
        var myFunction = function() {
            clearInterval(interval);
            data.tick++;
            /* eslint-disable-next-line no-restricted-globals */
            self.postMessage(data);
            interval = setInterval(myFunction,data.tickms);
        }
        var interval = setInterval(myFunction, data.tickms);

        doStart = false;
    }
  };

  let code = workertick.toString();
  code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

  const blob = new Blob([code], { type: "application/javascript" });
  const workertick_script = URL.createObjectURL(blob);

  module.exports = workertick_script;
