import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import ButtonToggle from "carbon-react/lib/components/button-toggle"

const ToggleButtonWrapper = styled.div`
  & label {
    font-weight: 400;
  }
`;

const InputToggleButton = ({ facet, control, value,  ...props }) => {

    const buttonRef = useRef(null);

    const [newValue, setNewValue] = useState(null);

    useEffect(() => {
        setNewValue(value.value);
    }, [control.roundTrip, value.value]);

    const onCheckValueChange = (event) => {
        let newVal = event.target.checked; 
        setNewValue(newVal);
        if (value.value!==newVal) control.setFacetValue(facet,value,newVal);
    }
    const getId = () => {
        return value.id.toString();
    }
    const getLabel = () => {
        if (value.title) return value.title;
        return null;
    }
    const getChecked = () => {
        // using only newValue don't work, need === !
        return (newValue===true ? true : false);
    }
    const getDisabled = () => {
        return value.disabled || false;
    }
    const getSize = (defSize) => {
        if (value.size) return value.size;
        if (defSize) return defSize;
        return "";
    }
    const getGroupName = (defGroup) => {
        if (value.groupName) return value.groupName;
        if (defGroup) return defGroup;
        return null;
    }
    const getGroupValue = (d) => {
        if (value.groupValue) return value.groupValue;
        return getId();
    }

    window.setTimeout(()=>{
        if (buttonRef.current && !getGroupName()) {
            // change the input type to checkbox
            // because the radio change event is not fire in any case
            const inputs = buttonRef.current.getElementsByTagName('input');
            if (inputs.length>0) {
                inputs[0].type="checkbox";
            }
        }
    },1)

    return (  
        <ToggleButtonWrapper ref={buttonRef}>
            <ButtonToggle 
                name={getGroupName()} 
                checked={getChecked()} 
                onChange={onCheckValueChange} 
                disabled={getDisabled()} 
                size={getSize("small")} 
                value={getGroupValue()}>

                <div>{getLabel()}</div>
            </ButtonToggle>
        </ToggleButtonWrapper>
    )
}

export default InputToggleButton;
