import React, { useState } from "react";
import { FacetListItem } from "../facets/Facet";
import FacetAccordionGrid from "./FacetAccordionGrid";
import FacetBaseGrid from "./FacetBaseGrid";
import FacetBusinessProcess from "./FacetBusinessProcess/FacetBusinessProcess";
import FacetButton from "./FacetButton";
import FacetCustomButton from "./FacetCustomButton";
import FacetDialogButton from "./FacetDialogButton";
import FacetMenuButton from "./FacetMenuButton";
import FacetPanelButton from "./FacetPanelButton";
import FacetIcon from "./FacetIcon";
import FacetLoadedDialog from "./FacetLoadedDialog";
import FacetPopoverButton from "./FacetPopoverButton";
import FacetSelectableGrid from "./FacetSelectableGrid";
import FacetSelectableList from "./FacetSelectableList";
import FacetTable from "./FacetTable";
import FacetTreeview from "./FacetTreeview";
import TextTypography from "../components/TextTypography";
import BigIconAction from "../components/BigIconAction";
import Checkbox from "../components/Checkbox";
import ItemSelect from "../components/ItemSelect";
import FacetDebouncedSearch from "../facets/FacetDebouncedSearch";
import IconAction from "../components/IconAction";
import IconPopover from "../components/IconPopover";
import IconProfile, { Profile } from "../components/IconProfile";
import IconEvents from "../components/IconEvents";
import IFrame from "../components/IFrame";
import IconEmailEditor from "../components/IconEmailEditor";
import IconCodeEditor from "../components/IconCodeEditor";
import FacetEmailEditor from "./FacetEmailEditor";
import FacetCodeEditor from "./FacetCodeEditor";
import IconLookup from "../components/IconLookup";
import IconParameters from "../components/IconParameters";
import IconTreeview from "../components/IconTreeview";
import Image from "../components/Image";
import InputField from "../components/InputField";
import InputSelect from "../components/InputSelect";
import InputText from "../components/InputText";
import InputAddSelect from "../components/InputAddSelect";
import IconDialog from "../components/IconDialog";
import IconForm from "../components/IconForm";
import Spacing from "../components/Spacing";
import TextValue from "../components/TextValue";
import TextTitle, {
  TextHeader,
  Separator,
  Divider,
  TextEllipsis,
} from "../components/TextTitle";
import TextMessage from "../components/TextMessage";
import FacetSearch from "./FacetSearch";
import FacetList from "./FacetList";
import FacetToolbar from "./FacetToolbar";
import FavouriteRow from "../components/FavouriteRow";
import Menu from "../components/Menu";
import VMenu from "../components/VMenu";
import Sage from "../components/Sage";
import Link from "../components/Link";

// FacetList cant be used in this list. See comment line 265.
const components = {
  BigIconAction,
  Checkbox,
  ItemSelect,
  FacetDebouncedSearch,
  FacetListItem,
  FacetAccordionGrid,
  FacetBaseGrid,
  FacetBusinessProcess,
  FacetButton,
  FacetCustomButton,
  FacetDialogButton,
  FacetMenuButton,
  FacetPanelButton,
  FacetIcon,
  FacetLoadedDialog,
  FacetPopoverButton,
  FacetSelectableGrid,
  FacetSelectableList,
  FacetTable,
  FacetTreeview,
  TextTypography,
  IconAction,
  IconPopover,
  IconProfile,
  Profile,
  IconEvents,
  IFrame,
  IconEmailEditor,
  IconCodeEditor,
  FacetEmailEditor,
  FacetCodeEditor,
  IconLookup,
  IconParameters,
  IconTreeview,
  Image,
  InputField,
  InputSelect,
  InputText,
  InputAddSelect,
  IconDialog,
  IconForm,
  Spacing,
  TextValue,
  TextTitle,
  TextHeader,
  TextMessage,
  Separator,
  Divider,
  TextEllipsis,
  FacetSearch,
  FacetToolbar,
  FavouriteRow,
  Menu,
  VMenu,
  Sage,
  Link,
};

const facetsToReactComponentsMapping = {
  search: { facetName: "search", componentName: "FacetSearch" },
  debouncedSearch: {
    facetName: "debouncedSearch",
    componentName: "FacetDebouncedSearch",
  },
  enum: { facetName: "enum", componentName: "InputSelect" },
  select: { facetName: "select", componentName: "InputSelect" },
  "input-select": { facetName: "input-select", componentName: "InputSelect" },
  accordionGrid: {
    facetName: "accordionGrid",
    componentName: "FacetAccordionGrid",
  },
  addSelect: { facetName: "addSelect", componentName: "InputAddSelect" },
  "input-addSelect": {
    facetName: "input-addSelect",
    componentName: "InputAddSelect",
  },
  mail: { facetName: "mail", componentName: "IconEmailEditor" },
  "input-mail": { facetName: "input-mail", componentName: "IconEmailEditor" },
  emailEditor: { facetName: "emailEditor", componentName: "FacetEmailEditor" },
  checkbox: { facetName: "checkbox", componentName: "Checkbox" },
  code: { facetName: "code", componentName: "IconCodeEditor" },
  "input-code": { facetName: "input-code", componentName: "IconCodeEditor" },
  codeEditor: { facetName: "codeEditor", componentName: "FacetCodeEditor" },
  itemSelect: { facetName: "itemSelect", componentName: "ItemSelect" },
  toolBar: { facetName: "toolBar", componentName: "FacetToolbar" },
  parameters: { facetName: "parameters", componentName: "IconParameters" },
  popoverButton: {
    facetName: "popoverButton",
    componentName: "FacetPopoverButton",
  },
  classProperty: { facetName: "classProperty", componentName: "IconTreeview" },
  lookup: { facetName: "lookup", componentName: "IconLookup" },
  iframe: { facetName: "iframe", componentName: "IFrame" },
  table: { facetName: "table", componentName: "FacetTable" },
  treeview: { facetName: "treeview", componentName: "FacetTreeView" },
  typography: { facetName: "typography", componentName: "TextTypography" },
  label: { facetName: "label", componentName: "TextTypography" },
  header: { facetName: "header", componentName: "TextHeader" },
  title: { facetName: "title", componentName: "TextTitle" },
  message: { facetName: "message", componentName: "TextMessage" },
  ellipsis: { facetName: "ellipsis", componentName: "TextEllipsis" },
  icon: { facetName: "icon", componentName: "FacetIcon" },
  separator: { facetName: "separator", componentName: "Separator" },
  divider: { facetName: "divider", componentName: "Divider" },
  selectableGrid: {
    facetName: "selectableGrid",
    componentName: "FacetSelectableGrid",
  },
  selectableList: {
    facetName: "selectableList",
    componentName: "FacetSelectableList",
  },
  spacing: { facetName: "spacing", componentName: "Spacing" },
  "input-form": { facetName: "input-form", componentName: "IconForm" },
  tabular: { facetName: "tabular", componentName: "FacetBaseGrid" },
  businessProcess: {
    facetName: "businessProcess",
    componentName: "FacetBusinessProcess",
  },
  button: { facetName: "button", componentName: "FacetButton" },
  customButton: {
    facetName: "customButton",
    componentName: "FacetCustomButton",
  },
  dialog: { facetName: "dialog", componentName: "IconDialog" },
  dialogButton: {
    facetName: "dialogButton",
    componentName: "FacetDialogButton",
  },
  menuButton: { facetName: "menuButton", componentName: "FacetMenuButton" },
  panelButton: { facetName: "panelButton", componentName: "FacetPanelButton" },
  loadedDialog: {
    facetName: "loadedDialog",
    componentName: "FacetLoadedDialog",
  },
  inputText: { facetName: "inputText", componentName: "InputText" },
  text: { facetName: "text", componentName: "TextValue" },
  messages: { facetName: "messages", componentName: "FacetList" },
  menu: { facetName: "menu", componentName: "Menu" },
  vmenu: { facetName: "vmenu", componentName: "VMenu" },
  action: { facetName: "action", componentName: "IconAction" },
  bigIconAction: { facetName: "bigIconAction", componentName: "BigIconAction" },
  iconAction: { facetName: "iconAction", componentName: "IconAction" },
  iconPopover: { facetName: "iconPopover", componentName: "IconPopover" },
  iconProfile: { facetName: "iconProfile", componentName: "IconProfile" },
  image: { facetName: "image", componentName: "Image" },
  actionProfile: { facetName: "actionProfile", componentName: "IconProfile" },
  profile: { facetName: "profile", componentName: "Profile" },
  iconEvents: { facetName: "iconEvents", componentName: "IconEvents" },
  sage: { facetName: "sage", componentName: "Sage" },
  favouriteRow: { facetName: "favouriteRow", componentName: "FavouriteRow" },
  link: { facetName: "link", componentName: "Link" },
  default: { componentName: "InputField" },
};

const getValueClass = (value) => {
  if (value.class === "edit") return value.editClass;
  return value.class;
};

const getClassName = (value) => {
  let aClass = "facet-value";

  if (!value || !value.class) return aClass;
  if (value.class === "edit") aClass = "facet-input";
  if (value.class === "grid") aClass = "facet-value-grid";
  if (value.class.startsWith("input-")) aClass = "facet-input";
  if (value.selected) return aClass + " facet-selected";

  return aClass;
};

const getStyle = ({ value, props }) => {
  if (value.style) return value.style;
  if (props.style) return props.style;
  return {};
};

const getFacet = ({ facet, value, componentConf }) => {
  if (
    componentConf.facetName === "vmenu" ||
    componentConf.facetName === "menu" ||
    componentConf.facetName === "messages"
  )
    return value;
  return facet;
};

const getValue = ({ value, componentConf }) => {
  if (
    componentConf.facetName === "vmenu" ||
    componentConf.facetName === "menu" ||
    componentConf.facetName === "messages"
  )
    return undefined;
  return value;
};

const FacetValue = ({ facet, value, control, noWrapping, ...props }) => {
  const componentConf =
    facetsToReactComponentsMapping[getValueClass(value)] ||
    facetsToReactComponentsMapping.default;

  // should be done this way for FacetList because of dependencies between FacetList and FacetValue
  // FacetList use FacetValue
  // FacetValue use also FacetList
  // compiler is able to solve that but FacetList import take more time to be populated
  const ComponentToDisplay =
    componentConf.componentName === "FacetList"
      ? FacetList
      : components[componentConf.componentName];

  return noWrapping ? (
    <ComponentToDisplay
      facet={getFacet({ facet, value, componentConf })}
      control={control}
      value={getValue({ value, componentConf })}
      {...props}
    />
  ) : (
    <FacetListItem
      className={getClassName(value)}
      key={value.id}
      style={getStyle({ value, props })}
    >
      <ComponentToDisplay
        facet={getFacet({ facet, value, componentConf })}
        control={control}
        value={getValue({ value, componentConf })}
        {...props}
      />
    </FacetListItem>
  );
};

export default FacetValue;
