import React, { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import eventBus from "../../events/eventBus";
import Icon from "carbon-react/lib/components/icon";
import iconUnicodes from "../icons/icon-unicodes";
import IconButton from "carbon-react/lib/components/icon-button";
import Pill from "carbon-react/lib/components/pill";
import PopoverContainer from "./PopoverContainer";
import FacetDialog from "../facets/FacetDialog";
import { getClickHandler, getActionType, getDialogValue } from "../../common/actionHelpers";

/*
  {
    id: 1,
    name:"action name",
    title:"action title",
    disable:false,

    dialog: {
      // open dialog
    },
    event: {
      // emit event action
      name:"event name",
      param:"event param",
    },
    form: {
      // when embedded inside legacy client
      // open form from legacy client
      uri:"form url",
    },
    call: {
      // callAction
      name:"action name",
      ...,
    },
    param:{
      // sent as action parameter
      // if default action
    },


    // as IconButton
    icon:"icon name", // => display as iconButton
    overlay:{type:"icon name"}, // icon overlay

    // with popover content
    popover: {
      title:"",
      ... TODO
    }

    // display as pill (default)
    //as pill
    role:"pill role",
    colorVariant:"pill color variant",

  }
*/

const IconContainer = styled.div.attrs((props) => ({
  theme: props.theme,
  type: props.type,
}))`
  display: flex;
  align-items: center;
  & span[data-component=icon]:after {
    position: absolute;
    top: 0;
    right: 0;
    transition: .5s ease;
    opacity:var(--opacity);
    color: var(--color);
    font-family: CarbonIcons;
    font-size:14px;
    content:  "${({ type }) => iconUnicodes[type]}";
  }
`;

const IconIndicator = styled.div``;
const IconDropdown = styled.div``;

const ActionIcon = ({ action, onClick, darkMode, ...props }) => {
  const reducer = (state, data) => {
    let newState = cloneDeep(state);
    switch (data.action) {
      case "set":
        if (newState.title !== data.data) {
          newState.title = data.data;
          newState.overlay = action.indicator.overlay;
        }
        break;
      case "clear":
        newState.overlay = "";
        break;
      default:
    }
    return newState;
  };

  const [open, setOpen] = useState(false);
  const [indicator, setIndicator] = useReducer(reducer, {
    title: "",
    overlay: "",
  });
  const onOpen = (e) => {
    if (e) e.preventDefault();
    setOpen(true);
  };
  const onClose = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
    setIndicator({ title: indicator.title, overlay: "" });
  };

  const ICStyle = {
    "--opacity": 1, //(action.overlay||(action.indicator) && (action.indicator.overlay)?1:0),
    "--color": props.theme ? props.theme.colors.warning : "#ffffff",
  };

  useEffect(() => {
    if (action.indicator && action.indicator.event) {
      eventBus.on(action.indicator.event, (data) => {
        //
        setIndicator({ action: "set", data: data.title });
        //
        if (action.indicator.overlay) {
          window.setTimeout(() => {
            setIndicator({ action: "clear", data: null });
          }, action.indicator.delay || 2000);
        }
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  const getIndicator = () => {
    if (indicator.title) return indicator.title;
    if (action.indicator) return action.indicator.title;
    return "";
  };

  const getOverlayType = () => {
    if (indicator.overlay) return indicator.overlay;
    if (action.overlay) return action.overlay.type;
    return "";
  };

  if (action.icon) {
    return (
      <IconButton onAction={onClick}>
        <IconContainer type={getOverlayType()} style={{ ...ICStyle }}>
          <Icon
            color={darkMode ? "white" : undefined}
            type={action.icon}
            aria-label={action.title}
            tooltipMessage={action.title}
            tooltipPosition="bottom"
            disabled={action.disabled}
            {...props}
          />
          <IconIndicator>{getIndicator()}</IconIndicator>
          {action.popover ? (
            <PopoverContainer
              title={action.popover.title || ""}
              open={open}
              position="left"
              onOpen={onOpen}
              onClose={onClose}
              renderOpenComponent={({ isOpen, ref, ...props }) => (
                <IconDropdown role="icon-dropdown" onClick={onOpen}>
                  <Icon type="dropdown" />
                </IconDropdown>
              )}
            >
              Contents
            </PopoverContainer>
          ) : (
            null
          )}
        </IconContainer>
      </IconButton>
    );
  }

  return (
    <Pill
      mr={0.5}
      size="M"
      pillRole={action.role || "status"}
      fill={action.fill || false}
      colorVariant={action.colorVariant || "neutral"}
      onClick={onClick}
    >
      {action.title || action.name}
    </Pill>
  );
};

const IconAction = ({
  facet,
  control,
  value,
  id,
  action,
  onAction,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const darkMode = value?.darkMode;
  const onDone = (result) => {
    setIsOpen(false);
  };

  const getAction = () => {
    if (action) return action;
    if (value && value.action) return value.action;
    return { name: "", icon: "", disabled: true };
  };

  let _action = getAction();

  ///////////////////////////////////////////////////////
  // Dispatch action type
  ///////////////////////////////////////////////////////

  const onClickActionIcon = getClickHandler({
    action: _action,
    control,
    facet,
    value,
    id,
    onAction,
    setIsOpen,
    isOpen,
  });

  switch (getActionType({ action: _action })) {
    case "dialog":
      return (
        <React.Fragment>
          <ActionIcon
            action={_action}
            darkMode={darkMode}
            {...props}
            onClick={onClickActionIcon}
          />
          <FacetDialog
            control={control}
            value={getDialogValue({ id, action: _action })}
            isOpen={isOpen}
            onCancel={setIsOpen}
            onDone={onDone}
            {...props}
          />
        </React.Fragment>
      );
    default:
      return (
        <ActionIcon
          action={_action}
          darkMode={darkMode}
          {...props}
          onClick={onClickActionIcon}
        />
      );
  }
};

export default IconAction;
