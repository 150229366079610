import React, { useState } from 'react'
import I18n from "i18n-js";
import InputButton from './InputButton'
import FacetDialog from '../facets/FacetDialog'


const IconTreeview = ({ facet, control, value, ...props }) => {

  //console.log("icon-treeview",value)

  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen(!isOpen)
  }

  const setDone= (result) => {
      console.log("dialog-done",result)
      control.setFacetValue(facet,value,result.value);
      setIsOpen(false);
  }

  const getOptions = () => {
      return value.parameters || value.options || {
      }
  }

    const defaultNodes = {
        name: 'root',
        toggled: true,
        children: [
            {
                name: 'parent',
                children: [
                    { name: 'child1' },
                    { name: 'child2' }
                ]
            },
            {
                name: 'loading parent',
                loading: true,
                children: []
            },
            {
                name: 'parent',
                children: [
                    {
                        name: 'nested parent',
                        children: [
                            { name: 'nested child 1' },
                            { name: 'nested child 2' }
                        ]
                    }
                ]
            }
        ]
    };

    const getNodes = () => {
        return value.nodes|| defaultNodes;
    }

    const getButtonFacet = () => {
        return {id:1, type:'list', orientation:'horz', position:'main-footer', values:[
          {facet:1, id:0, class:'button', button:{title:I18n.t("dialog.cancel", { defaultValue: "Cancel" }), action:'cancel' } },
          {facet:1, id:0, class:'button', button:{title:I18n.t("dialog.apply", { defaultValue: "Apply" }), action:'apply' } },
        ]};
      }


  const dialogCallBack = (req) => {

    if ((req.meta) && (req.meta.action)) {

        switch (req.meta.action.name) {

            case 'cancel':
                setIsOpen(false);
                break;

            case 'apply':
                // get result value in filter
                req.filters.forEach((v)=>{
                    if ((v.facet===0) && (v.id===0)) {
                        value.value = v.value;
                    }
                })
                //
                control.setFacetValue(facet,value,value.value);
                //
                setIsOpen(false);
                break;
            default:
        }
    }

    let resp = {
        meta: {id:1},
        facets:[
            {id:0,title:I18n.t("iconTreeview.dialogTitle", { defaultValue: "Select" }), type:'treeview', position:'main-body', values:[
            {facet:0, id:0, options: getOptions(), nodes: getNodes(), },
            ]},
        ],
    }
    resp.facets.push(getButtonFacet());
    return resp;
  }

  const getDialogValue = () => {
    return {facet:facet.id, id:value.id, lookup:value.lookup, dialog:{title:facet.title, callBack:dialogCallBack}};
  }

  return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={setOpen} placeHolder='er ...' />
        <FacetDialog facet={facet} control={control} value={getDialogValue()} isOpen={isOpen} onCancel={setOpen} onDone={setDone} {...props}/>
    </React.Fragment>
  )
}

export default IconTreeview
