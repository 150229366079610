import styled from 'styled-components';

const Facet = styled.div`
padding: 0;
border: 0;
display: flex;
flex-direction: column;

${({ autoWidth }) => autoWidth && `
    width: 100%;
  `}

`;

const FacetTitle = styled.div`
`;

const FacetValueTitle = styled.a`
`;

const FacetListContainer = styled.ul`
    padding: 0;
    list-style: none;
    ${({ justifyContent }) => justifyContent && `
        justify-content: ${justifyContent};
    `}
    ${({ alignItems }) => alignItems && `
        align-items: ${alignItems};
    `}
    ${({ horizontal }) => horizontal && `
        display: flex;
    `}
`;

const FacetListItem = styled.li`


`;



export {Facet, FacetTitle, FacetValueTitle, FacetListContainer, FacetListItem }
