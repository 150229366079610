import React from "react";
import { useDrop, useDrag } from "react-dnd";
import { filterStyledSystemPaddingProps } from "./utils";
import { StyledDraggableItem } from "./DraggableItemStyle";

const DraggableItem = ({
  id,
  findItem,
  moveItem,
  children,
  py = 1,
  ...rest
}) => {
  const originalIndex = findItem(id).index;
  const [{ isDragging }, drag] = useDrag({
    type: "carbonDraggableItem",
    item: { id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex: oIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        moveItem(droppedId, oIndex);
      }
    },
  });

  const [, drop] = useDrop({
    accept: "carbonDraggableItem",
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        moveItem(draggedId, overIndex);
      }
    },
  });

  const paddingProps = filterStyledSystemPaddingProps(rest);

  return (
    <StyledDraggableItem
      data-element="draggable"
      isDragging={isDragging}
      ref={(node) => drag(drop(node))}
      py={py}
      {...paddingProps}
    >
      {children}
    </StyledDraggableItem>
  );
};

DraggableItem.displayName = "DraggableItem";

export default DraggableItem;
