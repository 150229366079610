import React, {  useReducer, useEffect, useState, Component  } from "react";
import Layout from '../../../layout/Layout';
import { PanelController } from "../../../common/Controller";
import eventBus from "../../../events/eventBus";

const defaultLayout = {
  name: "layout",
  css: "panel",
  children: [
    { name: "topbar", css: "panel", children: [] },
    {
      name: "body",
      css: "panel",
      children: [
        {
          name: "main",
          css: "panel",
          children: [
            { name: "main-header", css: "panel", children: [] },
            { name: "main-body", css: "panel", children: [] },
            { name: "main-footer", css: "panel", children: [] },
          ],
        },
      ],
    },
  ],
};

function rstate(state, data) {
  //console.log('panel-old',state,data)
  let newState = {
    meta: {},
    facets: [],
    layout: defaultLayout,
    isLoading: false,
    isClosing: false,
  };
  switch (data.action) {
    case "setLoading":
      newState = { ...state, isLoading: data.loading };
      break;
    case "set":
      // set content from fetch
      newState = { ...newState, ...data.data };
      if (data && data.data) {
        newState.meta = data.data.meta || { itemId: "", itemsPerPage: 10 };
        newState.layout = data.data.layout || defaultLayout;
        newState.facets = data.data.facets || [];
      }
      newState.itemId = newState.meta.itemId;
      newState.isClosing = newState.meta.result ? true : false;
      break;
    default:
  }
  //console.log("panel-new",newState,data);
  return newState;
}

const NavTabContentPanel = ({ facet, value, control, loadMethod, tab, eventHandlers, ...props }) => {

  const [state, setState] = useReducer(rstate, {
    meta: {},
    facets: [],
    layout: defaultLayout,
    isLoading: true,
    isClosing: false,
  });

  const [visible, setVisible] = useState(true);

  //const { refreshEvents, initialObject } = tab?tab:{refreshEvents:[],initialObject:null};
  const { initialObject } = tab?tab:{initialObject:null};

    let controller = new PanelController({
      facet,
      control,
      value,
      state,
      setState,
      panelController: tab?.controller,
      meta: tab?.meta,
    });

    useEffect(()=>{
      setVisible(facet.visible ?? true);
    },[facet.visible])

    const getRefreshEvents = () => {
      if (tab?.refreshEvents) return tab.refreshEvents;
      if (facet?.refreshEvents) return facet.refreshEvents;
      return null;
    }

    const getLoadMethod = () => {
      if (loadMethod) return loadMethod;
      if (facet && facet.onLoadMethod) return facet.onLoadMethod;
      if (tab && tab.onLoadMethod) return tab.onLoadMethod;
      return null;
    }

    useEffect(() => {

      let refreshEvents = getRefreshEvents();
      
      if (Array.isArray(refreshEvents)) {
        const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
          callback: () => {
            // on rafraichis complètement le panel lors de ces events
            controller.submit();
          },
          event: refreshEvent,
        }));

        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.on(event, callback);
        });

        return () => {
          memoizedCallbacks.forEach(({ callback, event }) => {
            eventBus.remove(event, callback);
          });
        };
      }
    });

    useEffect(() => {
      if (initialObject) {
        controller.selectObject(initialObject);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialObject]);

    const loadContent = () => {

      if (!controller.hasController()) {
        //
        const onLoadMethod = getLoadMethod();
        if (onLoadMethod) {
            // dynamic content loaded asynchronously from server
            setState({ action: "setLoading", loading: true });
            control.callMethod(onLoadMethod, tab, (data)=>{
              if(data) {
                setState( {action: "set", data:data} );
              }
              setState({ action: "setLoading", loading: false });
            });
        }
      }
      else {
        controller.fetchData();
      }
    }

    useEffect(() => {

      loadContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facet.visible]) // be carefull, panel MUST no be reloaded on tab change

    if (eventHandlers) {
      eventHandlers.panelRefresh = (data) => {
        if (state.meta && state.meta.id && data.id && (state.meta.id===data.id)) {
          loadContent();
        }
      }
      eventHandlers.panelUpdate = (data) => {
        if (state.meta && state.meta.id && data.id && (state.meta.id===data.id)) {
          if (typeof data.visible!=="undefined") {
            setVisible(data.visible)
          }
        }
      }
    }

    const isHidden = () => {
      return !visible || state.isLoading || state.isClosing;
    };

    return isHidden() ? (
      null
    ) :
    state.layout && state.facets ? (
      <Layout tab={tab} layout={state.layout || defaultLayout} facets={state.facets} control={controller} {...props}/>
    ) : (
      <div><h1>Panel content</h1><div>{JSON.stringify(tab)}</div></div>
    );
}

///////////////////////////////////////////////////////////

var eventWrappers = (ComposedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { panelRefresh: null, panelUpdate:null };
      this.onRefresh_ = this.onRefresh.bind(this);
      this.onUpdate_ = this.onUpdate.bind(this);
    }
    onRefresh(data) {
      if (this.state.panelRefresh) this.state.panelRefresh(data);
    }
    onUpdate(data) {
      if (this.state.panelUpdate) this.state.panelUpdate(data);
    }
    componentDidMount() {
      eventBus.on("panel.refresh", this.onRefresh_).on("panel.update", this.onUpdate_);
    }
    componentWillUnmount() {
      eventBus.remove("panel.refresh", this.onRefresh_).remove("panel.update", this.onUpdate_);
    }

    render() {
      return <ComposedComponent {...this.props} eventHandlers={this.state} />;
    }
  };

export default eventWrappers(NavTabContentPanel);
