import React, { useState, useEffect, useRef } from "react";
import ButtonAction from "../FacetResultGrid/ButtonAction";
import { doDownloadUrl } from "../NavigTab/FacetNavigTabs";
import { useBusinessProcessContext } from "./FacetBusinessProcess";
import styled from "styled-components";
import { useDrop, useDrag } from "react-dnd";
import { debounce, cloneDeep } from "lodash";
import Typography from "carbon-react/lib/components/typography";
import IconButton from "carbon-react/lib/components/icon-button";
import Icon from "carbon-react/lib/components/icon";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";
import I18n from "i18n-js";
import LinkEditModePopover from "./LinkEditModePopover";
import { v4 as uuidv4 } from "uuid";

const LINK_CONTAINER_WIDTH = "300px";
const LINK_CONTAINER_HEIGHT = "101.6px";

export const areLinksEquals = (link1, link2) =>
  link1.href === link2.href && link1.title === link2.title;

export const saveBusinessProcessLocally = ({
  businessProcess,
  businessProcessTitle,
  setFacetLocalBusinessProcess,
  setFacetBusinessProcessTitle,
}) => {
  setFacetLocalBusinessProcess({
    businessProcess: businessProcess,
    businessProcessValue: JSON.parse(businessProcess.value),
  });
  setFacetBusinessProcessTitle?.(businessProcessTitle);
};

export const debouncedSaveBusinessProcess = debounce(
  saveBusinessProcessLocally,
  300
);

const startOverCallback = ({
  setOverFlag,
  localLinks,
  draggedItem,
  setLocalLinks,
}) => {
  if (localLinks?.length === 0) {
    setLocalLinks([draggedItem]);
  }
  setOverFlag(true);
};

const renameLink = ({
  control,
  link,
  setLocalLinks,
  newText,
  newHref,
  businessProcess,
  setFacetLocalBusinessProcess,
}) => {
  setLocalLinks((previousLocalLinks) => {
    const newLocalLinks = previousLocalLinks.map((currentLink) =>
      areLinksEquals(currentLink, link)
        ? { ...currentLink, title: newText, href: newHref }
        : currentLink
    );
    const newBusinessProcess = buildNewBusinessProcessWithNewLinks({
      links: newLocalLinks,
      businessProcess,
    });
    debouncedSaveBusinessProcess({
      control,
      setFacetLocalBusinessProcess,
      businessProcess: newBusinessProcess,
    });

    return newLocalLinks;
  });
};

const deleteLink = ({
  control,
  linkToDelete,
  setLocalLinks,
  localLinks,
  businessProcess,
  setFacetLocalBusinessProcess,
}) => {
  setLocalLinks((previousLocalLinks) => {
    const newLocalLinks = previousLocalLinks.filter(
      (previousLocalLink) =>
        !areLinksEquals(previousLocalLink, linkToDelete)
    );
    const newBusinessProcess = buildNewBusinessProcessWithNewLinks({
      links: newLocalLinks,
      businessProcess,
    });
    //backend cb here, should be the same in both place, debounced
    debouncedSaveBusinessProcess({
      control,
      setFacetLocalBusinessProcess,
      businessProcess: newBusinessProcess,
    });

    return newLocalLinks;
  });
};

const getActionFromLink = (link) => ({
  title: link.title ?? "",
  call: {
    name: "openLink",
    item: { url: link.href, title: link.title },
    url: link.href,
    title: link.title ?? "",
  },
  icon: link.icon,
});

const getMoveLocalItem =
  ({ setLocalLinks, localLinks }) =>
  ({ item, hoveredItem }) => {
    let linksToReturn;
    const existingItemIndex = localLinks.findIndex(
      (currentItem) =>
        areLinksEquals(item, currentItem)
    );
    const hoveredItemIndex = localLinks.findIndex(
      (currentItem) =>
        areLinksEquals(hoveredItem, currentItem)
    );
    // cas pas dans tableau, on insère avant l'élément hovered
    if (existingItemIndex < 0) {
      linksToReturn = [
        ...localLinks.slice(0, hoveredItemIndex),
        item,
        ...localLinks.slice(hoveredItemIndex),
      ];
    }
    //cas déjà dans tableau, on inverse les 2 éléments
    else if (existingItemIndex >= 0 && hoveredItemIndex >= 0) {
      const newLinks = cloneDeep(localLinks);
      newLinks[hoveredItemIndex] = item;
      newLinks[existingItemIndex] = hoveredItem;
      linksToReturn = newLinks;
    } else {
      linksToReturn = localLinks;
    }
    setLocalLinks(linksToReturn);
  };

const buildNewBusinessProcessWithNewLinks = ({ businessProcess, links }) => {
  const businessProcessValue = JSON.parse(businessProcess.value);
  const newLinks = Array.isArray(businessProcess.links)
    ? links
    : {
        ...(businessProcess?.links || {}),
        items: links,
      };
  const newBusinessProcessValue = {
    ...businessProcessValue,
    links: newLinks,
  };
  return {
    ...businessProcess,
    value: JSON.stringify(newBusinessProcessValue),
  };
};

const MessageAndLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & * {
    color: rgba(102, 132, 148, 1);
  }
`;

const LinksContainer = styled.div`
  min-width: ${LINK_CONTAINER_WIDTH};
  min-height: ${LINK_CONTAINER_HEIGHT};
  width: fit-content;
  display: flex;
  flex-direction: row;
  & div[role="button-action-container"] > button > span > span {
    color: ${({ theme }) => theme?.text?.color};
  }
  & div[role="button-action-container"] > button > span > span:hover {
    text-decoration: underline;
  }
  & span[data-component="icon"] {
    color: ${({ theme }) => theme?.text?.color};
  }
  & button:hover > span[data-component="icon"] {
    color: ${({ theme }) => theme?.text?.color};
  }
  margin-bottom: 24px;
  border: ${({ theme, canEdit }) =>
    canEdit ? `2px dashed ${theme?.colors?.dashedBorder}` : "none"};
`;

const LinkContainer = styled.div`
  height: 100%;
  width: ${LINK_CONTAINER_WIDTH};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ isDraggedItem, isDragging }) => {
    return isDraggedItem && isDragging ? "0" : "1";
  }};
  background-color: ${({ isDraggedItem, theme }) =>
    isDraggedItem ? theme?.colors?.white : "inherit"};
  & * {
    opacity: ${({ isDraggedItemFromMenu }) =>
      isDraggedItemFromMenu ? "0" : "1"};
  }
`;

const DragIconContainer = styled.div`
  cursor: grab;
  margin-left: 12px;
  margin-right: 12px;
`;

const DragIconAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${LINK_CONTAINER_HEIGHT};
`;

const LinkIconsActionsContainer = styled.div`
  display: flex;
`;

const ButtonAndActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const TitleAndActionsIconsContainerEditMode = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  & > div:first-child {
    width: 100%;
    margin-right: 0px !important;
  }
`;

const CancelAndValidateIconsContainer = styled.div`
  display: flex;
`;

const LinkItem = ({
  facet,
  control,
  link,
  keyProp,
  moveLocalItem,
  canEdit,
  setLocalLinks,
  localLinks,
  businessProcess,
  setFacetLocalBusinessProcess,
}) => {
  const [linkTitleEditMode, setLinkTitleEditMode] = useState(false);
  const [{ draggedItem, draggedItemType }, dropItem] = useDrop(
    () => ({
      accept: ["FRP1000-LEFT-MENU-DRAG-ITEM", "FRP1000-BUSINESS-LINKS-ITEM"],
      canDrop: () => false,
      hover(item) {
        if (!areLinksEquals(item, link)) {
          moveLocalItem({ item, hoveredItem: link });
        }
      },
      collect: (monitor) => ({
        draggedItem: monitor.getItem(),
        draggedItemType: monitor.getItemType(),
      }),
    }),
    [moveLocalItem, link]
  );

  const [{ isDragging }, dragItem, preview] = useDrag(
    () => ({
      type: "FRP1000-BUSINESS-LINKS-ITEM",
      item: link,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [link]
  );

  const ref = useRef();

  const isDraggedItem = !!(
    draggedItem &&
    areLinksEquals(draggedItem, link)
  );
  const isDraggedItemFromMenu =
    isDraggedItem && draggedItemType === "FRP1000-LEFT-MENU-DRAG-ITEM";

  return (
    <LinkContainer
      ref={canEdit ? dropItem : undefined}
      isDraggedItem={isDraggedItem}
      isDragging={isDragging}
      isDraggedItemFromMenu={isDraggedItemFromMenu}
    >
      {canEdit ? (
        <DragIconContainer ref={dragItem}>
          <Icon type="drag_vertical" />
        </DragIconContainer>
      ) : null}
      <ButtonAndActionsContainer>
        <TertiaryButtonWrapper ref={preview}>
          <ButtonAction
            facet={facet}
            control={control}
            action={getActionFromLink(link)}
            doDownloadUrl={doDownloadUrl}
            id={keyProp}
            key={keyProp}
            buttonProps={{
              buttonType: "tertiary",
              pl: "0",
              pr: "0",
              ml: "0",
              mr: "0",
            }}
          />
        </TertiaryButtonWrapper>
        {canEdit ? (
          <LinkIconsActionsContainer>
            <LinkEditModePopover
              setOpen={setLinkTitleEditMode}
              open={linkTitleEditMode}
              isDisplayedTop
              initialText={link.title}
              initialHref={link.href}
              onCancel={() => {
                setLinkTitleEditMode(false);
              }}
              onValidation={({ newHref, newText }) => {
                renameLink({
                  control,
                  link,
                  setLocalLinks,
                  newText,
                  newHref,
                  businessProcess,
                  setFacetLocalBusinessProcess,
                });
                setLinkTitleEditMode(false);
              }}
            />
            <IconButton
              onAction={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteLink({
                  control,
                  linkToDelete: link,
                  setLocalLinks,
                  localLinks,
                  businessProcess,
                  setFacetLocalBusinessProcess,
                });
              }}
            >
              <Icon type="delete" />
            </IconButton>
          </LinkIconsActionsContainer>
        ) : null}
      </ButtonAndActionsContainer>
    </LinkContainer>
  );
};

const BusinessProcessLinks = ({
  facet,
  control,
  links,
  businessProcess,
  canEdit,
}) => {
  const { businessProcessValue, setFacetLocalBusinessProcess } =
    useBusinessProcessContext();
  const [localLinks, setLocalLinks] = useState(links);
  const [overFlag, setOverFlag] = useState(false);
  const [{ isOver, draggedItem, canDrop }, dropZone] = useDrop(
    () => ({
      accept: ["FRP1000-LEFT-MENU-DRAG-ITEM", "FRP1000-BUSINESS-LINKS-ITEM"],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggedItem: monitor.getItem(),
      }),
      drop: () => {
        //timeout necessary or conflict with over callback setting flag to true
        setTimeout(() => {
          const newBusinessProcess = buildNewBusinessProcessWithNewLinks({
            links: localLinks,
            businessProcess,
          });
          // backend cb here, should be the same in both place, debounced
          debouncedSaveBusinessProcess({
            control,
            setFacetLocalBusinessProcess,
            businessProcess: newBusinessProcess,
          });
          setOverFlag(false);
        }, 100);
      },
    }),
    [localLinks, overFlag, businessProcess]
  );

  useEffect(() => {
    setLocalLinks(links);
  }, [links]);

  useEffect(() => {
    if (isOver && canDrop && canEdit && draggedItem && !overFlag) {
      startOverCallback({
        setOverFlag,
        localLinks,
        setLocalLinks,
        draggedItem,
      });
    }
  }, [
    isOver,
    draggedItem,
    overFlag,
    canDrop,
    canEdit,
    localLinks,
    businessProcess,
    control,
    setFacetLocalBusinessProcess,
  ]);

  return (
    <MessageAndLinksContainer>
      {canEdit ? (
        <MessageContainer>
          <Icon type="arrow_down" />
          <Typography
            mr="16px"
            ml="16px"
            fontWeight="700"
            fontSize="16px"
            lineHeight="21px"
          >
            {I18n.t("businessProcess.useThisZoneToAddShortcuts")}
          </Typography>
          <Icon type="arrow_down" />
        </MessageContainer>
      ) : null}
      <LinksContainer ref={canEdit ? dropZone : undefined} canEdit={canEdit}>
        {localLinks?.map((link) => {
          const keyValue = uuidv4();
          return (
            <LinkItem
              moveLocalItem={getMoveLocalItem({ setLocalLinks, localLinks })}
              facet={facet}
              control={control}
              link={link}
              key={keyValue}
              keyProp={keyValue}
              canEdit={canEdit}
              setLocalLinks={setLocalLinks}
              localLinks={localLinks}
              businessProcess={businessProcess}
              setFacetLocalBusinessProcess={setFacetLocalBusinessProcess}
            />
          );
        })}
      </LinksContainer>
    </MessageAndLinksContainer>
  );
};

export default BusinessProcessLinks;
