import React, { useState, useEffect } from "react";
import SelectableGrid from "../components/SelectableGrid";
import eventBus from "../../events/eventBus";
import { isEqual } from "lodash";
import Spacing from "../components/Spacing";
import I18n from "i18n-js";

const getNewSelectedItems = ({
  currentSelectedItems,
  previousSelectedItems,
  data,
  customItemId,
}) => {
  const previousSelectedItemNotInData = previousSelectedItems.reduce(
    (acc, previousSelectedItem) => {
      if (
        !data.find((item) =>
          customItemId
            ? item[customItemId] === previousSelectedItem[customItemId]
            : item.id === previousSelectedItem.id
        )
      )
        return [...acc, previousSelectedItem];
      return acc;
    },
    []
  );

  return [...previousSelectedItemNotInData, ...currentSelectedItems];
};

const getSelectCallback =
  ({ value, setSelectedItems, selectionName, customItemId, data }) =>
  (items) => {
    setSelectedItems((previousSelectedItems) => {
      const newSelectedItems = getNewSelectedItems({
        currentSelectedItems: items,
        previousSelectedItems,
        data,
        customItemId,
      });

      eventBus.emit("SelectDone", {
        id: value.groupId,
        item: selectionName
          ? { [selectionName]: newSelectedItems }
          : newSelectedItems,
      });

      return newSelectedItems;
    });
  };

const transformDataWithSelectedRows = ({ data, selectedRows, customItemId }) =>
  data.map((item) => ({
    ...item,
    selected: !!selectedRows.find((rowItem) =>
      customItemId
        ? rowItem[customItemId] === item[customItemId]
        : rowItem.id === item.id
    ),
  }));

const getSelectCallbackFacetValueMode =
  ({ value, setSelectedItems, selectionName, customItemId, data }) =>
  ({ selectedRows, control, facet }) => {
    const newItemsForFacetValue = transformDataWithSelectedRows({
      data,
      selectedRows,
      customItemId,
    });
    control.setFacetValue(facet, value, newItemsForFacetValue);
    eventBus.emit("SelectDone", {
      id: value.groupId,
      item: selectionName
        ? { [selectionName]: newItemsForFacetValue }
        : newItemsForFacetValue,
    });
  };

const FacetSelectableGrid = ({ facet, value, control }) => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    initialData,
    loadMethod,
    initialSelectedItems,
    columnDefs,
    selectionName,
    noHeader,
    fixedHeight,
    refreshEvents,
    shouldUpdateFacetValue,
    shouldLocallyInitialiseSelectedData,
    customItemId,
  } = value;

  useEffect(() => {
    setLoading(true);
    if (loadMethod) {
      control.callMethod(loadMethod, {}, (data) => {
        if (Array.isArray(data?.data)) {
          setData(data.data);
        }
        setLoading(false);
      });
    } else if (initialData) {
      setData(initialData);
      setLoading(false);
    }

    if (Array.isArray(initialSelectedItems)) {
      setSelectedItems(initialSelectedItems);
    }
  }, [control, loadMethod, initialSelectedItems, initialData]);

  useEffect(() => {
    if (refreshEvents?.length) {
      const memoizedCallbacks = refreshEvents.map((refreshEvent) => ({
        callback: (newData) => {
          if (Array.isArray(newData.values)) {
            setData((previousData) =>
              isEqual(newData.values, previousData)
                ? previousData
                : newData.values
            );
          }
        },
        event: refreshEvent,
      }));

      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  }, [refreshEvents]);

  return !loading && data?.length ? (
    <SelectableGrid
      columnDefs={columnDefs}
      data={data}
      selectedItems={selectedItems}
      selectCallback={getSelectCallback({
        data,
        value,
        setSelectedItems,
        selectionName,
        customItemId,
      })}
      selectCallbackFacetValueMode={getSelectCallbackFacetValueMode({
        data,
        value,
        setSelectedItems,
        selectionName,
        customItemId,
      })}
      noHeader={noHeader}
      fixedHeight={fixedHeight}
      control={control}
      facet={facet}
      value={value}
      shouldUpdateFacetValue={shouldUpdateFacetValue}
      shouldLocallyInitialiseSelectedData={shouldLocallyInitialiseSelectedData}
    />
  ) : !loading && !data?.length ? (
    <Spacing value={{ height: fixedHeight }}>{I18n.t("noResult")}</Spacing>
  ) : (
    <Spacing value={{ height: fixedHeight }} />
  );
};

export default FacetSelectableGrid;
