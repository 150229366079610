import React, {
  useState,
  useReducer,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { cloneDeep, get, isEqual } from "lodash";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import IconButton from "carbon-react/lib/components/icon-button";
import Box from "carbon-react/lib/components/box";
import Tree from "../../common/Tree";
import Dom from "../../common/Dom";
import DraggableContainer from "./dragndrop/DraggableContainer";
import DraggableItem from "./dragndrop/DraggableItem";
import eventBus from "../../events/eventBus";
import { useFacetNavigLeftContext } from "../facets/FacetNavigLeft";
import Ellipsis from "./Ellipsis";
import { useDrag } from "react-dnd";
import { useDragContext } from "../../../context/DragContext";

const ProcessDraggableItem = ({ children, item }) => {
  const rawItem = item?.item;
  const dragItem = {
    id: rawItem?.id,
    title: rawItem?.title,
    href: rawItem?.href,
    imageIndex: rawItem?.imageIndex,
  };
  const { canDragCount } = useDragContext();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "FRP1000-LEFT-MENU-DRAG-ITEM",
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: dragItem,
      canDrag: () => canDragCount > 0,
    }),
    [item, canDragCount]
  );

  useEffect(() => {
    if (isDragging) {
      eventBus.emit("process-drag-start", {});
    } else {
      eventBus.emit("process-drag-end", {});
    }
  }, [isDragging]);

  return <div ref={drag}>{children}</div>;
};

/////// drag and drop functions /////////////

const getOnDragEnd =
  ({ state, setState, dragEndData, control }) =>
  (idsArray) => {
    const tree = state.tree;
    if (tree) {
      const reorderedTree = idsArray.map((itemId) => {
        return tree.find((item) => item.id === itemId);
      });
      if (!isEqual(reorderedTree, tree)) {
        setState({ action: "set", data: reorderedTree });

        console.log("DragEndCallBack", dragEndData);

        if (dragEndData?.call?.name) {
          control.callAction(dragEndData.call.name, {
            ...dragEndData.call,
            items: reorderedTree,
          });
        }
      }
    }
  };

const getDragEndData = ({ facet, value }) => {
  if (value && value.dragEndCallback) return value.dragEndCallback;
  if (facet && facet.dragEndCallback) return facet.dragEndCallback;
  return undefined;
};

const getOnMouseEnter = (draggable) => (e) => {
  if (!e.buttons) draggable.classList.add("frp1000-dragging");
};

const getOnMouseLeave = (draggable) => () => {
  draggable.classList.remove("frp1000-dragging");
};

const getOnDragEndEvent = (draggable) => (e) => {
  if (e.srcElement === draggable) {
    e.srcElement.classList.add("frp1000-dragging");
  }
};

const getRemoveFavourite = (setState) => (props) => {
  setState({ action: "remove", data: props });
};

/////////////////////////////////////////////
const defaultMenu = {
  meta: { id: "", autoCollapse: true },
  tree: [
    {
      id: 1,
      title: "Menu item 1",
      custom: {},
    },
    {
      id: 2,
      title: "Sub Menu item 2",
      custom: {},
      children: [
        {
          id: 3,
          title: "Menu item 2.1",
        },
        {
          id: 4,
          title: "Menu item 2.2",
          children: [
            {
              id: 5,
              title: "Menu item 2.2.1",
            },
            {
              id: 6,
              title: "Menu item 2.2.2",
            },
          ],
        },
      ],
    },
    {
      id: 10,
      title: "Menu item 1",
      custom: {},
    },
  ],
};

/////////////////////////////////////////////
const VMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.vmenu.color};
  background-color: ${({ theme }) => theme.navigation.panel.backgroundColor};
  padding: 4px 0px 4px 0px; /* for focus outline of first and last */
  &:focus {
    outline: none;
  } /* at item level */

  .frp1000-not-draggable-item:hover {
    color: ${(props) => props.theme.vmenu.hoverColor};
    background: ${(props) => props.theme.vmenu.hoverBackgroundColor};
  }

  .frp1000-not-draggable-item:hover [data-component="icon"] {
    color: ${(props) => props.theme.vmenu.hoverColor};
    background: ${(props) => props.theme.vmenu.hoverBackgroundColor};
  }
`;
const VSubMenuWrapper = styled.div``;
const VMenuTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  line-height: var(--height);
  font-size: var(--fontSize);
`;
const VMenuTitleMargin = styled.span`
  flex-basis: var(--margin);
`;
const VMenuTitleText = styled.span`
  padding-left: ${(props) => props.theme.vmenu.item.paddingLeft + "px"};
`;
const VMenuTitleIcon = styled.span`
  margin-left: auto;
  & span:before {
    margin-right: 32px;
  }
`;
const VMenuTitleActionIcon = styled.span`
  margin-left: auto;
  padding-right: 16px;
`;
const VSubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--backcolor);
`;
const VMenuTitleDragIconContainer = styled.span`
  cursor: grab;
`;
const DraggableVMenuWrapper = styled.div`
  &:focus {
    outline: none;
  }
  & > div {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.vmenu.color};
    background-color: ${(props) => props.theme.vmenu.backgroundColor};
    padding: 4px 0px 4px 0px; /* for focus outline of first and last */
    border: none;
  }
  & > div:focus {
    /* at item level */
    outline: none;
  }
  & .frp1000-dragging [data-element="drag"] {
    color: ${({ theme }) => theme?.colors?.white};
  }
  & .frp1000-dragging [data-component="icon"] {
    color: ${({ theme }) => theme?.colors?.white};
  }
  & [data-element="draggable"] {
    padding: 0;
    margin: 0;
    display: initial;
    border: none;
    cursor: initial;
  }
  & .frp1000-dragging {
    background-color: ${({ theme }) => theme?.colors?.secondary};
    color: ${({ theme }) => theme?.colors?.white};
  }
  & [data-element="draggable"] > span[data-element="drag"]:not(:first-child) {
    display: none;
  }
`;

const VMenuTitle = ({
  item,
  level,
  iconType,
  onIconClick,
  control,
  draggable,
  ...props
}) => {
  // temporary store level for expand / collapse
  item.level = level;

  const itemRef = useRef(null);

  const itemClick = (e) => {
    if (e) e.preventDefault();
    if (props.onItemClick) props.onItemClick(item);
    return;
  };
  const itemIconClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (props.onItemIconClick) props.onItemIconClick(item);
  };
  const itemActionClick = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (props.onItemActionClick) props.onItemActionClick(item);
  };

  const getMargin = () => {
    let th =
      level === 0 ? props.theme.vmenu.levels[0] : props.theme.vmenu.levels[1];
    let rslt = {
      "--margin": level * th.margin + "px",
    };
    return rslt;
  };

  const getTitleStyle = () =>
    item.owned ? { fontWeight: 500 } : {};

  const getStyle = () => {
    let th =
      level === 0 ? props.theme.vmenu.levels[0] : props.theme.vmenu.levels[1];

    let rslt = {
      "--height": th.height,
      "--fontSize": th.fontSize,
    };
    //
    if (item.id === props.currentId) {
      rslt.outline = "thick solid " + props.theme.vmenu.focusColor;
    }
    //
    return rslt;
  };

  window.setTimeout(() => {
    if (itemRef.current && item.id === props.currentId) {
      let bw = 4; // TO DO border
      Dom.scrollParent(itemRef.current, bw);
    }
  }, 1);

  const getEllipsisWidth = ({ level }) => {
    let ww = props.theme.vmenu.item.defaultWidth;
    ww = ww - props.theme.vmenu.item.paddingLeft;
    ww = ww - props.theme.vmenu.item.paddingRight;
    if (item.icon) ww = ww - props.theme.vmenu.item.iconWidth;
    if (item.action) ww = ww - props.theme.vmenu.item.iconWidth;
    if (draggable) ww = ww - props.theme.vmenu.item.draggableWidth;
    ww = ww - props.theme.vmenu.item.levelInden * level;

    return ww;
  };

  return (
    <VMenuTitleContainer
      className={draggable ? "" : "frp1000-not-draggable-item"}
      ref={itemRef}
      onClick={itemClick}
      key={item.id}
      role="vmenu-title-container"
      style={getStyle()}
    >
      {item.icon ? (
        <VMenuTitleIcon role="vmenu-title-item-icon" onClick={itemIconClick}>
          <Icon type={item.icon}></Icon>
        </VMenuTitleIcon>
      ) : null}
      <VMenuTitleMargin role="vmenu-titile-margin" style={getMargin()} />
      {draggable && (
        <VMenuTitleDragIconContainer>
          <Icon type="drag" />
        </VMenuTitleDragIconContainer>
      )}
      <VMenuTitleText role="vmenu-title-text">
        <Ellipsis width={getEllipsisWidth({ level })} style={getTitleStyle()} text={item.title} />
      </VMenuTitleText>
      {item.action && (
        <VMenuTitleActionIcon role="vmenu-title-action-icon">
          <IconButton onAction={itemActionClick}>
            <Icon
              type={item.action.icon}
              color={
                item.action.iconColorPath &&
                get(props.theme, item.action.iconColorPath)
              }
            />
          </IconButton>
        </VMenuTitleActionIcon>
      )}
      {iconType ? (
        <VMenuTitleIcon role="vmenu-title-icon">
          <Icon type={iconType}></Icon>
        </VMenuTitleIcon>
      ) : null}
    </VMenuTitleContainer>
  );
};

const SubMenu = ({ item, level, ...props }) => {
  const getChildren = () => {
    if (item.children) return item.children;
    return [];
  };

  const toggle = () => {
    if (props.onItemToggle) props.onItemToggle(item, level);
  };

  const getIconType = () => {
    if (item.isExpanded) return "chevron_up";
    return "chevron_down";
  };
  const getContainerStyle = () => {
    let th =
      level === 0 ? props.theme.vmenu.levels[0] : props.theme.vmenu.levels[1];

    let rslt = {
      display: item.isExpanded ? "flex" : "none",
      "--backcolor":
        props.theme.vmenu.submenu.backgroundColors[level === 0 ? 0 : 1],
      "--margin": level * th.margin + "px",
      "--height": th.height,
      "--fontSize": th.fontSize,
    };
    return rslt;
  };

  return (
    <VSubMenuWrapper role="vmenu-sub" key={item.id}>
      <VMenuTitle
        item={item}
        level={level}
        iconType={getIconType()}
        onIconClick={toggle}
        {...props}
      />
      <VSubMenuContainer role="vmenu-container" style={getContainerStyle()}>
        {getChildren().map((iitem, index) => (
          <MenuNode key={iitem.id} item={iitem} level={level + 1} {...props} />
        ))}
      </VSubMenuContainer>
    </VSubMenuWrapper>
  );
};

const MenuItem = ({ ...props }) => {
  return props.draggable === true ? (
    <VMenuTitle {...props} />
  ) : (
    <ProcessDraggableItem
      item={{ item: props?.item, onItemClick: props?.onItemClick }}
    >
      <VMenuTitle {...props} />
    </ProcessDraggableItem>
  );
};

const MenuNode = ({ item, level, ...props }) => {
  const isSubMenu = () => {
    if (item.children && item.children.length > 0) return true;
    return false;
  };

  return (
    <React.Fragment>
      {isSubMenu() ? (
        <SubMenu item={item} level={level} {...props} />
      ) : (
        <MenuItem item={item} level={level} {...props} />
      )}
    </React.Fragment>
  );
};

const getFixedHeight = (height) => height?.replace?.(")", " - 10px)") ?? height;

const VMenu = ({ facet, value, ...props }) => {
  const { close } = useFacetNavigLeftContext();
  const menuRef = useRef();
  const supportKeyboard = true;
  const autoCollapse = true;
  const autoClose = true;
  const draggable = !!(facet?.draggable || value?.draggable);
  const [firstRenderFlag, setFirstRenderFlag] = useState(false);

  const getMenu = () => {
    if (value && value.menu) return value.menu;
    if (facet && facet.menu) return facet.menu;
    return defaultMenu;
  };

  const getMenuMeta = () => {
    let m = getMenu();
    if (m.meta) return m.meta;
    return {};
  };

  const doItemClick = (item) => {
    if (item.facetAction && props.control) {
      props.control.doFacetAction(facet, null, item.facetAction, () => {
        if (autoClose) close();
      });
    } else if (item.link && item.link.call && props.control) {
      props.control.callAction(item.link.call.name, item.link.call, () => {
        if (autoClose) close();
      });
    } else if (item.link && props.control) {
      props.control.callAction(
        item.link.action || "openLink",
        { item: item.link.item },
        () => {
          if (autoClose) close();
        }
      );
    } else if (item.link && item.link.call && props.control) {
      props.control.callAction(item.link.call.name, item.link.call, () => {
        if (autoClose) close();
      });
    } else if (item.id && item.id > 0 && props.control) {
      props.control.callAction(
        "menuItem",
        { menu: getMenuMeta(), item: item },
        (resp) => {
          if (autoClose) close();
        }
      );
    } else {
      if (autoClose) close();
    }
  };

  const reduceState = (state, data) => {
    let newState = cloneDeep(state);
    switch (data.action) {
      case "remove":
        if (newState?.tree?.filter && data?.data?.name) {
          newState.tree = state.tree.filter(
            (item) => item.name !== data.data.name
          );
          newState.currentId = Tree.getFirstSibling(newState.tree);
        }
        break;
      case "set":
        newState.tree = data.data;
        newState.currentId = Tree.getFirstSibling(newState.tree);
        break;
      case "setCurrentId":
        newState.currentId = data.data;
        break;
      case "Home":
        newState.currentId = Tree.getFirstSibling(newState.tree);
        break;
      case "End":
        newState.currentId = Tree.getLastSibling(newState.tree);
        break;
      case "ArrowDown":
        newState.currentId = Tree.getNextSibling(
          newState.tree,
          newState.currentId
        ).id;
        break;
      case "PageDown":
        newState.currentId = Tree.getIncrementSibling(
          newState.tree,
          newState.currentId,
          5
        ).id;
        break;
      case "ArrowUp":
        newState.currentId = Tree.getPrevSibling(
          newState.tree,
          newState.currentId
        ).id;
        break;
      case "PageUp":
        newState.currentId = Tree.getDecrementSibling(
          newState.tree,
          newState.currentId,
          5
        ).id;
        break;
      case "ArrowRight":
        if (newState.currentId != null)
          Tree.expand(newState.tree, newState.currentId, autoCollapse);
        break;
      case "ArrowLeft":
        if (newState.currentId != null)
          Tree.collapse(newState.tree, newState.currentId);
        break;
      case "toggle":
        newState.currentId = data.id;
        Tree.toggle(newState.tree, data.id, data.level, autoCollapse);
        break;
      default:
    }
    return newState;
  };

  const [state, setState] = useReducer(reduceState, {
    tree: [],
    currentId: null,
  });

  const getTree = () => {
    let m = getMenu();
    if (m.tree) return m.tree;
    return [];
  };

  useEffect(
    () => {
      setState({ action: "set", data: getTree() });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facet, value]
  );

  const onKeyDown = (e) => {
    if (e) e.preventDefault();

    switch (e.key) {
      case "Enter":
        if (state.currentId != null) {
          let ii = Tree.find(state.tree, state.currentId);
          if (ii && !(ii.children && ii.children.length > 0)) doItemClick(ii);
        }
        break;

      case "Escape":
        close();
        break;

      case "Home":
      case "End":
      case "ArrowUp":
      case "PageUp":
      case "ArrowDown":
      case "PageDown":
      case "ArrowRight":
      case "ArrowLeft":
        setState({ action: e.key, data: null });
        break;
      default:
    }
  };

  if (supportKeyboard) {
    window.setTimeout(() => {
      if (menuRef.current) {
        menuRef.current.setAttribute("tabindex", "-1");
        menuRef.current.focus();
      }
    }, 1);
  }

  const onItemToggle = (item, level) => {
    setState({ action: "toggle", id: item.id, level: level });
  };

  const onItemClick = (item) => {
    if (item.children && item.children.length > 0) {
      onItemToggle(item, item.level);
      return;
    }
    doItemClick(item);
  };

  const onItemIconClick = (item) => {
    console.log("menu-item-icon-click", item);
  };
  const onItemActionClick = (item) => {
    console.log("menu-item-action-click", item);
    if (props.control)
      props.control.callAction(item.action.call.name, item.action.call);
    if (autoClose) close();
  };

  useLayoutEffect(() => {
    if (menuRef.current) {
      const draggables = menuRef.current.querySelectorAll(
        "[data-element=draggable]"
      );
      if (draggables) {
        const draggablesArray = Array.from(draggables);
        const memoizedCallbacks = draggablesArray.map((draggable) => ({
          onMouseEnter: getOnMouseEnter(draggable),
          onMouseLeave: getOnMouseLeave(draggable),
          onDragEnd: getOnDragEndEvent(draggable),
        }));
        draggablesArray.forEach((draggable, index) => {
          draggable.addEventListener(
            "mouseenter",
            memoizedCallbacks[index].onMouseEnter
          );
          draggable.addEventListener(
            "mouseleave",
            memoizedCallbacks[index].onMouseLeave
          );
          draggable.addEventListener(
            "dragend",
            memoizedCallbacks[index].onDragEnd
          );
        });

        return () => {
          draggablesArray.forEach((draggable, index) => {
            draggable.removeEventListener(
              "mouseenter",
              memoizedCallbacks[index].onMouseEnter
            );
            draggable.removeEventListener(
              "mouseleave",
              memoizedCallbacks[index].onMouseLeave
            );
            draggable.removeEventListener(
              "dragend",
              memoizedCallbacks[index].onDragEnd
            );
          });
        };
      }
    }
  });

  useEffect(() => {
    if (state?.tree?.length && !firstRenderFlag) {
      setFirstRenderFlag(true);
    }
  }, [state, firstRenderFlag]);

  useEffect(() => {
    eventBus.on("fav.delete", getRemoveFavourite(setState));
  }, []);

  return (
    <Box
      role="navleft-panel-scroll"
      display="block"
      width="100%"
      overflow="auto"
      height={props.height ? getFixedHeight(props.height) : "100%"}
      scrollVariant="light"
    >
      {draggable ? (
        <DraggableVMenuWrapper role="vmenu" ref={menuRef} onKeyDown={onKeyDown}>
          <DraggableContainer
            getOrder={getOnDragEnd({
              state,
              setState,
              //dragEndCallback: getCustomDragEndCallbackFromFacet({ facet, value }),
              dragEndData: getDragEndData({ facet, value }),
              control: props.control,
            })}
          >
            {state.tree.map((iitem) => (
              <DraggableItem id={iitem.id} key={`draggable-item-${iitem.id}`}>
                <MenuNode
                  draggable={draggable}
                  key={iitem.id}
                  level={0}
                  item={iitem}
                  currentId={supportKeyboard ? state.currentId : -1}
                  onItemToggle={onItemToggle}
                  onItemClick={onItemClick}
                  onItemIconClick={onItemIconClick}
                  onItemActionClick={onItemActionClick}
                  {...props}
                />
              </DraggableItem>
            ))}
          </DraggableContainer>
        </DraggableVMenuWrapper>
      ) : (
        <VMenuWrapper role="vmenu" ref={menuRef} onKeyDown={onKeyDown}>
          {state.tree.map((iitem, index) => (
            <MenuNode
              key={index}
              level={0}
              item={iitem}
              currentId={supportKeyboard ? state.currentId : -1}
              onItemToggle={onItemToggle}
              onItemClick={onItemClick}
              onItemIconClick={onItemIconClick}
              onItemActionClick={onItemActionClick}
              {...props}
            />
          ))}
        </VMenuWrapper>
      )}
    </Box>
  );
};

export default VMenu;
