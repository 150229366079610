import React, { useState } from "react";
import styled from "styled-components";
import Button from "carbon-react/lib/components/button";
import IconButton from "carbon-react/lib/components/icon-button";
import Textbox from "carbon-react/lib/components/textbox";
import Icon from "carbon-react/lib/components/icon";
import PopoverContainer from "carbon-react/lib/components/popover-container";
import I18n from "i18n-js";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";

const LinkPopoverContainer = styled.div`
  display: flex;
  align-items: center;
  & [data-element="popover-container-content"] {
    z-index: 1 !important;
    padding: 8px;
    ${({ isDisplayedTop }) =>
      isDisplayedTop
        ? `
        -webkit-transform: translateY(-100%) translateY(-32px);
        transform: translateY(-100%) translateY(-32px) translateX(-100%) translateX(72px);
      `
        : `
        -webkit-transform: translateY(-50%) translateY(-32px);
        transform: translateY(-50%) translateY(-32px) translateX(-100%) translateX(72px);
        `}
  }
`;

const EditModeButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const LinkEditModePopover = ({
  open,
  setOpen,
  initialHref,
  initialText,
  onValidation,
  onCancel,
  isDisplayedTop,
}) => {
  const [localHref, setLocalHref] = useState(initialHref ?? "");
  const [localText, setLocalText] = useState(initialText ?? "");

  return (
    <LinkPopoverContainer isDisplayedTop={isDisplayedTop}>
      <PopoverContainer
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setLocalHref(initialHref ?? "");
          setLocalText(initialText ?? "");
          setOpen(false);
        }}
        open={open}
        renderOpenComponent={({ onClick }) => (
          <IconButton onAction={onClick}>
            <Icon type="edit" />
          </IconButton>
        )}
      >
        <Textbox
          key="textTextBox"
          mr="24px"
          ml="8px"
          label={I18n.t("businessProcess.title")}
          value={localText}
          onChange={(e) => {
            const newLinkText = e?.target?.value;
            setLocalText(newLinkText);
          }}
        />
        <Textbox
          key="hrefTextBox"
          mr="24px"
          ml="8px"
          label={I18n.t("businessProcess.link")}
          value={localHref}
          onChange={(e) => {
            const newLinkHref = e?.target?.value;
            setLocalHref(newLinkHref);
          }}
        />
        <EditModeButtonsContainer>
          <TertiaryButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onCancel();
                setLocalHref(initialHref ?? "");
                setLocalText(initialText ?? "");
              }}
              buttonType="tertiary"
              size="small"
            >
              {I18n.t("businessProcess.cancel")}
            </Button>
          </TertiaryButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onValidation({ newHref: localHref, newText: localText });
            }}
            buttonType="primary"
            size="small"
          >
            {I18n.t("businessProcess.save")}
          </Button>
        </EditModeButtonsContainer>
      </PopoverContainer>
    </LinkPopoverContainer>
  );
};

export default LinkEditModePopover;
