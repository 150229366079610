import React, { useState, useRef } from "react";
import { default as CProfile } from "carbon-react/lib/components/profile";
import { default as CPortrait } from "carbon-react/lib/components/portrait";
import Ellipsis from "./Ellipsis";
import IconPopover from "./IconPopover";
import styled from "styled-components";
import LayoutContent from "../../layout/LayoutContent";

const getProfile = ({ facet, value }) => {
  if (value && value.profile) return value.profile;
  if (facet && facet.profile) return facet.profile;
  return {
    name: "John Doe",
    initial: "JD",
    email: "john.doh@gmail.com",
    size: "XS",
  };
};

const Profile = ({ facet, value, ...props }) => {
  const { name, email, initial, size } = getProfile({ facet, value });
  const theme = props.theme;
  const ProfileContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    background-color: ${theme.colors?.white};
    text-align: left;
    font-weight: bold;
    line-height: normal;
    display: flex;
    align-items: center;
  `;

  const ProfileInfos = styled.div``;

  const ProfileMain = styled(Ellipsis)`
    font-weight: 700;
  `;
  const ProfileInfo = styled(Ellipsis)`
    font-size: smaller;
    font-weight: normal;
  `;

  const getProfileInfos = () => {
    if (value && value.infos) return value.infos;
    if (value && value.profile.infos) return value.profile.infos;
    if (facet && facet.profile && facet.profile.infos)
      return facet.profile.infos;
    return [];
  };

  return (
    <ProfileContainer>
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <CPortrait gravatar={email} initials={initial} size={size} />
      </div>
      <ProfileInfos>
        <ProfileMain key="name" text={name} />
        <ProfileInfo key="email" text={email} />
        {getProfileInfos().map((e, index) => (
          <ProfileInfo key={e.n} text={e.v} />
        ))}
      </ProfileInfos>
    </ProfileContainer>
  );
};

const CProfileContainer = styled.div`
  & [data-component="profile"] {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const IconProfile = ({ ...props }) => {
  const renderIcon = ({ value, facet, ...props }) => {
    const { email, initial, name, size } = getProfile({ value, facet });

    return (
      <CProfileContainer>
        <CProfile email={email} initials={initial} name={name} size={size} />
      </CProfileContainer>
    );
  };
  const theme = props.theme;
  const IconContainer = styled.div`
    background-color: ${theme.colors.black};
    padding-left: 10px;
  `;

  return (
    <IconContainer>
      <IconPopover
        isProfile
        renderContent={({
          open,
          setOpen,
          onClose,
          layout,
          content,
          refButtonAction,
          ...contentProps
        }) => {
          return (
            <LayoutContent
              setOpen={setOpen}
              refButtonAction={refButtonAction}
              open={open}
              focusIconPopoverAtOpening
              onClose={onClose}
              layout={layout}
              content={content}
              {...contentProps}
            />
          );
        }}
        renderIcon={renderIcon}
        {...props}
      />
    </IconContainer>
  );
};

export { IconProfile as default, Profile };
