import React, { useState, useRef } from "react";
import styled from "styled-components";
import PopoverContainer from "../components/PopoverContainer";
import LayoutContent from "../../layout/LayoutContent";
import Typography from "carbon-react/lib/components/typography";
import useTheme from "../hooks/useTheme";
import Icon from "carbon-react/lib/components/icon";

const getOnClickOpenPopover = (setOpen) => (e) => {
  e?.preventDefault?.();
  e?.stopPropagation?.();
  setOpen((previousOpen) => !previousOpen);
};

const getOnClose = (setOpen) => (e) => {
  e?.preventDefault?.();
  e?.stopPropagation?.();
  setOpen(false);
};

const getOnPointerEnter = (setOpen) => () => {
  setOpen(true);
};

const getOnPointerLeave = (setOpen) => () => {
  setOpen(false);
};

const getHandleKeyDown =
  ({ setOpen, isProfile, refMenuButtonContainer }) =>
  (e) => {
    const activeElement = document.activeElement;
    switch (e.key) {
      case "Enter":
      case "ArrowDown":
        if (
          !isProfile &&
          activeElement?.isEqualNode(refMenuButtonContainer?.current)
        ) {
          e.preventDefault?.();
          e.stopPropagation?.();
          setOpen(true);
        }
        break;
      case "Escape":
      case "ArrowUp":
        if (
          !isProfile &&
          refMenuButtonContainer?.current?.contains(activeElement)
        ) {
          e.preventDefault?.();
          e.stopPropagation?.();
          setOpen(false);
        }
        break;
      case "ArrowLeft":
      case "ArrowRight":
        if (
          isProfile &&
          refMenuButtonContainer?.current?.contains(activeElement)
        ) {
          e.preventDefault?.();
          e.stopPropagation?.();
          setOpen((previousOpen) => {
            if (previousOpen) {
              refMenuButtonContainer.current.focus();
            }
            return !previousOpen;
          });
        }
        break;
      default:
    }
  };

const getCssStringWithPosition = (position) => {
  if (position === "left") return "right: 100%";
  if (position === "right") return "left: 100%";
  return "right: 100%";
};

const getTextColor = ({ open, theme }) =>
  open ? theme?.customMenu?.hoverColor : theme?.customMenu?.color;

const getIconColor = ({ theme }) => theme?.colors?.white;

const MenuButtonContainer = styled.div`
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme?.customMenu?.borderColor};
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: ${({ theme, open }) =>
    open ? theme?.customMenu?.hoverBackground : theme?.customMenu?.background};
  & [data-element="popover-container-content"] {
    ${({ position }) => getCssStringWithPosition(position)};
    top: -9.4px;
  }

  & [data-component="popover-container"] {
    width: 100%;
  }
  &:focus {
    outline: 3px solid var(--colorsSemanticFocus500);
  }
`;

const RowButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  margin-left: 24px;
`;

const SelectableRowButton = ({ open, buttonTitle }) => {
  const theme = useTheme();
  return (
    <RowButtonContainer>
      <Typography color={getTextColor({ open, theme })}>
        {buttonTitle ?? ""}
      </Typography>
      <Icon type="chevron_right" color={getIconColor({ theme })} mr="16px" />
    </RowButtonContainer>
  );
};

const FacetPopoverButton = ({ value, control, ...props }) => {
  const [open, setOpen] = useState(false);
  const { popover, buttonTitle, isMenuItem, isProfile } = value || {};
  const position = value.position || "left";
  const refMenuButtonContainer = useRef();

  return (
    <MenuButtonContainer
      className={isMenuItem ? "frp1000-menu-item" : ""}
      ref={refMenuButtonContainer}
      tabIndex="0"
      onKeyDown={getHandleKeyDown({
        setOpen,
        refMenuButtonContainer,
        isProfile,
      })}
      open={open}
      position={position}
      onPointerEnter={getOnPointerEnter(setOpen)}
      onPointerLeave={getOnPointerLeave(setOpen)}
    >
      <PopoverContainer
        open={open}
        position={position}
        onClose={getOnClose(setOpen)}
        renderOpenComponent={() => (
          <SelectableRowButton buttonTitle={buttonTitle} open={open} />
        )}
        renderCloseComponent={() => null}
      >
        <LayoutContent
          setOpen={setOpen}
          open={open}
          layout={popover?.layout}
          content={popover?.facets}
          control={control}
          {...props}
        />
      </PopoverContainer>
    </MenuButtonContainer>
  );
};

export default FacetPopoverButton;
