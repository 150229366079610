import React, { useRef, Component } from 'react'
import DOMPurify from 'dompurify'
import eventBus from "../../events/eventBus";
import useTheme from "../hooks/useTheme";

const IFrame = ({ facet, control, id, value, width, height, eventHandlers, ...props }) => {

  const refIFrame = useRef(null);
  const theme = useTheme();

  const getAction = () => {
    if (value && value.action) return value.action;
    if (value && value.actions) return value.actions;
    return null;
  }
  const getWidth = () => {
    let ww = "100%";
    if (width) ww = width;
    if (value && value.width) ww = value.width;
    if (value && value.infos && value.infos.width) ww = value.infos.width;
    return theme?.viewPort?.dimension(ww);
  }
  const getHeight = () => {
    let hh = "100%";
    if (height) hh = height;
    if (value && value.height) hh = value.height;
    if (value && value.infos && value.infos.height) hh = value.infos.height;
    return theme?.viewPort?.dimension(hh);
  }

  const iframeStyle = {
    width:getWidth(),
    height:getHeight()
  }

  const getContent = () => {
      let dirty = value.value || '';
      if (dirty==='') return null;
      //
      let clean = DOMPurify.sanitize(dirty);
      return clean;
  }

  const getSource = () => {
    if (value && value.url) return value.url;
    return null;
  }

  const iframeMessage = (data) => {

    //console.log("iframeMessage",data)

    if (data && refIFrame.current) {
      refIFrame.current.contentWindow.postMessage(data,refIFrame.current.contentWindow.origin);
    }
  }

  if (eventHandlers) {
    eventHandlers.iframeMessage = iframeMessage;
  }

  window.setTimeout(()=>{
    //
    // Send action to the opened iframe
    //
    let action = getAction();
    if (action && refIFrame.current) {
      refIFrame.current.contentWindow.postMessage(action,refIFrame.current.contentWindow.origin);
    }
  },1000)

  return (
   <React.Fragment>
   {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
   <iframe
     id={id}
     ref={refIFrame}
     frameBorder="0"
     style={iframeStyle}
     src={getSource()}
     srcDoc={getContent()}
    />
  </React.Fragment>

  )
}

var eventWrappers = (ComposedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { iframeMessage: null };
    }

    componentDidMount() {
      eventBus.on("iframeMessage", (data) => {
        if (this.state.iframeMessage) this.state.iframeMessage(data);
      })
    }

    render() {
      return <ComposedComponent {...this.props} eventHandlers={this.state} />;
    }
  };

export default eventWrappers(IFrame)
