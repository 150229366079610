
const Dom = {
    getScrollParent: function(node) {
        if (node == null) {
          return null;
        }
      
        if (node.scrollHeight > node.clientHeight) {
          return node;
        } else {
          return this.getScrollParent(node.parentNode);
        }
    },
    scrollParent: function(elt,bw) {
        // Try to scroll focused item in view
         let p = this.getScrollParent(elt);
        if (p) {
            let rc = elt.getBoundingClientRect();
            let rcp = p.getBoundingClientRect();
    
            if (rc.bottom+bw>rcp.bottom) {
            p.scrollTo(p.scrollLeft,p.scrollTop+(rc.bottom+bw-rcp.bottom));
            }
            if (rc.top-bw<rcp.top) {
            p.scrollTo(p.scrollLeft,p.scrollTop-(rcp.top+bw-rc.top));
            }
        }
    },
		copyToClipboard: function(textToCopy) {

      // Create textarea element
    var textarea = document.createElement('textarea')
    
    // Set the value of the text
    textarea.value = textToCopy
    
    // Make sure we cant change the text of the textarea
    textarea.setAttribute('readonly', '');
    // Hide the textarea off the screnn
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    
    // Add the textarea to the page
    document.body.appendChild(textarea);
    
    // Copy the textarea
    textarea.select()
    
    try {
      var successful = document.execCommand('copy');
    } catch(err) {
    }
    
    textarea.remove()
    },

    download: function(url, name) {
    
      // Note :
      //  if firefox display the document inline instead of downloading 
      // it's not a bug, nothing you can do again that.
      // Go to settings in firefox, applications and define the 
      // default action for the document type
      //
      //console.log("doDownloadUrl",{url,name})
  
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      link.setAttribute("target", "_blank"); // in case of browser not supporting download
  
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    },
  

}

export default Dom;