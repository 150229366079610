import React, { useState } from 'react'
import styled from 'styled-components';
import Icon from "carbon-react/lib/components/icon";
import IconButton from 'carbon-react/lib/components/icon-button';

const ToolBarElt = styled.div`
padding: 8px;
display: flex;
justify-content: flex-start;
background: ${({ theme }) => theme?.menu?.custom?.hover};
flex-wrap: wrap;
font-size: 14px;
user-select: none;
order: 2;
border-right: none;
border-bottom: 1px solid rgb(102, 133, 146);
border-left: none;
border-image: initial;
border-top: 1px solid rgb(102, 133, 146);
`;

const ToolBarButton = ({ facet, control, value , button }) => {
  const onClick = (e) => {
    e.preventDefault();
    control.doFacetAction(facet,value,button.action);
  }

  return (
    <IconButton onAction={onClick}>
      <Icon type={button.icon} />
    </IconButton>
  )
}


const ToolBar = ({ facet, control, value }) => {

  const getButtons = () => {
    if ((value) && (value.buttons)) return value.buttons;
    if ((value) && (value.editor) && (value.editor.buttons)) return value.editor.buttons;
    return [];
  }

  return (
      <ToolBarElt>
        {getButtons().map((button, index) => (
            <ToolBarButton key={index} facet={facet} control={control} value={value} button={button} />
        ))}
      </ToolBarElt>
  )
}

export default ToolBar
