import * as React from 'react'
import styled from 'styled-components';
import { cloneDeep, mapValues } from "lodash"
import { FlowChart,actions,IPortDefaultProps,INodeDefaultProps,INodeInnerDefaultProps } from "@mrblenny/react-flow-chart" 
import CustomNodeInner from '../flowchart/CustomNodeInner'
import CustomPort from '../flowchart/CustomPort'

// facet business process editor
//  values[0] : chart
//  values[1] : nodeId
//  values[2] : newChart

const PageContent = styled.div`
display: flex;
flex-direction: row;
flex: 1;
max-width: 100vw;
max-height: 100vh;
`;

class FacetBpEditorWS extends React.Component {
    constructor(props) {
      super(props);

      //console.log("bpeditor-create",this.props.facet.values[0].chart);
      this.state = cloneDeep(this.props.facet.values[0].chart);
      this.onNodeClick_ = null;
      this.onNodeClick = this.onNodeClick.bind(this);
      this.onDeleteKey_ = null;
      this.onDeleteKey = this.onDeleteKey.bind(this);
    }

    onDeleteKey(...args) {
      //console.log("DeleteKey",...args,actions.onDeleteKey);
      this.props.control.setFacetValue(this.props.facet, this.props.facet.values[1], '');
      return this.onDeleteKey_(...args);
    }

    onNodeClick(...args) {
      //console.log("SelectNodeClick",...args, args[0].nodeId);
      if (this.props.facet.values[1].value!==args[0].nodeId) {
        this.props.control.setFacetValue(this.props.facet, this.props.facet.values[1], args[0].nodeId);
      }
      return this.onNodeClick_(...args);
    }

  
    render() {
        const chart = this.state

        let stateActions = mapValues(actions, (func: any) =>
        (...args: any) => this.setState(func(...args)))

        if (!this.onNodeClick_) this.onNodeClick_ = stateActions.onNodeClick;
        stateActions.onNodeClick = this.onNodeClick;
        
        if (!this.onDeleteKey_) this.onDeleteKey_ = stateActions.onDeleteKey;
        stateActions.onDeleteKey = this.onDeleteKey;
        
        // this is the updated chart
        // as the value is always selected it will be send
        // at the next roundtrip
        this.props.facet.values[2].newChart = chart; 
        //
        //console.log("bpEditor render",stateActions);
        return (
            <PageContent>
              <FlowChart
              chart={chart}
              callbacks={stateActions}
              Components={ {
                Port: CustomPort,
                NodeInner: CustomNodeInner,
              }}
              />
            </PageContent>
        );
    }
  }

export default FacetBpEditorWS
