import React from 'react'
import Container from './Container'
import './layout.css'

const Layout = ({ layout, ...props} ) => {

    return (
        <Container container={layout} {...props}/>
    )
}

export default Layout
