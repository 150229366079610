import React, {  useState }  from 'react';
import {includes} from 'lodash';
import {Treebeard} from 'react-treebeard';

const FacetTreeview = ( {facet, control, value, theme, ...props} ) => {

    const getController = () =>{
        if ((value) && (value.nodes) && (value.nodes.controller)) return value.nodes.controller;
        return null;
    }

    const getNodes = () => {
        if ((value) && (value.nodes) && (value.nodes.nodes)) return value.nodes.nodes;
        if ((value) && (value.nodes)) return value.nodes;
        if ((facet) && (facet.nodes)) return facet.nodes;
        return {
            name: 'root',
            toggled: true,
            children: [
                {
                    name: 'parent',
                    children: [
                        { name: 'child1' },
                        { name: 'child2' }
                    ]
                },
            ]
        };
    }

    const [data, setData] = useState(getNodes());
    const [cursor, setCursor] = useState(false);

    const onToggle = (node, toggled) => {
        
        //console.log("treeview-toggle",node,toggled)

        if (cursor) {
            cursor.active = false;
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        setCursor(node);

        if ((node.ref) && (getController())) {
            // we need to make a sync call to node controller to get more data.
            control.callMethod(getController(),node, (resp)=>{

                //console.log("treeview-call-resp",resp);

                delete node.ref;
                delete node.children;
                if ((resp) && (resp.nodes) && (resp.nodes.children)) {
                    // copy children inside newData
                    node.children = resp.nodes.children;
                    
                    setData(Object.assign({}, data));
                }

            });
        } else {
            setData(Object.assign({}, data))
        }
    
        if (node.active) control.setFacetValue(facet,value,node.id);
    }

    const onSelect = (node) => {
        
        console.log("tree-select",node)

        const {cursor, data} = this.state;

        if (cursor) {
            this.setState(() => ({cursor, active: false}));
            if (!includes(cursor.children, node)) {
                cursor.toggled = false;
                cursor.selected = false;
            }
        }

        node.selected = true;

        this.setState(() => ({cursor: node, data: Object.assign({}, data)}));
    }
    
    return (
       <Treebeard style={theme.treeview} data={data} onToggle={onToggle} onSelect={onSelect} />
    )
}

export default FacetTreeview