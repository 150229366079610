import React from "react";
import styled from "styled-components";
import Typography from "carbon-react/lib/components/typography";
import Button from "carbon-react/lib/components/button";
import Pill from "carbon-react/lib/components/pill";
import { Card } from "carbon-react/lib/components/card";
import Spacing from "../../components/Spacing";
import Ellipsis from "../../components/Ellipsis";
import useTheme from "../../hooks/useTheme";
import BoxCard from "./BoxCard";
import { saveBusinessProcessLocally } from "./BusinessProcessLinks";
import {
  useBusinessProcessContext,
  useSliderConfContext,
} from "./FacetBusinessProcess";
import eventBus from "../../../events/eventBus";
import { cloneDeep } from "lodash";
import I18n from "i18n-js";
import { v4 as uuidv4 } from "uuid";

const StepColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
`;

const BoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColumnTitleContainer = styled.div`
  min-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ColumnIndexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const HorizontalLine = styled.hr`
  flex-grow: 1;
  border: none;
  height: 2px;
  background-color: #ccd6db;
`;

const HorizontalBlankSpace = styled.hr`
  flex-grow: 1;
  border: none;
  height: 2px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  & button {
    width: ${({ cardWidth }) => cardWidth};
    margin-left: 12px;
    margin-right: 12px;
    color: ${({ theme }) => theme?.text?.color};
    border: 2px dashed ${({ theme }) => theme?.colors?.dashedBorder};
  }
  & button:hover {
    color: ${({ theme }) => theme?.text?.color};
    background-color: inherit;
  }
`;

const AddColumnButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const addBox = ({
  businessProcess,
  businessProcessValue,
  setFacetLocalBusinessProcess,
  stepIndex,
  theme,
}) => {
  const step = businessProcessValue?.steps?.[stepIndex];
  const newBox = {
    imageIndex: 1,
    title: "",
    titles: [],
    backgroundColorRef: theme?.colorPalette?.[0],
    uuid: uuidv4(),
  };
  const updatedStep = {
    ...step,
    boxes: [...(Array.isArray(step?.boxes) ? step.boxes : []), newBox],
  };
  const newBoxIndex = updatedStep.boxes.length - 1;
  const updatedSteps = cloneDeep(businessProcessValue?.steps);
  updatedSteps[stepIndex] = updatedStep;
  const updatedBusinessProcessValue = {
    ...businessProcessValue,
    steps: updatedSteps,
  };
  const updatedBusinessProcess = {
    ...businessProcess,
    value: JSON.stringify(updatedBusinessProcessValue),
  };
  saveBusinessProcessLocally({
    businessProcess: updatedBusinessProcess,
    setFacetLocalBusinessProcess,
  });
  setTimeout(() => {
    eventBus.emit("process-edit-box-added", {
      updatedBusinessProcess,
      stepIndex,
      newBoxIndex,
    });
  }, 100);
};

const ColumnIndex = ({ stepIndex, isLastStep }) => {
  const theme = useTheme();

  return (
    <ColumnIndexContainer>
      {stepIndex > 0 ? <HorizontalLine /> : <HorizontalBlankSpace />}
      <Pill pillRole="status" fill>
        {stepIndex + 1}
      </Pill>
      {isLastStep ? <HorizontalBlankSpace /> : <HorizontalLine />}
    </ColumnIndexContainer>
  );
};

const ColumnTitle = ({ title }) => {
  const { cardWidth } = useSliderConfContext();

  return (
    <ColumnTitleContainer>
      <Typography mb="0" fontWeight="700" fontSize="16px" lineHeight="21px">
        <Ellipsis
          text={title}
          width={Number(cardWidth.replace("px", ""))}
          style={{ textAlign: "center" }}
        />
      </Typography>
    </ColumnTitleContainer>
  );
};

const ColumnBoxes = ({ boxes, stepIndex }) => {
  return Array.isArray(boxes) ? (
    <BoxesContainer>
      {boxes.map((box, boxIndex) => (
        <BoxCard
          key={box.uuid}
          boxIndex={boxIndex}
          stepIndex={stepIndex}
          box={box}
        />
      ))}
    </BoxesContainer>
  ) : null;
};

const AddColumnButton = ({ stepIndex }) => {
  const {
    businessProcess,
    businessProcessValue,
    setFacetLocalBusinessProcess,
  } = useBusinessProcessContext();
  const { cardWidth } = useSliderConfContext();
  const theme = useTheme();

  return (
    <ButtonContainer cardWidth={cardWidth}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          addBox({
            theme,
            businessProcess,
            businessProcessValue,
            setFacetLocalBusinessProcess,
            stepIndex,
          });
        }}
        buttonType="tertiary"
      >
        {I18n.t("businessProcess.addANew")}
        <br />
        {I18n.t("businessProcess.subStep")}
      </Button>
    </ButtonContainer>
  );
};

const BusinessProcessStepColumn = ({ step, stepIndex, isLastStep }) => {
  const { boxes, title } = step;
  const { canEdit } = useBusinessProcessContext();

  return (
    <StepColumnContainer>
      <Spacing value={{ height: "24px" }} />
      <ColumnIndex stepIndex={stepIndex} isLastStep={isLastStep} />
      <Spacing value={{ height: "24px" }} />
      <ColumnTitle title={title} />
      <Spacing value={{ height: "16px" }} />
      <ColumnBoxes stepIndex={stepIndex} boxes={boxes} />
      {canEdit ? (
        <AddColumnButtonContainer>
          <AddColumnButton stepIndex={stepIndex} />
        </AddColumnButtonContainer>
      ) : null}
    </StepColumnContainer>
  );
};

export default BusinessProcessStepColumn;
