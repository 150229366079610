import React, {useEffect, useReducer, useRef} from 'react'
import Websocket from 'react-websocket';

const FacetWebsocket = ({facet}) => {
    const getUrl = () => {
        if (facet && facet.websocket) return facet.websocket.url||'';
        if (facet && facet.url) return facet.url||'';
        return '';
    }
    const getStart = () => {
        return getUrl()!=='';
    }
    const onMessage = (data) => {
        console.log("WS.Message",data)
    }

    console.log("WS.url",getUrl())

    if (getStart()) {
        return <Websocket url={getUrl()} onMessage={onMessage}></Websocket>
    } else {
        return null
    }
}

export default FacetWebsocket;
