import React, { useState, useEffect } from "react";
import { Checkbox as CarbonCheckbox } from "carbon-react/lib/components/checkbox";
import eventBus from "../../events/eventBus";

const getOnChange = ({ setChecked, selectionName, value }) => (e) => {
  setChecked(e.target.checked);

  if (value.groupId && selectionName) {
    eventBus.emit("SelectDone", {
      id: value.groupId,
      item: { [selectionName]: e.target.checked },
    });
  }
};
const Checkbox = ({ value }) => {
  const { initialChecked, checkboxProps, selectionName, title } = value;
  const [checked, setChecked] = useState(initialChecked ?? false);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  useEffect(() => {
    if(value.groupId && selectionName) {
      eventBus.emit("SelectDone", {
        id: value.groupId,
        item: { [selectionName]: initialChecked ?? false }
      })
    }
  }, [initialChecked, selectionName, value.groupId]);

  return (
    <CarbonCheckbox
      label={title}
      name={selectionName}
      checked={checked}
      onChange={getOnChange({ setChecked, selectionName, value })}
      {...(checkboxProps || {})}
    />
  );
};

export default Checkbox;
