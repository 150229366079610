import React, { useState, useEffect } from 'react'
import GroupedCharacter from "carbon-react/lib/components/grouped-character"


const InputTime = ({ facet, control, value, ...props }) => {

    const [newValue, setNewValue] = useState("000000");

    const timeToGroup = (t) => {
        if (t.length===6) return t;
        if (t.length===8) return t.replaceAll(':','');
        return '000000';
    }
    const groupToTime = (g) => {
        if (g.length===6) return g.slice(0,2)+':'+g.slice(2,4)+':'+g.slice(4);
        return g;
    }
    const validateDigit = (s) => {
        let n = parseInt(s,10);
        if (isNaN(n)||(n<0)||(n>9)) return '';
        return s;
    }
    const validateNumber = (s,min,max) => {
        let n = parseInt(s,10);
        if (isNaN(n)||(n<min)||(n>max)) n = 0;
        return n.toString().padStart(2, "0")
    }
    const validateGroup = (v) => {
        let r = '';
        switch (v.length) {
            case 0:
            r = '';
            break;
            case 1:
            r = validateDigit(v);
            break;
            case 2:
            r = validateNumber(v.slice(0,2),0,12);
            break;
            case 3:
            r = validateNumber(v.slice(0,2),0,12) + validateDigit(v.slice(2));
            break;
            case 4:
            r = validateNumber(v.slice(0,2),0,12) + validateNumber(v.slice(2),0,59);
            break;
            case 5:
            r = validateNumber(v.slice(0,2),0,12) + validateNumber(v.slice(2,4),0,59)+ validateDigit(v.slice(4));
            break;
            case 6:
            r = validateNumber(v.slice(0,2),0,12) + validateNumber(v.slice(2,4),0,59) + validateNumber(v.slice(4),0,59);
            break;
            default:
            r = v.slice(0,6);
        }
        return r;
    }
    useEffect(() => {
        setNewValue(timeToGroup(value.value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [control.roundTrip]);

    const onValueChange = (event) => {
        let v = event.target.value.rawValue;
        let s = validateGroup(v);
        setNewValue(s);
    }
    const onExit = () => {
        let newVal = groupToTime(newValue)
        if (value.value!==newVal) control.setFacetValue(facet,value,newVal);
    }
    const getId = () => {
        return value.id;
    }
    const getLabel = () => {
        if (value.title) return value.title;
        return null;
    }
    const getPlaceHolder = () => {
        if (value.placeHolder) return value.placeHolder;
        return null;
    }
    const getRequired = () => {
        if (value.required) return true;
        return false;
    }
    const getAutoFocus = () => {
        if (value.autoFocus) return true;
        return false;
    }
    const getDisabled = () => {
        return value.disabled || false;
    }

    console.log(getLabel(),newValue);

    return <GroupedCharacter groups={[2, 2, 2]} separator=":" placeHolder={getPlaceHolder()}  label={getLabel()} required={getRequired()} autoFocus={getAutoFocus()} id={getId()} value={newValue} onBlur={onExit} onChange={onValueChange} disabled={getDisabled()} />;
}

export default InputTime
