const fr = {
  businessProcess: {
    addNewStep: "Ajouter une nouvelle étape",
    editMode: "Mode édition",
    edit: "Éditer",
    deleteSubStep: "Supprimer la sous-étape",
    duplicate: "Dupliquer",
    cancel: "Annuler",
    cancelChanges: "Annuler les changements",
    save: "Enregistrer",
    useThisZoneToAddShortcuts:
      "Utilisez cette zone pour ajouter des raccourcis",
    addANew: "Ajouter une nouvelle",
    subStep: "sous-étape",
    businessProcess: "Tableau d'activités",
    dragAndDropExplanation:
      "Ajoutez une étape et une sous-étape puis glissez/déposez une entrée de menu pour ajouter une activité.",
    validationExplanation:
      "La duplication permet d'enregister vos changements sans altérer le tableau d'activités initial.",
    title: "Titre",
    link: "Lien",
    information: "Information",
    steps: "Étapes",
    setupBusinessProcess: "Paramétrer le tableau d’activités",
    none: "aucune",
    close: "Fermer",
    iconList: "Liste des icones",
  },
  noResult: "Aucun résultat",
  button: {
    commit: "Enregistrer",
    cancel: "Annuler",
    apply: "Appliquer",
  },
  action: {
    add: "Créer",
    modify: "Modifier",
    delete: "Supprimer",
  },
  grid: {
    noResult: "Aucun résultat",
  },
  confirm: {
    title: "Confirmation",
    areYouSure: "Êtes-vous sûr?",
    no: "Non",
    yes: "Oui",
  },
  dialog: {
    placeHolder: "Configurer...",
    cancel: "Annuler",
    apply: "Appliquer",
  },
  codeEditor: {
    placeHolder: "Editer...",
    compile: "Compiler",
    tooltipMessage: "Le script a été modifié",
  },
  emailEditor: {
    placeHolder: "Editer...",
    dialogTitle: "EMail",
  },
  iconParameters: {
    dialogTitle: "Paramètres",
    editTitle: "Paramètres",
    placeHolder: "Configurer...",
    headerName: "Nom",
    headerType: "Type",
    headerValue: "Valeur",
    tooltipMessage: "Des paramètres ont été configurés",
  },
  iconForm: {
    placeHolder: "Configurer...",
  },
  iconButton: {
    placeHolder: "Configurer...",
    isEdited: "Elément modifié",
  },
  iconTreeview: {
    placeHolder: "Configurer...",
    dialogTitle: "Sélectionner",
  },
  navigleft: {
    minimize: "Minimiser",
    closeAll: "Fermer tout",
    loading: "Chargement",
    noContent: "Aucun contenu",
  },
  menuEditor: {
    entry: {
      title: "Nouveau",
      caption: "Nouveau",
      action: {
        add: "Ajouter",
        remove: "Retirer",
        separator: "Séparateur",
      },
    },
    search: {
      placeholder: "Rechercher...",
      previous: "Préc.",
      next: "Suiv.",
    },
    collapseAll: "Tout réduire",
    expandAll: "Tout développer",
    addRootEntry: "Nouvelle entrée",
    filter: "Objets",
    sort: {
      label: "Tri",
      orderByDesc: "Tri descendant",
      orderByAsc: "Tri ascendant",
      order: "Sens",
    },
  },
  card: {
    noContent: "Cet élément n'a pas de contenu",
  },
};

const monthsStrictRegex =
    /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i,
  monthsShortStrictRegex =
    /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?)/i,
  monthsRegex =
    /(janv\.?|févr\.?|mars|avr\.?|mai|juin|juil\.?|août|sept\.?|oct\.?|nov\.?|déc\.?|janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i,
  monthsParse = [
    /^janv/i,
    /^févr/i,
    /^mars/i,
    /^avr/i,
    /^mai/i,
    /^juin/i,
    /^juil/i,
    /^août/i,
    /^sept/i,
    /^oct/i,
    /^nov/i,
    /^déc/i,
  ];

const moment_fr = {
  months:
    "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort:
    "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: monthsStrictRegex,
  monthsShortStrictRegex: monthsShortStrictRegex,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "di_lu_ma_me_je_ve_sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    ss: "%d secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    w: "une semaine",
    ww: "%d semaines",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
  ordinal: function (number, period) {
    switch (period) {
      // TODO: Return 'e' when day of month > 1. Move this case inside
      // block for masculine words below.
      // See https://github.com/moment/moment/issues/3375
      case "D":
        return number + (number === 1 ? "er" : "");

      // Words with masculine grammatical gender: mois, trimestre, jour
      default:
      case "M":
      case "Q":
      case "DDD":
      case "d":
        return number + (number === 1 ? "er" : "e");

      // Words with feminine grammatical gender: semaine
      case "w":
      case "W":
        return number + (number === 1 ? "re" : "e");
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
};

export { fr, moment_fr };
