import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Dialog from "carbon-react/lib/components/dialog";
import Button from "carbon-react/lib/components/button";
import IconButton from "carbon-react/lib/components/icon-button";
import Icon from "carbon-react/lib/components/icon";
import Pill from "carbon-react/lib/components/pill";
import Textbox from "carbon-react/lib/components/textbox";
import Textarea from "carbon-react/lib/components/textarea";
import { useBusinessProcessContext } from "./FacetBusinessProcess";
import { saveBusinessProcessLocally } from "./BusinessProcessLinks";
import DraggableContainer from "../../components/dragndrop/DraggableContainer";
import DraggableItem from "../../components/dragndrop/DraggableItem";
import AddStepButton from "./AddStepButton";
import { cloneDeep } from "lodash";
import useTheme from "../../hooks/useTheme";
import Typography from "carbon-react/lib/components/typography";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";
import I18n from "i18n-js";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
  margin-left: 32px;
  margin-bottom: 24px;
`;

const DialogContent = ({
  setChangesValidationDialogOpen,
  saveChangesCallback,
  cancelChangesCallback,
  saveDuplicateCallback,
}) => {
  const theme = useTheme();
  return (
    <ContentContainer>
      <Typography
        color={theme?.help?.color}
        mr="32px"
        ml="32px"
        fontSize="14px"
        fontWeight="400"
        lineHeight="21px"
      >
        {I18n.t("businessProcess.validationExplanation")}
      </Typography>
      <ButtonsContainer>
        <TertiaryButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              cancelChangesCallback();
              setChangesValidationDialogOpen(false);
            }}
            buttonType="tertiary"
          >
            {I18n.t("businessProcess.cancelChanges")}
          </Button>
        </TertiaryButtonWrapper>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            saveDuplicateCallback();
            setChangesValidationDialogOpen(false);
          }}
          buttonType="secondary"
        >
          {I18n.t("businessProcess.duplicate")}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            saveChangesCallback();
            setChangesValidationDialogOpen(false);
          }}
          buttonType="primary"
        >
          {I18n.t("businessProcess.save")}
        </Button>
      </ButtonsContainer>
    </ContentContainer>
  );
};

const ChangesValidationDialog = ({
  changesValidationDialogOpen,
  setChangesValidationDialogOpen,
  saveChangesCallback,
  cancelChangesCallback,
  saveDuplicateCallback,
}) => {
  const { businessProcessValue } = useBusinessProcessContext();
  const steps = useMemo(
    () => businessProcessValue?.steps || [],
    [businessProcessValue]
  );

  // div and not React Fragment is important here, to respect flex structure
  return (
    <Dialog
      open={changesValidationDialogOpen}
      size="medium-small"
      title="Sauvegarder les changements"
      onCancel={() => {
        setChangesValidationDialogOpen(false);
      }}
    >
      {changesValidationDialogOpen ? (
        <DialogContent
          saveChangesCallback={saveChangesCallback}
          cancelChangesCallback={cancelChangesCallback}
          saveDuplicateCallback={saveDuplicateCallback}
          setChangesValidationDialogOpen={setChangesValidationDialogOpen}
        />
      ) : null}
    </Dialog>
  );
};

export default ChangesValidationDialog;
