import React from "react";
import IFrame from '../../components/IFrame';

const NavTabContentIFrame = ({ height, control, tab, ...props}) => {
    const getIFrameValue = (tab) => {
      //process.env.REACT_APP_API_TOKEN
      let url = tab.url || '';
      let token = process.env.REACT_APP_API_TOKEN || '';
      if (url) {
          url = url.replace('%TOKEN%',token);
      }
      return {
          height,
          url:url,
          action:tab.action,
      };
    }

    return (
      <IFrame id={tab.id} control={control} height={height} value={getIFrameValue(tab)} {...props} />
    );
  }

export default NavTabContentIFrame;
