import React from "react";
import BaseGrid from "../components/BaseGrid";

const getBaseGridParametersFromTable = (table) => {
  if (!table) return {};
  return {
    columnDefs: table.columnDefs,
    data: table.rows,
    noHeader: table.noHeader,
  };
};

const FacetBaseGrid = ({ value, control }) => {
  const { table, usingMainController } = value;
  const { columnDefs, data, noHeader } = getBaseGridParametersFromTable(table);
  return (
    <BaseGrid
      columnDefs={columnDefs}
      data={data}
      noHeader={noHeader}
      control={control}
      usingMainController={usingMainController}
    />
  );
};

export default FacetBaseGrid;
