import React, { useState,useEffect } from 'react'
import I18n from "i18n-js";
import InputButton from './InputButton'

const IconForm = ({ facet, control, value }) => {

    const getAttribute = (elt,name) => {

        for(let propertyName in elt) {
            if (propertyName.startsWith('jQuery')) {
                let obj = elt[propertyName];
                return obj[name];
            }
        }
        return null;
    }

    const onCallBack = (event) => {
        //console.log("callBack event",event.data.uid,value.uid);
        if ((event.data.uid===value.uid) && (event.data.value))  {
        control.setFacetValue(facet,value,event.data.value);
        }
    }


    const onAction = () => {

        if (value.formUrl) {
            let payload = {action:"executeLink", uri:value.formUrl, cd:null};

            if ((window.parent) && (window.frameElement)) {
                let config = getAttribute(window.frameElement, 'config');
                payload.cd = config;
            }
            //we need to register the callback here to capture event uid
            if (value.uid) window.addEventListener("message", onCallBack);
            window.parent.postMessage(payload,window.parent.origin);
        }
    }

    const getSubTitle = () => {
    if ((typeof value.value==="object") && (value.value.text)) return value.value.text;
    if ((value) && (value.text)) return value.text;
    return null;
    }

    return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={onAction} subTitle={getSubTitle()} placeHolder={I18n.t("iconForm.placeHolder", { defaultValue: "Configure ..." })}/>
    </React.Fragment>
    )
}

export default IconForm
