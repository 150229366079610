import React, { useCallback, useLayoutEffect, useState, useEffect } from "react";
import Search from "carbon-react/lib/components/search";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import eventBus from "../../events/eventBus";

const DebouncedSearch = ({
  placeholder,
  defaultValue,
  searchWidth,
  onChangeCallback,
  debounceTime,
  otherSearchProps
}) => {
  const [value, setValue] = useState(defaultValue ?? "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDebouncedCallback = useCallback(
    debounce(onChangeCallback, debounceTime ?? 400),
    [onChangeCallback, debounceTime]
  );

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  useLayoutEffect(() => {
    setTimeout(() => {
      eventBus.emit("debounced-search-loaded", {});
    }, 50);
  }, []);

  return (
    <Search
      placeholder={placeholder}
      value={value}
      searchWidth={searchWidth && `${searchWidth}px`}
      onChange={(e) => {
        setValue((previousValue) => {
          if (e.target.value !== previousValue)
            memoizedDebouncedCallback(e.target.value);
          return e.target.value;
        });
      }}
      onClick={(e) => {
        setValue("");
      }}
      {...(otherSearchProps || {})}
    />
  );
};

DebouncedSearch.propTypes = {
  debounceTime: PropTypes.number,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  searchWidth: PropTypes.number,
  onChangeCallback: PropTypes.func.isRequired,
};

export default DebouncedSearch;
