import React, { useState, useEffect} from 'react'
import { merge } from "lodash";
import {Facet, FacetListContainer } from './Facet'
import FacetValue from './FacetValue'
import TextTitle from '../components/TextTitle';
import eventBus from '../../events/eventBus'

const getFacetFromList = list => {
  if(list.facets) return list.facets[0];

  return list;
};

const FacetList = ({ facet, ...props }) => {

    const [visible, setVisible] = useState(true)

    useEffect(()=> {
        setVisible(true);
    },[facet])


    const isHorz=()=>{
        return (facet.orientation==='horz');
    }

    const isWithMargin = () => {
        return !facet.noMargin;
    }

    const getStyle = () => {
        let rslt = {};
        if (props.style) rslt = merge(rslt,props.style);
        if (facet.style) rslt = merge(rslt,facet.style);
        if (props.theme) rslt = props.theme.translate(rslt);
        return rslt;
    }

    const getValueStyle=(value)=>{
        let rslt = {};
        if (isHorz() && isWithMargin()) rslt.marginRight="8px";
        if (isWithMargin()) rslt.marginBottom="8px";
        if (value && value.center) rslt.textAlign="center";
        if (value && value.left) rslt.textAlign="left";
        if (value && value.right) rslt.textAlign="right";
        if (value && value.align) rslt.textAlign=value.align;
        return rslt;
    }

    const hideEvent = () => {
        setVisible(false);
    }

    useEffect(() => {

        let event = facet.hideEvent;

        if (event) {
          eventBus.on(event, hideEvent);
        }
        return () => {
          if (event) {
            eventBus.remove(event, hideEvent);
          }
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []);

    return visible ? (
        <Facet autoWidth={facet.autoWidth} role="facet-list" style={getStyle()}>

            <TextTitle role="facet-list-title" facet={facet} {...props}/>
            {(facet.values) ?

                <FacetListContainer
                  role="facet-list-container"
                  justifyContent={facet?.justifyContent}
                  alignItems={facet?.alignItems}
                  horizontal={isHorz()}>
                {facet.values.map(value => {
                  if (!!value.list) {
                    const subFacet = getFacetFromList(value.list);

                    return <FacetList {...props} facet={subFacet} />;
                  }
                  return (
                    <FacetValue role="facet-value" style={getValueStyle(value)} key={value.id} facet={facet} value={value} {...props}/>
                )})}
                </FacetListContainer>
                :
                null
            }
        </Facet>
        ) : ( null 
    )
}

export default FacetList
