
  const eventBus = {
    list: new Map(),
    on(eventType, eventAction) {
      this.list.has(eventType) || this.list.set(eventType, []);
      if (this.list.get(eventType)) this.list.get(eventType).push(eventAction);
      return this;
    },

    remove(eventType, eventAction) {
      if (this.list.has(eventType)) {
        const eventActions = this.list.get(eventType);
        if (eventActions.length) {
          this.list.set(
            eventType,
            eventActions.filter((currentAction) => currentAction !== eventAction)
          );
        }
      }
      return this;
    },

    emit(eventType, ...args) {
      this.list.get(eventType) &&
        this.list.get(eventType).forEach((cb) => {
          cb(...args);
        });
    },

    emitAction(action, relay) {
      this.emit(action.type||action.name,action.data);
      if (relay && window.frameElement) {
        window.parent.postMessage(action,window.parent.origin);
      }
    },

  };

  export default eventBus;
