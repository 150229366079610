import React, { useState, useEffect } from "react";
import IconButton from "carbon-react/lib/components/icon-button";
import Button from "carbon-react/lib/components/button";
import Dialog from "carbon-react/lib/components/dialog";
import Icon from "carbon-react/lib/components/icon";
import Typography from "carbon-react/lib/components/typography";
import styled from "styled-components";
import I18n from "i18n-js";

const iconList = [
  "print",
  "ledger",
  "search",
  "settings",
  "cross",
  "email",
  "list_view",
  "spanner",
  "info",
  "help",
  "connect",
  "favourite",
  "folder",
  "add",
  "chart_pie",
  "calendar",
  "file_word",
  "file_excel",
  "file_pdf",
  "messages",
  "copy",
  "create",
  "delete",
  "download",
];

const defaultIcon = "image";

const IconSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DialogContentContainer = styled.div`
  margin: 24px;
  & button:focus {
    outline: none;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  & button {
    background-color: ${({ selected }) => (selected ? "#B3D6EF" : "inherit")};
  }

  & button:focus {
    background-color: ${({ selected }) => (selected ? "#B3D6EF" : "inherit")};
  }
`;

const RemoveIconContainer = styled.div`
  margin-left: 4px;
  & {
    background-color: ${({ selected }) => (selected ? "#B3D6EF" : "inherit")};
    cursor: pointer;
  }
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const DialogContent = ({
  customIconList,
  initialIcon,
  setSelectionDialogOpen,
  setLocalIcon,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(initialIcon);

  useEffect(() => {
    setSelectedIcon(initialIcon);
  }, [initialIcon]);

  return (
    <DialogContentContainer>
      <Typography>Sélectionnez l’icone de votre choix.</Typography>
      <RemoveIconContainer
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSelectedIcon(null);
        }}
        selected={!selectedIcon}
      >
        <Icon
          type="image"
          fontSize="medium"
          color="var(--colorsActionMinorYin030)"
        />
        <Typography variant="small">
          {I18n.t("businessProcess.none")}
        </Typography>
      </RemoveIconContainer>
      <IconsContainer>
        {(customIconList && Array.isArray(customIconList)
          ? customIconList
          : iconList
        ).map((icon) => (
          <IconContainer selected={icon === selectedIcon}>
            <IconButton
              onAction={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedIcon(icon);
              }}
            >
              <Icon fontSize="medium" type={icon} />
            </IconButton>
          </IconContainer>
        ))}
      </IconsContainer>
      <CloseButtonContainer>
        <Button
          buttonType="primary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setLocalIcon(selectedIcon);
            setSelectionDialogOpen(false);
          }}
        >
          {I18n.t("businessProcess.close")}
        </Button>
      </CloseButtonContainer>
    </DialogContentContainer>
  );
};

const IconSelection = ({ icon, setLocalIcon, customIconList }) => {
  const [selectionDialogOpen, setSelectionDialogOpen] = useState(false);
  return (
    <IconSelectionContainer>
      <IconButton
        onAction={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSelectionDialogOpen(true);
        }}
      >
        <Icon type={icon || defaultIcon} />
      </IconButton>
      <Dialog
        open={selectionDialogOpen}
        title={I18n.t("businessProcess.iconList")}
        size="medium-small"
        onCancel={() => setSelectionDialogOpen(false)}
      >
        {selectionDialogOpen ? (
          <DialogContent
            customIconList={customIconList}
            initialIcon={icon}
            setSelectionDialogOpen={setSelectionDialogOpen}
            setLocalIcon={setLocalIcon}
          />
        ) : null}
      </Dialog>
    </IconSelectionContainer>
  );
};

export default IconSelection;
