import React from "react";
import useTheme from "../../hooks/useTheme";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import Layout from "../../../layout/Layout";
import { Accordion } from "carbon-react/lib/components/accordion";

const ItemContainer = styled.li`
  list-style: none;
  text-decoration: none;
  display: block;
  border: ${(props) => props.theme.border.border};
  border-radius: ${(props) => props.theme.border.radius};
  box-shadow: ${(props) => props.theme.border.shadow};
  background: ${(props) => props.theme.border.background};
  overflow-wrap: break-word;
  overflow: hidden;
  margin-bottom: ${(props) => props.theme.itemResult.marginBottom};
  white-space: nowrap;

  &:hover {
    border: ${(props) => props.theme.border.hover.border};
    border-radius: ${(props) => props.theme.border.hover.radius};
    box-shadow: ${(props) => props.theme.border.hover.shadow};
    background-color: ${(props) => props.theme.itemResult.hover.backgroundColor};
  }
`;

const ItemClickable = styled.div`
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ItemIcon = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.messageColor(props.level)};
  flex-grow: 4;
`;

const Header_ = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.messageColor(props.level)};
  background-color: ${(props) => props.theme.colors.messageBackgroundColor(props.level)};
  flex-grow: 4;
`;

const ItemText = styled.div.attrs((props) => ({
  theme: props.theme,
  type: props.type,
}))`
  text-decoration: none;
  color: ${({ theme, type }) => theme.grid.item[type].color};
  font-size: ${({ theme, type }) => theme.grid.item[type].size};
  font-weight: ${({ theme, type }) => theme.grid.item[type].weight};
`;

const ItemTextR = styled(ItemText)`
  padding-right: 8px;
`;

const ItemTextL = styled(ItemText)`
  padding-left: 8px;
`;

const Body = styled.div`
  line-height: 1.5;
  margin-top: 0;
  display: flex;
`;

const Detail = styled.ul`
  list-style: none;
  padding: 12px 24px;
`;

const SubDetail = styled.li``;

const RValue = styled.span`
  font-weight: ${(props) => props.theme.text.weight};
  font-size: ${(props) => props.theme.text.size};
`;

const RKey = styled.span`
  font-family: monospace;
  font-weight: ${(props) => props.theme.text.weight};
  font-size: ${(props) => props.theme.text.size};
  flex: 0 1 50%;
  color: ${(props) => props.theme.text.color};

  &:before {
    content: '"';
  }
  &:after {
    content: '": ';
  }
`;

const Content = styled.p`
  line-height: 1.5;
  padding: 8px 24px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px 0;
  margin-top: 10px;
`;

const ExtraLayouts = styled.div`
  & .layout-body {
    display: inherit;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 10px;
`;

const ResultValue = ({ name, value }) => {
  return (
    <SubDetail>
      <RKey>{name}</RKey>
      <RValue>{value}</RValue>
    </SubDetail>
  );
};

const ResultContent = ({ item }) => {
  if (item.content) {
    return <Content>{item.content}</Content>;
  }
  return null;
};

const ResultDetail = ({ item }) => {
  if (item.properties) {
    return (
      <Detail>
        {item.properties.map?.((e, index) => (
          <ResultValue key={index} name={e[0]} value={e[1]} />
        ))}
      </Detail>
    );
  }
  if (item.values) {
    return (
      <Detail>
        {item.values.map?.((e, index) => (
          <ResultValue key={index} name={e.n} value={e.v} />
        ))}
      </Detail>
    );
  }

  return null;
};

const Item = ({
  itemRef,
  getRole,
  onItemClick,
  item,
  thisitem,
  getLActions,
  getRActions,
  extraLayouts,
  parentControl,
}) => {
  const theme = useTheme();
  return (
    <ItemContainer theme={theme} ref={itemRef} role={getRole()}>
      <ItemClickable onClick={(e) => onItemClick(item, e)}>
        <HeaderContainer>
          <ItemIcon>
            <Icon type={thisitem.icon} fontSize="medium" />
          </ItemIcon>
          <Header theme={theme} level={thisitem.titleLevel}>
            <ItemTextL theme={theme} type="header">
              {thisitem.title}
            </ItemTextL>
            <ItemTextR theme={theme} type="header">
              {thisitem.subTitle}
            </ItemTextR>
          </Header>
        </HeaderContainer>
        <Body>
          <ResultContent item={thisitem} />
          <ResultDetail item={thisitem} />
        </Body>
        <Footer>
          <ItemTextL theme={theme} type="footer">
            {thisitem.leftFooter}
          </ItemTextL>
          <ItemTextR theme={theme} type="footer">
            {thisitem.rightFooter}
          </ItemTextR>
        </Footer>
      </ItemClickable>
      <Actions>
        <ItemText type="action">{getLActions()}</ItemText>
        <ItemText type="action">{getRActions()}</ItemText>
      </Actions>
      <ExtraLayouts>
        {Object.entries(extraLayouts).map(([, { layout, facets, control, title }]) => (
          <Accordion defaultExpanded title={title ?? ""}>
            <Layout
              layout={layout}
              facets={facets}
              control={control || parentControl}
            />
          </Accordion>
        ))}
      </ExtraLayouts>
    </ItemContainer>
  );
};

export default Item;
