import React, { useState } from "react";
import { merge } from "lodash";
import styled from "styled-components";
import VerticalDivider from "carbon-react/lib/components/vertical-divider";
import Ellipsis from "./Ellipsis";

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--fontSize);
  font-weight: var(--fontWeight);
  color: ${({ darkMode }) => (darkMode ? "var(--darkColor)" : "var(--color)")};
  margin: var(--margin);
  background-color: ${({ darkMode, theme }) =>
    darkMode ? theme.colors.black : "inherit"};
  padding: 0;
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 1em 0em 0.5em 0em;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.headings["TT"]["--fontSize"]};
  color: ${(props) => props.theme.headings["TT"]["--color"]};
  letter-spacing: 2px;
  padding: 0;

  &:after {
    height: 1px;
    margin: 0 1em;
    background: black;
    content: "";
    flex: 1;
  }
`;

const TitleDivNT = styled.div`
  padding: 0;
`;

const HorzDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 1em -1em;

  &:before,
  &:after {
    background: black;
    height: 1px;
    margin: 0 1em;
    content: "";
    flex: 1;
  }
`;

const Separator = ({ facet, value }) => {
  return <VerticalDivider displayInline role="separator" />;
};

const Divider = ({ facet, value }) => {
  const getStyle = () => {
    if (value.style) return value.style;
    return {};
  };
  return (
    <HorzDivider role="divider" style={getStyle()}>
      {value.value || value.title}
    </HorzDivider>
  );
};

const TextTitle = ({ facet, control, value, title }) => {
  const hasTitle = () => {
    if (value && value.title) return true;
    if (facet && facet.title) return true;
    return false;
  };
  const getValue = () => {
    if (value && value.title) return value.title;
    if (facet && facet.title) return facet.title;
    return title;
  };
  return (
    <React.Fragment>
      {hasTitle() ? <TitleDiv>{getValue()}</TitleDiv> : <TitleDivNT />}
    </React.Fragment>
  );
};

const TextHeader = ({ facet, value, title, ...props }) => {
  const getValueProp = () => {
    if (value) return value;
    return facet;
  };

  const { darkMode } = getValueProp();

  const getTitle = () => {
    let prop = getValueProp();
    if (prop && prop.title) return prop.title;
    if (prop && prop.value) return prop.value;
    return title || "";
  };

  const getStyle = () => {
    let num = "def";
    let prop = getValueProp();

    if (prop && typeof prop.size === "number") {
      switch (prop.size) {
        case 0:
          num = "XL";
          break;
        case 1:
          num = "L";
          break;
        case 2:
          num = "M";
          break;
        case 3:
          num = "S";
          break;
        case 4:
          num = "XS";
          break;
        case 5:
          num = "XXS";
          break;
        default:
          num = "def";
          break;
      }
    }

    if (prop && typeof prop.size === "string") num = prop.size;

    let rslt = props.theme.headings[num];
    if (!rslt) rslt = props.theme.headings["def"];

    if (prop && prop.style) rslt = merge(rslt, prop.style);
    return rslt;
  };

  return (
    <HeaderDiv darkMode={darkMode} style={getStyle()}>
      {getTitle()}
    </HeaderDiv>
  );
};

const TextEllipsis = ({ facet, value, title }) => {
  const getValue = () => {
    if (value && value.title) return value.title;
    if (value && value.value) return value.value;
    if (facet && facet.title) return facet.title;
    return title;
  };
  const hasTitle = () => {
    return getValue() ? true : false;
  };
  return (
    <React.Fragment>
      {hasTitle() ? <Ellipsis text={getValue()} /> : <TitleDivNT />}
    </React.Fragment>
  );
};

export { TextTitle as default, TextHeader, Separator, Divider, TextEllipsis };
