import React, { useState, useEffect } from "react";
import Textbox from "carbon-react/lib/components/textbox";
import styled from "styled-components";
import eventBus from "../../events/eventBus";

const TextBoxContainer = styled.div`
  ${({ containerStyle }) => containerStyle ?? ""}
`;

const getOnExit =
  ({ facet, value, control, newValue, props }) =>
  () => {
    if (value.value !== newValue) control.setFacetValue(facet, value, newValue);
  };

const getId = (value) => {
  return value.name || value.id;
};

const getLabel = (value) => {
  if (value.title) return value.title;
  return undefined;
};

const getOnValueChange =
  ({ setNewValue, selectionName, customChange, value }) =>
  (event) => {
    setNewValue(event.target.value);
    customChange?.(event);
    if (value.groupId && selectionName) {
      eventBus.emit("SelectDone", {
        id: value.groupId,
        item: { [selectionName]: event.target.value },
      });
    }
  };

const InputText = ({
  facet,
  control,
  customChange,
  value,
  onBlur,
  ...props
}) => {
  const {
    value: initialText = "",
    textBoxProps,
    containerStyle,
    selectionName,
  } = value;
  const [newValue, setNewValue] = useState(null);

  //useEffect(() => {
  //  console.log("InputText.SetNewValue 1")
  //  setNewValue(value.value);
  //},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //[control.roundTrip]);

  useEffect(() => {
    setNewValue(initialText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextBoxContainer containerStyle={containerStyle}>
      <Textbox
        key={getId(value)}
        label={getLabel(value)}
        id={getId(value)}
        value={newValue}
        onBlur={onBlur || getOnExit({ facet, value, control, newValue, props })}
        onChange={getOnValueChange({
          setNewValue,
          customChange,
          selectionName,
          value,
        })}
        {...props}
        {...(textBoxProps || {})}
      />
    </TextBoxContainer>
  );
};

export default InputText;
