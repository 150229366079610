import React, { useState } from 'react'
import I18n from "i18n-js";
import InputButton from './InputButton'
import FacetDialog from '../facets/FacetDialog'

const IconDialog = ({ facet, control, value, ...props }) => {

  const [isOpen, setIsOpen] = useState(false);

  const onDone = (result) => {
    if (control) control.setFacetValue(facet,value,result);
    setIsOpen(false);
  }

  const getSubTitle = () => {
    if ((typeof value.value==="object") && (value.value.text)) return value.value.text;
    if ((value) && (value.text)) return value.text;
    return null;
  }

  return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={() => setIsOpen(!isOpen)} subTitle={getSubTitle()} placeHolder={I18n.t("dialog.placeHolder", { defaultValue: "Configure..." })}/>
        <FacetDialog facet={facet} control={control} value={value} isOpen={isOpen} onCancel={setIsOpen} onDone={onDone} {...props}/>
    </React.Fragment>
  )
}

export default IconDialog
