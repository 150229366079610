import React, { useState } from 'react'
import { merge } from "lodash"
import { ReactComponent as SageImage } from '../../../img/sage-logo.svg';

const Sage = ({...props}) => {

    const getStyle = () => {
        let rslt = {};
        let cfg = props.facet;
        if (props.value) cfg = props.value;

        if (cfg && cfg.size) {
            switch (cfg.size) {
                case "S":
                rslt.width = '57px';
                rslt.height = 'auto';
                break
                case "M":
                rslt.width = '114px';
                rslt.height = 'auto';
                break;
                case "L":
                rslt.width = '171px';
                rslt.height = 'auto';
                break;
                default:
            }
        }
        if (cfg && cfg.width) {
            rslt.width = cfg.width;
            rslt.height = 'auto';
        }

        if (props.style) rslt = merge(rslt,props.style);
        return rslt;
    }

    return (
        <SageImage  style={getStyle()} />
    )
}

export default Sage;
