import React, {useEffect, Component} from 'react';
import {Facet} from './Facet'
import styled, {css} from 'styled-components';
import eventBus from '../../events/eventBus'

const Pages = styled.ul`
width: 100%;
display:flex;
align-items: center;
justify-content: space-between;
`;

const currentStyle = css`
  outline:${props => props.theme.border.hover.outline};
`;

const Page = styled.li`
cursor: pointer;
min-width: 28px;
height: 28px;
line-height: 28px;
text-align: center;
list-style: none;
float: left;
margin-right: 8px;

&:focus-within {
  outline:${props => props.theme.border.focused.outline};
}

${props => props.current && currentStyle}

`;

const Link = styled.a`
  text-decoration: none;
  outline: none;
`;
const LinkPrev = styled(Link)`
  &:after {
    content: "\\2039";
    display: block;
  }
`;
const LinkNext = styled(Link)`
  &:after {
    content: "\\203a";
    display: block;
  }
`;

const PagesWrapper = styled.div`
  overflow-x: clip;
  padding-left: 4px;
`;

const Pagination = ({ data, ...props }) => {

  return (
      <PagesWrapper role="pagination" style={{overflowX:"clip"}}>
      <Pages role="pagination-pages">

        {data.prevValue ?
        <Page role="pagination-pagePrev">
            <LinkPrev title={data.prevValue.title} onClick={data.onPrevPages} href='!#'></LinkPrev>
        </Page>  : null }

        {data.pageNumbers.map(number => (
          <Page role="pagination-page" key={number} current={number===data.currentPage}>
            <Link onClick={(event)=>data.onSetPage(number,event)} href='!#'>{number}</Link>
          </Page>
        ))}

        {data.nextValue ?
        <Page role="pagination-pageNext">
            <LinkNext title={data.nextValue.title} onClick={data.onNextPages} href='!#'></LinkNext>
        </Page>  : null }
      </Pages>
      </PagesWrapper>
  );
};

//////////////////////////////////////////////////////

var eventWrappers = ComposedComponent => class extends Component {
  constructor(props) {
    super(props);
    this.state = { id:null, pageDown: null, pageUp:null };
  }

  componentDidMount() {
    eventBus
      .on("PageDown", (data) => {
        if (data.id === this.state.id && this.state.pageDown)
          this.state.pageDown(data);
      })
      .on("PageUp", (data) => {
        if (data.id === this.state.id && this.state.pageUp) this.state.pageUp(data);
      });
  }

  render() {
    return <ComposedComponent {...this.props} eventHandlers={this.state} />;
  }
};

const FacetPagination = ({ facet, control, eventHandlers, ...props }) => {

  const getValue = (t) => {
    if (facet.values)
    return facet.values.find(v=>{
        if ((v.name===t)||(v.title===t)) return true;
        return false;
    })
    return null;
  }

  const data = {

    pageNumbers: [],
    firstPage: 0,
    pageCount: 0,
    currentPage: 1,
    nextValue:null,
    prevValue:null,

    set:function(pageCount,firstPage,currentPage) {
      this.firstPage = firstPage;
      this.pageCount = pageCount;
      this.currentPage = currentPage;
      for (let i = 1; i <= this.pageCount; i++) {
        this.pageNumbers.push(firstPage+i);
      }
    },
    onPrevPage:function(e) { control.selectFacetValue(facet,this.prevValue); if (e) e.preventDefault(); },
    onNextPage:function(e) { control.selectFacetValue(facet,this.nextValue); if (e) e.preventDefault(); },
    onSetPage:function(number,e) { control.setPage(number-this.firstPage); if (e) e.preventDefault(); },
    onPageDown:function() { if (this.currentPage<this.pageCount) this.onSetPage(this.currentPage+1); },
    onPageUp:function() { if (this.currentPage>1) this.onSetPage(this.currentPage-1); },
  }

  data.set(
    Math.ceil(control.totalPosts / control.itemsPerPage),
    control.firstPage,
    control.currentPage
  )
  data.nextValue = getValue('next');
  data.prevValue = getValue('prev');

  if (eventHandlers) {
    eventHandlers.id = facet.groupId;
    eventHandlers.pageUp = () => {
      data.onPageUp();
    }
    eventHandlers.pageDown = () => {
      data.onPageDown();
    }
  }

  return (
    <Pagination data={data} {...props}/>
  );
};

export default eventWrappers(FacetPagination);
