import React, { useEffect, useState, } from "react";
import styled from 'styled-components';
import IconButton from 'carbon-react/lib/components/icon-button';
import Icon from 'carbon-react/lib/components/icon';
import IFrame from '../../components/IFrame';
import { getClickHandler } from "../../../common/actionHelpers";
import Modal from '../../components/Modal'
  
const NavTabDialogTitleContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${props => props.theme.navigation.panel.title.height};
    background-color: ${props => props.theme.navigation.panel.title.backgroundColor};
    color: ${props => props.theme.navigation.panel.title.color};
    font-size: ${props => props.theme.navigation.panel.title.fontSize};
    font-weight: ${props => props.theme.navigation.panel.title.fontWeight};
`;
const NavTabDialogTitle = styled.div`
    padding-left: ${props => props.theme.navigation.panel.title.paddingLeft};
    margin-right:auto;
`;
const NavTabDialogIFrameWrapper = styled.div`
    & > iframe > html {
        overflow: hidden;
    }
`;

const NavTabDialog = ({ control, data, title, isOpen, closeQuery, index, ...props}) => {

    const [isFavourite,setFavourite] = useState(false);

    useEffect(()=>{
        setFavourite(data?data.favourite||false:false)
    },[data])

    const getIFrameValue = () => {

        //procedss.env.REACT_APP_API_TOKEN
        let url = data.url || '';
        let token = process.env.REACT_APP_API_TOKEN || '';
        if (url) {
            url = url.replace('%TOKEN%',token);
        }
        return {
            facet:0,
            id:0,
            class:'iframe',
            url:url,
            infos:data.infos,
            action:data.action,
        };
    }
 
    const switchFavourite = (e) => {
      if (e) e.preventDefault();
      let wasFavourite = isFavourite;
      setFavourite(! isFavourite);
      if(wasFavourite) control.callAction("fav.delete", { tab: data });
      else control.callAction("fav.add", { tab: data });      
    }

    const _formTitle = (data && data?.infos?.formTitle?data.infos.formTitle:"");

    const getActions = () => {
      if (data.actions) return data.actions;
      return [];
    }

    const hasClose = () => {
      return data?.canClose?true:false;
    }

    const onCloseClick = (e) => {
        if (e) e.preventDefault();
        closeQuery(data);
    }

    return (
      <React.Fragment>
      {isOpen ?
          <Modal
            isOpen={isOpen}
            role="tabdialog"
            contentHeight={data.infos.height}
            contentWidth={data.infos.width}
            zindex = {index*10}
            children = {
              <div>
                <NavTabDialogTitleContainer role="tab-dialog-title-container">
                  <IconButton onAction={switchFavourite}>
                  <Icon
                    type={isFavourite ? "favourite" : "favourite_lined"}
                    tooltipMessage={
                      isFavourite
                        ? "Retirer des favoris"
                        : "Mettre en favoris"
                    }
                    color={
                      isFavourite
                        ? props.theme?.icon?.color?.favourite
                        : props.theme?.icon?.color?.default
                    }
                  />
                  </IconButton>
                  <NavTabDialogTitle role="tab-dialog-title">{_formTitle}</NavTabDialogTitle>
  
                  {getActions().map((action, index) => (
                    <IconButton key={index} onAction={getClickHandler({action,control})}><Icon type={action.icon} tooltipMessage={action.title} color="white" mr="8px"/></IconButton>
                    ))
                  }

                  {hasClose()?
                    <IconButton onAction={onCloseClick}><Icon type="close" tooltipMessage="close the form" color="white" mr="8px"/></IconButton>
                    :null
                  }
  
                </NavTabDialogTitleContainer>
                <NavTabDialogIFrameWrapper role="tab-dialog-iframe" >
                  <IFrame  value={getIFrameValue()} {...props} />
                </NavTabDialogIFrameWrapper>
              </div>
            }
          />
          : null
      }
      </React.Fragment>
      )
}

export default NavTabDialog;