import React, { useState } from 'react'
import I18n from "i18n-js";
import InputButton from './InputButton'
import FacetIFrame from './IFrame'
import FacetDialog from '../facets/FacetDialog'


const IconEmailEditor = ({ facet, control, value, ...props }) => {

  const [isOpen, setIsOpen] = useState(false);

  const setOpen = () => {
    setIsOpen(!isOpen)
  }

  const emailCallBack = (req) => {

    console.log("email-callback",req)

    if ((req.meta) && (req.meta.action)) {
        if (req.meta.action.name==='apply') {
            //
            if (req.filters) {
                req.filters.forEach((v)=>{
                    if (v.id===0) {
                        control.setFacetValue(facet,value,v.value);
                    }
                })
            }
            setIsOpen(false);
        }
        if (req.meta.action.name==='cancel') {
          setIsOpen(false);
        }
    }

    let resp = {
        meta: {id:1},
        facets:[
          {id:0,title:I18n.t("emailEditor.dialogTitle", { defaultValue: "Email" }), type:'list', position:'main-body', values:[
            {facet:0, id:0, class:'emailEditor', editor:{html:value.value, links:value.links, variables:value.variables} },
        ]},
        {id:1, type:'list', position:'main-footer', orientation:'horz', values:[
          {facet:1, id:0, class:'button', button:{title:I18n.t("dialog.cancel", { defaultValue: "Cancel" }), action:'cancel'}},
          {facet:1, id:1, class:'button', button:{title:I18n.t("dialog.apply", { defaultValue: "Apply" }), action:'apply'}}

      ]}
    ],
    }
    return resp;
}

  const getDialogValue = () => {
    return {facet:facet.id, id:value.id, dialog:{title:facet.title, callBack:emailCallBack}};
  }

  return (
    <React.Fragment>
        <InputButton facet={facet} control={control} value={value} onAction={setOpen} placeHolder={I18n.t("emailEditor.placeHolder", { defaultValue: "Editer..." })} />
        <FacetIFrame facet={facet} control={control} value={value} />
        <FacetDialog facet={facet} control={control} value={getDialogValue()} isOpen={isOpen} onCancel={setOpen} {...props}/>
    </React.Fragment>
  )
}

export default IconEmailEditor
