import React, { useState, useEffect, Component } from 'react'
import styled from "styled-components";
import Modal from "../components/Modal"
import Message from "carbon-react/lib/components/message"
import eventBus from "../../events/eventBus";
import I18n from "i18n-js";

const Container = styled.ul`
    position: absolute;
    margin: 0px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.theme.messageBox.width};
    height: auto;
    background-color: white;
    border-radius: 4px;
`;

const FacetError = ({control, eventHandlers, ...props}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(null);

    const getValue = () => {
        return error;
    }

    useEffect(() => {
        setError(control.error);
        setIsOpen(getValue()!==null);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [control]);

    const onClose = () => {

        setIsOpen(false);

        let value = getValue();
        if (!value) return ;
        //
        if (value.redirect) {
            window.setTimeout( function() {
                window.location.replace(value.redirect);
            }, 1000);
        } else {
            control.clearError();
        }
    }
    const getTitle = () => {
        return I18n.t("error.title", { defaultValue: "Erreur d'application." });
    }
    const getMessage = () => {
        let value = getValue();
        if (!value) return "";
        if (value.faultString) return value.faultString;
        if (value.redirect) return I18n.t("error.sessionExpired", { defaultValue: "Votre session a expiré." });
        return I18n.t("error.default", { defaultValue: "Une erreur est survenue." });
    }

    if (eventHandlers) {
        eventHandlers.sessionExpired = (data) => {
            if (data.data) {
                setError(data.data);
                setIsOpen(true);
            }
        };
    }

    return (
        <React.Fragment>
        {isOpen ?
            <Modal 
                role="error-background"
                isOpen={isOpen}
                role={"error"}
                contentHeight={0}
                contentWidth={0}
                children={
                    <Container role="error-container">
                        <Message role="error-message" open={isOpen} onDismiss={onClose} transparent={false} title={getTitle()} variant="warning" >{getMessage()}</Message>
                    </Container>
                }
            />
            : null
        }
        </React.Fragment>
        )
}

var eventWrappers = (ComposedComponent) =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { sessionExpired: null };
    }

    componentDidMount() {
      eventBus.on("sessionExpired", (data) => {
        if (this.state.sessionExpired) this.state.sessionExpired(data);
      })
    }

    render() {
      return <ComposedComponent {...this.props} eventHandlers={this.state} />;
    }
  };

export default eventWrappers(FacetError);
