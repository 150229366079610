import React from "react";
import CarbonImage from "carbon-react/lib/components/image";
import srcSpotIconProducts from "../../../img/spot-icon-products.svg";
import srcSpotIconNoResult from "../../../img/spot-icon-noresult.svg";
import srcSpotIconFirstSearch from "../../../img/spot-icon-first-search.svg";



const images = {
  "spot-icon-products.svg": srcSpotIconProducts,
  "spot-icon-noresult.svg": srcSpotIconNoResult,
  "spot-icon-first-search.svg": srcSpotIconFirstSearch
}

const Image = ({ value }) => {
  const { imageProps: { src, ...imageProps } } = value;

  return <CarbonImage src={images[src]} {...(imageProps || {})} />;
};

export default Image;
