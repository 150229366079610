import React from "react";
import Typography from "carbon-react/lib/components/typography";
import styled from "styled-components";

const TextContainer = styled.div`
  ${({ color, theme }) =>
    color ? `color: ${theme.colors.translate(color) ?? color};` : ""}
`;

const TextTypography = ({ value }) => {
  const { typographyProps, color } = value;

  const getText = () => {
    if (value.typographyText) return value.typographyText;
    if (value && value.typography) return value.typography.text;
    if (value.value) return value.value;
    if (value.title) return value.title;
  };

  return (
    <Typography {...typographyProps}>
      {color ? (
        <TextContainer color={color}>{getText()}</TextContainer>
      ) : (
        getText()
      )}
    </Typography>
  );
};

export default TextTypography;
