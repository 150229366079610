import React, { useState } from 'react'
import styled from 'styled-components';
import Message from "carbon-react/lib/components/message"

const TextMessage = ({ facet, value, title, message }) => {

    const getTitle = () => {
        if ((value) && (value.title)) return value.title;
        if ((facet) && (facet.title)) return facet.title;
        return title;
    }
    const getMessage = () => {
        if ((value) && (value.message)) return value.message;
        if ((value) && (value.value)) return value.value;
        if ((facet) && (facet.message)) return facet.message;
        return message;
    }
    const getVariant = () => {
        if (value && value.variant) return value.variant;
        if (facet && facet.variant) return facet.variant;
        return "warning";
    }

    return (
        <Message transparent={false} title={getTitle()} variant={getVariant()} >{getMessage()}</Message>
    )
}

export default TextMessage 
