import React, { useState, useEffect } from "react";
import Search from "carbon-react/lib/components/search";
import styled from "styled-components";
import eventBus from "../../events/eventBus";

const SearchContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  & div[data-component="search"] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  & div[data-component="search"] > :first-child {
    flex-shrink: 1;
    flex-basis: auto;
    max-width: ${({ searchWidth }) => searchWidth};
  }
  & div[data-component="search"] > :not(:first-child) {
    position: relative;
    margin-left: 0px;
    ${({ buttonTopOffset }) => buttonTopOffset ? `top: ${buttonTopOffset};` : ""}
  }
  & div[data-component="search"] > div:first-child > div:first-child {
    width: ${({ searchWidth }) => searchWidth};
  }
  & div[role="presentation"] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  & div[role="presentation"] > input {
    width: calc(${({ searchWidth }) => searchWidth} - 40px);
  }
`;

const FacetSearch = ({ facet, control, value }) => {

  const getValue = () => {
    if (value) return value;
    if (facet && facet.values) return facet.values[0];
    return null;
  };

  const getSearchText = () => {
    //
    let value = getValue();
    if (value && value?.search?.value) return value.search.value;
    if (value && value.filter) return value.filter.args[0];
    return "";
  };

  const getButtonTopOffset = () => {
    const value = getValue();
    return value?.search?.buttonTopOffset;
  };

  const [searchValue, setSearchValue] = useState(getSearchText());

  const getSearchProps = () => {
    let value = getValue();
    if (value && value?.search) return value.search;
    return {};
  };

  const submitSearch = (newVal) => {
    let value = getValue();

    if (value && value?.search?.callbackName) {
      control.callAction(
        value.search.callbackName,
        { value: newVal },
        (data) => { if (value.search.autoClear) setSearchValue('')},
        value.spinArea
      );
      return;
    }
    //
    if (value)
      control.setFacetValueParam(facet, value, 0, newVal, value.spinArea);
  };

  const onSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      let newVal = e.target.value;
      submitSearch(newVal);
    }
  };
  const onSearchChange = (e) => {
    let newVal = e.target.value;
    setSearchValue(newVal);

    if (newVal === "") {
      // clear the field
      let value = getValue();
      if (value.clearEvent) {
        eventBus.emit(value.clearEvent, { id: value.groupId });
      } else {
        submitSearch("");
      }
    }
  };
  const onSearchClick = (e) => {
    let newVal = e.target.value;
    submitSearch(newVal);
  };

  const getWidth = () => {
    let value = getValue();
    if (value && value.search) return value.search.width || "256px";
    return "256px";
  };

  const getPlaceHolder = () => {
    let value = getValue();
    if (value && value?.search?.placeHolder)
      return value.search.placeHolder || "";
    if (facet && facet.title) return facet.title;
    return "";
  };

  ///////////////////////////////////////////////////////////////////
  const getSearchEvent = () => {
    return getSearchProps().event;
  };

  const searchCallback = (data) => {

    let value = getSearchProps();

    if ((typeof value.eventId === "undefined") || (value.eventId===data.id)) {
      let newVal = data.value;
      setSearchValue(newVal);
      submitSearch(newVal);
    }
  };

  useEffect(
    () => {
      let event = getSearchEvent();

      if (event) {
        eventBus.on(event, searchCallback);
      }
      return () => {
        if (event) {
          eventBus.remove(event, searchCallback);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
///////////////////////////////////////////////////////////////////

  return (
    <SearchContainer
      buttonTopOffset={getButtonTopOffset()}
      role="search-container"
      searchWidth={getWidth()}
    >
      <Search
        role="search"
        value={searchValue}
        placeholder={getPlaceHolder()}
        searchwidth={getWidth()}
        searchButton
        onClick={onSearchClick}
        onChange={onSearchChange}
        onKeyDown={onSearchKeyDown}
      />
    </SearchContainer>
  );
};


export default FacetSearch;
