import React from 'react'
import {Facet, FacetTitle, FacetValueTitle} from './Facet'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

const FacetDaterange = ({ facet, control }) => {
    
    const value = () => {
        return facet.values[0];
    }

    const startDate = () => {
        return Date.parse(value().filter.args[0]);
    }
    const endDate = () => {
        return Date.parse(value().filter.args[1]);
    }
    
    const onStartChange = (date) => {
        control.setFacetValueParam(facet,facet.values[0],0,date.toISOString());
    }
    const onEndChange = (date) => {
        control.setFacetValueParam(facet,facet.values[0],1,date.toISOString());
    }


    return (
        <Facet>
            <FacetTitle>{facet.title}</FacetTitle>
            <FacetValueTitle href='!#'>{value().title}</FacetValueTitle>
            <DatePicker selected={startDate()} onChange={(date) => onStartChange(date)} selectsStart startDate={startDate()} endDate={endDate()}/>
            <DatePicker selected={endDate()} onChange={(date) => onEndChange(date)} selectsEnd startDate={startDate()} endDate={endDate()} minDate={startDate()} />
        </Facet>
    )
}

export default FacetDaterange
