const es = {
  businessProcess: {
    addNewStep: "Añadir paso",
    editMode: "Modo de edición",
    edit: "Editar",
    deleteSubStep: "Eliminar subpaso",
    duplicate: "Duplicar",
    cancel: "Cancelar",
    cancelChanges: "Deshacer cambios",
    save: "Guardar",
    useThisZoneToAddShortcuts: "Utiliza esta área para añadir accesos directos.",
    addANew: "Añadir",
    subStep: "subpaso",
    businessProcess: "Tabla de actividades",
    dragAndDropExplanation:
      "Añade un paso y un subpaso y, luego, arrastra y suelta una entrada de menú para añadir una actividad.",
    validationExplanation:
      "La replicación le permite guardar sus cambios sin alterar la tabla de actividades inicial.",
    title: "Título",
    link: "Enlace",
    information: "Información",
    steps: "Pasos",
    setupBusinessProcess: "Parametrizar tabla de actividades",
    none: "Ninguno",
    close: "Cerrar",
    iconList: "Lista de iconos",
  },
  noResult: "Ningún resultado",
  button: {
    commit: "Guardar",
    cancel: "Cancelar",
    apply: "Aplicar",
  },
  action: {
    add: "Crear",
    modify: "Modificar",
    delete: "Eliminar",
  },
  grid: {
    noResult: "Ningún resultado",
  },
  confirm: {
    title: "Confirmación",
    areYouSure: "¿Está usted seguro?",
    no: "No",
    yes: "Sí",
  },
  dialog: {
    placeHolder: "Configurar...",
    cancel: "Cancelar",
    apply: "Aplicar",
  },
  codeEditor: {
    placeHolder: "Editar...",
    compile: "Compilar",
    tooltipMessage: "El script se ha modificado.",
  },
  emailEditor: {
    placeHolder: "Editar...",
    dialogTitle: "E-mail",
  },
  iconParameters: {
    dialogTitle: "Configuración",
    editTitle: "Configuración",
    placeHolder: "Configurar...",
    headerName: "Nombre",
    headerType: "Tipo",
    headerValue: "Valor",
    tooltipMessage: "La configuración se ha completado.",
  },
  iconForm: {
    placeHolder: "Configurar...",
  },
  iconButton: {
    placeHolder: "Configurar...",
    isEdited: "Elemento modificado",
  },
  iconTreeview: {
    placeHolder: "Configurar...",
    dialogTitle: "Selección",
  },
  navigleft: {
    minimize: "Minimizar",
    closeAll: "Cerrar todo",
    loading: "Carga",
    noContent: "Sin contenido",
  },
  menuEditor: {
    entry: {
      title: "Nueva",
      caption: "Nueva",
      action: {
        add: "Añadir",
        remove: "Quitar",
        separator: "Separador",
      },
    },
    search: {
      placeholder: "Buscar...",
      previous: "Ant.",
      next: "Sig.",
    },
    collapseAll: "Contraer todo",
    expandAll: "Expandir todo",
    addRootEntry: "Nueva entrada",
    filter: "Objetos",
    sort: {
      label: "Orden",
      orderByDesc: "Orden descendente",
      orderByAsc: "Orden ascendente",
      order: "Orden",
    },
  },
  card: {
    noContent: "Este elemento no tiene contenido.",
  },
};

const monthsShortDot =
    "ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.".split("_"),
  monthsShort = "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
  monthsParse = [
    /^ene/i,
    /^feb/i,
    /^mar/i,
    /^abr/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^ago/i,
    /^sep/i,
    /^oct/i,
    /^nov/i,
    /^dic/i,
  ],
  monthsRegex =
    /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre|ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i;

const moment_es = {
  months:
    "enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre".split(
      "_"
    ),
  monthsShort: function (m, format) {
    if (!m) {
      return monthsShortDot;
    } else if (/-MMM-/.test(format)) {
      return monthsShort[m.month()];
    } else {
      return monthsShortDot[m.month()];
    }
  },
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  monthsStrictRegex:
    /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i,
  monthsShortStrictRegex:
    /^(ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: "domingo_lunes_martes_miércoles_jueves_viernes_sábado".split("_"),
  weekdaysShort: "dom._lun._mar._mié._jue._vie._sáb.".split("_"),
  weekdaysMin: "do_lu_ma_mi_ju_vi_sá".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "H:mm",
    LTS: "H:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D [de] MMMM [de] YYYY",
    LLL: "D [de] MMMM [de] YYYY H:mm",
    LLLL: "dddd, D [de] MMMM [de] YYYY H:mm",
  },
  calendar: {
    sameDay: function () {
      return "[hoy a la" + (this.hours() !== 1 ? "s" : "") + "] LT";
    },
    nextDay: function () {
      return "[mañana a la" + (this.hours() !== 1 ? "s" : "") + "] LT";
    },
    nextWeek: function () {
      return "dddd [a la" + (this.hours() !== 1 ? "s" : "") + "] LT";
    },
    lastDay: function () {
      return "[ayer a la" + (this.hours() !== 1 ? "s" : "") + "] LT";
    },
    lastWeek: function () {
      return (
        "[el] dddd [pasado a la" + (this.hours() !== 1 ? "s" : "") + "] LT"
      );
    },
    sameElse: "L",
  },
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "unos segundos",
    ss: "%d segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    w: "una semana",
    ww: "%d semanas",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: "%dº",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
  invalidDate: "Fecha inválida",
};

export { es, moment_es };
