import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import fetch from './components/service/ApiFetch.js'
import GlobalStyle from 'carbon-react/lib/style/global-style'
//
//import loadLanguages from 'prismjs/components/index';

// Prismjs languages
//loadLanguages(['objectpascal','javascript'])

fetch.initializeFromEnv();
window.name = 'frp1000-react-portal';
ReactDOM.render(
  //<React.StrictMode>

    <App >
      <GlobalStyle/>
    </App>,
  //</React.StrictMode>,
  document.getElementById('root')
)
