import React, { useState } from 'react'
import CodeEditor from '../editors/CodeEditor'

// May be used as a Facet (ie value===null)
// or as a Facet's value (ie value!=null) 
//
const FacetCodeEditor = ({ facet, control, value }) => {

  return (
      <CodeEditor facet={facet} control={control} value={value} />
  )
}

export default FacetCodeEditor
