import React, { useState, useEffect } from "react";
import Dialog from "carbon-react/lib/components/dialog";
import Layout from "../../layout/Layout";
import styled from "styled-components";

const defaultLayout = {
  name: "layout",
  css: "dialog",
  children: [
    { name: "topbar", css: "dialog", children: [] },
    {
      name: "body",
      css: "dialog",
      children: [
        {
          name: "main",
          css: "dialog",
          children: [
            { name: "main-header", css: "dialog", children: [] },
            { name: "main-body", css: "dialog", children: [] },
            { name: "main-footer", css: "dialog", children: [] },
          ],
        },
      ],
    },
  ],
};

const getOnCancel = (setOpen) => (e) => {
  e.stopPropagation();
  setOpen(false);
};

const FacetLoadedDialog = ({ loadMethod, open, setOpen, control, ...props }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      control.callMethod(loadMethod, {}, (newData) => {
        setData(newData);
        setIsLoading(false);
      });
    }
  }, [control, loadMethod, open]);
  const title = data?.title;

  return (
    <Dialog open={open} title={title} onCancel={getOnCancel(setOpen)}>
      <Layout
        isLoading={isLoading}
        facets={data?.facets || []}
        control={control}
        layout={data?.layout || defaultLayout}
        {...props}
      />
    </Dialog>
  );
};

export default FacetLoadedDialog;
