import React, { Component, useRef, useState } from "react";
import styled from "styled-components";
import {
  Editor,
  EditorState,
  ContentState,
  RichUtils,
  convertToRaw,
} from "draft-js";
import PrismDraftDecorator from "draft-js-prism";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
require("prismjs/components/prism-pascal.js");

const CodeEditorWrapper = styled.div`
  font-family: Consolas, Monaco, "Andale Mono", "Lucida Console", monospace;
  font-size: 13px;
  line-height: 1em;
`;

const getInitialCode = (value) => {
  let code = "begin \n\r end;";
  if (value && value.editor && value.editor.code) {
    code = value.editor.code;
  }
  return code;
};

const getInitialLanguage = (value) => {
  let language = "objectpascal";
  if (value && value.editor && value.editor.language) {
    language = value.editor.language;
  }
  return language;
};

const getOnEditorStateChange =
  ({ setEditorState, control, facet, value }) =>
  (newEditorState) => {
    setEditorState(newEditorState);
    const text = newEditorState.getCurrentContent().getPlainText();
    control.setFacetValue(facet, value, text);
  };

const CodeEditor = ({ value, control, facet }) => {
  const initialCode = getInitialCode(value);
  const initialLanguage = getInitialLanguage(value);
  const contentState = ContentState.createFromText(initialCode);
  const decorator = new PrismDraftDecorator({
    prism: Prism,
    defaultSyntax: initialLanguage,
    filter: function (block) {
      return true;
    },
  });

  const editorRef = useRef();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState, decorator)
  );

  console.log("test: ", { value, initialCode, initialLanguage, contentState, decorator, editorState });

  return (
    <CodeEditorWrapper>
      <Editor
        editorState={editorState}
        onChange={getOnEditorStateChange({
          setEditorState,
          control,
          facet,
          value,
        })}
        ref={editorRef}
      />
    </CodeEditorWrapper>
  );
};

export default CodeEditor;
