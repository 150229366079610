import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import BusinessProcessStepColumn from "./BusinessProcessStepColumn";
import Slider from "react-slick";
import BusinessProcessLinks from "./BusinessProcessLinks";
import {
  useNavLeftCurrentSizeContext,
  useTabContentContext,
} from "../NavigTab/FacetNavigTabs";
import BusinessProcessTitle from "./BusinessProcessTitle";
import ConfDialogButton from "./ConfDialogButton";
import ChangesValidationDialog from "./ChangesValidationDialog";
import eventBus from "../../../events/eventBus";
import { MENU_PANEL_WIDTH } from "../../../../constants";
import {
  CONF_3_COLUMNS,
  CONF_4_COLUMNS,
  CONF_5_COLUMNS,
} from "./sliderConfiguration";
import Switch from "carbon-react/lib/components/switch";
import I18n from "i18n-js";
import {v4 as uuidv4} from 'uuid';

const cEmptyBusinessProcess = { links: { items: [] }, steps: [], infos: {} };
const cErrorBusinessProcess = { links: { items: [] }, steps: [], infos: {} };

const ControlContext = React.createContext({});
export const useControlContext = () => useContext(ControlContext);

const BusinessProcessContext = React.createContext({});
export const useBusinessProcessContext = () =>
  useContext(BusinessProcessContext);

const SliderConfContext = React.createContext({});
export const useSliderConfContext = () => useContext(SliderConfContext);

const getFixedBoxesFromBoxes = boxes => boxes.map(box => ({ ...box, uuid: box.uuid ? box.uuid : uuidv4() }));

const getFixedBusinessProcessValueFromValue = (businessProcessValue) => {
  return {
    ...businessProcessValue,
    steps: businessProcessValue.steps?.map((step) => ({
      ...step,
      boxes: getFixedBoxesFromBoxes(step.boxes),
    })),
  };

};
const getLocalBusinessProcessFromValueBusinessProcess = (businessProcess) => {
  const businessProcessValue = getBusinessProcessValue(businessProcess);
  const fixedBusinessProcessValue =
    getFixedBusinessProcessValueFromValue(businessProcessValue);
  const fixedBusinessProcess = {
    ...businessProcess,
    value: fixedBusinessProcessValue
      ? JSON.stringify(fixedBusinessProcessValue)
      : businessProcess.value,
  };
  return {
    businessProcess: fixedBusinessProcess,
    businessProcessValue: fixedBusinessProcessValue,
  };
};

const getBusinessProcessValue = (businessProcess) => {
  let rslt = cEmptyBusinessProcess;

  if (businessProcess?.value) {
    //console.log("businessProcess.value", businessProcess.value)

    try {
      rslt = JSON.parse(businessProcess.value);
    } catch (e) {
      console.log("businessProcess.error", e);
      rslt = cErrorBusinessProcess;
    }
  }
  return rslt;
};

const hasClassSlickDisabled = (className) =>
  className.split(" ").find((elem) => elem === "slick-disabled");

const getLinksFromBusinessProcessValue = (businessProcessValue) =>
  Array.isArray(businessProcessValue?.links?.items)
    ? businessProcessValue.links.items
    : Array.isArray(businessProcessValue?.links)
    ? businessProcessValue.links
    : [];

const getInfosFromBusinessProcessValue = (businessProcessValue) =>
  Array.isArray(businessProcessValue?.infos?.lines)
    ? businessProcessValue.infos.lines
    : Array.isArray(businessProcessValue?.infos)
    ? businessProcessValue.infos
    : [];

const StepsToDisplayContainer = styled.div`
  & [data-component="card"] {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  & .frp1000-slider-div {
    width: ${({ sliderConf }) => sliderConf.sliderWidth};
    /* height: calc(100vh - 200px); */
  }

  & .frp1000-slider-arrow {
    color: transparent;
    outline: none;
    background: transparent;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
  }

  & .frp1000-slider-next-arrow {
    right: -25px;
  }

  & .frp1000-slider-prev-arrow {
    left: -25px;
  }

  & .frp1000-slider-next-arrow:before {
    content: "→";
  }

  & .frp1000-slider-prev-arrow:before {
    content: "←";
  }

  & .frp1000-slider-arrow:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #0073c1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .frp1000-slider-arrow.slick-disabled:before {
    opacity: 0.25;
  }

  & .slick-dots li.slick-active button:before {
    color: #0073c1;
  }

  & .slick-dots li button:before {
    color: #0073c1;
  }

  & .slick-track {
    margin-left: unset;
  }
`;

const BusinessProcessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TitleAndConfButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const EditModeSwitchAndConfDialogButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const FacetContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: calc(100vh - 104px);
  min-width: calc(
    100vw - 48px - ${({ navLeftCurrentWidth }) => navLeftCurrentWidth ?? "0"}
  );
`;

const SampleNextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={`${
        hasClassSlickDisabled(className) ? "slick-disabled " : ""
      }frp1000-slider-arrow frp1000-slider-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={`${
        hasClassSlickDisabled(className) ? "slick-disabled " : ""
      }frp1000-slider-arrow frp1000-slider-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const getWidthNumberFromWidth = (width) => Number(width.replace("px", ""));

const getSliderConfWithCurrentWidth = ({
  width,
  leftMenuPanelOpen,
  processDragFlag,
}) => {
  let widthNumber = getWidthNumberFromWidth(width);
  if (!processDragFlag) widthNumber = widthNumber - MENU_PANEL_WIDTH;
  const firstBreakpoint = getWidthNumberFromWidth(CONF_5_COLUMNS.sliderWidth);
  const secondBreakPoint = getWidthNumberFromWidth(CONF_4_COLUMNS.sliderWidth);
  if (widthNumber < secondBreakPoint) return CONF_3_COLUMNS;
  if (widthNumber < firstBreakpoint) return CONF_4_COLUMNS;
  return CONF_5_COLUMNS;
};

const getSlidesToShow = ({ sliderConfSlidesToShow, steps }) => {
  if (steps.length < sliderConfSlidesToShow) return steps.length;
  return sliderConfSlidesToShow;
};

const saveBusinessProcessDatabase = ({
  control,
  businessProcess,
  businessProcessTitle,
  setRollbackBusinessProcess,
  setRollbackBusinessProcessTitle,
  localBusinessProcess,
}) => {
  control.callAction("saveProcessus", {
    businessProcess,
    businessProcessTitle,
  });
  setRollbackBusinessProcess(localBusinessProcess);
  setRollbackBusinessProcessTitle(businessProcessTitle);
};

const saveDuplicateBusinessProcessDatabase = ({
  control,
  businessProcess,
  businessProcessTitle,
  setRollbackBusinessProcess,
  setRollbackBusinessProcessTitle,
  localBusinessProcess,
  tab,
}) => {
  control.callAction("saveProcessus", {
    businessProcess,
    businessProcessTitle,
    duplicate: true,
    tabId: tab.id,
  });
  setRollbackBusinessProcess(localBusinessProcess);
  setRollbackBusinessProcessTitle(businessProcessTitle);
};

const cancelLocalBusinessProcessChanges = ({
  rollbackBusinessProcess,
  rollbackBusinessProcessTitle,
  setLocalBusinessProcessTitle,
  setLocalBusinessProcess,
}) => {
  setLocalBusinessProcess(rollbackBusinessProcess);
  setLocalBusinessProcessTitle(rollbackBusinessProcessTitle);
};

const FacetBusinessProcess = ({ facet, value, control, tab }) => {
  const [confDialogOpen, setConfDialogOpen] = useState(false);
  const [changesValidationDialogOpen, setChangesValidationDialogOpen] =
    useState(false);
  const [leftMenuPanelOpen, setLeftMenuPanelOpen] = useState(false);
  const [processDragFlag, setProcessDragFlag] = useState(false);
  const [localBusinessProcess, setLocalBusinessProcess] = useState(
    getLocalBusinessProcessFromValueBusinessProcess(value.businessProcess)
  );
  const [rollbackBusinessProcess, setRollbackBusinessProcess] = useState(
    getLocalBusinessProcessFromValueBusinessProcess(value.businessProcess)
  );
  const { width } = useTabContentContext();
  const { businessProcess, businessProcessValue } = localBusinessProcess || {};
  const { steps } = businessProcessValue || {};
  const { canEdit, title } = value;
  const [editMode, setEditMode] = useState(false);
  const [localBusinessProcessTitle, setLocalBusinessProcessTitle] =
    useState(title);
  const [rollbackBusinessProcessTitle, setRollbackBusinessProcessTitle] =
    useState(title);
  const links = getLinksFromBusinessProcessValue(businessProcessValue);
  const infos = getInfosFromBusinessProcessValue(businessProcessValue);
  const navLeftCurrentSize = useNavLeftCurrentSizeContext();
  const [sliderConf, setSliderConf] = useState(
    getSliderConfWithCurrentWidth({
      width,
      leftMenuPanelOpen,
      processDragFlag,
    })
  );

  useEffect(() => {
    setLocalBusinessProcessTitle(title);
    setRollbackBusinessProcessTitle(title);
  }, [title]);

  useEffect(() => {
    const memoizedCallbackOpen = () => {
      setLeftMenuPanelOpen(true);
    };
    const memoizedCallbackClose = () => {
      setLeftMenuPanelOpen(false);
    };
    const memoizedCallbackDragStart = () => {
      setProcessDragFlag(true);
    };
    const memoizedCallbackDragEnd = () => {
      setProcessDragFlag(false);
    };
    eventBus.on("left-menu-panel-open", memoizedCallbackOpen);
    eventBus.on("left-menu-panel-close", memoizedCallbackClose);
    eventBus.on("process-drag-start", memoizedCallbackDragStart);
    eventBus.on("process-drag-end", memoizedCallbackDragEnd);
    return () => {
      eventBus.remove("left-menu-panel-open", memoizedCallbackOpen);
      eventBus.remove("left-menu-panel-close", memoizedCallbackClose);
      eventBus.remove("process-drag-start", memoizedCallbackDragStart);
      eventBus.remove("process-drag-end", memoizedCallbackDragEnd);
    };
  }, []);

  useEffect(() => {
    setSliderConf(
      getSliderConfWithCurrentWidth({
        width,
        leftMenuPanelOpen,
        processDragFlag,
      })
    );
  }, [width, leftMenuPanelOpen, processDragFlag, steps]);

  useEffect(() => {
    setLocalBusinessProcess(
      getLocalBusinessProcessFromValueBusinessProcess(value.businessProcess)
    );
    setRollbackBusinessProcess(
      getLocalBusinessProcessFromValueBusinessProcess(value.businessProcess)
    );
  }, [value.businessProcess]);

  useEffect(() => {
    if (editMode) {
      eventBus.emit("process-edit-increment-count", {});
      return () => {
        eventBus.emit("process-edit-decrement-count", {});
      };
    }
  }, [editMode]);

  return Array.isArray(steps) ? (
    <ControlContext.Provider value={control}>
      <BusinessProcessContext.Provider
        value={{
          canEdit: editMode,
          businessProcess,
          businessProcessValue,
          businessProcessTitle: localBusinessProcessTitle,
          setFacetBusinessProcessTitle: setLocalBusinessProcessTitle,
          setFacetLocalBusinessProcess: setLocalBusinessProcess,
        }}
      >
        <SliderConfContext.Provider value={sliderConf}>
          <FacetContentContainer
            navLeftCurrentWidth={navLeftCurrentSize?.width}
          >
            <BusinessProcessContainer>
              <div>
                <TitleAndConfButtonContainer>
                  <BusinessProcessTitle
                    title={localBusinessProcessTitle || ""}
                    infos={infos}
                  />
                  {canEdit && tab ? (
                    <EditModeSwitchAndConfDialogButtonContainer>
                      <Switch
                        labelInline
                        label={I18n.t("businessProcess.editMode")}
                        name="switch-edit-mode"
                        checked={editMode}
                        onChange={(e) =>
                          setEditMode((previousEditMode) => {
                            if (!previousEditMode) return !previousEditMode;
                            setChangesValidationDialogOpen(true);
                            return previousEditMode;
                          })
                        }
                      />

                      {editMode ? (
                        <React.Fragment>
                          <ConfDialogButton
                            setConfDialogOpen={setConfDialogOpen}
                            confDialogOpen={confDialogOpen}
                          />
                          {changesValidationDialogOpen ? (
                            <ChangesValidationDialog
                              changesValidationDialogOpen={
                                changesValidationDialogOpen
                              }
                              setChangesValidationDialogOpen={
                                setChangesValidationDialogOpen
                              }
                              saveChangesCallback={() => {
                                saveBusinessProcessDatabase({
                                  control,
                                  businessProcess,
                                  localBusinessProcess,
                                  businessProcessTitle:
                                    localBusinessProcessTitle,
                                  setRollbackBusinessProcess,
                                  setRollbackBusinessProcessTitle,
                                });
                                setEditMode(false);
                              }}
                              cancelChangesCallback={() => {
                                cancelLocalBusinessProcessChanges({
                                  rollbackBusinessProcess,
                                  rollbackBusinessProcessTitle,
                                  setLocalBusinessProcessTitle,
                                  setLocalBusinessProcess,
                                });
                                setEditMode(false);
                              }}
                              saveDuplicateCallback={() => {
                                saveDuplicateBusinessProcessDatabase({
                                  control,
                                  businessProcess,
                                  localBusinessProcess,
                                  businessProcessTitle:
                                    localBusinessProcessTitle,
                                  setRollbackBusinessProcess,
                                  setRollbackBusinessProcessTitle,
                                  tab,
                                });
                                setEditMode(false);
                              }}
                            />
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </EditModeSwitchAndConfDialogButtonContainer>
                  ) : null}
                </TitleAndConfButtonContainer>
                <StepsToDisplayContainer sliderConf={sliderConf}>
                  <Slider
                    className="frp1000-slider-div"
                    dots={true}
                    slidesToShow={getSlidesToShow({
                      sliderConfSlidesToShow: sliderConf.slidesToShow,
                      steps,
                    })}
                    slidesToScroll={1}
                    speed={500}
                    infinite={false}
                    swipe={false}
                    nextArrow={<SampleNextArrow />}
                    prevArrow={<SamplePrevArrow />}
                  >
                    {steps.map((stepToDisplay, stepIndex) => {
                      return (
                        <BusinessProcessStepColumn
                          key={stepIndex}
                          step={stepToDisplay}
                          stepIndex={stepIndex}
                          isLastStep={stepIndex === steps.length - 1}
                        />
                      );
                    })}
                  </Slider>
                </StepsToDisplayContainer>
              </div>
              <BusinessProcessLinks
                canEdit={editMode}
                facet={facet}
                control={control}
                businessProcess={businessProcess}
                links={links}
              />
            </BusinessProcessContainer>
          </FacetContentContainer>
        </SliderConfContext.Provider>
      </BusinessProcessContext.Provider>
    </ControlContext.Provider>
  ) : null;
};

export default FacetBusinessProcess;
