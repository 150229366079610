import aegeanTheme from "carbon-react/lib/style/themes/sage";
import "./SageFrp1000.css";

const colorWhite = "#FFFFFF";
const colorGreen = "#00B000"; //        = 11;      // green
const colorOrange = "#000000"; //        = 12;      // orange
const colorRed = "#B00000"; //        = 13;      // red
const colorBlue = "#0000B0"; //        = 14;      // blue
const colorAzul = "#000000"; //        = 15;      // Azul
const colorSlate = "#000000"; //        = 16;      // Slate
const colorFuchsia = "#000000"; //        = 17;      // Fuchsia
const colorGray = "#000000"; //        = 18;      // Gray
const colorBlack = "#000000"; //        = 19;      // Black

const MSGSTATUS_SUCCESS = 0; // none
const MSGSTATUS_FAILED = 1; // white/red
const MSGSTATUS_ERROR = 2; // red
const MSGSTATUS_REQUIRE_ACTION = 3; // blue
const MSGSTATUS_INFORMATION = 4; // orange
const MSGSTATUS_HEADER = 5; // bold
const MSGSTATUS_ALERT = 6; // maroon
//
const MSGSTATUS_LEVEL_0 = 10; // none
const MSGSTATUS_LEVEL_1 = 11; // green
const MSGSTATUS_LEVEL_2 = 12; // orange
const MSGSTATUS_LEVEL_3 = 13; // red
const MSGSTATUS_LEVEL_4 = 14; // blue
const MSGSTATUS_LEVEL_5 = 15; // Azul
const MSGSTATUS_LEVEL_6 = 16; // Slate
const MSGSTATUS_LEVEL_7 = 17; // Fuchsia
const MSGSTATUS_LEVEL_8 = 18; // Gray
const MSGSTATUS_LEVEL_9 = 19; // Black

const MSGSTATUS_LEVEL_100 = 100; // none
const MSGSTATUS_LEVEL_101 = 101; // green
const MSGSTATUS_LEVEL_102 = 102; // orange
const MSGSTATUS_LEVEL_103 = 103; // red
const MSGSTATUS_LEVEL_104 = 104; // blue
const MSGSTATUS_LEVEL_105 = 105; //
const MSGSTATUS_LEVEL_106 = 106; //
const MSGSTATUS_LEVEL_107 = 107; //
const MSGSTATUS_LEVEL_108 = 108; //
const MSGSTATUS_LEVEL_109 = 109; //

const sageFrp1000Theme = (locale) => {
  let theme = aegeanTheme;

  theme.locale = locale;

  theme.text = {
    color: "rgba(0,0,0,0.9)",
    placeholder: "rgba(0,0,0,0.55)",
    size: "14px",
    smallSize: "13px",
    weight: "400",
    weightTitle: "500",
    weightAction: "500",
  };

  theme.text.secondaryColor = theme.colors.secondary;

  //old theme first, new one after
  theme.colors = {
    ...{
      base: "#0077C8",
      primary: "#0073C2",
      secondary: "#005C9A",
      tertiary: "#004472",
      brand: "#00DC00",
      disabled: "#66ADDE",
      whiteMix: "#E6F1FA",
      withOpacity: "rgba(0,119,200,0.55)",
      loadingBarBackground: "#B3D6EF",
      black: "#000000",
      slate: "#003349",
      white: "#FFFFFF",
      border: "#668592",
      dashedBorder: "#99ADB6",
      dashedButtonText: "rgba(0, 0, 0, 0.9)",
      dashedHoverBackground: "#CCD6DB",
      focusedIcon: "#335C6D",
      focusedLinkBackground: "#FFDA80",
      previewBackground: "#BFCCD2",
      hoveredTabKeyline: "#4DA0D9",
      error: "#C7384F",
      focus: "#FFB500",
      info: "#0073C2",
      success: "#00B000",
      warning: "#E96400",
      destructive: {
        hover: "#9F2D3F",
      },
      asterisk: "#C7384F",
      divider: "rgba(0, 20, 29, 0.2)",
      messages: {
        normal: "rgba(0,0,0,0.9)",
        alert: "#E96400",
        error: "#C7384F",
        attention: "#C7384F",
        success: "#00B000",
        info: "#0073C2",
        background: "#FFFFFF",
      },
      badges: {
        error: "#d64113",
        warning: "#FFB500",
      },
      navigation: {
        primary: "#0073C2",
        secondary: "#005C9A",
        tertiary: "#E6EBED",
        white: theme.colors.white,
        black: theme.colors.black
      },
    },
    ...theme.colors,
  };

  theme.colors.messages = {
    normal: theme.text.color,
    alert: theme.colors.warning,
    error: theme.colors.error,
    attention: theme.colors.asterisk,
    success: theme.colors.success,
    info: theme.colors.info,
    background: theme.colors.white,
  };

  theme.colors.badges = {
    error: "#d64113",
    warning: "#FFB500",
  };

  theme.colors.messageColor = function (level) {
    if (!level) return theme.text.color;
    switch (level) {
      case MSGSTATUS_SUCCESS:
        return theme.colors.messages.success; //        = 0;       // none
      case MSGSTATUS_ERROR:
        return theme.colors.messages.error; //          = 2;       // red
      case MSGSTATUS_INFORMATION:
        return theme.colors.messages.info; //    = 4;       // orange
      case MSGSTATUS_HEADER:
        return theme.colors.messages.normal; //         = 5;       // bold
      case MSGSTATUS_ALERT:
        return theme.colors.messages.alert; //          = 6;       // maroon
      //
      case MSGSTATUS_REQUIRE_ACTION: // = 3;       // blue
      case MSGSTATUS_FAILED:
        return colorWhite; //         = 1;       // white/red
      //
      case MSGSTATUS_LEVEL_0:
        return theme.colors.messages.normal; //        = 10;      // none
      case MSGSTATUS_LEVEL_1:
        return colorGreen; //        = 11;      // green
      case MSGSTATUS_LEVEL_2:
        return colorOrange; //        = 12;      // orange
      case MSGSTATUS_LEVEL_3:
        return colorRed; //        = 13;      // red
      case MSGSTATUS_LEVEL_4:
        return colorBlue; //        = 14;      // blue
      case MSGSTATUS_LEVEL_5:
        return colorAzul; //        = 15;      // Azul
      case MSGSTATUS_LEVEL_6:
        return colorSlate; //        = 16;      // Slate
      case MSGSTATUS_LEVEL_7:
        return colorFuchsia; //        = 17;      // Fuchsia
      case MSGSTATUS_LEVEL_8:
        return colorGray; //        = 18;      // Gray
      case MSGSTATUS_LEVEL_9:
        return colorBlack; //        = 19;      // Black

      case MSGSTATUS_LEVEL_100: //      = 100;     // none
      case MSGSTATUS_LEVEL_101: //      = 101;     // green
      case MSGSTATUS_LEVEL_102: //      = 102;     // orange
      case MSGSTATUS_LEVEL_103: //      = 103;     // red
      case MSGSTATUS_LEVEL_104: //      = 104;     // blue
      case MSGSTATUS_LEVEL_105: //      = 105;     //
      case MSGSTATUS_LEVEL_106: //      = 106;     //
      case MSGSTATUS_LEVEL_107: //      = 107;     //
      case MSGSTATUS_LEVEL_108: //      = 108;     //
      case MSGSTATUS_LEVEL_109: //      = 109;     //
        return colorWhite;
      //
      default:
        return theme.text.color;
    }
  };

  theme.colors.messageBackgroundColor = function (level) {
    if (!level) return theme.colors.messageBackgroundColor;
    switch (level) {
      case MSGSTATUS_SUCCESS: //        = 0;       // none     = 1;       // white/red
      case MSGSTATUS_ERROR: //          = 2;       // red
      case MSGSTATUS_INFORMATION: //    = 4;       // orange
      case MSGSTATUS_HEADER: //         = 5;       // bold
      case MSGSTATUS_ALERT: //          = 6;       // maroon
        return theme.colors.messages.background;

      case MSGSTATUS_REQUIRE_ACTION:
        return theme.colors.messages.attention; // = 3;       // blue
      case MSGSTATUS_FAILED:
        return theme.colors.messages.error; //
      //

      case MSGSTATUS_LEVEL_0: //        = 10;      // none
      case MSGSTATUS_LEVEL_1: //        = 11;      // green
      case MSGSTATUS_LEVEL_2: //        = 12;      // orange
      case MSGSTATUS_LEVEL_3: //        = 13;      // red
      case MSGSTATUS_LEVEL_4: //        = 14;      // blue
      case MSGSTATUS_LEVEL_5: //        = 15;      // Azul
      case MSGSTATUS_LEVEL_6: //        = 16;      // Slate
      case MSGSTATUS_LEVEL_7: //        = 17;      // Fuchsia
      case MSGSTATUS_LEVEL_8: //        = 18;      // Gray
      case MSGSTATUS_LEVEL_9: //        = 19;      // Black
        return theme.colors.messages.background;

      case MSGSTATUS_LEVEL_100:
        return theme.colors.messages.normal; //        = 10;      // none
      case MSGSTATUS_LEVEL_101:
        return colorGreen; //        = 11;      // green
      case MSGSTATUS_LEVEL_102:
        return colorOrange; //        = 12;      // orange
      case MSGSTATUS_LEVEL_103:
        return colorRed; //        = 13;      // red
      case MSGSTATUS_LEVEL_104:
        return colorBlue; //        = 14;      // blue
      case MSGSTATUS_LEVEL_105:
        return colorAzul; //        = 15;      // Azul
      case MSGSTATUS_LEVEL_106:
        return colorSlate; //        = 16;      // Slate
      case MSGSTATUS_LEVEL_107:
        return colorFuchsia; //        = 17;      // Fuchsia
      case MSGSTATUS_LEVEL_108:
        return colorGray; //        = 18;      // Gray
      case MSGSTATUS_LEVEL_109:
        return colorBlack; //        = 19;      // Black

      default:
        return theme.colors.messages.background;
    }
  };

  theme.colorPalette = [
    "backgroundBusinessProcessBox1",
    "backgroundBusinessProcessBox2",
    "backgroundBusinessProcessBox3",
    "backgroundBusinessProcessBox4",
    "backgroundBusinessProcessBox5"
  ];

  theme.border = {
    size: "1px",
    sizeInt: 1,
    color: theme.colors.secondary,
    background: theme.colors.white,
    border: "none",
    radius: "0px",
    shadow:
      "rgb(0 20 29 / 20%) 0px 3px 3px 0px, rgb(0 20 29 / 15%) 0px 2px 4px 0px",
    //border: "1px solid "+theme.colors.secondary,
    //radius: "4px",
    //shadow: "0 0 1px 0 rgba(0,0,0,.1)",
    focused: {
      size: "3px",
      sizeInt: 3,
      color: theme.colors.focus,
      border: "none", //"3px solid "+theme.colors.secondary,
      radius: "0px", // "3px",
      shadow:
        theme.colors.focus +
        " 0px 3px 3px 0px, " +
        theme.colors.focus +
        " 0px 2px 4px 0px",
      outline: "3px solid " + theme.colors.focus,
    },
    hover: {
      size: "3px",
      sizeInt: 3,
      color: theme.colors.secondary,
      border: "none", //"3px solid "+theme.colors.secondary,
      radius: "0px", // "3px",
      shadow:
        theme.colors.secondary +
        " 0px 3px 3px 0px, " +
        theme.colors.secondary +
        " 0px 2px 4px 0px",
      outline: "3px solid " + theme.colors.secondary,
    },
  };

  theme.pod = {
    border: "#CCD6DB",
    secondaryBackground: "#F2F5F6",
    tertiaryBackground: "#E6EBED",
    softDeleteText: "rgba(0,0,0,0.3)",
    tileBackground: "#FFFFFF",
    footerBackground: "#F2F5F6",
    hoverBackground: "#D9E0E4",
  };

  theme.headings = {
    //0
    XL: {
      "--fontSize": "32px",
      "--height": "40px",
      "--fontWeight": "900",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "1em 0em 1em 0em",
    },
    XL0: {
      "--fontSize": "32px",
      "--height": "40px",
      "--fontWeight": "900",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    //1
    L: {
      "--fontSize": "24px",
      "--height": "31px",
      "--fontWeight": "900",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "1em 0em 1em 0em",
    },
    L0: {
      "--fontSize": "24px",
      "--height": "31px",
      "--fontWeight": "900",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    //2
    M: {
      "--fontSize": "22px",
      "--height": "29px",
      "--fontWeight": "700",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "1em 0em 1em 0em",
    },
    //2
    M0: {
      "--fontSize": "22px",
      "--height": "29px",
      "--fontWeight": "700",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    //3
    S: {
      "--fontSize": "20px",
      "--height": "26px",
      "--fontWeight": "400",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "1em 0em 1em 0em",
    },
    //4
    XS: {
      "--fontSize": "18px",
      "--height": "23px",
      "--fontWeight": "400",
      "--color": theme.text.color,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    //5
    XXS: {
      "--fontSize": "16px",
      "--height": "21px",
      "--fontWeight": "400",
      "--color": theme.text.color,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    //5
    VXS: {
      "--fontSize": "14px",
      "--height": "21px",
      "--fontWeight": "400",
      "--color": theme.text.color,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    TT: {
      "--fontSize": "14px",
      "--height": "21px",
      "--fontWeight": "400",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "0",
    },
    def: {
      "--fontSize": "22px",
      "--height": "29px",
      "--fontWeight": "700",
      "--color": theme.colors.primary,
      "--darkColor": theme.colors.white,
      "--margin": "1em 0em 1em 0em",
    },
  };

  theme.draggableItem = {
    border: "#E6EBED",
  };

  theme.treeview = {
    tree: {
      base: {
        listStyle: "none",
        backgroundColor: theme.colors.white,
        margin: 0,
        padding: 0,
        color: theme.text.color,
        fontFamily: "inherit",
        fontSize: "14px",
      },
      node: {
        base: {
          position: "relative",
        },
        link: {
          cursor: "pointer",
          position: "relative",
          padding: "0px 5px",
          display: "block",
        },
        activeLink: {
          background: theme.colors.secondary,
        },
        toggle: {
          base: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            marginLeft: "-5px",
            height: "24px",
            width: "24px",
          },
          wrapper: {
            position: "absolute",
            top: "50%",
            left: "50%",
            margin: "-7px 0 0 -7px",
            height: "14px",
          },
          height: 14,
          width: 14,
          arrow: {
            fill: theme.colors.secondary,
            strokeWidth: 0,
          },
        },
        header: {
          base: {
            display: "inline-block",
            verticalAlign: "top",
            color: theme.text.color,
          },
          connector: {
            width: "2px",
            height: "12px",
            borderLeft: "solid 2px black",
            borderBottom: "solid 2px black",
            position: "absolute",
            top: "0px",
            left: "-21px",
          },
          title: {
            lineHeight: "24px",
            verticalAlign: "middle",
          },
        },
        subtree: {
          listStyle: "none",
          paddingLeft: "19px",
        },
        loading: {
          color: theme.text.color,
        },
      },
    },
  };

  theme.colors.navigation = {
    primary: theme.colors.primary,
    secondary: theme.colors.secondary,
    tertiary: "#DEE8EE",
    white: theme.colors.white,
  };

  theme.icon = {
    ...theme.icon,
    color: { default: "#4D7080", favourite: "#FFB500" },
  };

  theme.icon.bigIcon = {
    fontSize: "large",
    bgSize: "extra-large",
    bg: "#668592",
    color: theme.colors.white,
  };

  theme.viewPort = {
    topMargin: 104, // Top bar and Top height
    leftMargin: 64, // left navig bar

    clientHeight: "calc(100vh - 40px - 48px)",
    clientWidth: "calc(100vw - 64px)",

    dimension: function (xx) {
      if (typeof xx === "string") {
        if (xx === "clientHeight") xx = this.clientHeight;
        if (xx === "clientWidth") xx = this.clientWidth;
      }
      if (typeof ww === "number") {
        xx = xx + "px";
      }
      return xx;
    },
  };

  theme.messageBox = {
    width: "456px",
  };

  theme.customMenu = {
    hoverBackground: "var(--colorsComponentsMenuWinterChild600)",
    hoverBackgroundLight: "var(--colorsActionMajor600)",
    hoverColor: theme.colors.white,
    background:theme.colors.black,
    backgroundLight: "#E6EBED",
    color: theme.colors.white,
    colorLight: theme.colors.black,
    borderColor: "#262626",
    selectedBackground: "#4D4D4D",
    selectedBackgroundLight: "#D9E0E4"
  };

  theme.navigation = {
    topBarSelect: {
      companySelectBackgroundColor: theme.colors.navigation.secondary,
      backgroundColor: theme.customMenu.selectedBackground
    },
    actions: {
      backgroundColor: "#E6EBED",
      textColor: theme.colors.black,
      hoveredTextColor: theme.colors.white
    },
    topBar: {
      marginRight: "12px",
      backgroundColor: theme.colors.black,
      highLightColor: theme.colors.navigation.tertiary,
      height: "40px",
    },
    iconBar: {
      minItems: 13,
      iconSize: 48,
      expandedWidth: 240,
      //
      color: theme.colors.navigation.black,
      hoverColor: theme.colors.white,
      backgroundColor: theme.colors.navigation.white,
      backgroundOver: theme.colors.navigation.secondary,
    },
    menu: {
      height: "48px",
    },
    panel: {
      width: 320,
      height: "calc(100vh - 40px)", // 40px is the size of the topbar
      backgroundColor: "#E6EBED",
      title: {
        height: "48px",
        padding: "24px",
        paddingTop: "12px",
        paddingBottom: "12px",
        color: theme.colors.navigation.white,
        backgroundColor: theme.colors.black,
        fontSize: "24px",
        fontWeight: "900",
      },
      content: {
        height: "calc(100vh - 40px - 48px)", // 48px is the size of the accordion title
      },
    },
    tabs: {
      height: "48px",
      tab: {
        height: 13 * 48,
      },
      title: {
        height: 48,
      },
    },
  };

  theme.itemResult = {
    marginBottom: "2px",
    current: {
      backgroundColor:"rgb(242, 245, 246);",
    },
    hover: {
      backgroundColor:"rgb(242, 245, 246);",
    }
  };

  theme.card = {
    minHeight: "168px",
    maxHeight: "168px",
    padding: "24px 0 24px 0",
    marginBottom: "1px",
    icon: {
      marginLeft: "24px",
      marginRight: "24px",
      colors: {
        success: theme.colors.white,
        error: theme.colors.white,
        info: theme.colors.white,
        warning: theme.colors.white,
        //
        adsNone: theme.colors.white,
        adsMustCare: theme.colors.white,
        adsAlmostLate: theme.colors.white,
        adsJustLate: theme.colors.white,
        adsOverdue: theme.colors.white,
      },
      backgroundColors: {
        success: theme.colors.success,
        error: theme.colors.error,
        info: theme.colors.info,
        warning: theme.colors.warning,
        //
        adsNone: colorGreen,
        adsMustCare: colorBlue,
        adsAlmostLate: colorOrange,
        adsJustLate: colorOrange,
        adsOverdue: colorRed,
      },
    },
    title: {
      font: "400 14px/21px",
      color: "#000000E5",
      marginBottom: "4px",
    },
    subTitle: {
      font: "400 12px/18px",
      color: "#0000008C",
      marginBottom: "4px",
    },
    content: {
      lines: 2,
      marginBottom: "4px",
    },
  };

  theme.grid = {
    item: {
      header: {
        size: "18px",
        color: theme.text.color,
        weight: theme.text.bold,
      },
      content: {
        size: theme.text.size,
        color: theme.text.color,
        weight: theme.text.weight,
      },
      footer: {
        size: theme.text.size,
        color: theme.text.secondaryColor,
        weight: theme.text.weight,
      },
      action: {
        size: theme.text.smallSize,
        color: theme.text.secondaryColor,
        weight: theme.text.weight,
      },
    },
  };

  theme.search = {
    ...theme.search,
    border: "rgb(115, 143, 155)",
  };

  theme.sidebar = {
    header: {
      backgroundColor: "#E6EBED",
    },
  };

  theme.menu = {
    ...theme.menu,
    custom: { color: "white", hover: "rgb(242, 245, 246);" },
  };

  theme.vmenu = {
    color: theme.text.color,
    hoverColor: theme.colors.navigation.white,
    focusColor: theme.colors.focus,
    hoverBackgroundColor: theme.colors.navigation.secondary,
    backgroundColor: "#E6EBED",

    fontSize: theme.text.size,
    fontWeight: theme.text.weight,

    item: {
      defaultWidth: theme.navigation.panel.width,
      paddingLeft: 16,
      paddingRight: 24,
      iconWidth: 16,
      levelInden: 10,
      draggableWidth: 32,
    },

    submenu: {
      margin: 10,
      backgroundColors: [
        "#E6EBED",
        "#E6EBED",
      ],
    },
    levels: [
      {
        margin: 0,
        height: "48px",
        fontSize: "16px",
      },
      {
        margin: 10,
        height: "40px",
        fontSize: "14px",
      },
    ],
  };

  theme.colors.translate = function (color) {
    if (typeof color === "string") {
      if (color === "backgroundLayout") return theme.colors.black;
      if (color === "textLayout") return theme.colors.white;
      if (color === "backgroundDialog") return "rgb(242, 245, 246)";
      if (color === "backgroundBusinessProcessBox1") {
        return "white";
      }
      if (color === "backgroundBusinessProcessBox2")
        return "rgba(0, 115, 194, 0.2)";
      if (color === "backgroundBusinessProcessBox3")
        return "rgba(205, 56, 75, 0.2)";
      if (color === "backgroundBusinessProcessBox4")
        return "rgba(0, 103, 56, 0.2)";
      if (color === "backgroundBusinessProcessBox5")
        return "rgba(239, 103, 0, 0.2)";
    }
    return undefined;
  };

  theme.translate = function (style) {
    let rslt = style;
    if (rslt.color !== "undefined") {
      const translatedColor = theme.colors.translate(rslt.color);
      if (translatedColor) rslt.color = translatedColor;
    }
    if (rslt.backgroundColor !== "undefined") {
      const translatedBackgroundColor = theme.colors.translate(
        rslt.backgroundColor
      );
      if (translatedBackgroundColor)
        rslt.backgroundColor = translatedBackgroundColor;
    }
    return rslt;
  };

  //console.log("THEME", theme);
  return theme;
};

export { sageFrp1000Theme };
