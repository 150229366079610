import ToastMsg from "./ToastMsg";
import { toast } from "react-toastify";

export const toastMsg = ({ text, time, data, type }) => {
  const toastId = {};
  toastId.current = toast(<ToastMsg text={text} toastId={toastId} time={time} msgData={data} type={type} />, {
    position: "top-center",
    autoClose: 5000,
    closeOnClick: false,
    hideProgressBar: true,
    closeButton: true,
    type: type,
    theme: "colored",
    icon: false,
    draggable: false,
  });
};

export const toastInfo = ({ text, time, data }) => {
  toastMsg({ text, time, data, type: "info" });
};

export const toastWarning = ({ text, time, data }) => {
  toastMsg({ text, time, data, type: "warning" });
};

export const toastError = ({ text, time, data }) => {
  toastMsg({ text, time, data, type: "error" });
};

export const toastSuccess = ({ text, time, data }) => {
  toastMsg({ text, time, data, type: "success" });
};
