import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import I18n from 'i18n-js';
import SortableTree from '@nosferatu500/react-sortable-tree';
import MinimalTheme from 'react-sortable-tree-theme-minimal';
import ButtonToggle from 'carbon-react/lib/components/button-toggle';
import ButtonToggleGroup from 'carbon-react/lib/components/button-toggle-group';
import Search from 'carbon-react/lib/components/search';
import { Select, Option } from 'carbon-react/lib/components/select';
import { SELECTION_ACTION } from './MenuEnums';

const Selection = styled.div`
  @media (max-width: 1600px) {
    min-width: 23rem;
  }

  /* fix button-toggle icon only */
  div[data-component='button-toggle'] > label > div > div {
    margin-right: 0;
  }
`;

const Tree = styled.div`
  height: 66vh;
`;

const DefaultComponent = styled.div`
  margin: 0.25rem 0;
`;

const ResponsiveWideComponent = styled(DefaultComponent)`
  @media (max-width: 1600px) {
    display: none;
  }
`;

const ResponsiveNarrowComponent = styled(DefaultComponent)`
  @media (min-width: 1601px) {
    display: none;
  }
`;

const SortGroupComponent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px 0;

  // fix margin on second inline toggle-group
  & > div {
    margin-top: 0 !important;
  }

  @media (max-width: 1600px) {
    flex-flow: column wrap;
    align-items: stretch;
  }
`;

const SortableTreeCSS = styled(SortableTree)`
  /* css for minimal theme */
  .rstcustom__rowSubtitle {
    display: block;
    line-height: normal;
  }

  .rstcustom__nodeContent {
    width: 90%;
  }
`;

/**
 * Menu entry selection with filters and sorts
 *
 * @returns The menu entries selection UI
 */
const MenuEntrySelection = (props) => {
  const { data, filters, sort } = props.data;
  const [tree, setTree] = useState(data);
  const [searchString, setSearchString] = useState('');
  const [filterSelect, setFilterSelect] = useState('5');
  const [sortSelect, setSortSelect] = useState('0');

  useEffect(() => {
    setTree(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  /**
   * Check if the node is draggable
   * @param {*} nodeInfo
   * @returns true if the node is a tree's leaf
   */
  const canDragNodeSelectionHandler = (nodeInfo) => {
    return !nodeInfo.node.hasOwnProperty('children');
  };

  const onChangeToogleHandler = (event, action) => {
    props.control.doSelectionAction(event.target.value, action);
  };

  const filterButtonToggleList = filters.map((filter) => (
    <ButtonToggle
      key={filter.name}
      value={filter.name}
      grouped
      defaultChecked={filter.default}
    >
      {filter.caption}
    </ButtonToggle>
  ));

  const filterSelectList = filters.map((filter) => (
    <Option key={filter.name} text={filter.caption} value={filter.name} />
  ));

  const sortButtonToggleList = sort.map((sort) => (
    <ButtonToggle
      key={sort.name}
      value={sort.name}
      grouped
      defaultChecked={sort.default}
    >
      {sort.caption}
    </ButtonToggle>
  ));

  const sortSelectList = sort.map((sort) => (
    <Option key={sort.name} text={sort.caption} value={sort.name} />
  ));

  return (
    <Selection>
      {/* Object filter */}
      <ResponsiveWideComponent>
        <ButtonToggleGroup
          id='filter-button-toggle-group'
          name='filter-button-toggle-group'
          label={I18n.t('menuEditor.filter', { defaultValue: 'Object type' })}
          labelWidth={20}
          labelAlign='left'
          onChange={(event) =>
            onChangeToogleHandler(event, SELECTION_ACTION.FILTER)
          }
        >
          {filterButtonToggleList}
        </ButtonToggleGroup>
      </ResponsiveWideComponent>
      <ResponsiveNarrowComponent>
        <Select
          id='filter-select'
          name='filter-select'
          label={I18n.t('menuEditor.filter', { defaultValue: 'Object type' })}
          labelWidth={20}
          labelAlign='left'
          onChange={(event) => {
            setFilterSelect(event.target.value);
            onChangeToogleHandler(event, SELECTION_ACTION.FILTER);
          }}
          value={filterSelect}
        >
          {filterSelectList}
        </Select>
      </ResponsiveNarrowComponent>
      <Search
        placeholder={I18n.t('menuEditor.search.placeholder', {
          defaultValue: 'Search...',
        })}
        defaultValue=''
        aria-label='search'
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          props.control.doSelectionAction(
            event.target.value,
            SELECTION_ACTION.SEARCH
          );
        }}
      />
      <SortGroupComponent>
        <ResponsiveWideComponent>
          <ButtonToggleGroup
            id='button-toggle-sort'
            name='button-toggle-sort'
            label={I18n.t('menuEditor.sort.label', { defaultValue: 'Sort' })}
            labelWidth={20}
            labelAlign='left'
            onChange={(event) =>
              onChangeToogleHandler(event, SELECTION_ACTION.SORT)
            }
          >
            {sortButtonToggleList}
          </ButtonToggleGroup>
        </ResponsiveWideComponent>
        <ResponsiveNarrowComponent>
          <Select
            id='sort-select'
            name='sort-select'
            label={I18n.t('menuEditor.sort.label', { defaultValue: 'Sort' })}
            labelWidth={20}
            labelAlign='left'
            inputWidth={120}
            onChange={(event) => {
              setSortSelect(event.target.value);
              onChangeToogleHandler(event, SELECTION_ACTION.SORT);
            }}
            value={sortSelect}
          >
            {sortSelectList}
          </Select>
        </ResponsiveNarrowComponent>
        <ButtonToggleGroup
          id='button-toggle-order'
          name='button-toggle-order'
          label={I18n.t('menuEditor.sort.order', { defaultValue: 'Order by' })}
          onChange={(event) =>
            onChangeToogleHandler(event, SELECTION_ACTION.ORDER)
          }
        >
          <ButtonToggle
            key='asc'
            aria-label={I18n.t('menuEditor.sort.orderByAsc', {
              defaultValue: 'Descending order',
            })}
            value=''
            grouped
            buttonIcon='sort_down'
            defaultChecked={true}
          >{''}
          </ButtonToggle>
          <ButtonToggle
            key='desc'
            aria-label={I18n.t('menuEditor.sort.orderByDesc', {
              defaultValue: 'Ascending order',
            })}
            value='-'
            grouped
            buttonIcon='sort_up'
          >{''}
          </ButtonToggle>
        </ButtonToggleGroup>
      </SortGroupComponent>
      <Tree>
        <SortableTreeCSS
          //theme={MinimalTheme}
          dndType={props.dndType}
          treeData={tree}
          onChange={(tree) => setTree(tree)}
          getNodeKey={props.getNodeKey}
          generateNodeProps={({ node, path }) => ({
            subtitle: (
              <React.Fragment>
                <p>{node.name}</p>
                <p>
                  {node.obDate
                    ? new Date(node.obDate).toLocaleDateString(undefined, {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                      }) +
                      ' - ' +
                      node.author
                    : ''}
                </p>
              </React.Fragment>
            ),
            style: {
              margin: '0 0 0 5px',
              padding: '0',
            },
          })}
          shouldCopyOnOutsideDrop={true}
          canDrop={() => false}
          canDrag={canDragNodeSelectionHandler}
        />
      </Tree>
    </Selection>
  );
};

export default MenuEntrySelection;
