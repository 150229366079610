import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Dialog from "carbon-react/lib/components/dialog";
import Button from "carbon-react/lib/components/button";
import IconButton from "carbon-react/lib/components/icon-button";
import Icon from "carbon-react/lib/components/icon";
import Pill from "carbon-react/lib/components/pill";
import Textbox from "carbon-react/lib/components/textbox";
import Textarea from "carbon-react/lib/components/textarea";
import { useBusinessProcessContext } from "./FacetBusinessProcess";
import { saveBusinessProcessLocally } from "./BusinessProcessLinks";
import DraggableContainer from "../../components/dragndrop/DraggableContainer";
import DraggableItem from "../../components/dragndrop/DraggableItem";
import AddStepButton from "./AddStepButton";
import { cloneDeep } from "lodash";
import useTheme from "../../hooks/useTheme";
import Typography from "carbon-react/lib/components/typography";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";
import I18n from "i18n-js";

const updateLocalStepsWithNewOrder = ({ setLocalSteps, newOrder }) => {
  setLocalSteps((prevLocalSteps) => {
    const newLocalSteps = newOrder.reduce(
      (acc, currentValue) => [...acc, prevLocalSteps[currentValue]],
      []
    );
    return newLocalSteps;
  });
};

const getStringInfoFromBusinessProcessValue = (businessProcessValue) => {
  if (!Array.isArray(businessProcessValue?.infos?.lines)) return "";
  return businessProcessValue.infos.lines.join("\n");
};

const getNewInfosFromString = (infosString) => {
  let newInfos = {};
  if (!infosString || infosString === "") return {};
  return { lines: infosString.split("\n") };
};

const buildNewBusinessProcess = ({
  localSteps,
  businessProcess,
  businessProcessValue,
  localInformation,
}) => {
  const newBusinessProcessValue = {
    ...businessProcessValue,
    steps: localSteps,
    infos: getNewInfosFromString(localInformation),
  };
  const newBusinessProcess = {
    ...businessProcess,
    value: JSON.stringify(newBusinessProcessValue),
  };
  return newBusinessProcess;
};

const saveProcessusConf = ({
  businessProcessValue,
  businessProcess,
  businessProcessTitle,
  setFacetBusinessProcessTitle,
  setFacetLocalBusinessProcess,
  localSteps,
  localTitle,
  localInformation,
}) => {
  const newBusinessProcess = buildNewBusinessProcess({
    localSteps,
    localInformation,
    businessProcess,
    businessProcessValue,
  });

  saveBusinessProcessLocally({
    businessProcess: newBusinessProcess,
    businessProcessTitle,
    setFacetBusinessProcessTitle,
    setFacetLocalBusinessProcess,
  });
};

const StepsLinesContainer = styled.div`
  margin-top: 24px;
  & [data-element="draggable"] {
    padding: 0;
    margin: 0;
    border: none;
    cursor: initial;
  }
  & [data-element="draggable"] > span[data-element="drag"]:not(:first-child) {
    display: none;
  }
`;

const StepLineContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 38px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DragIconContainer = styled.div`
  cursor: grab;
`;

const TitleTextBoxContainer = styled.div`
  flex-grow: 1;
  margin-left: 11px;
  margin-right: 11px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 19px;
  margin-bottom: 24px;
`;

const SetupBusinessProcessButtonContainer = styled.div`
  & button {
    padding: 0;
  }
`;

const StepLine = ({ step, stepIndex, setLocalSteps }) => {
  const theme = useTheme();
  return (
    <StepLineContainer>
      <DragIconContainer>
        <Icon type="drag" />
      </DragIconContainer>
      <Pill
        size="S"
        ml="11px"
        pillRole="status"
        color="white"
        borderColor="rgba(77, 112, 128, 1)"
      >
        {stepIndex + 1}
      </Pill>
      <TitleTextBoxContainer>
        <Textbox
          value={step.title}
          onChange={(e) => {
            const newStepTitle = e?.target?.value;
            setLocalSteps((prevLocalSteps) => {
              const newLocalSteps = cloneDeep(prevLocalSteps);
              newLocalSteps[stepIndex].title = newStepTitle;
              return newLocalSteps;
            });
          }}
        />
      </TitleTextBoxContainer>
      <IconButton
        onAction={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setLocalSteps((prevLocalSteps) => [
            ...prevLocalSteps.slice(0, stepIndex),
            ...prevLocalSteps.slice(stepIndex + 1),
          ]);
        }}
      >
        <Icon type="delete" />
      </IconButton>
    </StepLineContainer>
  );
};

const DialogContent = ({ steps, setConfDialogOpen }) => {
  const [localSteps, setLocalSteps] = useState(steps);
  const {
    setFacetLocalBusinessProcess,
    setFacetBusinessProcessTitle,
    businessProcessValue,
    businessProcess,
    businessProcessTitle,
  } = useBusinessProcessContext();
  const [localTitle, setLocalTitle] = useState(businessProcessTitle ?? "");
  const [localInformation, setLocalInformation] = useState(
    getStringInfoFromBusinessProcessValue(businessProcessValue)
  );

  useEffect(() => {
    setLocalSteps(steps);
  }, [steps]);

  useEffect(() => {
    setLocalTitle(businessProcessTitle ?? "");
  }, [businessProcessTitle]);

  useEffect(() => {
    setLocalInformation(
      getStringInfoFromBusinessProcessValue(businessProcessValue)
    );
  }, [businessProcessValue]);

  return (
    <React.Fragment>
      <Typography variant="segment-subheader" ml="24px" mb="8px">
        {I18n.t("businessProcess.title")}
      </Typography>
      <Textbox
        ml="24px"
        mr="24px"
        mb="8px"
        value={localTitle}
        onChange={(e) => {
          const newTitle = e?.target?.value;
          setLocalTitle(newTitle);
        }}
      />
      <Typography variant="segment-subheader" ml="24px" mb="8px">
        {I18n.t("businessProcess.information")}
      </Typography>
      <Textarea
        ml="24px"
        mr="24px"
        mb="8px"
        characterLimit="256"
        expandable
        value={localInformation}
        onChange={(e) => {
          const newInformation = e?.target?.value;
          setLocalInformation(newInformation);
        }}
      />
      <Typography variant="segment-header" ml="24px">
        {I18n.t("businessProcess.steps")}
      </Typography>
      <StepsLinesContainer>
        <DraggableContainer
          getOrder={(newOrder) => {
            updateLocalStepsWithNewOrder({ setLocalSteps, newOrder });
          }}
        >
          {localSteps.map((step, stepIndex) => (
            <DraggableItem id={stepIndex} key={`draggable-item-${stepIndex}`}>
              <StepLine
                key={stepIndex}
                setLocalSteps={setLocalSteps}
                step={step}
                stepIndex={stepIndex}
              />
            </DraggableItem>
          ))}
        </DraggableContainer>
        <AddStepButton setLocalSteps={setLocalSteps} />
        <ButtonsContainer>
          <TertiaryButtonWrapper>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setConfDialogOpen(false);
              }}
              buttonType="tertiary"
            >
              {I18n.t("businessProcess.cancel")}
            </Button>
          </TertiaryButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              saveProcessusConf({
                businessProcessValue,
                businessProcess,
                businessProcessTitle: localTitle,
                setFacetBusinessProcessTitle,
                setFacetLocalBusinessProcess,
                localSteps,
                localTitle,
                localInformation,
              });
              setConfDialogOpen(false);
            }}
            buttonType="primary"
          >
            {I18n.t("businessProcess.save")}
          </Button>
        </ButtonsContainer>
      </StepsLinesContainer>
    </React.Fragment>
  );
};

const ConfDialogButton = ({ setConfDialogOpen, confDialogOpen }) => {
  const { businessProcessValue } = useBusinessProcessContext();
  const steps = useMemo(
    () => businessProcessValue?.steps || [],
    [businessProcessValue]
  );

  // div and not React Fragment is important here, to respect flex structure
  return (
    <div>
      <TertiaryButtonWrapper>
        <SetupBusinessProcessButtonContainer>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setConfDialogOpen(true);
            }}
            buttonType="tertiary"
            iconType="settings"
            iconPosition="after"
          >
            {I18n.t("businessProcess.setupBusinessProcess")}
          </Button>
        </SetupBusinessProcessButtonContainer>
      </TertiaryButtonWrapper>
      <Dialog
        open={confDialogOpen}
        title="Paramètres du tableau d’activités"
        size="medium-small"
        onCancel={() => setConfDialogOpen(false)}
      >
        {confDialogOpen ? (
          <DialogContent steps={steps} setConfDialogOpen={setConfDialogOpen} />
        ) : null}
      </Dialog>
    </div>
  );
};

export default ConfDialogButton;
