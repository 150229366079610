import React, { useState, useReducer, useEffect } from "react";
import { cloneDeep, mapValues } from "lodash";
import eventBus from "../../events/eventBus";
import fetch from "../../service/ApiFetch";
import Layout from "../../layout/Layout";

const defaultGridLayout = {
  name: "grid",
  children: [
    { name: "main-header", children: [] },
    { name: "main-body", children: [] },
    { name: "main-footer", children: [] },
  ],
};

const defaultState = {
  layout: defaultGridLayout,
  facets: [],
  data: {},
  items: [],
  itemsPerPage: 10,
  itemsPerQuery: 100,
  currentPage: 1,
  filters: [],
};

const rquery = (state, data) => {
  //console.log('grid-dispatch-old',state,data);
  let newState = cloneDeep(state);
  switch (data.action) {
    case "set":
      // set query result
      newState.layout = data.data.layout || defaultGridLayout;
      newState.facets = data.data.facets || [];
      newState.data = data.data.data || {};
      newState.itemsPerPage = newState.data.itemsPerPage || 10;
      newState.itemsPerQuery = newState.data.itemsPerQuery || 100;
      newState.firstPage =
        (newState.data.currentPage * newState.itemsPerQuery) /
        newState.itemsPerPage;
      newState.items = data.data.items || [];
      newState.filters = [];
      if (newState.currentPage > newState.firstPage + 1)
        newState.currentPage = newState.firstPage + 1;
      break;
    case "setPage":
      newState.currentPage = data.data;
      break;
    case "clear":
      newState.data = {};
      newState.items = [];
      newState.currentPage = 1;
      newState.filters = [];
      newState.firtsPage =
        (newState.data.currentPage * newState.itemsPerQuery) /
        newState.itemsPerPage;
      break;
    case "toggleFilter":
      let newFilter = data.data;
      if (
        newState.filters.find(
          (f) => f.value === newFilter.value && f.name === newFilter.name
        )
      ) {
        newState.filters = newState.filters.filter(
          (f) => f.name !== newFilter.name || f.value !== newFilter.value
        );
      } else {
        newState.filters.push(newFilter);
      }
      newState.currentPage = 1;
      break;
    case "setPageSize":
      let newPageSize = data?.data?.item?.value;
      if (newPageSize && newState.itemsPerPage !== newPageSize) {
        newState.itemsPerPage = newPageSize;
      }
      break;

    default:
  }
  //console.log("grid-dispatch-new",newState,data);
  return newState;
};

const getLayout = ({ facet }) => {
  if (facet.layout) return facet.layout;
  return defaultGridLayout;
};

const getFilters = ({ state }) => {
  let filters = [];
  //
  if (state.facets) {
    state.facets.forEach((f) => {
      (f.values || []).forEach((v) => {
        if (v.selected) {
          filters.push(v);
        }
      });
    });
  }
  return filters;
};

const fetchItems = async ({ facet, control, state, dispatch }) => {
  let handler = facet.controller || control.handler || "main";

  const payload = { filters: getFilters({ state }), data: state.data };
  delete payload.data.items;
  const params = { body: JSON.stringify(payload) };

  fetch(handler, params)
    .then((result) => result.json())
    .then((result) => {
      dispatch({ action: "set", data: result.facets[0] });
    });
};

const getSelectFacetValue = ({ state, data }) => {
  // this doesn't change the state
  // no need to use dispatch
  state.facets.forEach((f) => {
    if (f.id === data.facet.id) {
      f.values.forEach((v) => {
        if (v.id === data.value.id) {
          //console.log("set",v)
          v.selected = data.newval;
        } else {
          if (
            data.newval &&
            (f.type === "oneOfMany" || f.type === "fromList")
          ) {
            //console.log("clear",v)
            v.selected = false;
          }
        }
      });
    }
  });
};

const getFilteredItems = ({ state }) => {
  if (state.filters.length > 0) {
    let rslt = [];
    state.items.forEach((itm) => {
      state.filters.forEach((filter) => {
        if (itm[filter.name] === filter.value && rslt.indexOf(itm) === -1)
          rslt.push(itm);
      });
    });
    //console.log("filteredItems",rslt)
    return rslt;
  }
  return state.items;
};

const FacetGrid = ({ facet, control, ...props }) => {
  const [state, dispatch] = useReducer(rquery, defaultState);

  useEffect(() => {
    dispatch({ action: "set", data: facet });
  }, [facet]);

  const controllerGrid = {
    // grid result
    meta: control.meta,
    handler: control.handler,
    data: state.data,
    currentPage: state.currentPage,
    firstPage: state.firstPage,
    itemsPerQuery: state.itemsPerQuery,
    itemsPerPage: state.itemsPerPage,
    totalPosts: getFilteredItems({ state }).length,
    currentItems: getFilteredItems({ state }).slice(
      (state.currentPage - 1) * state.itemsPerPage,
      state.currentPage * state.itemsPerPage
    ),
    //
    submit: function () {
      fetchItems({ facet, control, state, dispatch });
    },
    getAutoSubmit(f, v) {
      if (f && f.autoSubmit) return true;
      if (v && v.autoSubmit) return true;
      return false;
    },
    refresh: function (f, v) {
      if (this.getAutoSubmit(f, v)) this.submit();
    },
    nextPages: function () {
      this.submit();
    },
    prevPages: function () {
      this.submit();
    },
    setPage: function (pageNumber) {
      dispatch({ action: "setPage", data: pageNumber });
    }, // setCurrentPage(pageNumber)},
    selectItem: function (f, itemId) {
      control.selectItem(f, itemId);
    },
    selectFacetValue: function (f, v) {
      if (!v.selected) {
        getSelectFacetValue({
          data: { action: "sel", facet: f, value: v, newval: true },
          state,
        });
        this.refresh(f, v);
      }
    },
    getData: function () {
      return facet.data;
    },
    doFacetAction: function (...props) {
      control.doFacetAction(...props);
    },
    callMethod: function (...props) {
      control.callMethod(...props);
    },
    callAction: function (...props) {
      control.callAction(...props);
    },
  };

  //////////////////////////////////////////////////////////////////
  const { clearEvent, filterEvent, setPageSizeEvent } = facet;

  const eventMatch = (evt) => {
    let rslt =
      facet.groupId && evt.id
        ? facet.groupId === evt.id
        : evt.id
        ? false
        : true;
    //console.log("eventMatch",{facet,evt,rslt})
    return rslt;
  };
  const clearResultHandler = (data) => {
    if (eventMatch(data)) dispatch({ action: "clear", data: data });
  };
  const filterResultHandler = (data) => {
    if (eventMatch(data)) dispatch({ action: "toggleFilter", data: data });
  };
  const setPageSizeHandler = (data) => {
    if (eventMatch(data)) dispatch({ action: "setPageSize", data: data });
  };

  useEffect(
    () => {
      if (clearEvent) eventBus.on(clearEvent, clearResultHandler);
      if (filterEvent) eventBus.on(filterEvent, filterResultHandler);
      if (setPageSizeEvent) eventBus.on(setPageSizeEvent, setPageSizeHandler);

      return () => {
        if (clearEvent) eventBus.remove(clearEvent, clearResultHandler);
        if (filterEvent) eventBus.remove(filterEvent, filterResultHandler);
        if (setPageSizeEvent)
          eventBus.remove(setPageSizeEvent, setPageSizeHandler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facet.groupId]
  );
  //////////////////////////////////////////////////////////////////

  return (
    <Layout
      facets={state.facets}
      control={controllerGrid}
      layout={getLayout({ facet })}
      {...props}
    />
  );
};

///////////////////////////////////////////////////////////////

export default FacetGrid;
