import React, { useState, useEffect } from "react";
import useTheme from "../hooks/useTheme";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import eventBus from "../../events/eventBus";

const IconWithDotBadgeContainer = styled.div`
  & span[data-component="icon"]::after {
    content: "";
    background: #fafafa;
    background-color: ${({ dotColor }) => dotColor};
    color: #fff;
    transform: translateX(-100%);
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
    height: 6px;
    left: ${({ leftOffset }) => leftOffset};
    line-height: 1;
    margin: 0;
    min-width: 0;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: ${({ topOffset }) => topOffset};
    width: 6px;
    border-color: transparent;
    box-sizing: border-box;
    display: inline-block;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif",
      "-apple-system", "BlinkMacSystemFont";
    font-size: 0.75em;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    vertical-align: middle;
  }
`;

const getDotColorFromDotColorCode = ({ dotColorCode, theme }) => {
  const colorFromTheme = theme?.colors?.badges?.[dotColorCode];
  return colorFromTheme || dotColorCode;
};
// look at theme.colors.badges to see existing color code
const IconWithBadge = ({
  displayBadge = true,
  dotColorCode = "error",
  topOffset = "2px",
  leftOffset = "85%",
  dotAddEvents,
  dotRemoveEvents,
  ...iconProps
}) => {
  const theme = useTheme();
  const [internalDisplayBadge, setInternalDisplayBadge] = useState(
    Array.isArray(dotAddEvents) && dotAddEvents.length ? false : displayBadge
  );

  useEffect(() => {
    if (
      (displayBadge === true || displayBadge === false) &&
      !(Array.isArray(dotAddEvents) && dotAddEvents.length)
    ) {
      setInternalDisplayBadge(displayBadge);
    }
  }, [displayBadge, dotAddEvents]);

  useEffect(() => {
    if (Array.isArray(dotAddEvents)) {
      const memoizedCallbacks = dotAddEvents.map((dotAddEvent) => ({
        event: dotAddEvent,
        callback: () => {
          setInternalDisplayBadge(true);
        },
      }));
      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  }, [dotAddEvents]);

  useEffect(() => {
    if (Array.isArray(dotRemoveEvents)) {
      const memoizedCallbacks = dotRemoveEvents.map((dotRemoveEvent) => ({
        event: dotRemoveEvent,
        callback: () => {
          setInternalDisplayBadge(false);
        },
      }));
      memoizedCallbacks.forEach(({ callback, event }) => {
        eventBus.on(event, callback);
      });

      return () => {
        memoizedCallbacks.forEach(({ callback, event }) => {
          eventBus.remove(event, callback);
        });
      };
    }
  }, [dotRemoveEvents]);

  return internalDisplayBadge ? (
    <IconWithDotBadgeContainer
      topOffset={topOffset}
      leftOffset={leftOffset}
      dotColor={getDotColorFromDotColorCode({ dotColorCode, theme })}
    >
      <Icon {...iconProps} />
    </IconWithDotBadgeContainer>
  ) : (
    <Icon {...iconProps} />
  );
};

export default IconWithBadge;
