import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Mover } from '../../common/Mover';

const ModalWrapper = styled.div`
    position: fixed;
    inset: 0px;
    z-index: var(--zindex);
`;
const ModalBackgound = styled.div`
    background-color: rgba(0, 20, 29, 0.6);
    opacity: 0.6;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: var(--zindex);
`;
const ModalContainer = styled.div`
    position: absolute;
    background-color: white;
    top: calc(50vh - var(--height) / 2);
    left: calc(50% - var(--width) / 2);
    z-index: var(--zindex);
`;

const Modal = ( {isOpen, role, contentWidth, contentHeight, zindex, children} ) => {

    const _role = role||"modal";
    const _width = contentWidth||0;
    const _height = contentHeight||0;
    const _zindex = zindex||0;
    const _style = {
        "--zindex":3000+_zindex,
        "--height":_height+"px",
        "--width":_width+"px",
    }

    const refContainer = useRef();
    const refBackgroundContainer = useRef();
    const [mover, setMover] = useState();
    useEffect(() => {
      window.setTimeout(() => {
        if (refContainer.current && refBackgroundContainer.current) {
          setMover(new Mover({ elt: refContainer.current, backgroundElt: refBackgroundContainer.current }));
        }
      }, 1);
    }, [])



    return (
        <React.Fragment>
        {isOpen ?
            <ModalWrapper ref={refBackgroundContainer} role={_role} style={_style}>
                <ModalBackgound role={_role+"-background"} style={_style} />
                <ModalContainer ref={refContainer} role={_role+"-container"} style={_style}>
                    {children}
                </ModalContainer>
            </ModalWrapper>
            : null
        }
        </React.Fragment>
    )
}

export default Modal
