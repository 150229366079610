import React, { useState } from 'react'
import I18n from "i18n-js";
import InputButton from './InputButton'
import FacetDialog from '../facets/FacetDialog'


const IconParameters = ({ facet, control, value, ...props }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [editingData, setEditingData] = useState(null); // save for editing
  const [oldValue, setOldValue] = useState(value.value); //save for cancel

  const setOpen = () => {
    setIsOpen(!isOpen)
  }

  const setDone= (result) => {
      control.setFacetValue(facet,value,result.value);
      setIsOpen(false);
  }

  const getIcon = () => {
      if (value.icon) return value.icon;
      return 'ellipsis_horizontal';
  }

  const getOptions = () => {
      return value.parameters || value.options || {
        canInsert: false,
        canEdit : true,
        canDelete: false,
      }
  }

  const getHeaders = () => {
      return value.headers|| [
        {title:I18n.t("iconParameters.headerName", { defaultValue: "Name" }), name:'name', editClass:'none'},
        {title:I18n.t("iconParameters.headerType", { defaultValue: "Type" }), name:'type', editClass:'none'},
        {title:I18n.t("iconParameters.headerValue", { defaultValue: "Value" }), name:'value', editClass:'param'},
    ];
  }

  const getRows = () => {
    // get rows from current value parameters
    let rslt = [];
    let headers = getHeaders();
    let params = Array.isArray(value.value) ? value.value : JSON.parse(value.value);

    params.forEach((p,indx) => {
        let r = [];
        headers.forEach((h,indx)=>{
            let cell = {title:'', editClass:h.editClass, editValues:h.editValues, editLookup:h.editLookup, editSubTitle:h.editSubtitle, autoSubmit:h.autoSubmit};
            //
            if (indx===0) {
              // memorize parameter on first cell
              cell.param = p;
            }
            if ((!h.editValues) && (p.editValues)) cell.editValues = p.editValues;
            if ((!h.editLookup) && (p.editLookup)) cell.editLookup = p.editLookup;
            if ((!h.editClass) && (p.editClass)) cell.editClass = p.editClass;
            if ((!h.editSubTitle) && (p.editSubTitle)) cell.editSubTitle = p.editSubTitle;
            if ((p.autoSubmit) && (p.autoSubmit.includes(h.name))) cell.autoSubmit = true;
            //
            if (h.name in p) {
              cell.title = p[h.name];
            }
            r.push(cell);
        })
        rslt.push(r);
    });
    return rslt;
  }

  const updateParam = (p,filters) => {
    // update parameter with input values
    if (filters) {
      filters.forEach((v)=>{
        if ((v.facet===0) && (v.name)) {
          // facet 0 is input facet
          // v.id is header index
          // v.name is header name
          // v.value is new value
          p[v.name] = v.value;
          if (v.name==="value") p["editClass"] = v.editClass;
        }
      })
    }
  }

  const getParams = (rows, rowId, filters) => {
    // get value parameters from rows
    let params = [];
    let headers = getHeaders();
    rows.forEach((row,id)=>{
        let p = {}

        if (typeof(row[0].param) !== 'undefined') {
          // original parameter has been stored on first cell
          p = row[0].param;
        }

        headers.forEach((h,indx)=>{
          p[h.name] = row[indx].title;
        })
        if ((filters) && (rowId===id)) {
          // update this specifc row with filtered value
          updateParam(p,filters);
        }
        params.push(p);
    })

    // insert new param at end
    if ((filters) && (rowId===-1)) {
      let p = {}
      headers.forEach((h,indx)=>{
        p[h.name] = '';
      })
      updateParam(p,filters);
      params.push(p);
    }

    return params;
  }

  const getButtonAction = (action,editing) => {
    if (editing) return action+'Editing'; else return action;
  }

  const getCancelButton = (editing) => {
    return {title:I18n.t("dialog.cancel", { defaultValue: "Cancel" }), type:'tertiary', size:'medium', icon:'', action:getButtonAction('cancel',editing) };
  }
  const getApplyButton = (editing) => {
    return {title:I18n.t("dialog.apply", { defaultValue: "Apply" }), type:'tertiary', size:'medium', icon:'', action:getButtonAction('apply',editing) };
  }

  const getEditingValues = (row) => {

    let rslt = [];
    getHeaders().forEach((header,indx) => {
      let value = {facet:0, id:indx, name:header.name, title:header.title, class:'edit',
        editClass:header.editClass||'',
        value:'',
        lookup:header.lookup||{},
        values:header.editValues||[],
        nodes:header.editNodes||{},
        autoSubmit:header.autoSubmit,
        subTitle:header.subTitle,
        textValue:header.textValue,
      };
      if (row) {
        value.value = row[indx].title || '';
        if (row[indx].editClass) value.editClass = row[indx].editClass;
        if (row[indx].editValues) value.values = row[indx].editValues;
        if (row[indx].editNodes) value.values = row[indx].editNodes;
        if (row[indx].editLookup) value.lookup = row[indx].editLookup;
        if (row[indx].editSubTitle) value.subTitle = row[indx].editSubTitle;
        if (row[indx].autoSubmit) value.autoSubmit = row[indx].autoSubmit;
        if (row[indx].textValue) value.textValue = row[indx].textValue;
      }
      rslt.push(value);
    })

    console.log("editing",rslt)

    return rslt;
  }

  const getEditingFacet = (row) => {
    let rslt = { id:0, title:I18n.t("iconParameters.editTitle", { defaultValue: "Paramètre" }), type:'list', position:'main-body', values:[] }
    getEditingValues(row).forEach((v)=>{
      rslt.values.push(v);
    })
    return rslt;
  }

  const getTableFacet = () => {
    return {
          id:0,title:I18n.t("iconParameters.dialogTitle", { defaultValue: "Paramètre" }), type:'table', position:'main-body', values:[
          {facet:0, id:0,
              options: getOptions(),
              headers: getHeaders(),
              rows: getRows(),
          },
          ]
      };
  }
  const getButtonFacet = (editing) => {
    return {id:1, type:'list', orientation:'horz', position:'main-footer', values:[
      {facet:1, id:0, class:'button', button:getCancelButton(editing) },
      {facet:1, id:1, class:'button', button:getApplyButton(editing) },
    ]};
  }

  const dialogCallBack = (req) => {

    let _editingData = editingData;
    let _isEditing = (_editingData!=null);

    if ((req.meta) && (!req.meta.action) && (req.filters) && (req.filters.length!==0)) {
      // editing value has autoSubmit
      req.meta.action = {name:'checkEdit'};
    }

    if ((req.meta) && (req.meta.action)) {

        switch (req.meta.action.name) {
          case 'new':
          case 'edit':
          case 'insert':
          case 'udpate':
          case 'delete':
            if (req.meta.action.param.rows) {
                value.value = JSON.stringify(getParams(req.meta.action.param.rows));
            }
            break;
          case 'cancel':
            // rollback all modifications
            value.value = oldValue;
            break;
          case 'apply':
            // apply modification
            control.setFacetValue(facet,value,value.value);
            setOldValue(value.value);
            break;
          case 'enterEdit':
            // start editing item
            // param : currentRow state and id of edited row (or -1 if new)
            _isEditing = true;
            _editingData = req.meta.action.param;
            setEditingData(_editingData);
            break;
          case 'cancelEditing':
            break;
          case 'checkEdit':
          case 'applyEditing':
            // the input values are in the filters[] of the req
            value.value = JSON.stringify(getParams(_editingData.rows,_editingData.rowId,req.filters));
            break;
          default:
        }

        switch (req.meta.action.name) {
          case "checkEdit":
            if (_editingData.rowId===-1) {
              // new row is last row
              _editingData.rows = getRows();
              _editingData.rowId = _editingData.rows.length-1;
              setEditingData(_editingData);
            }
          break;
          default:
        }

        switch (req.meta.action.name) {
          case 'apply':
          case 'cancel':
            // exit dialog
            setEditingData(null);
            setIsOpen(false);
            break;
          case 'cancelEditing':
          case 'applyEditing':
            // exit editing mode
            _isEditing = false;
            _editingData = null;
            setEditingData(_editingData);
          break;
          case 'checkEdit':
          // this is to refresh the dialog
          value.autoSubmit = true;
          control.setFacetValue(facet,value,value.value);
          break;
          default:
        }
    }

    let resp = {
      meta: {id:1},
      facets:[],
    }

    if (_isEditing) {
      let row = null;
      if (_editingData.rowId!==-1) {
        let rows = getRows();
        row = rows[_editingData.rowId];
      }
      resp.facets.push(getEditingFacet(row));
    }
    else  resp.facets.push(getTableFacet());

    resp.facets.push(getButtonFacet(_isEditing));

    return resp;
  }

  const getDialogValue = () => {
    return {facet:facet.id, id:value.id, lookup:value.lookup, dialog:{title:value.title, callBack:dialogCallBack}};
  }

return (
  <React.Fragment>
      <InputButton facet={facet} control={control} value={value} onAction={setOpen} placeHolder={I18n.t("iconParameters.placeHolder", { defaultValue: "Configure..." })} tooltipMessage={I18n.t("iconParameters.tooltipMessage", { defaultValue: "Some parameters have been configured..." })}/>
      <FacetDialog facet={facet} control={control} value={getDialogValue()} isOpen={isOpen} onCancel={setOpen} onDone={setDone} changeCount={control.roundTrip} {...props}/>
  </React.Fragment>
)
}

export default IconParameters
