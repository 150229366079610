import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import styled from 'styled-components';
import Button from 'carbon-react/lib/components/button';
import Search from 'carbon-react/lib/components/search';

const SearchBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  margin: 5px 0;
`;

const SearchActions = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Advanced search component for React-Sortable-Tree
 *
 */
const FacetSearchMenuEditor = (props) => {
  /**
   * Select the previous matched node
   */
  const selectPrevMatch = () => {
    props.setSearchFocusIndex(
      props.searchFocusIndex !== null
        ? (props.searchFoundCount + props.searchFocusIndex - 1) %
            props.searchFoundCount
        : props.searchFoundCount - 1
    );
  };

  /**
   * Select the next matched node
   */
  const selectNextMatch = () => {
    props.setSearchFocusIndex(
      props.searchFocusIndex !== null
        ? (props.searchFocusIndex + 1) % props.searchFoundCount
        : 0
    );
  };

  return (
    <SearchBar>
      <Search
        placeholder={I18n.t('menuEditor.search.placeholder', {
          defaultValue: 'Search...',
        })}
        defaultValue=''
        searchWidth='250px'
        aria-label='search'
        value={props.searchString}
        onChange={(event) => props.setSearchString(event.target.value)}
      />
      <SearchActions>
        <Button
          size='small'
          buttonType='secondary'
          disabled={!props.searchFoundCount}
          iconType='chevron_left'
          onClick={selectPrevMatch}
        >
          {I18n.t('menuEditor.search.previous', {
            defaultValue: 'Previous',
          })}
        </Button>
        <Button
          size='small'
          buttonType='secondary'
          disabled={!props.searchFoundCount}
          iconPosition='after'
          iconType='chevron_right'
          onClick={selectNextMatch}
        >
          {I18n.t('menuEditor.search.next', {
            defaultValue: 'Next',
          })}
        </Button>
        <div>
          {props.searchFoundCount > 0 ? props.searchFocusIndex + 1 : 0}
          &nbsp;/&nbsp;
          {props.searchFoundCount || 0}
        </div>
      </SearchActions>
    </SearchBar>
  );
};

FacetSearchMenuEditor.propTypes = {
  searchString: PropTypes.string.isRequired,
  searchFoundCount: PropTypes.number.isRequired,
  searchFocusIndex: PropTypes.number.isRequired,
  setSearchString: PropTypes.func.isRequired,
  setSearchFocusIndex: PropTypes.func.isRequired,
};

export default FacetSearchMenuEditor;
