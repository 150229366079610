const it = {
  businessProcess: {
    addNewStep: "Aggiungi un nuovo step",
    editMode: "Modifica",
    edit: "Modificare",
    deleteSubStep: "Elimina step",
    duplicate: "Duplicare",
    cancel: "Cancellare",
    cancelChanges: "Cancella i cambiamenti",
    save: "Registrare",
    useThisZoneToAddShortcuts:
      "Utilizzare la zona per aggiungere la scelta rapida",
    addANew: "Aggiungere nuovo",
    subStep: "sotto step",
    businessProcess: "Tavola delle attività",
    dragAndDropExplanation:
      "Aggiungi un passaggio e un passaggio secondario, quindi trascina e rilascia una voce di menu per aggiungere un'attività.",
    validationExplanation:
      "La replica consente di salvare le modifiche senza alterare la tavola delle attività iniziali.",
    title: "Titolo",
    link: "Collegamento",
    information: "Informazione",
    steps: "Steps",
    setupBusinessProcess: "Imposta la tavola delle attività",
    none: "vuoto",
    close: "Chiudere",
    iconList: "Elenco di icone",
  },
  noResult: "Nessun risultato",
  button: {
    commit: "Registrare",
    cancel: "Per cancellare",
    apply: "Applicare",
  },
  action: {
    add: "Creare",
    modify: "Modificare",
    delete: "Cancellare",
  },
  grid: {
    noResult: "Nessun risultato",
  },
  confirm: {
    title: "Conferma",
    areYouSure: "Sei sicuro?",
    no: "No",
    yes: "Sì",
  },
  dialog: {
    placeHolder: "Configura...",
    cancel: "Per cancellare",
    apply: "Applicare",
  },
  codeEditor: {
    placeHolder: "Modificare...",
    compile: "Compilare",
    tooltipMessage: "Lo script è stato modificato",
  },
  emailEditor: {
    placeHolder: "Modificare...",
    dialogTitle: "EMail",
  },
  iconParameters: {
    dialogTitle: "Impostazioni",
    editTitle: "Impostazioni",
    placeHolder: "Configura...",
    headerName: "Nome",
    headerType: "Genere",
    headerValue: "Valore",
    tooltipMessage: "I parametri sono stati configurati",
  },
  iconForm: {
    placeHolder: "Configura...",
  },
  iconButton: {
    placeHolder: "Configura...",
    isEdited: "Elemento modificato",
  },
  iconTreeview: {
    placeHolder: "Configura...",
    dialogTitle: "Selezionare",
  },
  navigleft: {
    minimize: "Minimizzare",
    closeAll: "Chiudi tutto",
    loading: "Caricamento in corso",
    noContent: "Nessun contenuto",
  },
  menuEditor: {
    entry: {
      title: "Nuovo",
      caption: "Nuovo",
      action: {
        add: "Aggiungere",
        remove: "Rimuovere",
        separator: "Separatore",
      },
    },
    search: {
      placeholder: "Ricercare...",
      previous: "Prec.",
      next: "Seg.",
    },
    collapseAll: "Comprimi tutto",
    expandAll: "Espandi tutto",
    addRootEntry: "Nuovo arrivato",
    filter: "Oggetti",
    sort: {
      label: "Ordinamento",
      orderByDesc: "Ordine discendente",
      orderByAsc: "Ordine crescente",
      order: "Ordine",
    },
  },
  card: {
    noContent: "Questo elemento non ha contenuto",
  },
};

const moment_it = {
  months:
    "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split(
      "_"
    ),
  monthsShort: "gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic".split("_"),
  weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split(
    "_"
  ),
  weekdaysShort: "dom_lun_mar_mer_gio_ven_sab".split("_"),
  weekdaysMin: "do_lu_ma_me_gi_ve_sa".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: function () {
      return (
        "[Oggi a" +
        (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
        "]LT"
      );
    },
    nextDay: function () {
      return (
        "[Domani a" +
        (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
        "]LT"
      );
    },
    nextWeek: function () {
      return (
        "dddd [a" +
        (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
        "]LT"
      );
    },
    lastDay: function () {
      return (
        "[Ieri a" +
        (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
        "]LT"
      );
    },
    lastWeek: function () {
      switch (this.day()) {
        case 0:
          return (
            "[La scorsa] dddd [a" +
            (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
            "]LT"
          );
        default:
          return (
            "[Lo scorso] dddd [a" +
            (this.hours() > 1 ? "lle " : this.hours() === 0 ? " " : "ll'") +
            "]LT"
          );
      }
    },
    sameElse: "L",
  },
  relativeTime: {
    future: "tra %s",
    past: "%s fa",
    s: "alcuni secondi",
    ss: "%d secondi",
    m: "un minuto",
    mm: "%d minuti",
    h: "un'ora",
    hh: "%d ore",
    d: "un giorno",
    dd: "%d giorni",
    w: "una settimana",
    ww: "%d settimane",
    M: "un mese",
    MM: "%d mesi",
    y: "un anno",
    yy: "%d anni",
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: "%dº",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
};

export { it, moment_it };
