import React from "react";
import Link from "carbon-react/lib/components/link";
import Dom from "../../../common/Dom"

const getLinkOnClick =
  ({ control, link, url, title }) =>
  () => {

    console.log("link",link,url,title)

    if (url) {
      Dom.download(url,title)
      return;
    }

    control.callAction("openLink", { item: { url:link, title } });
  };

const linkRenderer = ({ value, context }) => {
  const { url, link, linkTitle, title } = value;
  const { control } = context;
  return (
    <Link onClick={getLinkOnClick({ control, link, url, title: linkTitle })}>
      {title}
    </Link>
  );
};

export default linkRenderer;
