import React, { useState } from "react";
import Grid from "sagegrid/components/BaseSageGrid";
import styled from "styled-components";
import LinkRenderer from "./agGridRenderers/LinkRenderer";
import { useControlContext } from "../../../App";

const getGridReadyHandler =
  ({ setApi, setColumnApi }) =>
  ({ api, columnApi }) => {
    setApi(api);
    setColumnApi(columnApi);
  };

const getGridOptions = ({
  columnDefs,
  components,
  control,
  mainControl,
  usingMainController,
}) => ({
  rowModelType: "clientSide",
  domLayout: "autoHeight",
  columnDefs,
  components,
  context: { control: usingMainController ? mainControl : control },
});

const GridContainer = styled.div`
  & .ag-center-cols-container {
    width: 100% !important;
  }
  ${({ noHeader }) =>
    noHeader &&
    `& .ag-header[role="presentation"] {
       height: 0px !important;
       min-height: 0px !important;
     }
    `}
`;

const BaseGrid = ({
  columnDefs,
  data,
  noHeader,
  control,
  usingMainController,
}) => {
  const [api, setApi] = React.useState(undefined);
  const [columnApi, setColumnApi] = React.useState(undefined);
  const [components] = useState({
    linkRenderer: LinkRenderer,
  });
  const mainControl = useControlContext();

  return (
    <GridContainer noHeader={noHeader}>
      <Grid
        enableEnterpriseVersion={false}
        headerHeight={noHeader ? 0 : undefined}
        gridOptions={getGridOptions({
          columnDefs,
          components,
          control,
          mainControl,
          usingMainController,
        })}
        rowData={data}
        onGridReady={getGridReadyHandler({ setApi, setColumnApi })}
      />
    </GridContainer>
  );
};

export default BaseGrid;
