import React, { useState, useEffect } from "react";
import workertick_script from "../../service/workertick";
import eventBus from "../../events/eventBus";
import { toastMsg } from "../components/toast/toastHelpers";

const FacetOnTick = ({ facet, control, ...props }) => {
  const [worker, setWorker] = useState(null);

  ////////////////////////////////////////////////////
  // Start worker
  ////////////////////////////////////////////////////

  useEffect(() => {
    if (facet.enabled) {
      setWorker(new Worker(workertick_script));
    }

    // cleanup this component
    return () => {
      if (worker) {
        worker.terminate();
        setWorker(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (worker) {
    worker.onmessage = function (e) {
      //console.log("receive tick",e.data)
      eventBus.emit("tick", e.data);
    };
    worker.postMessage({
      cmd: "tickms",
      param: Math.max(facet.tickms || 5000, 2000),
    });
  }

  useEffect(
    () => {
      eventBus.on("tick", () => {
        if (facet && facet.action) {
          control.callAction(facet.action.name, facet.action, (resp) => {
            if (resp.error) {
              control.setError(resp);
            }

            if (resp.notifications) {
              if (resp.notifications.messages) {
                eventBus.emit("messages", resp.notifications.messages);
              }
              if (resp.notifications.tasks) {
                eventBus.emit("tasks", resp.notifications.tasks);
              }
              if (resp.notifications.activities) {
                eventBus.emit("activities", resp.notifications.activities);
              }
              if (resp.notifications.notifications) {
                eventBus.emit(
                  "notifications",
                  resp.notifications.notifications
                );
              }
              if (resp.notifications.actions) {
                resp.notifications.actions.forEach((a) =>
                  eventBus.emitAction(a)
                );
              }
              if (resp.notifications.tweets?.length) {
                resp.notifications.tweets.forEach((tweet) => {
                  toastMsg({
                    text: tweet.title,
                    time: tweet.rightTitle,
                    data: tweet,
                    type: tweet?.status || "info",
                  });
                });
              }
            }
          });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <div></div>;
};

export default FacetOnTick;
