import React from "react";
import { ReactComponent as EsLogo } from "../../../img/flags/es.svg";
import { ReactComponent as FrLogo } from "../../../img/flags/fr.svg";
import { ReactComponent as GbLogo } from "../../../img/flags/gb.svg";
import { ReactComponent as ItLogo } from "../../../img/flags/it.svg";
import { ReactComponent as DeLogo } from "../../../img/flags/de.svg";

const getStyle = () => ({ width: "24px", height: "24px", padding: "3px" });

const getEmptySpanStyle = () => ({
  width: "24px",
  height: "24px",
  backgroundColor: "white",
});

const FlagIcon = ({ code }) => {
  const logosComponents = {
    1031: DeLogo,
    1034: EsLogo,
    1036: FrLogo,
    1040: ItLogo,
    2057: GbLogo,
  };
  const ComponentToDisplay = logosComponents[code];

  return ComponentToDisplay ? (
    <ComponentToDisplay style={getStyle()} />
  ) : (
    <span style={getEmptySpanStyle()} />
  );
};

export default FlagIcon;
