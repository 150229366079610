import eventBus from '../events/eventBus'

const getMessagesHandler = (control) => {

  const receiveMessage = (e) => {
    if (e?.data?.action === "executeLink") {
      const { data: { uri: url }, } = e;
      const call = { name: "openUrl", item: { url } };
      control.callAction(call.name,call);
    }

    if (e?.data?.type) {
      //console.log("receive action",process.env.REACT_APP_API_PACKAGE,e.data);
      eventBus.emit(e.data.type,e.data.data);
   }

  };

  return {
    addMessageHandler: () => {
      window.addEventListener("message", receiveMessage, false);
    },
    removeMessageHandler: () => {
      window.removeEventListener("message", receiveMessage, false);
    },
  };
};

export default getMessagesHandler;
