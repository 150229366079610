import React, {  useState,  } from "react";

const NavTabContentDefault = ( { tab } ) => {

    const [state,setState] = useState({content:null})

    return (
        <div><h1>Tab content not supported</h1><div>{JSON.stringify(tab)}</div></div>
    );
}

export default NavTabContentDefault;