import React, { useState } from 'react'
import Button from 'carbon-react/lib/components/button'


const FacetSubmit = ({ facet, control }) => {

  const onClick = (e) => {
    e.preventDefault();
    control.submit();
  }
  const getButtonType = () => {
    if (facet.buttonType) return facet.buttonType; else return 'primary';
  }
  const getButtonSize = () => {
    if (facet.buttonSize) return facet.buttonSize; else return 'medium';
  }
  const getButtonDisabled = () => {
    return false;
    //return control.refreshCount!==0;
  }

  return (
   <React.Fragment>
   <Button  buttonType={getButtonType()} size={getButtonSize()} disabled={getButtonDisabled()} onClick={(e) => onClick(e)} value={facet.title}>{facet.title}</Button>
  </React.Fragment>

  )
}

export default FacetSubmit
