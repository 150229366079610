import React from "react";
import Icon from "carbon-react/lib/components/icon";
import styled from "styled-components";
import useTheme from "../../hooks/useTheme";
import Ellipsis from "../Ellipsis";
import Link from "carbon-react/lib/components/link";
import { useControlContext } from "../../../../App";
import { toast } from "react-toastify";

const iconFromType = {
  success: "tick",
  error: "error",
  warning: "warning",
  info: "info",
};

const MsgContainer = styled.div`
  display: flex;
  align-items: center;
  height: 73px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, type }) => theme?.colors?.[type]};
  height: 73px;
  width: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 268px;
  height: 73px;
`;

const TextContainer = styled.div`
  width: 238px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const TimeContainer = styled.div`
  font-size: ${({ theme }) => theme?.fontSizes025};
`;

const getHandleClickLink = ({ action, msgData, control, toastId }) => (e) => {
  e.preventDefault();
  const call = { name: action, item: msgData };
  control.callAction(call.name,call);
  toast.dismiss(toastId.current);
};

const ToastMsg = ({ text, toastId, time, msgData, type }) => {
  const theme = useTheme();
  const control = useControlContext();

  return (
    <MsgContainer>
      <IconContainer theme={theme} type={type}>
        <Icon
          type={iconFromType[type]}
          color={theme?.colors?.white}
          bg={theme?.colors?.[type]}
        />
      </IconContainer>
      <ContentContainer>
        {time && <TimeContainer theme={theme}>{time}</TimeContainer>}
        <TextContainer>
          <Ellipsis lines={2} width={msgData?.action ? 200 : undefined} text={text} />
          {msgData?.action && msgData.url && (
            <Link
              onClick={getHandleClickLink({
                action: msgData.action,
                msgData,
                control,
                toastId
              })}
            >
              Détail
            </Link>
          )}
        </TextContainer>
      </ContentContainer>
    </MsgContainer>
  );
};

export default ToastMsg;
