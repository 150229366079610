import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme?.navigation?.actions?.backgroundColor};
  & [data-component="button"] {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    width: fill-available;
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
    color: ${({ theme }) => theme?.navigation?.actions?.textColor};
  }
  & [data-component="button"]:hover {
    color: ${({ theme }) => theme?.navigation?.actions?.hoveredTextColor}
  }
  & [data-component="icon"] {
    color: ${({ theme }) => theme?.navigation?.actions?.textColor};
  }

  & [data-component="icon"]:hover {
    color: ${({ theme }) => theme?.navigation?.actions?.hoveredTextColor};
  }
`;

const ActionsContainer = ({ children, setOpen, refButtonAction, ...props }) => {
  const actionsContainerRef = useRef();

  useEffect(() => {
    const memoizedCallback = (event) => {
      if (refButtonAction?.current?.contains?.(event?.target)) return;
      if (actionsContainerRef?.current?.contains?.(event?.target) === false)
        setOpen?.(false);
    };
    document.addEventListener("click", memoizedCallback);
    return () => {
      document.removeEventListener("click", memoizedCallback);
    };
  }, [setOpen, refButtonAction]);

  return (
    <StyledActionsContainer role="actions-container" {...props} ref={actionsContainerRef}>
      {children}
    </StyledActionsContainer>
  );
};

export default ActionsContainer;
