
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "carbon-react/lib/components/loader";

const Spinner = ( {...props} ) => {

  const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});

  return (
    promiseInProgress && (
        <Loader/>
    )
  );

}
  
export default Spinner;

