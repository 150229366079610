import React, { useRef, useState, useLayoutEffect } from "react";
import Tooltip from "carbon-react/lib/components/tooltip";
import PropTypes from "prop-types";

export const getTextWidth = ({ text, title, font }) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (font) context.font = font;
  const metrics = context.measureText(`${title ? `${title} ` : ""}${text}`);

  return metrics.width;
};

export const getDivFont = (currentDivRef) => {
  const style = window.getComputedStyle(currentDivRef);
  const font = [
    style["font-weight"],
    style["font-style"],
    style["font-size"],
    style["font-family"],
  ]
    .filter((elem) => elem !== undefined)
    .join(" ");

  return font;
};

const getShouldHaveTooltip = ({ divRef, textWidth, width, lines }) =>
  lines > 1 ? divRef?.scrollHeight > divRef?.clientHeight : textWidth > width;

export const getWidthFromParent = (currentDivRef) =>
  currentDivRef?.parentNode?.offsetWidth || 0;

const getStyle = ({ customWidth, width, textWidth, lines }) => ({
  width,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: lines,
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const Ellipsis = ({
  text,
  title,
  width: customWidth,
  lines = 1,
  style,
  ...customProps
}) => {
  const divRef = useRef();
  const [font, setFont] = useState();
  const [width, setWidth] = useState();
  const [shouldHaveTooltip, setShouldHaveTooltip] = useState(false);
  const textWidth = getTextWidth({ text, title, font });
  //const shouldHaveTooltip = textWidth > width * lines;

  useLayoutEffect(() => {
    if (divRef.current) {
      setShouldHaveTooltip(
        getShouldHaveTooltip({
          divRef: divRef.current,
          textWidth,
          width,
          lines,
        })
      );
      if(!customWidth) {
        setFont(getDivFont(divRef.current?.parentNode));
      }
      else {
        setFont(getDivFont(divRef.current))
      }

      if (!customWidth) {
        setWidth(getWidthFromParent(divRef.current));
      } else {
        setWidth(customWidth);
      }
    }
  }, [divRef, customWidth, lines, textWidth, width]);

  return shouldHaveTooltip ? (
    <Tooltip message={text} position="bottom">
      <div
        {...customProps}
        ref={divRef}
        style={{
          ...(style || {}),
          ...getStyle({ customWidth, width, textWidth, lines }),
        }}
      >
        {title ? <strong>{title}:</strong> : ""} {text}
      </div>
    </Tooltip>
  ) : (
    <div
      {...customProps}
      ref={divRef}
      style={{
        ...(style || {}),
        ...getStyle({ width, textWidth, customWidth, lines }),
      }}
    >
      {title ? <strong>{title}:</strong> : ""} {text}
    </div>
  );
};

Ellipsis.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  lines: PropTypes.number,
  style: PropTypes.object,
};

export default Ellipsis;
