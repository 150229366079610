import { cloneDeep } from 'lodash';
import React, { useState, Component } from 'react'
import {Facet, FacetTitle} from './Facet'
import { Select, Option } from "carbon-react/lib/components/select";
import eventBus from '../../events/eventBus'


const FacetSelect = ({ facet, control, value }) => {

    // Dropdown select
    //
    const getItems = () => {
        if ((value) && (value.values)) return value.values;
        return facet.values || [];
    }
    const getItemText = (item) => {
        return item.title || item.text || item.value || item.id || '';
    }
    const getItemValue = (item) => {
        let rslt = item.value || item.title || item.text || item.id || '';
        if (typeof rslt==="number") rslt = rslt.toString();
        return rslt;
    }
    const getDefaultValue = () => {
        if ((value) && (value.value)) return value.value;
        return getItemValue(getItems().find(item => item.selected));
    }
    const getItem = (value) => {
        return getItems().find(item => getItemValue(item)===value); 
    }

    const [selectedValue, setValue] = useState(getDefaultValue());

    const onSelectChange = (evt)=>{
        
        setValue(evt.target.value);

        let item = getItem(evt.target.value);

        if (facet.onChangeEvent) {
            let evt = cloneDeep(facet.onChangeEvent);
            evt.item = item; 
            eventBus.emit(evt.name,evt);
        }

        if (value) {
            control.setFacetValue(facet,value,item.value);
        } else {
            control.selectFacetValue(facet,item);
        }
    }

    return (
        <Facet>
        <FacetTitle>{facet.title}</FacetTitle>
          <Select value={selectedValue} onChange={onSelectChange}>
              {getItems().map(item => (
              <Option text={getItemText(item)} value={getItemValue(item)} />
              ))}
        </Select>
        </Facet>
    )
}

export default FacetSelect
