import React, { useState, useReducer, useEffect, useContext } from "react";
import { cloneDeep } from "lodash";
import Layout from "./components/layout/Layout";
import fetch from "./components/service/ApiFetch";
import getMessagesHandler from "./components/service/messagesHandler.js";
import "./App.css";
import "sagegrid/themes/ag-theme-sage.css";
import { ThemeProvider } from "styled-components";
import { sageFrp1000Theme } from "./SageFrp1000";
import CarbonScopedTokensProvider from "carbon-react/lib/style/design-tokens/carbon-scoped-tokens-provider/";
import initLocales from "./locales/i18n";
import eventBus from "./components/events/eventBus";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tabs/style/react-tabs.css";
import { ToastContainer } from "react-toastify";
import GlobalStyle from "./GlobalStyle";
import { MainController } from "./components/common/Controller";
import FacetError from "./components/ui/facets/FacetError";
import Spinner from "./components/ui/components/Spinner";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragContext } from "./context/DragContext";
import Dom from './components/common/Dom'

const ControlContext = React.createContext();
export const useControlContext = () => useContext(ControlContext);

const App = () => {
  const defaultLayout = {
    name: "layout",
    children: [
      { name: "topbar", children: [] },
      {
        name: "body",
        children: [
          { name: "sidebar", children: [] },
          {
            name: "main",
            children: [
              { name: "main-header", children: [] },
              { name: "main-body", children: [] },
              { name: "main-footer", children: [] },
            ],
          },
          { name: "leftsidebar", children: [] },
        ],
      },
    ],
  };

  function rstate(state, data) {
    //console.log('main-dispatch-old',state,data)
    let newState = {
      meta: {},
      layout: defaultLayout,
      facets: [],
      isLoading: false,
      error: null,
      trip: 0,
    };
    switch (data.action) {
      case "set":
        // set state result
        if (data.data.error) {
          newState = cloneDeep(state);
          newState.error = data.data.error;
        } else {
          newState.meta = data.data.meta || {};
          newState.layout = data.data.layout || defaultLayout;
          newState.facets = data.data.facets || [];
        }
        newState.trip = state.trip + 1;
        break;
      case "setError":
        newState = cloneDeep(state);
        newState.error = data.data.error;
        break;
      case "clearError":
        newState = cloneDeep(state);
        newState.error = null;
        break;
      default:
    }
    //console.log("main-dispatch-new",newState,data);
    return newState;
  }

  const [state, setState] = useReducer(rstate, {
    meta: {},
    layout: defaultLayout,
    facets: [],
    isLoading: true,
    trip: 0,
    error: null,
  });
  const [roundTrip, setRoundTrip] = useState(0);

  ////////////////////////////////////////////////////
  // notify back end the page is closed
  ////////////////////////////////////////////////////
  const postSyncAction = (a) => {
    let handler = state.meta.handler || "main";
    const payload = { meta: state.meta };

    // cleanup, do not send these
    payload.meta.action = a;
    if (payload.meta.items) delete payload.meta.items;

    fetch.sendBeacon(handler, JSON.stringify(payload));
  };

  const onUnload = () => {
    if (state.meta && state.meta.transaction) {
      postSyncAction({ name: "abort", param: {} });
    }
  };

  const onBeforeUnload = () => {
    // if we have a transaction opened ask for confirmation
    if (state.meta && state.meta.transaction) {
      return "";
    } else return null;
  };

  useEffect(() => {
    //console.log("set handlers",state)
    //
    if (state && state.meta && state.meta.transaction) {
      window.onbeforeunload = onBeforeUnload;
      window.onunload = onUnload;
    }
    return () => {
      //console.log("clean handlers")
      window.onbeforeunload = null;
      window.onunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundTrip]);

  ////////////////////////////////////////////////////
  // Handle some outband actions
  ////////////////////////////////////////////////////
  
  // redirect : on an other app
  eventBus.on("redirect", (data) => {
    window.setTimeout(function () {
      window.location.replace(data.url);
    }, 1000);
  });


  // location : open a new browser Tab and navigate
  useEffect(() => {

    const memoizedCallbackLocation = (data) => {
      window.setTimeout(function () {
        if (data.location.newTab) {
          window.open(data.location.url, '_blank');
        }
        else 
          window.location = data.location.url;
      }, 1000);
    }
    eventBus
      .on("location", memoizedCallbackLocation)
    return () => {
      eventBus
        .remove("location", memoizedCallbackLocation)
    };
  }, [state]);

  // download : download a document
  useEffect(() => {

    const memoizedCallbackDownload = (data) => {
      Dom.download(data.download.url, data.download.name);
    }
    eventBus
      .on("download", memoizedCallbackDownload)
    return () => {
      eventBus
        .remove("download", memoizedCallbackDownload)
    };
  }, [state]);

  ////////////////////////////////////////////////////
  // Controller
  ////////////////////////////////////////////////////

  let controller = new MainController({
    state,
    setState,
    roundTrip,
    setRoundTrip,
  });

  // initial fetch
  useEffect(
    () => {
      controller.setQueryParameters();
      controller.fetchData("mainFetch");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  ////////////////////////////////////////////////////
  // handle messages from IFrames
  ////////////////////////////////////////////////////
  const messagesHandler = getMessagesHandler(controller);

  useEffect(
    () => {
      messagesHandler.addMessageHandler();

      return () => {
        messagesHandler.removeMessageHandler();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  ////////////////////////////////////////////////////
  // Styles and locales
  ////////////////////////////////////////////////////
  const getLocale = () => {
    if (controller.meta && controller.meta.locale)
      return controller.meta.locale;
    return "fr";
  };

  // where we initialize translation
  let locale = getLocale();
  initLocales(locale);
  let theme = sageFrp1000Theme(locale);

  document.body.style.color = theme.text.color;
  document.body.style.fontSize = theme.text.size;
  document.body.style.fontWeight = theme.text.weight;

  ////////////////////////////////////////////////////
  const [canDragCount, setCanDragCount] = useState(0);

  useEffect(() => {
    const memoizedCallbackIncrement = () => {
      setCanDragCount((previousCanDragCount) => previousCanDragCount + 1);
    };

    const memoizedCallbackDecrement = () => {
      setCanDragCount((previousCanDragCount) => previousCanDragCount - 1);
    };
    eventBus.on("process-edit-increment-count", memoizedCallbackIncrement);
    eventBus.on("process-edit-decrement-count", memoizedCallbackDecrement);

    return () => {
      eventBus.remove(
        "process-edit-increment-count",
        memoizedCallbackIncrement
      );
      eventBus.remove(
        "process-edit-decrement-count",
        memoizedCallbackDecrement
      );
    };
  }, []);

  useEffect(() => {
    const memoizedCallback = () => {
      window.location.reload(true);
    };

    eventBus.on("refresh", memoizedCallback);

    return () => {
      eventBus.remove("refresh", memoizedCallback);
    }

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CarbonScopedTokensProvider>
        <GlobalStyle theme={theme}>
          <ControlContext.Provider value={controller}>
            <DragContext.Provider value={{ canDragCount }}>
              <DndProvider backend={HTML5Backend}>
                <Layout
                  isLoading={state.isLoading}
                  facets={state.facets}
                  control={controller}
                  layout={state.layout}
                  theme={theme}
                />
              </DndProvider>
              <ToastContainer />
              <FacetError control={controller} theme={theme}/>
              <Spinner area="mainFetch" />
            </DragContext.Provider>
          </ControlContext.Provider>
        </GlobalStyle>
      </CarbonScopedTokensProvider>
    </ThemeProvider>
  );
};

export default App;
