import React, { useState } from "react";
import { FacetListContainer } from "./Facet";
import styled from "styled-components";
import NavigationBar from "carbon-react/lib/components/navigation-bar";
import VerticalDivider from "carbon-react/lib/components/vertical-divider";
import { ReactComponent as Sage } from '../../../img/sage-logo.svg';
import FacetValue from "./FacetValue";

const TopBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  background-color: ${({ theme }) => theme?.navigation.topBar.backgroundColor};
  color: ${({ theme }) => theme?.navigation.topBar.color};
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

const LogoContainer = styled.div`
  vertical-align: middle;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding-top: 8.5px;
  margin-right: 24px;
`;

const NavigBarContainer = styled.ul`
  display: flex;
  flex: 1;
  margin: 0 auto;
  align-items: center;
  & input {
    width:fit-content;
  }
  & div[role=presentation] {
    border:none;
  }
  & button[type=button] {
    margin-top:12px;
  }
  & div[role=presentation] {
    background-color:inherit;
  }
  padding: 0;
  list-style: none;
`;

const FacetNavigBar = ({ facet, ...props }) => {
  const getValues = () => {
    let rslt = [];
    if (facet.values) rslt = rslt.concat(facet.values);
    return rslt;
  };

  const valueStyle = ({ value, valueIndex }) => {
    let rslt = {
      backgroundColor: value.highLight
        ? props.theme.navigation.topBar.highLightColor
        : "inherited",
    };
    if(valueIndex === 0)
      rslt = { ...rslt, flexBasis: "auto", flexShrink: 0, flexGrow: 0 };
    return rslt;
  };

  return (
    <TopBarContainer>
      <LogoContainer>
      <Sage style={{ width: 'auto', height: '26px' }} />
      </LogoContainer>
      <NavigBarContainer role="navigbar-container">
        {getValues().map((value, valueIndex) => (
          <FacetValue
            key={value.id}
            style={valueStyle({ value, valueIndex })}
            facet={facet}
            value={value}
            {...props}
          />
        ))}
      </NavigBarContainer>
    </TopBarContainer>
  );
};

export default FacetNavigBar;
