import React, { useState, useEffect, useCallback } from "react";
import I18n from "i18n-js";
import Button from "carbon-react/lib/components/button";
import _uniqueId from "lodash/uniqueId";
import styled from "styled-components";
import useTheme from "../hooks/useTheme";
import eventBus from "../../events/eventBus";
import { isEqual } from "lodash";

// May be used as a Facet (ie value===null)
// or as a Facet's value (ie value!=null)
//

const ButtonContainer = styled.div`
  & {
    visibility: ${({ button, disabledState }) =>
      button.hiddenOnDisable && disabledState ? "hidden" : "visible"};
  }
`;

const ButtonContainerUL = styled.div`
  background-color: ${(props) => props.theme.pod.tertiaryBackground};
  padding-bottom: 10px;

  & [data-element="main-text"] {
    text-decoration: underline;
  }
`;

const isEmptyCompanyGroup = (itemToValidate) =>
  itemToValidate?.societies?.length === 0 && !itemToValidate.isAllSocieties;

const shouldValidateWithKeyWithoutFocus = (facet) =>
  facet?.button?.validateWithKeyWithoutFocus;

const getButtonActionWithItemToValidate = ({
  itemToValidate,
  buttonAction,
}) => {
  if (!itemToValidate) return buttonAction;
  return { name: buttonAction, param: itemToValidate };
};

const getDisabledState = ({
  button,
  itemToValidate,
  initialItemToValidate,
  disabledWhenItemToValidateNotChanged,
}) => {
  return !!(
    (button.disableWhenNoItem && !itemToValidate) ||
    button.disabled ||
    (isEmptyCompanyGroup(itemToValidate) &&
      button.isCompanyGroupSelectButton) ||
    (disabledWhenItemToValidateNotChanged &&
      isEqual(itemToValidate, initialItemToValidate))
  );
}


const getOnUploadClick = (uploadId) => (e) => {
  e.preventDefault();

  let inputElt = document.getElementById(uploadId);
  if (inputElt) {
    inputElt.click();
  }
};

const getOnButtonClick =
  ({ getButton, control, itemToValidate, facet, value }) =>
  (e, actionValue) => {
    if (e) e.preventDefault();
    if (e) e.stopPropagation();

    let button = getButton();

    if (button.callBack) {
      // when the button as a callBack function
      button.callBack();
      return;
    }

    if (button.call) {
      control.callAction(button.call.name, button.call);
      return;
    }

    if (button.action) {
      // when the button is used as an action
      const buttonAction = getButtonActionWithItemToValidate({
        itemToValidate,
        buttonAction: button.action,
      });

      control.doFacetAction(facet, value, buttonAction);
    } else {
      // when the button is used as a facet
      // selection of the first facet's value
      let setVal = facet.values[0];
      if (value) setVal = value;
      control.setFacetValue(facet, setVal);
    }
  };

const getIsUpload = (getButton) => () => {
  return getButton().upload ? true : false;
};

const FacetButton = ({ facet, control, value }) => {
  const {
    isMultiItemValidation,
    initialItemToValidate,
    disabledWhenItemToValidateNotChanged,
  } = value || {};
  const theme = useTheme();

  const [uploadId] = useState(_uniqueId("upload-"));
  const [uploadFile] = useState(null);
  const [itemToValidate, setItemToValidate] = useState(initialItemToValidate);

  useEffect(() => {
    setItemToValidate(initialItemToValidate);
  }, [initialItemToValidate]);

  const getButton = () => {
    let btn = {
      title: facet.title,
      style: "tool",
      icon: "",
      disabled: false,
      autoFocus: false,
      action: null,
      callBack: null,
    };
    if (value && value.button) btn = value.button;
    if (facet && facet.button) btn = facet.button;

    if (btn.style && btn.style === "commit") {
      //
      // predefined button style
      //
      btn.type = "primary";
      btn.size = "medium";
      btn.title =
        btn.title ||
        btn.text ||
        I18n.t("button.commit", { defaultValue: "Save" });
    }

    if (btn.style && btn.style === "apply") {
      //
      // predefined button style
      //
      btn.type = "primary";
      btn.size = "medium";
      btn.title =
        btn.title ||
        btn.text ||
        I18n.t("button.apply", { defaultValue: "Apply" });
    }

    if (btn.style && btn.style === "cancel") {
      //
      // predefined button style
      //
      btn.type = "secondary";
      btn.size = "medium";
      btn.title =
        btn.title ||
        btn.text ||
        I18n.t("button.cancel", { defaultValue: "Cancel" });
    }

    if (btn.style && btn.style === "primary") {
      //
      // predefined button style
      //
      btn.type = "primary";
      btn.size = "medium";
    }

    if (btn.style && btn.style === "secondary") {
      //
      // predefined button style
      //
      btn.type = "secondary";
      btn.size = "medium";
    }

    if (btn.style && btn.style === "tertiary") {
      //
      // predefined button style
      //
      btn.type = "tertiary";
      btn.size = "medium";
    }

    if (btn.style && btn.style === "tool") {
      //
      // predefined button style
      //
      btn.type = "tertiary";
      btn.size = "medium";
    }

    return btn;
  };

  const getStyle = () => {
    const btn = getButton();
    let r = {};
    if (btn.autoWidth) r.width = "100%";
    return r;
  };

  const onButtonClick = getOnButtonClick({
    getButton,
    control,
    itemToValidate,
    facet,
    value,
  });
  const onUploadClick = getOnUploadClick(uploadId);

  const uploadChange = (e) => {
    //
    const file = e.target.files[0];
    const upd = getButton().upload;
    if (!upd) return;
    //
    const data = new FormData();
    data.append("file", file);

    let url = "../../server/sdata/rpc.l1000/html/formpage.upload";
    if (upd.updloadUrl) url = upd.uploadUrl;

    // used raw fetch api here because it's not sdata based
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((result) => {
        onButtonClick(null, file.name);
      })
      .catch((error) => {
        //console.error('Error:', error);
      });
  };

  const uploadAccept = () => {
    let btn = getButton();
    if (btn.upload && btn.upload.accept) return btn.upload.accept;
    return "*/*";
  };

  const isUpload = getIsUpload(getButton);

  const UnderlinedButton = ({ children, ...props }) => {
    return (
      <ButtonContainerUL>
        <Button {...props}>{children}</Button>
      </ButtonContainerUL>
    );
  };

  const ButtonToDisplay = value?.button?.underlined ? UnderlinedButton : Button;

  useEffect(() => {
    const memoizedCallback = (dataSelection) => {
      if (dataSelection.id === value.groupId) {
        if (isMultiItemValidation) {
          setItemToValidate((previousItem) => ({
            ...(previousItem || {}),
            ...dataSelection.item,
          }));
        } else {
          setItemToValidate(dataSelection.item);
        }
      }
    };

    eventBus.on("SelectDone", memoizedCallback);

    return () => {
      eventBus.remove("SelectDone", memoizedCallback);
    };
  }, [value, isMultiItemValidation]);

  const validateWithKeyWithoutFocus = shouldValidateWithKeyWithoutFocus(facet);

  useEffect(() => {
    if (validateWithKeyWithoutFocus) {
      const memoizedCallback = (e) => {
        setTimeout(() => {
          if (e?.key === "Enter") {
            if (isUpload()) {
              onUploadClick(e);
            } else {
              onButtonClick(e);
            }
          }
        }, 100);
      };
      document.addEventListener("keydown", memoizedCallback);
      return () => {
        document.removeEventListener("keydown", memoizedCallback);
      };
    }
  }, [validateWithKeyWithoutFocus, isUpload, onButtonClick, onUploadClick]);

  return (
    <ButtonContainer
      button={getButton()}
      disabledState={getDisabledState({
        button: getButton(),
        itemToValidate,
        initialItemToValidate,
        disabledWhenItemToValidateNotChanged,
      })}
    >
      {isUpload() ? (
        <span>
          <input
            type="file"
            id={uploadId}
            accept={uploadAccept()}
            style={{ display: "none" }}
            onChange={uploadChange}
          />
          <ButtonToDisplay
            style={getStyle()}
            buttonType={getButton().type}
            autoFocus={getButton().autoFocus}
            size={getButton().size}
            iconType={getButton().icon}
            destructive={getButton().destructive}
            disabled={getDisabledState({
              button: getButton(),
              itemToValidate,
              initialItemToValidate,
              disabledWhenItemToValidateNotChanged,
            })}
            onClick={onUploadClick}
          >
            {getButton().title}
          </ButtonToDisplay>
        </span>
      ) : (
        <ButtonToDisplay
          style={getStyle()}
          buttonType={getButton().type}
          autoFocus={getButton().autoFocus}
          size={getButton().size}
          iconType={getButton().icon}
          destructive={getButton().destructive}
          disabled={getDisabledState({
            button: getButton(),
            itemToValidate,
            initialItemToValidate,
            disabledWhenItemToValidateNotChanged,
          })}
          onClick={onButtonClick}
        >
          {getButton().title}
        </ButtonToDisplay>
      )}
    </ButtonContainer>
  );
};

export default FacetButton;
