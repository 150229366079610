import React from "react";
import styled from "styled-components";

// Put here global style which will be applied on all components inside the layout, with theme accessible
const GlobalStyle = styled.div`
  .Toastify__toast-theme--colored.Toastify__toast--info {
    border: 1px solid ${({ theme }) => theme?.colors?.info};
    background-color: ${({ theme }) => theme?.colors?.white};
    color: ${({ theme }) => theme?.colors?.messages?.normal};
    border-radius: 0;
    padding: 0;
    min-height: 73px;
    height: 73px;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
    background: ${({ theme }) => theme?.colors?.info};
  }

  .Toastify__toast-theme--colored.Toastify__toast--warning {
    border: 1px solid ${({ theme }) => theme?.colors?.warning};
    background-color: ${({ theme }) => theme?.colors?.white};
    color: ${({ theme }) => theme?.colors?.messages?.normal};
    border-radius: 0;
    padding: 0;
    min-height: 73px;
    height: 73px;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
    background: ${({ theme }) => theme?.colors?.warning};
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    border: 1px solid ${({ theme }) => theme?.colors?.error};
    background-color: ${({ theme }) => theme?.colors?.white};
    color: ${({ theme }) => theme?.colors?.messages?.normal};
    border-radius: 0;
    padding: 0;
    min-height: 73px;
    height: 73px;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    background: ${({ theme }) => theme?.colors?.error};
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    border: 1px solid ${({ theme }) => theme?.colors?.success};
    background-color: ${({ theme }) => theme?.colors?.white};
    color: ${({ theme }) => theme?.colors?.messages?.normal};
    border-radius: 0;
    padding: 0;
    min-height: 73px;
    height: 73px;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
    background: ${({ theme }) => theme?.colors?.success};
  }

  .Toastify__toast-body {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    height: 73px;
  }

  .Toastify__close-button.Toastify__close-button--colored {
    color: ${({ theme }) => theme?.colors?.messages?.normal};
    height: 73px;
    padding-right: 8px;
  }

  div[data-element="popover-container-content"] {
    z-index: auto;
  }

  button[data-element="select-tab"][aria-selected="true"] {
    z-index: auto;
  }

  div[data-element="tab-selected-indicator"] {
    z-index: auto;
  }

  div[data-component="switch"] span::before {
    bottom: inherit;
    z-index: inherit;
  }

  div[data-component="switch"] span {
    align-items: center;
    z-index: inherit;
  }

  div[data-component="switch"] span > div {
    margin: auto;
  }

  div[data-component="switch"] span > div[type="off"] {
    margin-right: 4px;
    visibility: hidden;
  }

  div[data-component="switch"] span > div[type="on"] {
    margin-left: 4px;
    visibility: hidden;
  }
  div[data-component="search"] {
    height: 40px;
    background-color: ${({ theme }) => theme?.colors?.white};
  }
  div[data-component="search"] > div:first-child {
    z-index: auto;
  }
`;

export default GlobalStyle;
