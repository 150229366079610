const en = {
  businessProcess: {
    addNewStep: "Add a new step",
    editMode: "Edit mode",
    edit: "Edit",
    deleteSubStep: "Remove sub-step",
    duplicate: "Duplicate",
    cancel: "Cancel",
    cancelChanges: "Undo changes",
    save: "Save",
    useThisZoneToAddShortcuts: "Use this box to add shortcuts",
    addANew: "Add a new",
    subStep: "sub-step",
    businessProcess: "Activity table",
    dragAndDropExplanation:
      "Add a step and sub-step and then drag and drop a menu entry to add an activity.",
    validationExplanation:
      "Replication allows you to save your changes without altering the initial activity table.",
    title: "Title",
    link: "Link",
    information: "Information",
    steps: "Steps",
    setupBusinessProcess: "Set up the activity table",
    none: "None",
    close: "Close",
    iconList: "Icon list",
  },
  noResult: "No result",
  button: {
    commit: "save",
    cancel: "Cancel",
    apply: "Apply",
  },
  action: {
    add: "Create",
    modify: "Modify",
    delete: "Delete",
  },
  grid: {
    noResult: "No result",
  },
  confirm: {
    title: "Confirmation",
    areYouSure: "Are you sure?",
    no: "No",
    yes: "Yes",
  },
  dialog: {
    placeHolder: "Configure...",
    cancel: "Cancel",
    apply: "Apply",
  },
  codeEditor: {
    placeHolder: "Edit...",
    compile: "Compile",
    tooltipMessage: "The script has been modified",
  },
  emailEditor: {
    placeHolder: "Edit...",
    dialogTitle: "EMail",
  },
  iconParameters: {
    dialogTitle: "Settings",
    editTitle: "Settings",
    placeHolder: "Configure...",
    headerName: "Name",
    headerType: "Type",
    headerValue: "Value",
    tooltipMessage: "Settings have been configured",
  },
  iconForm: {
    placeHolder: "Configure...",
  },
  iconButton: {
    placeHolder: "Configure...",
    isEdited: "Modified element",
  },
  iconTreeview: {
    placeHolder: "Configure...",
    dialogTitle: "Select",
  },
  navigleft: {
    minimize: "Minimiser",
    closeAll: "Close all",
    loading: "Loading",
    noContent: "No content",
  },
  menuEditor: {
    entry: {
      title: "New",
      caption: "New",
      action: {
        add: "Add",
        remove: "Remove",
        separator: "Separator",
      },
    },
    search: {
      placeholder: "Search...",
      previous: "Prev.",
      next: "Next",
    },
    collapseAll: "Collapse all",
    expandAll: "Expand all",
    addRootEntry: "New entry",
    filter: "Objects",
    sort: {
      label: "Sorting",
      orderByDesc: "Order down",
      orderByAsc: "Ascending order",
      order: "Order",
    },
  },
  card: {
    noContent: "This item has no content",
  },
};

const moment_en = {
  months:
    "January_February_March_April_May_June_July_August_September_October_November_December".split(
      "_"
    ),
  monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"),
  weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split(
    "_"
  ),
  weekdaysShort: "Sun_Mon_Tue_Wed_Thu_Fri_Sat".split("_"),
  weekdaysMin: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd, D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    nextWeek: "dddd [at] LT",
    lastDay: "[Yesterday at] LT",
    lastWeek: "[Last] dddd [at] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
  ordinal: function (number) {
    var b = number % 10,
      output =
        ~~((number % 100) / 10) === 1
          ? "th"
          : b === 1
          ? "st"
          : b === 2
          ? "nd"
          : b === 3
          ? "rd"
          : "th";
    return number + output;
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
};

export { en, moment_en };
