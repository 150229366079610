import React from 'react'
import FacetList from './FacetList'
import FacetText from './FacetText'
import FacetCardList from "./FacetCardList";
import FacetDatepicker from './FacetDatepicker'
import FacetDaterange from './FacetDaterange'
import FacetDraggableList from './FacetDraggableList'
import FacetSelect from './FacetSelect'
import FacetSubmit from './FacetSubmit'
import FacetPagination from './FacetPagination'
import FacetResultGrid from './FacetResultGrid/FacetResultGrid'
import FacetGrid from './FacetGrid'
import FacetToolbox from './FacetToolbox'
import FacetBpEditorWS from './FacetBpEditorWS'
import FacetButton from './FacetButton'
import FacetTable from './FacetTable'
import FacetDialog from './FacetDialog'
import FacetSearch from './FacetSearch'
import FacetTreeview from './FacetTreeview'
import FacetMenu from './menu/FacetMenu';
import FacetMenuEditor from './menu/FacetMenuEditor';
import FacetSearchMenuEditor from './menu/FacetSearchMenuEditor';
import FacetTabs from './FacetTabs';
import FacetRedirect from './FacetRedirect'
import FacetNavigationBar from './FacetNavigBar'
import FacetNavigTabs from './NavigTab/FacetNavigTabs'
import FacetNavigLeft from './FacetNavigLeft'
import FacetWebsocket from './FacetWebsocket'
import FacetOnTick from './FacetOnTick'
import FacetError from './FacetError'
import NavTabContentPanel from './NavigTab/TabContentPanel'


const FacetSwitch = ( {facet, control, type, ...props} ) => {

  const getValue = () => {
      if (facet.values) return (facet.values[0]);
      if (facet.value) return (facet.value);
      return null;
    }

    return (
        <React.Fragment>
        {(() => {
            switch (type) {
              case 'oneOfMany':
                return <FacetList facet={facet} control={control} {...props}/>;
              case 'severalOfMany':
                return <FacetList facet={facet} control={control} {...props}/>;
              case 'checkList':
                return <FacetList facet={facet} control={control} {...props}/>;
              case 'list':
                return <FacetList facet={facet} control={control} {...props}/>;
              case 'cardList':
                return <FacetCardList facet={facet} control={control} {...props}/>;
              case 'draggableList':
                return <FacetDraggableList facet={facet} control={control} {...props}/>;
              case 'search':
                return <FacetSearch facet={facet} control={control} {...props}/>;
              case 'text':
                return <FacetText facet={facet} control={control} {...props}/>;
              case 'date' :
                return <FacetDatepicker facet={facet} control={control} {...props}/>;
              case 'dateRange' :
                return <FacetDaterange facet={facet} control={control} {...props}/>;
              case 'select' :
              case 'fromList' :
                return <FacetSelect facet={facet} control={control} {...props}/>;
              case 'button' :
                return <FacetButton facet={facet} control={control} {...props}/>;
              case 'submit' :
                return <FacetSubmit facet={facet} control={control} {...props}/>;
              case 'grid' :
                return <FacetGrid facet={facet} control={control} {...props}/>;
              case 'pagination' :
                return <FacetPagination facet={facet} control={control} {...props}/>;
              case 'resultGrid' :
                return <FacetResultGrid facet={facet} control={control} {...props}/>;
              case 'toolbox' :
                return <FacetToolbox facet={facet} control={control} {...props}/>;
              case 'bpeditor' :
                return <FacetBpEditorWS key={control.roundTrip} facet={facet} control={control} {...props} />;
              case 'table':
                return <FacetTable facet={facet} control={control} value={getValue()} {...props}/>;
              case 'treeview':
                return <FacetTreeview facet={facet} control={control} value={getValue()} {...props} />;
              case 'dialog':
                return <FacetDialog facet={facet} control={control} {...props} />;
              case 'menu' :
                return <FacetMenu facet={facet} control={control} {...props} />;
              case 'menueditor':
                return <FacetMenuEditor facet={facet} control={control} {...props} />;
              case 'searchmenueditor':
                return <FacetSearchMenuEditor facet={facet} control={control} {...props} />;
              case 'tabs':
                return <FacetTabs facet={facet} control={control} {...props} />;
              case 'redirect':
                return <FacetRedirect facet={facet} control={control} />;
              case 'navigbar':
                return <FacetNavigationBar facet={facet} control={control} {...props}/>
              case 'navigtabs':
                return <FacetNavigTabs facet={facet} control={control} {...props}/>
              case 'navigleft':
                  return <FacetNavigLeft facet={facet} control={control} {...props}/>;
              case 'websocket':
                return <FacetWebsocket facet={facet} control={control} {...props}/>;
              case 'ontick':
                return <FacetOnTick facet={facet} control={control} {...props}/>;
              case 'onerror':
                return <FacetError facet={facet} control={control} {...props}/>;
              case 'contentPanel':
                return <NavTabContentPanel facet={facet} control={control} {...props}></NavTabContentPanel> 
              default:
                console.log("unknow facet",type)
                return null;
            }
          })()}
        </React.Fragment>
    )
}

export default FacetSwitch
