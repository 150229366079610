import React, { useState, useRef } from "react";
import styled from "styled-components";
import PopoverContainer, {
  PopoverContainerWithChildren,
} from "./PopoverContainer";
import LayoutContent from "../../layout/LayoutContent";
import Icon from "carbon-react/lib/components/icon";

const IconContainer = styled.div`
  display: flex;
  align-items: baseline;
  ${({ isProfile }) =>
    isProfile
      ? `&:focus {
          outline: 3px solid var(--colorsSemanticFocus500);
        }`
      : ""}
`;
const PopoverDropdown = styled.div`
  & [data-component="icon"] {
    color: ${({ theme, isProfile }) =>
      isProfile ? theme.colors.white : "inherit"};
  }
`;

const getHandleKeyDown =
  ({ setOpen, open, isProfile, refIconContainer }) =>
  (e) => {
    const activeElement = document.activeElement;
    switch (e.key) {
      case "Enter":
      case "ArrowDown":
        if (activeElement?.isEqualNode(refIconContainer?.current)) {
          if (!open) {
            e.preventDefault?.();
            e.stopPropagation?.();
            setOpen(true);
          }
        }
        //case already open, should focus next entrie, for profile
        if (open && isProfile) {
          const menuItems = Array.from(
            refIconContainer?.current?.querySelectorAll(".frp1000-menu-item")
          );
          const indexActiveElement = menuItems.findIndex((menuItem) =>
            activeElement?.isEqualNode(menuItem)
          );
          if(indexActiveElement >= 0) {
            const nextMenuItem = menuItems[indexActiveElement+1];
            if(nextMenuItem) {
              nextMenuItem.focus();
            }
          }
        }
        break;
      case "Escape":
      case "ArrowUp":
        if (refIconContainer?.current?.contains(activeElement)) {
          if(open && !isProfile) {
            e.preventDefault?.();
            e.stopPropagation?.();
            setOpen(false);
          }
        }
        if (open && isProfile) {
          const menuItems = Array.from(
            refIconContainer?.current?.querySelectorAll(".frp1000-menu-item")
          );
          const indexActiveElement = menuItems.findIndex((menuItem) =>
            activeElement?.isEqualNode(menuItem)
          );
          if(indexActiveElement >= 0) {
            const prevMenuItem = menuItems[indexActiveElement-1];
            if(prevMenuItem) {
              prevMenuItem.focus();
            }
            else {
              setOpen(false);
              refIconContainer?.current?.focus();
            }
          }
          else {
            setOpen(false);
            refIconContainer?.current?.focus();
          }
        }
        break;
      default:
    }
  };

const IconPopover = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const isProfile = props?.isProfile;
  const onOpen = (e) => {
    if (e) e.preventDefault();
    setOpen(!open);
  };
  const onClose = (e) => {
    if (e) e.preventDefault();
    setOpen(false);
  };
  const refButtonAction = useRef();
  const refIconContainer = useRef();

  const getIconLayout = () => {
    if (props.iconLayout) return props.iconLayout;
    if (props.value && props.value.iconContent)
      return props.value.iconContent.layout;
    return null;
  };
  const getIconContent = () => {
    if (props.iconContent) return props.iconContent;
    if (props.value && props.value.iconContent)
      return props.value.iconContent.facets;
    return [];
  };

  const getPopoverLayout = () => {
    if (props.popoverLayout) return props.popoverLayout;
    if (props.value && props.value.popover) return props.value.popover.layout;
    return null;
  };
  const getPopoverContent = () => {
    if (props.value && props.value.popover) return props.value.popover.facets;
    return [];
  };

  const PopoverContainerToDisplay = props.isProfile
    ? PopoverContainerWithChildren
    : PopoverContainer;

  return (
    <IconContainer
      isProfile={isProfile}
      tabIndex="0"
      ref={refIconContainer}
      onKeyDown={getHandleKeyDown({
        setOpen,
        open,
        isProfile,
        refIconContainer,
      })}
    >
      {props.renderIcon ? (
        props.renderIcon({ ...props })
      ) : (
        <LayoutContent
          setOpen={setOpen}
          layout={getIconLayout()}
          content={getIconContent()}
          {...props}
        />
      )}
      <PopoverContainerToDisplay
        open={open}
        position="left"
        onOpen={onOpen}
        onClose={onClose}
        renderOpenComponent={({ isOpen, ref, ...props }) => (
          <PopoverDropdown
            isProfile={isProfile}
            ref={refButtonAction}
            role="icon-dropdown"
            onClick={onOpen}
          >
            <Icon type={open ? "caret_up" : "dropdown"} />
          </PopoverDropdown>
        )}
        // eslint-disable-next-line no-empty-pattern
        renderCloseComponent={({}) => null}
      >
        {props.renderContent ? (
          props.renderContent({
            open,
            setOpen,
            onClose,
            refButtonAction,
            layout: getPopoverLayout(),
            content: getPopoverContent(),
            ...props,
          })
        ) : (
          <LayoutContent
            refButtonAction={refButtonAction}
            setOpen={setOpen}
            open={open}
            onClose={onClose}
            layout={getPopoverLayout()}
            content={getPopoverContent()}
            {...props}
          />
        )}
      </PopoverContainerToDisplay>
    </IconContainer>
  );
};

export { IconPopover as default };
