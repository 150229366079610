import React, { useState } from "react";
import styled from "styled-components";
import Button from "carbon-react/lib/components/button";
import Dialog from "carbon-react/lib/components/dialog";
import Typography from "carbon-react/lib/components/typography";
import useTheme from "../../hooks/useTheme";
import I18n from "i18n-js";
import FacetDialog from "../FacetDialog";
import {
  getClickHandler,
  getDialogValue,
  getActionType,
} from "../../../common/actionHelpers";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";

const ButtonActionContainer = styled.div`
  width: 100%;
  & > button {
    font-weight: ${({ theme }) => theme?.text?.weightAction};
  }
`;

const ConfirmationDialogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ConfirmationButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 32px;
  margin-left: 32px;
  margin-bottom: 24px;
`;

const getOnClick = ({
  action,
  facet,
  value,
  id,
  onAction,
  setIsOpen,
  isOpen,
  control,
}) => {
  return getClickHandler({
    action,
    control,
    facet,
    value,
    id,
    onAction,
    setIsOpen,
    isOpen,
  });
};

const getOnDone = (setIsOpen) => () => {
  setIsOpen(false);
};

const ButtonAction = ({
  facet,
  value,
  control,
  action,
  id,
  onAction,
  buttonProps,
  afterActionCallback,
}) => {
  const { title, name, param, download, kind, confirmationDialog } =
    action || {};
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const theme = useTheme();
  return (
    <ButtonActionContainer role="button-action-container">
      <Button
        role="button-action"
        buttonType="secondary"
        size="small"
        destructive={kind === "error"}
        onClick={
          confirmationDialog
            ? () => {
                setConfirmationDialogOpen(true);
              }
            : (e) => {
                const clickCallback = getOnClick({
                  facet,
                  value,
                  action,
                  id,
                  onAction,
                  setIsOpen,
                  isOpen,
                  control,
                });
                clickCallback(e);
                afterActionCallback?.();
              }
        }
        {...(buttonProps || {})}
      >
        {title}
      </Button>
      <Dialog
        open={confirmationDialogOpen}
        size="extra-small"
        title={I18n.t("confirm.title")}
        onCancel={() => {
          setConfirmationDialogOpen(false);
        }}
      >
        {confirmationDialogOpen ? (
          <ConfirmationDialogContentContainer>
            <Typography
              color={theme?.help?.color}
              mr="32px"
              ml="32px"
              fontSize="14px"
              fontWeight="400"
              lineHeight="21px"
            >
              {I18n.t("confirm.areYouSure")}
            </Typography>
            <ConfirmationButtonsContainer>
              <TertiaryButtonWrapper>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setConfirmationDialogOpen(false);
                  }}
                  buttonType="tertiary"
                >
                  {I18n.t("dialog.cancel")}
                </Button>
              </TertiaryButtonWrapper>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const clickCallback = getOnClick({
                    facet,
                    value,
                    action,
                    id,
                    onAction,
                    setIsOpen,
                    isOpen,
                    control,
                  });
                  clickCallback(e);
                  afterActionCallback?.();
                  setConfirmationDialogOpen(false);
                }}
                buttonType="primary"
                destructive
              >
                {I18n.t("dialog.apply")}
              </Button>
            </ConfirmationButtonsContainer>
          </ConfirmationDialogContentContainer>
        ) : null}
      </Dialog>
      {getActionType({ action }) === "dialog" ? (
        <FacetDialog
          control={control}
          value={getDialogValue({ id, action })}
          isOpen={isOpen}
          onCancel={setIsOpen}
          onDone={getOnDone(setIsOpen)}
        />
      ) : null}
    </ButtonActionContainer>
  );
};

export default ButtonAction;
