import React, { useState } from "react";
import styled from "styled-components";
import {
  Select,
  FilterableSelect,
  Option,
} from "carbon-react/lib/components/select";
import Icon from "carbon-react/lib/components/icon";
import IconAction from "./IconAction";

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ hasIcon }) =>
    hasIcon &&
    `
      grid-template-columns: auto 16px;
    `}
  & input {
    min-width: var(--inputMinWidth);
  }
  & span[data-element="select-text"] {
    min-width: var(--inputMinWidth);
  }
  & div[role="presentation"] {
    flex: auto;
    position: inherit !important;
    width: inherit !important;
    display: flex !important;
    flex-direction: row !important;
  }
`;

const InputAndRightIconsContainer = styled.div`
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
`;

const InputSimpleSelect = ({ facet, control, value, ...props }) => {
  const getValues = () => {
    let rslt = [];
    if ((typeof value.allowEmpty==="undefined") || value.allowEmpty) rslt.push({ id: "", title: "" });
    if (value && value.values) return rslt.concat(value.values);
    return rslt;
  };

  const onValueChange = (event) => {
    control.setFacetValue(facet, value, event.target.value);
  };
  const getLabel = () => {
    if (value.title) return value.title;
    return null;
  };
  const getRequired = () => {
    if (value.required) return true;
    return false;
  };
  const getItemText = (item) => {
    if (item && typeof item.title !== "undefined") return item.title;
    if (item && typeof item.value !== "undefined") return item.value;
    if (item && typeof item.id !== "undefined") return item.id;
    return "";
  };
  const getItemValue = (item) => {
    //tip : doesn't like integer value
    if (item && typeof item.value !== "undefined") return item.value;
    if (item && typeof item.id !== "undefined") return item.id;
    if (item && typeof item.title !== "undefined") return item.title;
    return null;
  };
  const getDefaultValue = () => {
    if (value && value.value) return value.value;
    return getItemValue(getValues().find((item) => item.selected));
  };

  const useFilterable = () => {
    if (value && value.values && value.values.length > 10) return true;
    else return false;
  };

  return useFilterable() ? (
    <FilterableSelect
      role="input-filterable-select"
      label={getLabel()}
      required={getRequired()}
      defaultValue={getDefaultValue()}
      onChange={onValueChange}
      {...props}
    >
      {getValues().map((item) => (
        <Option
          key={item.id}
          text={getItemText(item)}
          value={getItemValue(item)}
        />
      ))}
    </FilterableSelect>
  ) : (
    <Select
      role="input-select"
      label={getLabel()}
      required={getRequired()}
      defaultValue={getDefaultValue()}
      onChange={onValueChange}
      {...props}
    >
      {getValues().map((item) => (
        <Option
          key={item.id}
          text={getItemText(item)}
          value={getItemValue(item)}
        />
      ))}
    </Select>
  );
};

const InputSelect = ({ facet, control, value, ...props }) => {
  const getLeftIcon = () => {
    if (value.leftIcon) return value.leftIcon;
    return null;
  };
  const getRightIcon = () => {
    if (value.rightIcon) return value.rightIcon;
    return null;
  };
  const hasRightIcon = () => {
    if (value.action) return true;
    return false;
  };
  const getContainerStyle = () => {
    const mw = value.minWidth?value.minWidth:0;
    return {
      "--inputMinWidth": mw + "px",
    };
  };

  return (
    <SelectContainer role="select-container" style={getContainerStyle()} hasIcon={hasRightIcon()}>
      {getLeftIcon() ? <Icon type={getLeftIcon()}></Icon> : null}
      <InputAndRightIconsContainer role="select-icon-container">
        <InputSimpleSelect
          facet={facet}
          control={control}
          value={value}
          {...props}
        />
        {getRightIcon() ? <Icon type={getRightIcon()}></Icon> : null}
        {hasRightIcon() ? (
          <IconAction
            facet={facet}
            control={control}
            value={value}
            {...props}
          />
        ) : (
          null
        )}
      </InputAndRightIconsContainer>
    </SelectContainer>
  );
};

export default InputSelect;
