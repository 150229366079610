import React from "react";
import { default as CLink}  from "carbon-react/lib/components/link";
import { getClickHandler } from "../../common/actionHelpers";

const Link = ({ facet, value, control }) => {

  const getTitle= () => {
      if (value && value.title) return value.title;
      if (value && value.value) return value.value;
      return ""; 
  }
  const getIcon = ()=> {
      if (value && value.icon) return value.icon;
      return null;
  }
  const getAction = () => {
      if (value.action) return value.action;
      return {noop:{}};
  }

  return (
    <CLink icon={getIcon()} onClick={getClickHandler({action:getAction(),control})}>{getTitle()}</CLink>
  );
};

export default Link;
