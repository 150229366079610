import React from 'react';
import IconAction from './IconAction';
import styled from 'styled-components';

const BigIconActionStyle = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleStyle = styled.div`
  font-size: x-small;
  line-height: normal;
  margin-top: 5px;
  text-align: center;
`;

const BigIconAction = ({ ...props }) => {
  const { value: { title } } = props;

  const bigIconTheme = props.theme?.icon?.bigIcon;

  return (
    <BigIconActionStyle>
      <IconAction 
        bigIcon

        fontSize={bigIconTheme?.fontSize}
        bgSize={bigIconTheme?.bgSize}
        bg={bigIconTheme?.bg}
        color={bigIconTheme?.color}

        {...props}
       />
      <TitleStyle>
        {title}
      </TitleStyle>
    </BigIconActionStyle>
  )
}

export default BigIconAction;
