import React, { useState } from 'react';
import I18n from 'i18n-js';
import styled from 'styled-components';
import Textbox from 'carbon-react/lib/components/textbox';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import Badge from 'carbon-react/lib/components/badge';

const InputButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div:first-child {
    flex-grow: 1;
  }

  & button[data-component='badge'] {
    z-index: 1;
  }
`;

const InputButton = ({
  facet,
  control,
  value,
  onAction,
  placeHolder,
  subTitle,
  onKeyDown,
  rightIcon,
  onRightIconClick,
  tooltipMessage,
}) => {
  const getIcon = () => {
    if (value.icon) return value.icon;
    return 'ellipsis_horizontal';
  };
  const getLabel = () => {
    if (value.title) return value.title;
    return null;
  };
  const getValue = () => {
    if (value.textValue) return value.textValue;
    if (value.subTitle) return value.subTitle;
    return subTitle;
  };
  const getPlaceHolder = () => {
    if (value.placeHolder) return value.placeHolder;
    if (placeHolder) return placeHolder;
    return I18n.t('iconButton.placeHolder', { defaultValue: 'Configure...' });
  };
  const getChildren = () => {
    if (rightIcon) return <IconButton onAction={onRightIconClick}><Icon type={rightIcon} /></IconButton>;
    else return null;
  };
  /**
   * Display an `edited` Icon indicating a change
   * Display an additionnal Badge component for array values
   * @returns Badge component with edited Icon
   */
  const getBadge = () => {
    if (value.showBadge) {
      let counter;
      try {
        counter = JSON.parse(value.value);
      } catch (e) {
        counter = value.value;
      }
      const showEdited =
        (Array.isArray(counter) && counter.length > 0) ||
        (!Array.isArray(counter) && counter !== '');
      return (
        showEdited && (
          <Badge counter={Array.isArray(counter) ? counter.length : 0}>
            <Icon
              type='edited'
              tooltipMessage={tooltipMessage}
              ariaLabel={I18n.t('iconButton.isEdited', {
                defaultValue: 'Element edited',
              })}
            />
          </Badge>
        )
      );
    }
  };

  return (
    <InputButtonStyle>
      <Textbox
        label={getLabel()}
        placeholder={getPlaceHolder()}
        value={getValue()}
        inputIcon={getIcon()}
        children={getChildren()}
        iconOnClick={onAction}
        onKeyDown={onKeyDown}
      />
      {getBadge()}
    </InputButtonStyle>
  );
};

export default InputButton;
