import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import IconButton from "carbon-react/lib/components/icon-button";
import Icon from "carbon-react/lib/components/icon";
import Button from "carbon-react/lib/components/button";
import { Card } from "carbon-react/lib/components/card";
import {
  Menu,
  MenuItem,
  SubmenuBlock,
  MenuDivider,
} from "carbon-react/lib/components/menu";
import LinkBoxTitle from "./LinkBoxTitle";
import { useDrop, useDrag } from "react-dnd";
import Link from "carbon-react/lib/components/link";
import Typography from "carbon-react/lib/components/typography";
import Textbox from "carbon-react/lib/components/textbox";
import {
  useControlContext,
  useBusinessProcessContext,
  useSliderConfContext,
} from "./FacetBusinessProcess";
import Ellipsis from "../../components/Ellipsis";
import {
  saveBusinessProcessLocally,
  areLinksEquals,
} from "./BusinessProcessLinks";
import { cloneDeep, isString } from "lodash";
import useTheme from "../../hooks/useTheme";
import eventBus from "../../../events/eventBus";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";
import IconSelection from "../../components/IconSelection";
import I18n from "i18n-js";
import LinkEditModePopover from "./LinkEditModePopover";

const BoxCardContainer = styled.div`
  & div[data-component="card"] {
    ${({ backgroundColor }) =>
      `background-color: ${backgroundColor ? backgroundColor : "inherit"};`}
    ${({ canEdit, backgroundColor }) =>
      !canEdit && !backgroundColor ? "box-shadow: none;" : ""}
    padding-top: 8px;
    padding-bottom: 12px;
  }
`;

const TitleAndEditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const IconAndTitleContainer = styled.div`
  display: flex;
`;

const EditAndDeleteIconsContainer = styled.div`
  display: flex;
`;

const LinkRowContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ isDraggedItem, isDragging }) => {
    return isDraggedItem && isDragging ? "0" : "1";
  }};
  background-color: ${({ isDraggedItem, theme }) =>
    isDraggedItem ? theme?.colors?.white : "inherit"};
  & * {
    opacity: ${({ isDraggedItemFromMenu }) =>
      isDraggedItemFromMenu ? "0" : "1"};
  }
`;

const TitleAndActionsIconsContainer = styled.div`
  & button {
    text-decoration: none;
  }
  & button:hover {
    text-decoration: underline;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleAndActionsIconsContainerEditMode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > div:first-child {
    width: 100%;
    margin-right: 0px !important;
  }
`;

const TitlesContainer = styled.div`
  min-height: 24px;
`;

const TitleContainer = styled.div`
  & div {
    text-align: start;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const DragIconContainer = styled.span`
  cursor: grab;
`;

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const getOnClickLink =
  ({ control, url, title }) =>
  () => {
    control.callAction("openLink", { item: { url, title } });
  };

const startOverCallback = ({
  setOverFlag,
  localTitles,
  setLocalTitles,
  draggedItem,
}) => {
  if (localTitles?.length === 0) {
    setLocalTitles([draggedItem]);
  }
  setOverFlag(true);
};

const getMoveLocalItem =
  ({ setLocalTitles, localTitles }) =>
  ({ item, hoveredItem }) => {
    let titlesToReturn;
    const existingItemIndex = localTitles.findIndex((currentItem) =>
      areLinksEquals(item, currentItem)
    );
    const hoveredItemIndex = localTitles.findIndex((currentItem) =>
      areLinksEquals(currentItem, hoveredItem)
    );
    // cas pas dans tableau, on insère avant l'élément hovered
    if (existingItemIndex < 0) {
      titlesToReturn = [
        ...localTitles.slice(0, hoveredItemIndex),
        item,
        ...localTitles.slice(hoveredItemIndex),
      ];
    }
    //cas déjà dans tableau, on inverse les 2 éléments
    else if (existingItemIndex >= 0 && hoveredItemIndex >= 0) {
      const newTitles = cloneDeep(localTitles);
      newTitles[hoveredItemIndex] = item;
      newTitles[existingItemIndex] = hoveredItem;
      titlesToReturn = newTitles;
    } else {
      titlesToReturn = localTitles;
    }
    setLocalTitles(titlesToReturn);
  };

const getUpdateLink =
  ({ linkItem, setLocalTitles, localTitles }) =>
  ({ newTitle, newHref }) => {
    const linkItemIndex = localTitles.findIndex((currentItem) =>
      areLinksEquals(linkItem, currentItem)
    );
    const newLocalTitles = [...localTitles];
    newLocalTitles[linkItemIndex] = {
      ...newLocalTitles[linkItemIndex],
      title: newTitle,
      href: newHref,
    };
    setLocalTitles(newLocalTitles);
  };

const getDeleteTitle =
  ({ setLocalTitles }) =>
  (title) => {
    setLocalTitles((previousLocalTitles) =>
      previousLocalTitles.filter(
        (localTitle) => !areLinksEquals(localTitle, title)
      )
    );
  };

const buildNewBusinessProcessWithNewBackgroundColor = ({
  colorRef,
  localBusinessProcess,
  boxIndex,
  stepIndex,
}) => {
  const businessProcessValue = localBusinessProcess?.businessProcessValue;
  const businessProcess = localBusinessProcess?.businessProcess;
  const newSteps = cloneDeep(businessProcessValue.steps);
  if (newSteps?.[stepIndex]?.boxes?.[boxIndex]) {
    if (!colorRef && newSteps[stepIndex].boxes[boxIndex].backgroundColorRef) {
      delete newSteps[stepIndex].boxes[boxIndex].backgroundColorRef;
    }
    if (colorRef) {
      newSteps[stepIndex].boxes[boxIndex].backgroundColorRef = colorRef;
    }
    const newBusinessProcessValue = {
      ...businessProcessValue,
      steps: newSteps,
    };
    const newBusinessProcess = {
      ...businessProcess,
      value: JSON.stringify(newBusinessProcessValue),
    };
    return newBusinessProcess;
  }
  return businessProcess;
};

const buildNewBusinessProcessWithNewTitlesAndTitle = ({
  localTitles,
  localTitle,
  localIcon,
  localBusinessProcess,
  boxIndex,
  stepIndex,
}) => {
  const businessProcessValue = localBusinessProcess?.businessProcessValue;
  const businessProcess = localBusinessProcess?.businessProcess;
  const newSteps = cloneDeep(businessProcessValue.steps);
  if (Array.isArray(newSteps?.[stepIndex]?.boxes?.[boxIndex]?.titles)) {
    newSteps[stepIndex].boxes[boxIndex].titles = localTitles;
    newSteps[stepIndex].boxes[boxIndex].boxTitle = localTitle;
    newSteps[stepIndex].boxes[boxIndex].icon = localIcon;
    const newBusinessProcessValue = {
      ...businessProcessValue,
      steps: newSteps,
    };
    const newBusinessProcess = {
      ...businessProcess,
      value: JSON.stringify(newBusinessProcessValue),
    };
    return newBusinessProcess;
  }
  return businessProcess;
};

const saveBoxBackgroundColor = ({
  saveBusinessProcessLocally,
  colorRef,
  localBusinessProcess,
  boxIndex,
  stepIndex,
  setFacetLocalBusinessProcess,
}) => {
  const newBusinessProcess = buildNewBusinessProcessWithNewBackgroundColor({
    colorRef,
    localBusinessProcess,
    boxIndex,
    stepIndex,
  });

  saveBusinessProcessLocally({
    businessProcess: newBusinessProcess,
    setFacetLocalBusinessProcess,
  });
};

const saveBox = ({
  saveBusinessProcessLocally,
  localTitles,
  localTitle,
  localIcon,
  localBusinessProcess,
  boxIndex,
  stepIndex,
  setFacetLocalBusinessProcess,
}) => {
  const newBusinessProcess = buildNewBusinessProcessWithNewTitlesAndTitle({
    localTitles,
    localTitle,
    localIcon,
    localBusinessProcess,
    boxIndex,
    stepIndex,
  });

  saveBusinessProcessLocally({
    businessProcess: newBusinessProcess,
    setFacetLocalBusinessProcess,
  });
};

const removeBox = ({
  businessProcess,
  businessProcessValue,
  setFacetLocalBusinessProcess,
  boxIndex,
  stepIndex,
}) => {
  const oldSteps = businessProcessValue?.steps;
  const oldStep = oldSteps?.[stepIndex];
  if (oldStep && Array.isArray(oldStep.boxes)) {
    const oldBoxes = oldStep.boxes;
    const newBoxes = [
      ...oldBoxes.slice(0, boxIndex),
      ...oldBoxes.slice(boxIndex + 1),
    ];
    const newStep = { ...oldStep, boxes: newBoxes };
    const newSteps = [
      ...oldSteps.slice(0, stepIndex),
      newStep,
      ...oldSteps.slice(stepIndex + 1),
    ];
    const newBusinessProcessValue = {
      ...businessProcessValue,
      steps: newSteps,
    };
    const newBusinessProcess = {
      ...businessProcess,
      value: JSON.stringify(newBusinessProcessValue),
    };
    saveBusinessProcessLocally({
      businessProcess: newBusinessProcess,
      setFacetLocalBusinessProcess,
    });
  }
};

const CancelAndValidateIconsContainer = styled.div`
  display: flex;
`;

const LinkEditMode = ({ href, initialText, onValidation, onCancel }) => {
  const [localText, setLocalText] = useState(initialText);

  return (
    <TitleAndActionsIconsContainerEditMode>
      <Textbox
        mr="24px"
        ml="8px"
        value={localText}
        onChange={(e) => {
          const newLinkTitle = e?.target?.value;
          setLocalText(newLinkTitle);
        }}
      />
      <CancelAndValidateIconsContainer>
        <IconButton
          onAction={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCancel();
          }}
        >
          <Icon type="blocked" />
        </IconButton>
        <IconButton
          onAction={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onValidation(localText);
          }}
        >
          <Icon type="tick" />
        </IconButton>
      </CancelAndValidateIconsContainer>
    </TitleAndActionsIconsContainerEditMode>
  );
};

const LinkBoxEdit = ({ updateLink, boxTitle, moveLocalItem, deleteTitle }) => {
  const { title, href } = boxTitle || {};
  const control = useControlContext();
  const sliderConf = useSliderConfContext();
  const [linkTitleEditMode, setLinkTitleEditMode] = useState(false);
  const [{ draggedItem, draggedItemType }, dropItem] = useDrop(
    () => ({
      accept: ["FRP1000-LEFT-MENU-DRAG-ITEM", "FRP1000-BOX-EDIT-DRAG-ITEM"],
      canDrop: () => false,
      hover(item) {
        if (!areLinksEquals(item, boxTitle)) {
          moveLocalItem({ item, hoveredItem: boxTitle });
        }
      },
      collect: (monitor) => ({
        draggedItem: monitor.getItem(),
        draggedItemType: monitor.getItemType(),
      }),
    }),
    [moveLocalItem, boxTitle]
  );
  const [{ isDragging }, dragItem, dragPreview] = useDrag(
    () => ({
      type: "FRP1000-BOX-EDIT-DRAG-ITEM",
      item: boxTitle,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [boxTitle]
  );
  const isDraggedItem = !!(
    draggedItem && areLinksEquals(draggedItem, boxTitle)
  );
  const isDraggedItemFromMenu =
    isDraggedItem && draggedItemType === "FRP1000-LEFT-MENU-DRAG-ITEM";
  const ref = useRef();

  return (
    <LinkRowContainer
      isDragging={isDragging}
      isDraggedItem={isDraggedItem}
      isDraggedItemFromMenu={isDraggedItemFromMenu}
      ref={dragPreview(dropItem(ref))}
    >
      <DragIconContainer ref={dragItem}>
        <Icon type="drag" />
      </DragIconContainer>
      <TitleAndActionsIconsContainer>
        <Link onClick={getOnClickLink({ control, url: href, title })}>
          <TitleContainer>
            <Ellipsis text={title} width={sliderConf.linkEllipsisWidth} />
          </TitleContainer>
        </Link>
        <EditAndDeleteIconsContainer>
          <LinkEditModePopover
            setOpen={setLinkTitleEditMode}
            open={linkTitleEditMode}
            initialText={title}
            initialHref={href}
            onCancel={() => {
              setLinkTitleEditMode(false);
            }}
            onValidation={({ newHref, newText }) => {
              updateLink({ newTitle: newText, newHref });
              setLinkTitleEditMode(false);
            }}
          />
          <IconButton
            onAction={(e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteTitle(boxTitle);
            }}
          >
            <Icon type="delete" />
          </IconButton>
        </EditAndDeleteIconsContainer>
      </TitleAndActionsIconsContainer>
    </LinkRowContainer>
  );
};

const ActionsPopoverContainer = styled.div`
  & [data-component="submenu-wrapper"] > span {
    background-color: inherit;
  }

  & [data-component="submenu-wrapper"] > span a:hover {
    background-color: inherit;
  }

  & [data-component="submenu-wrapper"] > span a:focus {
    background-color: inherit;
  }

  & [data-component="menu-divider"] {
    height: 1px;
  }

  & [data-component="menu-item"] > span {
    background-color: ${({ theme }) => theme?.menu?.custom?.color};
  }

  & [data-component="menu-item"] > span:hover {
    background-color: ${({ theme }) => theme?.menu?.custom?.hover};
    color: ${({ theme }) => theme?.text?.color};
  }

  & [data-component="menu-item"] > span:hover span {
    background-color: ${({ theme }) => theme?.menu?.custom?.hover};
    color: ${({ theme }) => theme?.text?.color} !important;
  }

  & [data-component="menu-item"] > span:hover button {
    background-color: ${({ theme }) => theme?.menu?.custom?.hover};
    color: ${({ theme }) => theme?.text?.color} !important;
  }

  &
    [data-component="menu"]
    > [data-component="menu-item"]
    > [data-component="submenu-wrapper"]
    > span:first-child
    > a:first-child {
    padding-right: 4px;
    padding-left: 4px;
  }

  & [data-component="submenu"] {
    right: 24px;
    top: 0px;
  }
`;

const ColorBoxContainer = styled.div`
  box-shadow: 1px 1px 2px 1px #00141e33;
  background-color: ${({ color }) => color} !important;
  margin-left: 8px;
  width: 22px;
  height: 22px;
  &:hover {
    border: 1px solid ${({ theme }) => theme?.menu?.custom?.hover};
  }
`;

const ColorBox = ({
  color,
  colorRef,
  businessProcess,
  businessProcessValue,
  setFacetLocalBusinessProcess,
  boxIndex,
  stepIndex,
}) => {
  const control = useControlContext();

  return (
    <ColorBoxContainer
      onClick={() => {
        saveBoxBackgroundColor({
          saveBusinessProcessLocally,
          colorRef,
          localBusinessProcess: { businessProcess, businessProcessValue },
          boxIndex,
          stepIndex,
          setFacetLocalBusinessProcess,
        });
      }}
      color={color}
    />
  );
};

const PaletteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
`;

const ButtonNoColorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 8px;
`;

const IconAndTextBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div:not(:first-child) {
    width: 100%;
  }
`;

const ColorPalette = ({
  businessProcess,
  businessProcessValue,
  setFacetLocalBusinessProcess,
  boxIndex,
  stepIndex,
}) => {
  const theme = useTheme();
  const {
    colorPalette: colors,
    colors: { translate: translateColor },
  } = theme;

  return (
    <PaletteContainer>
      <ButtonNoColorContainer
        onClick={() => {
          saveBoxBackgroundColor({
            saveBusinessProcessLocally,
            colorRef: "noColor",
            localBusinessProcess: { businessProcess, businessProcessValue },
            boxIndex,
            stepIndex,
            setFacetLocalBusinessProcess,
          });
        }}
      >
        <Icon type="blocked" />
      </ButtonNoColorContainer>
      {colors.map((colorRef) => (
        <ColorBox
          businessProcess={businessProcess}
          businessProcessValue={businessProcessValue}
          setFacetLocalBusinessProcess={setFacetLocalBusinessProcess}
          color={translateColor(colorRef)}
          colorRef={colorRef}
          boxIndex={boxIndex}
          stepIndex={stepIndex}
        />
      ))}
    </PaletteContainer>
  );
};

const ActionsPopover = ({ setEditMode, boxIndex, stepIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    businessProcess,
    businessProcessValue,
    setFacetLocalBusinessProcess,
  } = useBusinessProcessContext();
  const control = useControlContext();
  /* clickCount is here just to change Menu key when item is clicked,
     so it force to unmount/remount, and so it act like force closing it */
  const [clickCount, setClickCount] = useState(0);

  return (
    <ActionsPopoverContainer>
      <Menu key={clickCount}>
        <MenuItem
          icon="ellipsis_vertical"
          submenu
          submenuDirection="left"
          clickToOpen
          showDropdownArrow={false}
        >
          <MenuItem
            icon="edit"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditMode?.(true);
            }}
          >
            {I18n.t("businessProcess.edit")}
          </MenuItem>
          <MenuDivider size="large" />
          <MenuItem
            onClick={() => {
              setClickCount((prevClickCount) => prevClickCount + 1);
            }}
          >
            <ColorPalette
              businessProcess={businessProcess}
              businessProcessValue={businessProcessValue}
              setFacetLocalBusinessProcess={setFacetLocalBusinessProcess}
              boxIndex={boxIndex}
              stepIndex={stepIndex}
            />
          </MenuItem>
          <MenuDivider size="large" />
          <MenuItem
            icon="delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeBox({
                businessProcess,
                businessProcessValue,
                setFacetLocalBusinessProcess,
                boxIndex,
                stepIndex,
              });
            }}
          >
            {I18n.t("businessProcess.deleteSubStep")}
          </MenuItem>
        </MenuItem>
      </Menu>
    </ActionsPopoverContainer>
  );
};

const getBoxTitleEllipsisWidth = ({
  titleEllipsisWidth,
  hasFirstIcon,
  hasSecondIcon,
}) => {
  let resultWidth = titleEllipsisWidth;
  if (hasFirstIcon) resultWidth = resultWidth - 24;
  if (hasSecondIcon) resultWidth = resultWidth - 24;
  return resultWidth;
};

const BoxCardReadMode = ({ box, setEditMode, boxIndex, stepIndex }) => {
  const { titles } = box;

  const { canEdit } = useBusinessProcessContext();
  const sliderConf = useSliderConfContext();

  return Array.isArray(titles) ? (
    <Card mb="8px" mt="8px" cardWidth={sliderConf.cardWidth}>
      <TitleAndEditButtonContainer>
        <IconAndTitleContainer>
          {box.icon ? <Icon mr="8px" type={box.icon} /> : null}
          <Typography
            variant="segment-subheader"
            fontSize="16px"
            fontWeight="500"
            lineHeight="21px"
          >
            <Ellipsis
              width={getBoxTitleEllipsisWidth({
                titleEllipsisWidth: sliderConf.titleEllipsisWidth,
                hasFirstIcon: !!box.icon,
                hasSecondIcon: canEdit,
              })}
              text={box.boxTitle}
            />
          </Typography>
        </IconAndTitleContainer>
        {canEdit ? (
          <ActionsPopover
            boxIndex={boxIndex}
            setEditMode={setEditMode}
            stepIndex={stepIndex}
          />
        ) : null}
      </TitleAndEditButtonContainer>
      {titles.map((title) => (
        <LinkBoxTitle boxTitle={title} />
      ))}
    </Card>
  ) : null;
};

const getIconFromBox = (box) => {
  if (box.icon && isString(box.icon)) return box.icon;
  return undefined;
};

/* ces 2 fonctions sont pour la compatibilité :
 - Pour les tableaux d'activité actuelle sans color ref, la couleur par défaut reste le blanc
 - La colorRef noColor correspond aux box sans bordures (en lecture, sur fond inherited en écriture )
*/
const isNoColor = (colorRef) => colorRef === "noColor";

const getBoxCardBackgroundColor = ({
  isNoColor,
  translateColor,
  backgroundColorRef,
}) => {
  const translatedColor = translateColor(backgroundColorRef);
  if (isNoColor(backgroundColorRef)) return undefined;
  if (!translatedColor) return "white";
  return translatedColor;
};

const BoxCardEditMode = ({ box, setEditMode, boxIndex, stepIndex }) => {
  const { titles, boxTitle: title } = box;
  const [localTitles, setLocalTitles] = useState(titles);
  const [localTitle, setLocalTitle] = useState(title);
  const [localIcon, setLocalIcon] = useState(getIconFromBox(box));
  const [overFlag, setOverFlag] = useState(false);
  const {
    businessProcess,
    businessProcessValue,
    setFacetLocalBusinessProcess,
  } = useBusinessProcessContext();
  const [localBusinessProcess, setLocalBusinessProcess] = useState({
    businessProcess,
    businessProcessValue,
  });
  const sliderConf = useSliderConfContext();

  const [{ isOver, draggedItem, canDrop }, dropZone] = useDrop(
    () => ({
      accept: ["FRP1000-LEFT-MENU-DRAG-ITEM", "FRP1000-BOX-EDIT-DRAG-ITEM"],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggedItem: monitor.getItem(),
      }),
      drop: () => {
        //timeout necessary or conflict with over callback setting flag to true
        setTimeout(() => {
          setOverFlag(false);
        }, 100);
      },
    }),
    [localTitles, overFlag, localBusinessProcess]
  );

  useEffect(() => {
    setLocalBusinessProcess({ businessProcess, businessProcessValue });
  }, [businessProcess, businessProcessValue]);

  useEffect(() => {
    if (isOver && canDrop && draggedItem && !overFlag) {
      startOverCallback({
        setOverFlag,
        localTitles,
        setLocalTitles,
        draggedItem,
      });
    }
  }, [isOver, draggedItem, overFlag, canDrop, localTitles]);

  return Array.isArray(localTitles) ? (
    <Card mb="8px" mt="8px" cardWidth={sliderConf.cardWidth}>
      <IconAndTextBoxContainer>
        <IconSelection icon={localIcon} setLocalIcon={setLocalIcon} />
        <Textbox
          mr="24px"
          ml="8px"
          value={localTitle}
          onChange={(e) => {
            const newBoxTitle = e?.target?.value;
            setLocalTitle(newBoxTitle);
          }}
        />
      </IconAndTextBoxContainer>
      <TitlesContainer ref={dropZone}>
        {localTitles.map((title) => (
          <LinkBoxEdit
            key={title.href}
            boxTitle={title}
            updateLink={getUpdateLink({
              linkItem: title,
              setLocalTitles,
              localTitles,
            })}
            moveLocalItem={getMoveLocalItem({ setLocalTitles, localTitles })}
            deleteTitle={getDeleteTitle({ setLocalTitles })}
          />
        ))}
      </TitlesContainer>
      <ButtonsContainer>
        <TertiaryButtonWrapper>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditMode(false);
            }}
            buttonType="tertiary"
            size="small"
          >
            {I18n.t("businessProcess.cancel")}
          </Button>
        </TertiaryButtonWrapper>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            saveBox({
              saveBusinessProcessLocally,
              localTitles,
              localTitle,
              localIcon,
              localBusinessProcess,
              stepIndex,
              boxIndex,
              setFacetLocalBusinessProcess,
            });
            setEditMode(false);
          }}
          buttonType="primary"
          size="small"
        >
          {I18n.t("businessProcess.save")}
        </Button>
      </ButtonsContainer>
    </Card>
  ) : null;
};

const BoxCard = ({ box, boxIndex, stepIndex }) => {
  const [editMode, setEditMode] = useState(false);
  const { backgroundColorRef } = box;
  const {
    colors: { translate: translateColor },
  } = useTheme();
  const { businessProcess, canEdit } = useBusinessProcessContext();

  //when a box is added, it starts in edit mode
  useEffect(() => {
    const memoizedCallbackBoxAdded = ({
      updatedBusinessProcess,
      stepIndex: stepIndexAdded,
      newBoxIndex,
    }) => {
      if (
        stepIndex === stepIndexAdded &&
        newBoxIndex === boxIndex &&
        updatedBusinessProcess.title === businessProcess.title
      ) {
        setEditMode(true);
      }
    };
    eventBus.on("process-edit-box-added", memoizedCallbackBoxAdded);
    return () => {
      eventBus.remove("process-edit-box-added", memoizedCallbackBoxAdded);
    };
  }, [boxIndex, businessProcess.title, stepIndex]);

  return (
    <BoxCardContainer
      canEdit={canEdit}
      backgroundColor={getBoxCardBackgroundColor({
        isNoColor,
        translateColor,
        backgroundColorRef,
      })}
    >
      {editMode ? (
        <BoxCardEditMode
          box={box}
          boxIndex={boxIndex}
          stepIndex={stepIndex}
          setEditMode={setEditMode}
        />
      ) : (
        <BoxCardReadMode
          boxIndex={boxIndex}
          box={box}
          setEditMode={setEditMode}
          stepIndex={stepIndex}
        />
      )}
    </BoxCardContainer>
  );
};

export default BoxCard;
