import React, { useState, useReducer, useEffect } from 'react';
import { changeNodeAtPath, getNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { cloneDeep } from 'lodash';
import fetch from '../../../service/ApiFetch';
import Layout from '../../../layout/Layout';
import { SELECTION_ACTION } from './MenuEnums';

import './FacetMenu.css';

const FacetMenu = ({ facet, control, ...props }) => {
  let delayTimer;
  const menuEditorFacetName = 'menueditor';
  const tabsFacetName = 'tabs';

  const defaultMenuLayout = {
    name: 'menu',
    children: [
      { name: 'topbar', children: [] },
      {
        name: 'body',
        children: [
          {
            name: 'main',
            children: [
              // { name: 'main-header', children: [] },
              { name: 'main-body', children: [] },
            ],
          },
          { name: 'leftsidebar', children: [] },
        ],
      },
    ],
  };

  const defaultState = {
    layout: defaultMenuLayout,
    facets: [],
    data: {},
  };

  const [isLoading, setIsLoading] = useState(false);
  const [roundTrip, setRoundTrip] = useState(0);
  const [filterSelection, setFilterSelection] = useState({
    name: 'filter',
    value: '5',
  });
  const [sortSelection, setSortSelection] = useState({
    name: 'sort',
    value: '0',
  });
  const [orderSelection, setOrderSelection] = useState({
    name: 'order',
    value: '',
  });
  const [searchSelection, setSearchSelection] = useState({
    name: 'search',
    value: '',
  });

  const [state, dispatch] = useReducer(rquery, defaultState);

  function rquery(state, data) {
    let newState;
    let facets;
    let indexFacet;
    switch (data.action) {
      case 'updateTree':
        // update the tree locally
        //
        newState = cloneDeep(state);
        facets = newState.facets;
        indexFacet = facets.findIndex(
          (facet) => facet.type === menuEditorFacetName
        );
        if (indexFacet >= 0) {
          facets[indexFacet].values[0].tree.tree = data.data;
          newState = { ...newState, facets };
        }

        if (data.path) {
          if (data.path === -1) {
            newState.data.node = { id: data.path };
          } else {
            newState.data.node = getNodeAtPath({
              treeData: data.data,
              path: data.path,
              getNodeKey: ({ node }) => node.id,
            }).node;
            newState.data.node.path = data.path;
          }
        }
        break;
      case 'selectNode':
        // set the selected node data for XHR
        //
        newState = cloneDeep(state);
        newState.data.node = data.data;
        break;
      case 'updateNode':
        // update the node property in the tree
        // update the node property facet
        //
        newState = cloneDeep(state);
        // find the menu facet
        indexFacet = newState.facets.findIndex(
          (facet) => facet.type === menuEditorFacetName
        );
        if (indexFacet >= 0) {
          if (data.data.nodePath) {
            // find the node
            const node = getNodeAtPath({
              treeData: newState.facets[indexFacet].values[0].tree.tree,
              path: data.data.nodePath,
              getNodeKey: ({ node }) => node.id,
            }).node;
            // update the node property
            let optionRegex = new RegExp('^seo');
            let visibilityRegex = new RegExp('^mv');
            let nodeArrayName;
            let isNodeArray = false;
            if (optionRegex.test(data.data.name)) {
              nodeArrayName = 'formOptions';
              isNodeArray = true;
            } else if (visibilityRegex.test(data.data.name)) {
              nodeArrayName = 'menuVisibility';
              isNodeArray = true;
            }
            if (isNodeArray) {
              // if value === true, add the name to the array
              // otherwise, remove the name from the array
              if (!data.value) {
                node.custom[nodeArrayName] = node.custom[nodeArrayName].filter(
                  (option) => option !== data.data.name
                );
              } else {
                if (!node.custom[nodeArrayName]) {
                  node.custom[nodeArrayName] = [];
                }
                if (
                  node.custom[nodeArrayName].findIndex(
                    (option) => option === data.data.name
                  ) === -1
                ) {
                  node.custom[nodeArrayName].push(data.data.name);
                }
              }
            } else {
              if (data.data.name === 'arguments') {
                node.custom[data.data.name] = Array.isArray(data.value)
                  ? data.value
                  : JSON.parse(data.value);
              } else {
                if (data.data.name === 'caption') {
                  node.title = data.value.replace('&', '');
                }
                node.custom[data.data.name] = data.value;
              }
            }
            // update the tree
            newState.facets[indexFacet].values[0].tree.tree = changeNodeAtPath({
              treeData: newState.facets[indexFacet].values[0].tree.tree,
              path: data.data.nodePath,
              getNodeKey: ({ node }) => node.id,
              newNode: node,
            });
            // update node property value in the facet
            const nodePropertyFacet = newState.facets
              .find((prop) => prop.type === tabsFacetName)
              .tabs.find((prop) => prop.id === data.facet.tabId)
              .facets[data.facet.id].values.find(
                (prop) => prop.name === data.data.name
              );
            nodePropertyFacet.value = data.value;

            // fetch server data to update UI
            if (data.data.autoSubmit) {
              newState.data.node = node;
              newState.data.node.path = data.data.nodePath;
            }
          } else {
            // update metadata to the tree
            newState.facets[indexFacet].values[0].tree.meta[data.data.name] =
              data.value;
          }
        }
        break;
      case 'set':
        // initialize data from the server
        //
        newState = cloneDeep(defaultState);
        // set query result
        // {layout:{], facets:[], data:{}, items:[],}
        newState.layout = data.data.layout || defaultMenuLayout;
        newState.facets = data.data.facets || [];
        break;
      case 'setFacets':
        // only update the node property facet
        // reset the tree to local state
        //
        newState = cloneDeep(state);
        indexFacet = newState.facets.findIndex(
          (facet) => facet.type === menuEditorFacetName
        );
        const newFacets = data.data.facets;
        //
        newFacets[indexFacet].values[0].tree =
          newState.facets[indexFacet].values[0].tree;
        newState.facets = newFacets;
        delete newState.data.node;
        break;
      default:
        newState = cloneDeep(state);
    }
    return newState;
  }

  useEffect(() => {
    dispatch({ action: 'set', data: facet });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ action: 'set', data: facet });
    setRoundTrip(roundTrip + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control.roundTrip]);

  /**
   * Fetch data when a node in the tree is selected
   */
  useEffect(() => {
    if (state.data.node) {
      fetchItems(false);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [state]);

  /**
   * Fetch data when selection's options change
   */
  useEffect(() => {
    fetchItems(false);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [filterSelection, sortSelection, orderSelection, searchSelection]);

  const getLayout = () => {
    if (facet.layout) return facet.layout;
    return defaultMenuLayout;
  };

  const getFilters = () => {
    let filters = [];
    filters.push(filterSelection);
    filters.push(sortSelection);
    filters.push(orderSelection);
    filters.push(searchSelection);
    return filters;
  };

  const fetchItems = async (updateTree = true) => {
    let handler = facet.controller || 'main';

    const payload = {
      meta: control.meta,
      filters: getFilters(),
      data: state.data,
    };
    const params = { body: JSON.stringify(payload) };

    fetch(handler, params)
      .then((result) => result.json())
      .then((result) => {
        if (updateTree) {
          dispatch({ action: 'set', data: result.facets[0] });
        } else {
          dispatch({ action: 'setFacets', data: result.facets[0] });
        }
      });
  };

  const controllerMenu = {
    roundTrip: roundTrip,
    //
    submit: function () {
      fetchItems();
    },
    updateTree: function (tree, path = undefined) {
      dispatch({ action: 'updateTree', data: tree, path: path });
    },
    selectNode: function (node) {
      dispatch({ action: 'selectNode', data: node });
    },
    // for inputs
    setFacetValue: function (facet, value, newValue) {
      dispatch({
        action: 'updateNode',
        data: value,
        value: newValue,
        facet: facet,
      });
    },
    // for buttons
    doFacetAction: function (facet, value, action) {
      let param = '';
      if (action === 'commit') {
        param = state.facets.find((facet) => facet.type === menuEditorFacetName)
          .values[0].tree;
      }
      control.doFacetAction(facet, value, {
        name: action,
        param: param,
      });
    },
    doSelectionAction: function (value, action) {
      if (action === SELECTION_ACTION.FILTER) {
        setFilterSelection({ name: action, value: value });
      } else if (action === SELECTION_ACTION.SORT) {
        setSortSelection({ name: action, value: value });
      } else if (action === SELECTION_ACTION.ORDER) {
        setOrderSelection({ name: action, value: value });
      } else if (action === SELECTION_ACTION.SEARCH) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
          setSearchSelection({ name: action, value: value });
        }, 1000);
      }
    },
  };

  return (
    <React.Fragment>
      {isLoading ? (
        null
      ) : (
        <Layout
          isLoading={isLoading}
          facets={state.facets}
          control={controllerMenu}
          layout={getLayout()}
          {...props}
        />
      )}
    </React.Fragment>
  );
};

export default FacetMenu;
