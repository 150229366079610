import React from 'react'
import {Facet, FacetTitle, FacetValueTitle} from './Facet'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

const FacetDatepicker = ({ facet, control }) => {
    
    const value = () => {
        return facet.values[0];
    }

    const startDate = () => {
        return Date.parse(value().filter.args[0]);
    }
    
    const onChange = (date) => {
        control.setFacetValueParam(facet,facet.values[0],0,date.toISOString());
    }


    return (
        <Facet>
            <FacetTitle>{facet.title}</FacetTitle>
            <FacetValueTitle href='!#'>{value().title}</FacetValueTitle>
            <DatePicker selected={startDate()} onChange={(date) => onChange(date)}  />
        </Facet>
    )
}

export default FacetDatepicker
