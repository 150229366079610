import React, { useEffect } from 'react'
import styled from "styled-components"
import IconAction from "./IconAction"
import eventBus from "../../events/eventBus"

const FavouriteWrapper = styled.div`
  font-size: 16px;
  width: 320px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

const FavoriteTitle = styled.div`
`;

const FavouriteRow = ({ value, facet, theme, ...props}) => {

  useEffect( () => {
    eventBus.on("fav.delete", (item)=>{
      if (item.name===value.action.call.item.name) {
        // return of the delete action : delete from the list
        // TODO
      }
  })},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const onFavoriteClick = (e) => {
    if (e) e.preventDefault();
    //
    console.log("fav.click",value.action.call.item);
    //
    if (props.control) {
        props.control.callAction('openUrl',{item:value.action.call.item}, ()=> {
          // return on the open action :close the container
          if (props.onClose) props.onClose();
        });
    }
 }

  return (
    <FavouriteWrapper>
      <FavoriteTitle onClick={onFavoriteClick}>{value?.value}</FavoriteTitle>
      <IconAction facet={facet} value={value} color={theme?.icon?.color?.favourite} {...props}/>
    </FavouriteWrapper>
  );
};

export default FavouriteRow;
