import { cloneDeep } from "lodash";
import fetch from "../service/ApiFetch";
import eventBus from "../events/eventBus";
import { trackPromise } from "react-promise-tracker";

export class Controller {
  constructor({ state, roundTrip, setState, onChange, setRoundTrip }) {
    // properties
    this.state = state;
    this.meta = state.meta;
    this.error = state.error;
    this.handler = state.meta.handler || "main";
    this.nodeId = state.meta.nodeId || "";
    this.roundTrip = roundTrip;
    // methods (update react State)
    this.setState = setState;
    this.setRoundTrip = setRoundTrip;
    this.onChange = onChange;
  }

  /////////////////////////////////////////////////////
  getViewport() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const vt = 40+64; // should comes from css === top bar + tab height
    //
    return {vw:vw, vh:vh, ch:vh-vt};
  }

  getPayloadFilters() {
    // collect the modified values to include
    // in the "filters" member of the callback payload
    //
    let filters = [];
    this.state.facets.forEach((f) => {
      (f.values || []).forEach((v) => {
        // All values with "selected" flag are pushed
        if (v.selected) {
          filters.push(v);
        }
      });
    });
    return filters;
  }

  getPayload() {
    // build the callback payload
    return { meta: this.state.meta, filters: this.getPayloadFilters(), viewPort:this.getViewport() };
  }

  getCallBack() {
    // return the local callback
    // used by Dialog
    return null;
  }

  getController() {
    // return backend handler
    return this.handler;
  }

  fetchData = async (area) => {
    const payload = this.getPayload();
    const params = { body: JSON.stringify(payload) };

    const callBack = this.getCallBack();
    if (callBack) {
      // Call a local callback function
      // Used by dialog wich are controlled by front components
      //
      let resp = callBack(payload);
      this.setState({ action: "set", data: resp });
      return;
    }

    const handler = this.getController();
    if (handler) {
      // Call the backend handler
      //
      trackPromise(
        fetch(handler, params)
          .then((result) => result.json())
          .then((result) => {
            this.setState({ action: "set", data: result });
            if (this.setRoundTrip) this.setRoundTrip(this.roundTrip + 1);

            setTimeout(()=>{
              // emit actions in result
              if(result.action) {
                eventBus.emitAction(result.action, true);
              }
              if(result.actions) {
                result.actions.forEach(action => {
                  eventBus.emitAction(action, true);
                });
              }
            },1)
          }),
        area
      );
    }
  };

  submit = function (area) {
    // Call the backend handler with current modifications
    //
    setTimeout(() => {
      this.fetchData(area);
    }, 1);
  };

  /////////////////////////////////////////////////////
  callMethod(handler, data, callBack, area) {
    // Call a backend method
    //
    const params = { body: JSON.stringify(data) };

    trackPromise(
      fetch(handler, params)
        .then((result) => result.json())
        .then((result) => {
          callBack(result);
        }),
      area
    );
  }

  callAction(action, data, cb, area) {
    // Call a backend method
    // The backend response may content "actions" which are pushed on the eventBus
    //
    let r = {};
    r.meta = cloneDeep(this.meta);
    r.meta.action = { name: action, param: data, viewPort:this.getViewport() };

    this.callMethod(
      this.handler ?? "main",
      r,
      (resp) => {
        if (resp.action) {
          //console.log("emit action",process.env.REACT_APP_API_PACKAGE,resp.action);
          eventBus.emitAction(resp.action, true);
        }
        if (resp.actions) {
          //console.log("emit actions",process.env.REACT_APP_API_PACKAGE,resp.actions);
          resp.actions.forEach((a) => {
            eventBus.emitAction(a, true);
          });
        }
        if (cb) cb(resp);
      },
      area
    );
  }

  /////////////////////////////////////////////////////
  dispatch(data) {
    // update local state with value modifications
    // modifications will be send back in the next callback
    //
    switch (data.action) {
      case "reset":
        // reset dialog result
        delete this.state.meta.result;
        this.state.isClosing = false;
        this.state.meta.itemId = "";
        break;

      case "sel":
        // select a facet value
        this.state.facets.forEach((f) => {
          if (f.id === data.facet.id) {
            f.values.forEach((v) => {
              if (v.id === data.value.id) {
                //console.log("set",v)
                v.selected = data.newval;
              } else {
                if (
                  data.newval &&
                  (f.type === "oneOfMany" || f.type === "fromList")
                ) {
                  //console.log("clear",v)
                  v.selected = false;
                }
              }
            });
          }
        });
        break;
      case "setvalparam":
        // set args of a facet value
        this.state.facets.forEach((f) => {
          if (f.id === data.facet.id) {
            f.values.forEach((v) => {
              if (v.id === data.value.id) {
                v.selected = true;
                v.filter.args[data.index] = data.newval;
              }
            });
          }
        });
        break;
      case "setval":
        // set args of a facet value
        this.state.facets.forEach((f) => {
          if (f.id === data.facet.id) {
            f.values.forEach((v) => {
              if (v.id === data.value.id) {
                v.selected = true;
                v.value = data.newval;
                if (data.after) data.after(f, v);
              }
            });
          }
        });
        break;
      case "setItem":
        // set new selected node
        this.state.meta.itemId = data.itemId;
        break;
      case "setObject":
        this.state.meta.object = data.object;
        break;
      case "setAction":
        // set action node
        if (typeof data.param === "string") {
          this.state.meta.action = { name: data.param, param: {} };
        } else {
          this.state.meta.action = data.param;
        }
        break;
      case "setQuery":
        this.state.meta.query = data.data;
        break;
      default:
    }
  }

  getAutoSubmit(f, v) {
    //
    if (this?.meta?.autoSubmit) return true;
    if (f && f.autoSubmit) return true;
    if (v && v.autoSubmit) return true;
    return false;
  }

  getSpinArea(f,v,area) {
    if (area) return area;
    if (v && v.spinArea) return v.spinArea;
    if (f && f.spinArea) return f.spinArea;
    return null;
  }

  NotifyChange() {
    // notify when a value changed
    if (this.onChange) this.onChange();
  }

  refresh(facet, value, area) {
    //
    if (value && value.action) this.doFacetAction(facet, value, value.action);
    //
    this.NotifyChange();
    //
    if (this.getAutoSubmit(facet, value)) this.submit(this.getSpinArea(facet,value,area));
  }
  //
  clearError() {
    this.setState({ action: "clearError" });
  }
  setError(data) {
    this.setState({ action: "setError", data: data });
  }
  //
  selectItem(f, itemId) {
    this.dispatch({ action: "setItem", itemId: itemId });
    this.submit();
  }
  selectObject(object) {
    this.dispatch({ action: "setObject", object });
    this.submit();
  }
  selectFacetValue(f, v) {
    if (!v.selected) {
      this.dispatch({ action: "sel", facet: f, value: v, newval: true });
      this.refresh(f, v);
    }
  }
  unSelectFacetValue(f, v) {
    if (v.selected) {
      this.dispatch({ action: "sel", facet: f, value: v, newval: false });
      this.refresh(f, v);
    }
  }
  toggleFacetValue(f, v) {
    if (v.selected) this.unSelectFacetValue(f, v);
    else this.selectFacetValue(f, v);
  }
  setFacetValue(f, v, val) {
    this.dispatch({ action: "setval", facet: f, value: v, newval: val });
    this.refresh(f, v);
  }
  setFacetValueParam(f, v, i, val, area) {
    this.dispatch({
      action: "setvalparam",
      facet: f,
      value: v,
      index: i,
      newval: val,
    });
    this.refresh(f, v, area);
  }
  doFacetAction(f, v, a) {
    this.dispatch({ action: "setAction", facet: f, value: v, param: a });
    this.submit();
  }
  setQueryParameters() {
    const queryParams = new URLSearchParams(window.location.search);
    this.dispatch({
      action: "setQuery",
      data: Array.from(queryParams.entries())
    })
  }
}

export class MainController extends Controller {}

export class DialogController extends Controller {
  constructor({ facet, control, value, ...props }) {
    super({ ...props });

    // dialog facet/value/parent controller
    this.facet = facet;
    this.value = value;
    this.parentController = control;
  }

  getCallBack() {
    // When the dialog is used with a local callback function
    if (this.value.dialog && this.value.dialog.callBack)
      return this.value.dialog.callBack;
    return null;
  }

  getController() {
    // get backend handler method for the dialog
    if (this.value?.dialog && this.value.dialog.controller)
      return this.value.dialog.controller;
    if (this.value?.controller) return this.value.controller;
    if (this.facet?.controller) return this.facet.controller;
    return null;
  }

  getPayload() {
    // get payload for a backend callback
    // payload includes the dialog facet and value
    return {
      meta: this.state.meta,
      filters: this.getPayloadFilters(),
      dialog: {
        meta: this.parentController.meta,
        facet: this.facet,
        value: this.value,
      },
    };
  }

  getAutoSubmit(f, v) {
    // get autoSublit for the dialog
    if (f && f.autoSubmit) return true;
    if (v && v.autoSubmit) return true;
    // autoSublit may be defined on the dialog facet or value
    if (this?.facet?.autoSubmit) return true;
    if (this?.value?.autoSubmit) return true;
    if (this?.value?.dialog?.autoSubmit) return true;
    return false;
  }
}

export class PanelController extends Controller {
  constructor({ facet, control, value, panelController, meta, ...props }) {
    super({ ...props });

    // panel facet/value/parent controller
    this.facet = facet;
    this.value = value;
    this.handler = panelController;
    this.parentController = control;
    this.panelController = panelController;
    if (meta) this.state.meta = meta;
  }

  getController() {
    // get backend handler method for the dialog
    if (this.panelController) return this.panelController;
    if (this.value?.panel && this.value.panel.controller)
      return this.value.panel.controller;
    if (this.value?.controller) return this.value.controller;
    if (this.facet?.controller) return this.facet.controller;
    return null;
  }

  hasController() {
    return this.getController()!=null;
  }

}
