import * as React from 'react'
import styled from 'styled-components'

// !! MUST DECLARED OUTSIDE THE CLASS !!
// !! if not, the click events don't work !!
//
const OuterInitial = styled.div`
padding: 5%;
border-radius: 10px;
border: 4px solid #66ff66;
text-align:center;
`
const OuterWait = styled.div`
padding: 5%;
border-radius: 10px;
border: 4px solid #ff9933;
text-align:center;
`
const OuterFinal = styled.div`
padding: 5%;
border-radius: 10px;
border: 4px solid black;
text-align:center;
`
const OuterActivity = styled.div`
padding: 5%;
border-radius: 10px;
border: 4px solid cornflowerblue;
text-align:center;
`
const CustomNodeInner = ( {node, config} ) => {

    if (node.type === 'initial') {
    return (
        <OuterInitial>
        <p>{node.title}</p>
        <br />
        </OuterInitial>
    )
    } 
    else 
    if (node.type === 'wait') {
    return (
        <OuterWait >
        <p>{node.title}</p>
        <br />
        </OuterWait>
    )
    } 
    else 
    if (node.type === 'final') {
    return (
        <OuterFinal >
        <p>{node.title}</p>
        <br/>
        </OuterFinal>
    )
    } 
    else {
    return (
        <OuterActivity >
        <p>{node.title}</p>
        <br/>
        </OuterActivity>
    )
    }
}

export default CustomNodeInner
