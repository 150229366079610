const de = {
  businessProcess: {
    addNewStep: "Einen neuen Schritt hinzufügen",
    editMode: "Bearbeitungsmodus",
    edit: "Bearbeiten",
    deleteSubStep: "Teilschritt löschen",
    duplicate: "Duplikat",
    cancel: "Abbrechen",
    save: "Speichern",
    cancelChanges: "Änderungen rückgängig machen",
    useThisZoneToAddShortcuts:
      "Verwenden Sie diesen Bereich, um Verknüpfungen hinzuzufügen",
    addANew: "Einen neuen",
    subStep: "Teilschritt hinzufügen",
    businessProcess: "Aktivitätstabelle",
    dragAndDropExplanation:
      "Fügen Sie einen Schritt und einen Teilschritt hinzu und verschieben Sie dann einen Menüeintrag per Drag & Drop, um eine Aktivität hinzuzufügen.",
    validationExplanation:
      "Die Replikation ermöglicht es Ihnen, Ihre Änderungen zu speichern, ohne die anfängliche Aktivitätstabelle zu ändern.",
    title: "Titel",
    link: "Link",
    information: "Information",
    steps: "Schritte",
    setupBusinessProcess: "Aktivitätstabelle einrichten",
    none: "Keins",
    close: "Schließen",
    iconList: "Liste der Symbole",
  },
  noResult: "Kein Ergebnis",
  button: {
    commit: "Speichern",
    cancel: "Abbrechen",
    apply: "Anwenden",
  },
  action: {
    add: "Erstellen",
    modify: "Ändern",
    delete: "Löschen",
  },
  grid: {
    noResult: "Kein Ergebnis",
  },
  confirm: {
    title: "Bestätigung",
    areYouSure: "Bist du dir sicher?",
    no: "Nein",
    yes: "Ja",
  },
  dialog: {
    placeHolder: "Konfigurieren...",
    cancel: "Abbrechen",
    apply: "Anwenden",
  },
  codeEditor: {
    placeHolder: "Bearbeiten...",
    compile: "Kompilieren",
    tooltipMessage: "Das Skript wurde geändert",
  },
  emailEditor: {
    placeHolder: "Bearbeiten...",
    dialogTitle: "E-Mail",
  },
  iconParameters: {
    dialogTitle: "Einstellungen",
    editTitle: "Einstellungen",
    placeHolder: "Konfigurieren...",
    headerName: "Name",
    headerType: "Typ",
    headerValue: "Wert",
    tooltipMessage: "Einstellungen wurden konfiguriert",
  },
  iconForm: {
    placeHolder: "Konfigurieren...",
  },
  iconButton: {
    placeHolder: "Konfigurieren...",
    isEdited: "Element geändert",
  },
  iconTreeview: {
    placeHolder: "Konfigurieren...",
    dialogTitle: "Auswählen",
  },
  navigleft: {
    minimize: "Minimieren",
    closeAll: "Alle schließen",
    loading: "Laden",
    noContent: "Kein Inhalt",
  },
  menuEditor: {
    entry: {
      title: "Neu",
      caption: "Neu",
      action: {
        add: "Hinzufügen",
        remove: "Entfernen",
        separator: "Separator",
      },
    },
    search: {
      placeholder: "Suchen...",
      previous: "Vorh.",
      next: "Näch.",
    },
    collapseAll: "Alle reduzieren",
    expandAll: "Alle erweitern",
    addRootEntry: "Neuer Eintrag",
    filter: "Objekte",
    sort: {
      label: "Sortierung",
      orderByDesc: "Absteigende Sortierung",
      orderByAsc: "Aufsteigende Sortierung",
      order: "Richtung",
    },
  },
  card: {
    noContent: "Dieses Element hat keinen Inhalt",
  },
};

const processRelativeTime = (number, withoutSuffix, key, isFuture) => {
  var format = {
    m: ["eine Minute", "einer Minute"],
    h: ["eine Stunde", "einer Stunde"],
    d: ["ein Tag", "einem Tag"],
    dd: [number + " Tage", number + " Tagen"],
    w: ["eine Woche", "einer Woche"],
    M: ["ein Monat", "einem Monat"],
    MM: [number + " Monate", number + " Monaten"],
    y: ["ein Jahr", "einem Jahr"],
    yy: [number + " Jahre", number + " Jahren"],
  };
  return withoutSuffix ? format[key][0] : format[key][1];
};

const moment_de = {
  months:
    "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split(
      "_"
    ),
  monthsShort:
    "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.".split("_"),
  monthsParseExact: true,
  weekdays: "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split(
    "_"
  ),
  weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),
  weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D. MMMM YYYY",
    LLL: "D. MMMM YYYY HH:mm",
    LLLL: "dddd, D. MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[heute um] LT [Uhr]",
    sameElse: "L",
    nextDay: "[morgen um] LT [Uhr]",
    nextWeek: "dddd [um] LT [Uhr]",
    lastDay: "[gestern um] LT [Uhr]",
    lastWeek: "[letzten] dddd [um] LT [Uhr]",
  },
  relativeTime: {
    future: "in %s",
    past: "vor %s",
    s: "ein paar Sekunden",
    ss: "%d Sekunden",
    m: processRelativeTime,
    mm: "%d Minuten",
    h: processRelativeTime,
    hh: "%d Stunden",
    d: processRelativeTime,
    dd: processRelativeTime,
    w: processRelativeTime,
    ww: "%d Wochen",
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime,
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./,
  ordinal: "%d.",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
};

export { de, moment_de };
