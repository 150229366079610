import { useState, useEffect } from "react";
import eventBus from "../../../events/eventBus";

const useEvents = () => {
  const [state, setState] = useState({
    closeForm: null,
    closeTab: null,
    updateTab: null,
  });

  useEffect(() => {
    const closeFormCallback = (data) => {
      if (state.closeForm) state.closeForm(data);
    };
    const closeTabCallback = (data) => {
      if (state.closeTab) state.closeTab(data);
    };
    const updateTabCallback = (data) => {
      if (state.updateTab) state.updateTab(data);
    }
    eventBus
      .on("closeForm", closeFormCallback)
      .on("tab.close", closeTabCallback)
      .on("tab.update", updateTabCallback);

    return () => {
      eventBus
        .remove("closeForm", closeFormCallback)
        .remove("tab.close", closeTabCallback)
        .remove("tab.update", updateTabCallback);
    };
  }, [state]);

  return setState;
};

export default useEvents;
