import React from 'react';
import styled from 'styled-components';

const Spacing = styled.div`
    width: ${({ value: { width } }) => width ?? 'auto' };
    height: ${({ value: { height } }) => height ?? 'auto' };
    margin-bottom: 0 !important;
`;

export default Spacing;
