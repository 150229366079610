import React, { useState,useEffect } from 'react'
import styled from 'styled-components';
import { Checkbox } from "carbon-react/lib/components/checkbox";
import eventBus from "../../events/eventBus"

const Section = styled.section`
    display: flex;
    width: 100%;
`;
const CheckWrapper = styled.div`
    min-width:24px;
`;
const Title = styled.div`
`;
const Value = styled.div`
    margin-left:auto;
    padding-right:8px;
`;

const TextValue = ({ facet, control, value }) => {

    const [isChecked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(value.checked ? true:false);
    }, [value]);

    const getTitle = () => {
        return value.title;
    }
    const getValue = () => {
        if (typeof value.value === "object") {
            if (typeof value.value.value === "string") return value.value.value;
            if (typeof value.value.title === "string") return value.value.title;
            if (typeof value.value.text === "string") return value.value.text;
            return "";
        }
        return value.value ? value.value : value.count;
    }

    const isCheckList = () => {
        return (facet?.type==="checkList");
    }
    const hasCheck = () => {
        return (typeof value.checked !== "undefined");
    }

    const onCheckClick = () => {

        if (value.onCheckEvent) {
            eventBus.emit(value.onCheckEvent,value.filter);
        }
        setChecked(! isChecked);
    }

    return (
        <Section>
        {isCheckList()?
            <CheckWrapper>
            {hasCheck()?
                <Checkbox size="small" checked={isChecked} onClick={onCheckClick} />
            :null}
        </CheckWrapper>
        :
        null}
        <Title>{getTitle()}</Title>
        <Value>{getValue()}</Value>
        </Section>
    )
}

export default TextValue
