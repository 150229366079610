import React from "react";
import styled from "styled-components";

const TertiaryButtonWrapper = styled.div`
  ${({ forceLine }) =>
    forceLine
      ? `
  & button {
    flex-wrap: nowrap;
  }`
      : ""}
  & button:hover {
    background-color: inherit;
    color: var(--colorsActionMajor500);
  }

  & button:hover > span[data-component="icon"] {
    color: var(--colorsActionMajor500);
  }
`;

export default TertiaryButtonWrapper;
