import React from "react";
import DebouncedSearch from "../components/DebouncedSearch";
import styled from "styled-components";

const getSearchPropsFromValue = (value) => value?.searchProps ?? {};

const getOnChangeCallback = ({ control, callbackName }) => (newValue) => {
  control.callAction(callbackName, { item: newValue });
};

const DebouncedSearchContainer = styled.div`
  & [data-component="search"] {
    height: 30px;
    min-height: 0px;
  }
  & * {
    height: 27px;
    min-height: 0px;
  }

  & [data-component="search"] {
    border: 2px solid ${({ theme }) => theme?.search?.border};
  }
`;

const FacetDebouncedSearch = ({ facet, value, control }) => {
  const {
    searchWidth,
    defaultValue,
    placeholder,
    debounceTime,
    callbackName,
    ...otherSearchProps
  } = getSearchPropsFromValue(value);
  return (
    <DebouncedSearchContainer>
      <DebouncedSearch
        searchWidth={searchWidth}
        defaultValue={defaultValue}
        placeholder={placeholder}
        debounceTime={debounceTime}
        onChangeCallback={getOnChangeCallback({ callbackName, control })}
        otherSearchProps={otherSearchProps}
      />
    </DebouncedSearchContainer>
  );
};

export default FacetDebouncedSearch;
