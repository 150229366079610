import React, { useState, useReducer, useEffect, useRef } from "react";
import I18n from "i18n-js";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import IconAction from "../../components/IconAction";
import Box from "carbon-react/lib/components/box";
import Confirm from "carbon-react/lib/components/confirm";
import eventBus from "../../../events/eventBus";
import Tree from "../../../common/Tree";
import Dom from "../../../common/Dom";
import Card, { LoadingCard, EmptyCard } from "./Card";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "carbon-react/lib/components/loader";
import Item from "./Item";
import Layout from "../../../layout/Layout";

const Container = styled.ul`
  padding: 0;
  list-style: none;
  &:focus {
    outline: none;
  } /* uggly, at item level */

  &:focus li[role="current"] {
    border: ${(props) => props.theme.border.focused.border};
    border-radius: ${(props) => props.theme.border.focused.radius};
    box-shadow: ${(props) => props.theme.border.focused.shadow};
    background-color: ${(props) => props.theme.itemResult.current.backgroundColor};
  }
`;

const CardsContainer = styled.div`
  & div[data-component="card"] {
    padding: 0;
  }
  max-height: calc(100vh - 125px);
  overflow: auto;
`;

const ActionTitle = styled.div`
  cursor: pointer;
`;
const ActionsContainer = styled.div`
  display: flex;
`;
const ActionContainer = styled.div`
  display: flex;
`;

const EmptyResult = styled.div``;

const getContainerToRender = (kind) =>
  kind === "card" ? CardsContainer : Container;

const getLoadingContent = ({ kind, theme }) => {
  return kind === "card" ? <LoadingCard theme={theme} /> : <Loader />;
};

const getEmptyContent = ({ facet, controller, kind, message, theme }) => {

  if (facet?.noResult?.facets) {
    // no result panel
    return (
    <Layout
      facets={facet.noResult.facets}
      control={controller}
      layout={facet.noResult.layout||{}}
      theme = {theme}
    />
    );    
  }


  return kind === "card" ? (
    <EmptyCard theme={theme} message={message} />
  ) : (
    <EmptyResult>{message}</EmptyResult>
  );
};

const shouldGiveLoadingContent = ({
  usingLoading,
  loading,
  promiseInProgress,
}) => (usingLoading ? loading : promiseInProgress);

const ResultItem = ({
  control,
  facet,
  value,
  item,
  onItemClick,
  kind,
  ...props
}) => {
  const [thisitem, setItem] = useState(item);
  const [extraLayouts, setExtraLayouts] = useState({});
  const itemRef = useRef(null);
  const doDownloadUrl = (url, name) => {
    Dom.download(url, name);
  };

  const onAction = (action) => {
    //
    // Item left/right action
    // action : {
    //  name,
    //  itemId,
    //  updateSate:bool,
    //  confirm:bool,
    //  ...
    //}
    //
    if (action.updateState) {
      //
      control.doFacetAction(facet, null, action);
    } else {
      //
      control.callAction(action.name, action, (resp) => {
        // Update the item
        //
        if (resp.item) setItem(resp.item);

        // start download action
        //
        if (resp.download && resp.download.url) {
          doDownloadUrl(resp.download.url, resp.download.name);
        }

        // case resp has panel
        if (resp.panel?.panelId) {
          setExtraLayouts((previousExtraLayouts) => ({
            ...previousExtraLayouts,
            [resp.panel.panelId]: {
              layout: resp.panel.layout,
              facets: resp.panel.facets,
              title: resp.panel.title
            },
          }));
        }
      });
    }
  };

  const getActions = (actions) => {
    return (
      <ActionsContainer>
        {actions.map((action, index) => (
          <ActionContainer>
            {action.showTitle ? (
              <ActionTitle
                onClick={(e) => {
                  e.preventDefault();
                  onAction(action);
                }}
              >
                {action.title}
              </ActionTitle>
            ) : null}
            <IconAction
              key={index}
              facet={facet}
              control={control}
              id={thisitem.id}
              action={action}
              onAction={onAction}
            />
          </ActionContainer>
        ))}
      </ActionsContainer>
    );
  };

  const getLActions = () => {
    if (thisitem.leftActions) {
      return getActions(thisitem.leftActions);
    }
    return null;
  };

  const getRActions = () => {
    if (thisitem.rightActions) {
      return getActions(thisitem.rightActions);
    }
    return null;
  };

  const getRole = () => {
    if (item.id === props.currentId) return "current";
    return "item";
  };

  window.setTimeout(() => {
    // WAF !!
    // Try to scroll focused item in view
    //
    if (itemRef.current && item.id === props.currentId) {
      let bw = props.theme.border.focused.sizeInt;
      Dom.scrollParent(itemRef.current, bw);
    }
  }, 1);

  return kind === "card" ? (
    <Card
      role={getRole()}
      key={item.id}
      data={item}
      facet={facet}
      value={value}
      control={control}
      doDownloadUrl={doDownloadUrl}
      id={thisitem.id}
      extraLayouts={extraLayouts}
      onAction={onAction}
      onItemClick={onItemClick}
    />
  ) : (
    <Item
      parentControl={control}
      itemRef={itemRef}
      getRole={getRole}
      onItemClick={onItemClick}
      item={item}
      thisitem={thisitem}
      getLActions={getLActions}
      getRActions={getRActions}
      extraLayouts={extraLayouts}
    />
  );
};

export const ResultGrid = ({
  loading,
  usingLoading,
  control,
  facet,
  value = {},
  items,
  keyboardSupport,
  kind,
  noPromiseTrack,
  ...props
}) => {
  const supportKeyboard = keyboardSupport ?? true;

  let { promiseInProgress } = usePromiseTracker({
    area: facet.spinArea,
    delay: 0,
  });

  if (noPromiseTrack) {
    promiseInProgress = null;
  }

  const reduceState = (state, data) => {
    let newState = cloneDeep(state);
    switch (data.action) {
      case "set":
        newState.items = data.data;
        newState.currentId = Tree.getFirstSibling(newState.items);
        break;
      case "setCurrent":
        newState.currentId = data.data;
        break;
      default:
    }
    return newState;
  };

  const [state, setState] = useReducer(reduceState, {
    items: [],
    currentId: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState({ confirm: {} });
  const gridRef = useRef(null);

  useEffect(() => {
    setState({ action: "set", data: items });
  }, [items]);

  const setOpen = () => {
    setIsOpen(!isOpen);
  };

  const isEmpty = () => {
    if (!items || items.length === 0) return true;
    return false;
  };

  const EmptyMessage = () => {
    if (control.data && control.data.noResult) return control.data.noResult;
    return I18n.t("grid.noResult", {
      defaultValue: "The query returns no result",
    });
  };

  const ResultItemController = {
    meta: control.meta,
    data: control.data,
    handler: control.handler,
    selectItem: function (...props) {
      control.selectItem(...props);
    },
    doFacetAction: function (facet, value, action) {
      if (action.confirm) {
        setAction(action);
        setIsOpen(true);
      } else {
        control.doFacetAction(facet, value, action);
      }
    },
    callMethod: function (action, ...props) {
      if (action.confirm) {
        setAction(action);
        setIsOpen(true);
      } else {
        control.callMethod(...props);
      }
    },
    callAction: function (name, action, cb, area) {
      if (action.confirm) {
        setAction(action);
        setIsOpen(true);
      } else {
        control.callAction(name, action, cb, area);
      }
    },
  };

  const onConfirm = () => {
    // on action confirm
    control.doFacetAction(facet, null, action);
    setIsOpen(false);
  };

  const onItemClick = (item, e) => {
    if (e) e.preventDefault();

    if (item.readOnly === true) return;

    if (item.action) {
      control.callAction(item.action, { item: item });
      return;
    }

    control.selectItem(facet, item.id);
  };

  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      return;
    }

    if (e) e.preventDefault();

    let newId = null;
    switch (e.key) {
      case "PageDown":
        eventBus.emit("PageDown", { id: facet.groupId });
        break;
      case "PageUp":
        eventBus.emit("PageUp", { id: facet.groupId });
        break;
      case "ArrowDown":
        newId = Tree.getNextSibling(state.items, state.currentId);
        newId.sibling
          ? setState({ action: "setCurrent", data: newId.id })
          : eventBus.emit("PageDown", { id: facet.groupId });
        break;
      case "ArrowUp":
        newId = Tree.getPrevSibling(state.items, state.currentId);
        newId.sibling
          ? setState({ action: "setCurrent", data: newId.id })
          : eventBus.emit("PageUp", { id: facet.groupId });
        break;
      case "Enter":
        if (state.currentId != null) {
          let ii = Tree.find(state.items, state.currentId);
          if (ii) onItemClick(ii);
        }
        break;
      default:
    }
  };

  if (supportKeyboard) {
    window.setTimeout(() => {
      if (gridRef.current) {
        gridRef.current.setAttribute("tabindex", "0");
        gridRef.current.focus();
      }
    }, 1);
  }

  if (shouldGiveLoadingContent({ usingLoading, loading, promiseInProgress })) {
    return getLoadingContent({ kind, theme: props.theme });
  }

  if (isEmpty()) {
    return getEmptyContent({
      facet,
      control,
      kind,
      message: EmptyMessage(),
      theme: props.theme,
    });
  }

  const ContainerToRender = getContainerToRender(kind);

  return (
    <ContainerToRender
      role="grid-container"
      ref={gridRef}
      onKeyDown={onKeyDown}
    >
      {items.map((item) => (
        <ResultItem
          kind={kind}
          role="grid-item"
          key={item.id}
          control={ResultItemController}
          facet={facet}
          value={value}
          item={item}
          currentId={supportKeyboard ? state.currentId : null}
          onItemClick={onItemClick}
          {...props}
        />
      ))}
      <Confirm
        title={action.confirm.title || ""}
        subtitle={action.confirm.subtitle || ""}
        destructive={action.confirm.destructive}
        cancelButtonType="tertiary"
        open={isOpen}
        onConfirm={onConfirm}
        onCancel={setOpen}
      >
        {action.confirm.content || ""}
      </Confirm>
    </ContainerToRender>
  );
};

const FacetResultGrid = ({
  facet,
  control,
  loading,
  usingLoading,
  ...props
}) => {
  const height = props.height ? props.height : "auto";

  const BoxStyle = {
    display: "block",
    width: "100%",
    height: height,
    overflow: "auto",
    padding: "4px",
    boxSizing: "border-box",
  };

  return (
    <Box role="grid-scroll" style={BoxStyle} scrollVariant="light">
      <ResultGrid
        loading={loading}
        usingLoading={usingLoading}
        control={control}
        facet={facet}
        items={control.currentItems}
        height={height}
        {...props}
      />
    </Box>
  );
};

export default FacetResultGrid;
