import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from 'carbon-react/lib/components/icon';
import IconButton from 'carbon-react/lib/components/icon-button';
import InputSelect from './InputSelect';
import InputText from './InputText';


const Composite = styled.div`
    display: grid;
    grid-template-columns: auto 20px;
    align-items: end;
    width: 100%;

`;

const InputAddSelect = ({ ...props }) => {

    const [isSelect, setIsSelect] = useState(true);

    const onAddSelectClick = (e) => {
        e.preventDefault();
        setIsSelect(! isSelect);
    }
    
    const buttonStyle = {
        paddingLeft: '2px',
        paddingBottom: '4px',
    };

    return (
        <Composite>
        {isSelect ?
            <InputSelect {...props} />
            :
            <InputText {...props} />
        }
        <IconButton style={buttonStyle} onAction={onAddSelectClick}><Icon type='addselect' /></IconButton>
        </Composite>
    )
}

export default InputAddSelect
