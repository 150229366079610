import React, { useState } from 'react'
import { cloneDeep } from "lodash"
import { Menu as CMenu, MenuItem as CMenuItem, SubmenuBlock, MenuDivider, } from "carbon-react/lib/components/menu";
import eventBus from '../../events/eventBus'

const defaultMenu = {
    meta: {id:''},
    tree: [
        {
            id: 1,
            title:"Menu item 1",
            custom: {},
        },
        {
            id: 2,
            title:"Sub Menu item 2",
            custom: {},
            children: [
                {
                    id:3,
                    title:"Menu item 2.1",
                },
                {
                    id:4,
                    title:"Menu item 2.2",
                }
            ]
        },
    ]
}

const MenuItem = ({ facet, control, menu, item }) => {

    const isSubMenu = () => {
        if ((item.children) && (item.children.length>0)) return true;
        return false;
    }
    const getChildren = () => {
        if (item.children) return item.children;
        return [];
    }
    const onClick = (e,item) => {
        if (e) e.preventDefault();
        
        console.log("menu-click",item);

        control.callAction('menuItem',{menu:menu,item:item})
    }
 
    return (
        <React.Fragment>
        {isSubMenu() ?
            <CMenuItem submenu={item.title}>
            {getChildren().map((iitem,index) => (
                <MenuItem key={iitem.id} facet={facet} control={control} menu={menu} item={iitem}/>
            ))} 
            </CMenuItem>
        :
            <CMenuItem onClick={(e) => {onClick(e,item)}}>{item.title}</CMenuItem>
        }
        </React.Fragment>
    );
}

const Menu = ({ facet, control, value }) => {

    const getMenu = () => {
        if (value && value.menu && value.menu) return (value.menu);
        if (facet && facet.menu && facet.menu) return (facet.menu);
        return defaultMenu;
    }

    const getMenuMeta = () => {
        let m = getMenu();
        if (m.meta) return m.meta;
        return {};
    }

    const getTree = () => {
        let m = getMenu();
        if (m.tree) return m.tree;
        return [];
    }

    return (

    <CMenu display="flex" flex="1">

        {getTree().map((item,index) => (
            <MenuItem key={item.id} facet={facet} control={control} menu={getMenuMeta()} item={item}/>
        ))}    

    </CMenu>
    )
}

export default Menu