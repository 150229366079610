import React from 'react'
import FacetSwitch from './FacetSwicth'

const Facets = ({ facets, position, ...props }) => {

    return (
    <React.Fragment>
        {facets.map((facet) => (
            (facet.position===position) ? <FacetSwitch key={facet.id} facet={facet} type={facet.type} {...props}/> : null
        ))}
    </React.Fragment>
    )
}

export default Facets
