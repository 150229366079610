import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext,
} from "react";
import { cloneDeep } from "lodash";
import I18n from "i18n-js";
import styled from "styled-components";
import Icon from "carbon-react/lib/components/icon";
import { Accordion } from "carbon-react/lib/components/accordion";
import Loader from "carbon-react/lib/components/loader";
import { Facet } from "./Facet";
import Layout from "../../layout/Layout";
import eventBus from "../../events/eventBus";
import { MENU_PANEL_WIDTH } from "../../../constants";
import useTheme from "../hooks/useTheme";

const FacetNavigLeftContext = React.createContext({});

export const navLeftWidth = { current: "64px" };
export const navLeftHeight = { current: undefined };

export const useFacetNavigLeftContext = () => {
  return useContext(FacetNavigLeftContext);
};

const NavLeftWrapper = styled.div`
  border-right: 1px solid
    ${({ theme }) => theme.navigation.panel.backgroundColor};
  display: flex;
  align-items: start;
  position: relative;
  height: max(
    ${({ currentNavListHeight }) => currentNavListHeight},
    calc(100vh - ${({ theme }) => theme.navigation.topBar.height})
  );

  & div[data-element="accordion-title-container"] {
    color: ${(props) => props.theme.navigation.panel.title.color};
    background-color: ${(props) =>
      props.theme.navigation.panel.title.backgroundColor};
    padding: ${(props) => props.theme.navigation.panel.title.padding};
    padding-top: ${({ theme }) => theme?.navigation.panel.title.paddingTop};
    padding-bottom: ${({ theme }) =>
      theme?.navigation.panel.title.paddingBottom};
  }
`;

const NavPanelsWrapper = styled.div`
  display: flex;
  position: ${({ processDragFlag }) =>
    processDragFlag ? "relative" : "absolute"};
  background-color: var(--color);
  margin-left: ${({ processDragFlag }) =>
    processDragFlag ? "0" : "var(--margin)"};
  & [data-component="accordion"] {
    border-top: none;
    border-bottom: none;
  }
`;
const NavPanelWrapper = styled.div`
  width: 0px;
  transition: width 0.3s;
  z-index: 1000;
  & div[data-element="accordion-content"] {
    padding: 0px;
  }
  & div[role="region"] {
    background-color: ${(props) =>
      props.theme.navigation.panel.backgroundColor};
  }
  & [data-element="accordion-title"] {
    font-weight: 500;
  }
`;
const NavPanelTextWrapper = styled.div`
  height: ${(props) => props.theme.navigation.panel.title.height};
  display: flex;
  justify-content: center;
  align-items: center;
`;

///////////////////////////////////
const NavAccordionWrapper = styled.div``;
const NavAccordionTitleWrapper = styled.div`
  padding: ${(props) => props.theme.navigation.panel.title.padding};
  height: ${(props) => props.theme.navigation.panel.title.height};
  color: var(--color);
  background-color: var(--background);
`;
const NavAccordionContentWrapper = styled.div``;
/////////////////////////////////
// item
/////////////////////////////////
const NavItemWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--color);
  background-color: var(--background);
  position: var(--position);
  margin-bottom: var(--marginBottom);
`;
const NavIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NavTitleWrapper = styled.div`
  font-weight: 500;
`;
const NavOverWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--color);
  background-color: var(--background);
  float: left;
  position: absolute;
  z-index: 1000;
  top: var(--top);
  width: var(--width);
`;

const NavItemOver = ({ facet, item, ...props }) => {
  const getStyle = () => {
    return {
      width: props.theme.navigation.iconBar.iconSize + "px",
      height: props.theme.navigation.iconBar.iconSize + "px",
      color: props.theme.navigation.iconBar.hoverColor,
    };
  };
  const getWrapperStyle = () => {
    return {
      "--color": props.theme.navigation.iconBar.hoverColor,
      "--background": props.theme.navigation.iconBar.backgroundOver,
      "--top": item.indx * props.theme.navigation.iconBar.iconSize + "px",
      "--width": props.theme.navigation.iconBar.expandedWidth + "px",
    };
  };

  return (
    <NavOverWrapper
      role="navleft-item-over"
      style={getWrapperStyle()}
      onMouseLeave={() => props.onLeave(item)}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(item);
      }}
    >
      <NavIconWrapper role="navleft-item-icon" style={getStyle()}>
        <Icon type={item.icon} color={getStyle().color} />
      </NavIconWrapper>
      <NavTitleWrapper role="navleft-item-title">{item.title}</NavTitleWrapper>
    </NavOverWrapper>
  );
};

const NavItem = ({ facet, item, isExpanded, ...props }) => {
  const getIsOpened = () => {
    return props.current && props.current.id === item.id;
  };
  const getIconStyle = () => {
    return {
      width: props.theme.navigation.iconBar.iconSize + "px",
      height: props.theme.navigation.iconBar.iconSize + "px",
      color: getIsOpened()
        ? props.theme.navigation.iconBar.hoverColor
        : props.theme.navigation.iconBar.color,
    };
  };
  const getWrapperStyle = () => {
    let th = props.theme.navigation.iconBar;
    return {
      "--color": getIsOpened() ? th.hoverColor : th.color,
      "--background": getIsOpened() ? th.backgroundOver : th.backgroundColor,
      "--marginBottom": item.isLast ? "auto" : "inherit",
      borderTop: item.isFirstAction
        ? `2px solid ${props.theme?.colors?.white}`
        : undefined,
    };
  };

  return (
    <NavItemWrapper
      role="navleft-item"
      style={getWrapperStyle()}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(item);
      }}
    >
      <NavIconWrapper
        role="navleft-item-icon"
        style={getIconStyle()}
        onMouseEnter={() => props.onEnter(item)}
      >
        <Icon type={item.icon} color={getIconStyle().color} />
      </NavIconWrapper>
      {isExpanded ? (
        <NavTitleWrapper
          role="navleft-item-title"
          onClick={(e) => {
            e.stopPropagation();
            props.onClick(item);
          }}
        >
          {item.title}
        </NavTitleWrapper>
      ) : null}
    </NavItemWrapper>
  );
};

const NavItemList = ({
  facet,
  items,
  isExpanded,
  onHeightChange,
  ...props
}) => {
  const refNavItemList = useRef();
  const theme = useTheme();

  const getStyle = () => {
    return {
      minHeight: `calc(100vh - ${theme.navigation.topBar.height})`,
      width: isExpanded
        ? props.theme.navigation.iconBar.expandedWidth
        : props.theme.navigation.iconBar.iconSize,
      backgroundColor: props.theme.navigation.iconBar.backgroundColor,
      margin: "0",
    };
  };

  const getItems = () => {
    let rslt = [];
    if (items) {
      items.forEach((item) => {
        rslt.push(item);
      });
    }
    if (rslt.length) rslt[rslt.length - 1].isLast = true;
    if (facet.actions) {
      facet.actions.forEach((action, actionIndex) => {
        rslt.push({
          id: rslt.length,
          title: action.title,
          icon: action.icon,
          [action.onLoadMethod ? "onLoadMethod" : "action"]: action.onLoadMethod
            ? action.onLoadMethod
            : action,
          isFirstAction: actionIndex === 0,
        });
      });
    }
    rslt.push({
      id: rslt.length,
      title: I18n.t("navigleft.minimize", { defaultValue: "Minimize" }),
      icon: isExpanded ? "chevron_left" : "chevron_right",
      isToggle: true,
    });

    rslt.forEach((itm, indx) => {
      itm.indx = indx;
    });

    return rslt;
  };

  useEffect(() => {
    const style = window.getComputedStyle(refNavItemList.current);
    const height = style["height"];
    onHeightChange(height);
  });

  return (
    <Facet ref={refNavItemList} role="navleft-items" style={getStyle()}>
      {getItems().map((item) => (
        <NavItem
          key={item.id}
          facet={facet}
          item={item}
          isExpanded={isExpanded}
          {...props}
        />
      ))}
    </Facet>
  );
};

const NavPanel = ({ offset, index, item, ...props }) => {
  const panelRef = useRef(null);

  const reduceState = (state, data) => {
    let newState = cloneDeep(state);
    switch (data.action) {
      case "open":
        newState.current = data.data;
        newState.isOpened = true;
        break;
      case "close":
        newState.current = null;
        newState.isOpened = false;
        break;
      case "setContent":
        newState.content = data.data;
        break;
      default:
    }
    return newState;
  };
  const [state, setState] = useReducer(reduceState, {
    content: null,
  });

  const loadContent = () => {
    //
    if (!item) return false;
    //
    if (item.onLoad) {
      // static onLoad event which return rendererd content
      setState({ action: "setContent", data: item.onLoad(item) });
      return true;
    }
    //
    if (item.onLoadMethod) {
      // dynamic content loaded asynchronously from server
      props.control.callMethod(item.onLoadMethod, item, (data) => {
        let render = (
          <Layout layout={data.layout} facets={data.facets} {...props} />
        );
        setState({ action: "setContent", data: render });
      });
      return true;
    }
    return false;
  };

  const getContent = () => {
    if (state.content) {
      return state.content;
    }

    if (loadContent()) {
      return (
        <NavPanelTextWrapper>
          <Loader />
        </NavPanelTextWrapper>
      );
    }

    return (
      <NavPanelTextWrapper>
        {I18n.t("navigleft.noContent", {
          defaultValue: "You don't have any content",
        })}
      </NavPanelTextWrapper>
    );
  };

  const getTitle = () => {
    if (item) return item.title;
    return "";
  };

  window.setTimeout(() => {
    // to animate the panel
    if (panelRef.current) {
      let ww = item ? props.theme.navigation.panel.width : 0;
      panelRef.current.setAttribute("style", "width:" + ww + "px");
    }
  }, 1);

  return (
    <NavPanelWrapper ref={panelRef} role="navleft-panel">
      <Accordion defaultExpanded={true} title={getTitle()}>
        {getContent()}
      </Accordion>
    </NavPanelWrapper>
  );
};

const FacetNavigLeft = ({ facet, theme, ...props }) => {
  const reduceState = (state, data) => {
    let newState = cloneDeep(state);
    switch (data.action) {
      case "set":
        newState.items = data.data;
        newState.current = null;
        newState.isOpened = false;
        newState.panels = [];
        break;
      case "toggle":
        newState.isExpanded = !newState.isExpanded;
        break;
      case "open":
        newState.current = data.data;
        newState.isOpened = true;
        newState.isOver = false;
        newState.over = null;
        newState.panels = [];
        newState.panels.push({ item: data.data });
        //newState.panels.push({item:{title:"second panel"}})
        break;
      case "close":
        newState.current = null;
        newState.isOpened = false;
        newState.panels = [];
        break;
      case "enter":
        newState.isOver = true;
        newState.over = data.data;
        break;
      case "leave":
        newState.isOver = false;
        newState.over = null;
        break;
      default:
    }
    return newState;
  };

  const [state, setState] = useReducer(reduceState, {
    isExpanded: false,
    isOpened: false,
    current: null,
    isOver: false,
    over: null,
    items: [],
    panels: [],
  });

  const navLeftWrapperRef = useRef();

  const [currentNavListHeight, setCurrentNavListHeight] = useState();

  const getItems = () => {
    if (facet.items) return facet.items;
    return [{ id: 1, title: "home", icon: "home", action: "home" }];
  };

  useEffect(
    () => {
      setState({ action: "set", data: getItems() });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [facet]
  );

  const onItemEnter = (item) => {
    if (state.isOpened) return;
    if (item.isToggle) return;
    if (item.action) return;
    if (item.title) setState({ action: "enter", data: item });
  };
  const onItemLeave = (item) => {
    if (state.isOver) setState({ action: "leave", data: null });
  };

  const onAction = (action) => {
    if (props.control) {
      props.control.callAction(action.name, { param: action });
    }
  };
  const onToggle = () => {
    setState({ action: "toggle", data: null });
  };
  const onItemClick = (item) => {
    eventBus.emit("close-item-select", {});
    if (item.isToggle) {
      onToggle();
      return;
    }
    if (item.action) {
      onAction(item.action);
      return;
    }

    //
    if (state.isOpened) {
      setState({ action: "close", data: null });
      return;
    }
    //
    if (item.action && props.control) {
      props.control.callAction(item.action, { item: item });
      return;
    }
    if (!item.isFiller) {
      setState({ action: "open", data: item });
      return;
    }
  };

  const getHeight = () => {
    let h = theme.navigation.panel.content.height;
    return h;
  };

  const getOffset = () => {
    let rslt = state.isExpanded
      ? theme.navigation.iconBar.expandedWidth
      : theme.navigation.iconBar.iconSize;
    return rslt;
  };

  const getPanelsStyle = () => {
    return {
      "--color": theme.navigation.panel.backgroundColor,
      "--margin": getOffset() + "px",
    };
  };

  useEffect(() => {
    const memoizedCallback = (e) => {
      if (
        state.isOpened &&
        navLeftWrapperRef.current?.contains &&
        !navLeftWrapperRef.current.contains(e.target)
      ) {
        setState({ action: "close", data: null });
      }
    };
    const memoizedCallbackBlur = (e) => {
      if (document?.activeElement?.tagName === "IFRAME" && state.isOpened) {
        setState({ action: "close", data: null });
      }
    };
    window.addEventListener("click", memoizedCallback);
    window.addEventListener("blur", memoizedCallbackBlur);
    return () => {
      window.removeEventListener("click", memoizedCallback);
      window.removeEventListener("blur", memoizedCallbackBlur);
    };
  });

  useEffect(() => {
    if (state.isOpened) {
      eventBus.emit("left-menu-panel-open");
    } else {
      eventBus.emit("left-menu-panel-close");
    }
  }, [state.isOpened]);

  const [processDragFlag, setProcessDragFlag] = useState(false);

  useEffect(() => {
    const style = window.getComputedStyle(navLeftWrapperRef.current);
    const width = style["width"];
    const height = style["height"];
    navLeftWidth.current = width;
    navLeftHeight.current = height;
    eventBus.emit("navleft-width-changed", {});
    eventBus.emit("navleft-height-changed", {});
  });

  useEffect(() => {
    const handleResize = () => {
      const style = window.getComputedStyle(navLeftWrapperRef.current);
      const width = style["width"];
      const height = style["height"];
      navLeftWidth.current = width;
      navLeftHeight.current = height;
      eventBus.emit("navleft-width-changed", {});
      eventBus.emit("navleft-height-changed", {});
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const processDragStartCallback = () => {
      setProcessDragFlag(true);
    };
    const processDragEndCallback = () => {
      setProcessDragFlag(false);
    };
    eventBus.on("process-drag-start", processDragStartCallback);
    eventBus.on("process-drag-end", processDragEndCallback);

    return () => {
      eventBus.remove("process-drag-start", processDragStartCallback);
      eventBus.remove("process-drag-end", processDragEndCallback);
    };
  }, []);

  return (
    <FacetNavigLeftContext.Provider
      value={{
        close: () => {
          if (state.isOpened) {
            setState({ action: "close", data: null });
          }
        },
      }}
    >
      <NavLeftWrapper
        currentNavListHeight={currentNavListHeight}
        ref={navLeftWrapperRef}
        role="navleft"
      >
        <NavItemList
          onHeightChange={(newHeight) => {
            setCurrentNavListHeight(newHeight);
          }}
          facet={facet}
          theme={theme}
          items={state.items}
          isExpanded={state.isExpanded}
          current={state.current}
          onEnter={onItemEnter}
          onLeave={onItemLeave}
          onClick={onItemClick}
          {...props}
        />
        {state.isOver ? (
          <NavItemOver
            facet={facet}
            theme={theme}
            item={state.over}
            onEnter={onItemEnter}
            onLeave={onItemLeave}
            onClick={onItemClick}
            {...props}
          ></NavItemOver>
        ) : null}
        <NavPanelsWrapper
          processDragFlag={processDragFlag}
          role="navleft-panels"
          style={getPanelsStyle()}
        >
          {state.panels.map((panel, pindex) => (
            <NavPanel
              key={pindex}
              offset={getOffset()}
              height={getHeight()}
              index={pindex}
              item={panel.item}
              theme={theme}
              {...props}
            />
          ))}
        </NavPanelsWrapper>
      </NavLeftWrapper>
    </FacetNavigLeftContext.Provider>
  );
};

export default FacetNavigLeft;
