import eventBus from "../events/eventBus";
import { cloneDeep } from "lodash";
import Dom from '../common/Dom'

export const getDialogValue = ({ action, id }) => {
  let rslt = { value: id, dialog: action.dialog };
  return rslt;
};

const getOnActionCallClick = ({ action, control }) => (e, name) => {
  
  control.callAction(action.call.name, action.call);
};
///////////////////////////////////////////////////////
// Legacy form (when the app is embbeded in the legacy client)
///////////////////////////////////////////////////////
const getAttribute = (elt, name) => {
  for (let propertyName in elt) {
    if (propertyName.startsWith("jQuery")) {
      let obj = elt[propertyName];
      return obj[name];
    }
  }
  return null;
};

const getId = ({ id, value }) => {
  if (id) return id;
  if (value && value.id) return value.id;
  if (value && value.value) return value.value;
  return null;
};

const getOnActionDefaultClick = ({
  action,
  control,
  onAction,
  facet,
  value,
  id,
}) => (e, name) => {
  if (e) e.preventDefault();
  //
  // {name:"", itemId:""}
  let a = cloneDeep(action);
  a.itemId = getId({ id, value });
  //
  if (name) a.name = name;
  //
  if (onAction) {
    onAction(a);
    return;
  }
  //
  control.doFacetAction(facet, value, a);
};

const getOnCallBack = ({
  action,
  control,
  facet,
  value,
  onActionDefaultClick,
}) => (event) => {
  //
  //console.log("callBack event",event.data.uid,_action.form.uid);
  //
  if (event.data.uid === action.form.uid) {
    if (event.data.value && value)
      control.setFacetValue(facet, value, event.data.value);
    if (action.after) onActionDefaultClick(null, action.after);
  }
};

const getOnActionFormClick = ({
  action,
  control,
  facet,
  value,
  id,
  onAction,
}) => (e) => {
  if (e) e.preventDefault();

  if (action.form.url) {
    let uri = action.form.url.replace("[id]", getId({ id, value }));
    let payload = { action: "executeLink", uri: uri, cd: null };

    if (window.parent && window.frameElement) {
      let config = getAttribute(window.frameElement, "config");
      payload.cd = config;
    }
    // work only for process configuration forms
    // we need to register the callback here to capture event uid
    if (action.form.uid)
      window.addEventListener(
        "message",
        getOnCallBack({
          action,
          control,
          facet,
          value,
          onActionDefaultClick: getOnActionDefaultClick({
            action,
            control,
            onAction,
            facet,
            value,
            id,
          }),
        }),
        false
      );
    window.parent.postMessage(payload, window.parent.origin);
    //
    //console.log("postMessage",payload);
  }
};

const getOnActionDialogClick = ({ setIsOpen, isOpen }) => (e) => {
  if (e) e.preventDefault();
  setIsOpen(!isOpen);
};

const getOnActionEventClick = ({action}) => (e) => {
  if (e) e.preventDefault();
  eventBus.emit(action.event.name, action.event.param);
};

const getOnActionLocationClick = ({action}) => (e) => {
  console.log("action.location click",action)
  if (e) e.preventDefault();
  if (action.location.newTab) 
    window.open(action.location.url, '_blank');
  else 
    window.location = action.location.url;
}
const getOnActionNavigateClick = ({action}) => (e) => {
  if (e) e.preventDefault();
  window.open(action.navigate.url)
}
const getOnActionCopyClick = ({action}) => (e) => {
  if (e) e.preventDefault();
  if (action.copy.url) Dom.copyToClipboard(action.copy.url);
}

const getOnActionDownloadClick = ({action, control}) => (e) => {

  if (e) e.preventDefault();
  control.callAction(action.name, {url:action.download.url, name:action.download.name}, (resp) => {
    if (resp.download && resp.download.url) {
      Dom.download(resp.download.url, resp.download.name);
    }
  });
}

export const getActionType = ({ action }) => {
  if (action.dialog) return "dialog";
  if (action.form) return "form";
  if (action.event) return "event";
  if (action.call) return "call";
  if (action.location) return "location";
  if (action.navigate) return "navigate";
  if (action.copy) return "copy";
  if (action.download) return "download";
  if (action.noop) return "noop";
  return "default";
};

export const getClickHandler = ({
  action,
  control,
  facet,
  value,
  id,
  onAction,
  setIsOpen,
  isOpen,
}) => {
  // tip: Icon don't manage disabled click
  //
  if (action.disabled) return null;
  //
  switch (getActionType({ action })) {
    case "form":
      return getOnActionFormClick({
        action,
        control,
        facet,
        value,
        id,
        onAction,
      });
    case "dialog":
      return getOnActionDialogClick({ setIsOpen, isOpen });
    case "event":
      return getOnActionEventClick(action);
    case "call":
      return getOnActionCallClick({ action, control });
    case "location":
      return getOnActionLocationClick({ action, control });
    case "navigate":
      return getOnActionNavigateClick({ action, control });
    case "copy":
      return getOnActionCopyClick({ action, control });
    case "download":
      return getOnActionDownloadClick({action,control});
    case "noop":
      return () => {};
    default:
      return getOnActionDefaultClick({
        action,
        control,
        onAction,
        facet,
        value,
        id,
      });
  }
};
