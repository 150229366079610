import React, { useReducer, useEffect, useState, useRef } from 'react'
import { cloneDeep } from "lodash"
import eventBus from '../../events/eventBus'
import IconAction from './IconAction'
import IconPopover from './IconPopover'
import FacetResultGrid from '../facets/FacetResultGrid/FacetResultGrid'
import { toastMsg } from "./toast/toastHelpers";

const IconEvents = ({ value, ...props }) => {

    const getLimits = () => {
        if (value && value.event && value.event.limits) return value.event.limits;
        return 20;
    }

    const reducer= (state,data) => {
        //console.log('iconEvents-old',state,data);
        let newState = cloneDeep(state);
        switch (data.action) {
         case 'set' :
            newState.items = data.data;
            if (newState.items.length>0) newState.itemId = newState.items[0].id;
            break;
         case 'add' :
            let item = cloneDeep(data.data);
            if (typeof item.id==="undefined") item.id = newState.items.length.toString();
            newState.itemId = item.id;
            newState.items.unshift(item);//push(item);
            if (newState.items.length>getLimits()) {
               newState.items.pop();//splice(0,1);
            }
            newState.overlay = (value && value.action && value.action.overlay?value.action.overlay.type:'');
            break;
         case 'rmv' :
            let itemId = data.data.id;
            let itemIndx = newState.items.findIndex(function(value){ return (value.id===itemId)});
            newState.items = newState.items.filter(function(value){ return (value.id!==itemId)});
            if (newState.itemId === itemId) {
                if (newState.items.length===0) newState.itemsId=null; else {
                    if (itemIndx>=newState.items.length) itemIndx=itemIndx-1;
                    newState.itemId = newState.items[itemIndx].id
                }
            }
            break;
        case 'clear' :
            newState.overlay = '';
            break;
        default:
        }
        //console.log("iconEvents-new",newState,data);
        return newState;
    }

    const [state,setState] = useReducer(reducer,{itemId:0,items:[],overlay:''});

    const getItems = () => {
        if (value && value.items) return value.items;
        return {};
    }
    const getEventType = () => {
        if (value && value.event) return value.event.type;
        return 'tweet';
    }

    useEffect(() => {
        setState({action:"set",data:getItems().items||[]});

        const memoizedCallback = (data)=>{
            //console.log("tweetAdd",data)
            setState({action:"add",data:data})
            if (data?.title)
              toastMsg({
                text: data.title,
                time: data.subTitle,
                data,
                type: data?.status || "info"
              });
            window.setTimeout(()=>{
                setState({action:"clear",data:null})
            },(value && value.action && value.action.overlay && value.action.overlay.delay?value.action.overlay.delay:2000))
        };
        const eventType = getEventType();
        eventBus.on(eventType, memoizedCallback);

        return () => {
          eventBus.remove(eventType, memoizedCallback);
        };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]);

    const renderIcon=({...props})=>{
        let val = {action:{title:"Notifications", icon:"talk", overlay:{type:''}, indicator:{title:"0"}}};
        if (value && value.action) val.action = cloneDeep(value.action);
        //
        if (val.action.indicator) val.action.indicator.title = state.items.length.toString();
        if (val.action.overlay) val.action.overlay.type = state.overlay;
        //
        return <IconAction value={val} {...props} ></IconAction>;
    }

    const renderContent = ({...props}) => {
        return (
            <FacetResultGrid items={state.items} {...props}></FacetResultGrid>
        )
    }

    return (
        <IconPopover
        renderIcon = {renderIcon}
        renderContent = {renderContent}
        {...props}/>
    );
}

export default IconEvents;
