import React, {  useState,  } from "react";
import Layout from '../../../layout/Layout';

const defaultLayout = {
    name: "layout",
    children: [
      { name: "top" },
      { name: "bottom" },
    ],
  };
  
const NavHomeContent = ( { facet, control, ...props } ) => {

    return (facet.homeTab?
        <Layout layout={facet.homeTab.layout || defaultLayout} facets={facet.homeTab.facets} control={control} {...props}/>
        : 
        <h1>No home data</h1>
    );
}

export default NavHomeContent;