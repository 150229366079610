import React, { useState } from 'react'
import EmailEditor from '../editors/EmailEditor'

// May be used as a Facet (ie value===null)
// or as a Facet's value (ie value!=null) 
//
const FacetEmailEditor = ({ facet, control, value, ...props }) => {

  return (
      <EmailEditor facet={facet} control={control} value={value} {...props} />
  )
}

export default FacetEmailEditor
