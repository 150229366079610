const SELECTION_ACTION = {
  FILTER: 'filter',
  SORT: 'sort',
  ORDER: 'order',
  SEARCH: 'searchString',
};
export { SELECTION_ACTION };

const ENTRY_TYPE = {
  DFM: 'tiExecuteDFM',
  EXCEL_WORKBOOK: 'tiExcelWorkbook',
  INFINEO_DOCUMENT: 'tiInfineoDocument',
  PORTAL_EXECUTE: 'tiPortalExecute',
  MENU: 'tiMenuParent',
  OTHER: '',
};
export { ENTRY_TYPE };
