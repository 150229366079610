import React from "react";
import I18n from "i18n-js";
import { Card as CBCard } from "carbon-react/lib/components/card";
import Loader from "carbon-react/lib/components/loader";
import Typography from "carbon-react/lib/components/typography";
import Ellipsis from "../../components/Ellipsis";
import Icon from "carbon-react/lib/components/icon";
import useTheme from "../../hooks/useTheme";
import styled from "styled-components";
import ButtonAction from "./ButtonAction";
import Button from "carbon-react/lib/components/button";
import TertiaryButtonWrapper from "../../../common/TertiaryButtonWrapper";

const CardContainer = styled.div`
  ${({ read, theme }) =>
    read === false
      ? `
    & [data-component="card"] {
      background-color: ${theme?.card?.footerBackground};
    }
  `
      : ""}
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: ${(props) => props.theme.card.minHeight};
  padding: ${(props) => props.theme.card.padding};
`;

const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardSecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
`;

const CardThirdColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 8px;
`;

const InfoContainer = styled.div``;

const LinkAndActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ContentContainer = styled.p``;

const LinkContainer = styled.div`
  & [data-component="button"] {
    margin-left: 2px;
  }
`;

const getLinkClick =
  ({ item, onItemClick }) =>
  (e) => {
    onItemClick?.(item, e);
  };

const Card = ({
  data,
  control,
  facet,
  value,
  doDownloadUrl,
  id,
  onAction,
  onItemClick,
}) => {
  const {
    icon,
    subTitle,
    rightTitle,
    linkTitle,
    content,
    status,
    title,
    read,
    actions,
  } = data;
  const theme = useTheme();
  return (
    <CardContainer read={read} theme={theme}>
      <CBCard
        mt="0"
        mr="0"
        ml="0"
        mb={theme.card.marginBottom}
        cardWidth="auto"
      >
        <CardRow role="card-row">
          {icon ? (
            <CardColumn role="card-col-1">
              <Icon
                type={icon}
                color={theme.card.icon.colors?.[status]}
                bgSize="large"
                fontSize="medium"
                ml={theme.card.icon.marginLeft}
                mr={theme.card.icon.marginRight}
                bg={theme.card.icon.backgroundColors?.[status]}
              />
            </CardColumn>
          ) : null}
          <CardSecondColumn role="card-col-2">
            <InfoContainer>
              <Typography
                mb={theme.card.subTitle.marginBottom}
                font={theme.card.subTitle.font}
                color={theme.card.subTitle.color}
              >
                {subTitle}
              </Typography>

              {false ? (
                <Typography
                  mb={theme.card.subTitle.marginBottom}
                  font={theme.card.subTitle.font}
                  color={theme.card.subTitle.color}
                  textTransform="uppercase"
                >
                  {status}
                </Typography>
              ) : null}

              <Typography
                mb={theme.card.title.marginBottom}
                font={theme.card.title.font}
                color={theme.card.title.color}
              >
                <ContentContainer>
                  {title ? (
                    <Typography variant="strong">
                      <Ellipsis text={title} lines={2} />
                    </Typography>
                  ) : null}
                </ContentContainer>

                <ContentContainer>
                  {content ? (
                    <Ellipsis text={content} lines={theme.card.content.lines} />
                  ) : null}
                </ContentContainer>
              </Typography>
            </InfoContainer>
            <LinkAndActionsContainer role="card-link">
              {data?.action ? (
                <LinkContainer>
                  <TertiaryButtonWrapper forceLine>
                    <Button
                      pl="0"
                      buttonType="tertiary"
                      iconType="link"
                      iconPosition="after"
                      onClick={getLinkClick({ item: data, onItemClick })}
                    >
                      <Ellipsis
                        style={{ textAlign: "left" }}
                        text={linkTitle || title}
                        lines={1}
                      />
                    </Button>
                  </TertiaryButtonWrapper>
                </LinkContainer>
              ) : null}
              {Array.isArray(actions) ? (
                <ActionsContainer>
                  {actions.map((action) => (
                    <ButtonAction
                      action={action}
                      facet={facet}
                      value={value}
                      control={control}
                      doDownloadUrl={doDownloadUrl}
                      id={id}
                      onAction={onAction}
                    />
                  ))}
                </ActionsContainer>
              ) : null}
            </LinkAndActionsContainer>
          </CardSecondColumn>
          {rightTitle ? (
            <CardThirdColumn role="card-col-3">
              <InfoContainer>
                <Typography
                  mb={theme.card.subTitle.marginBottom}
                  font={theme.card.subTitle.font}
                  color={theme.card.subTitle.color}
                >
                  {rightTitle}
                </Typography>
              </InfoContainer>
            </CardThirdColumn>
          ) : null}
        </CardRow>
      </CBCard>
    </CardContainer>
  );
};

const TableCellWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const LoadingCard = ({ theme }) => {
  return (
    <CBCard style={{ display: "table", minHeight: theme?.card?.minHeight }}>
      <TableCellWrapper>
        <Loader />
      </TableCellWrapper>
    </CBCard>
  );
};

const EmptyCard = ({ message, theme }) => {
  return (
    <CBCard style={{ display: "table", minHeight: theme?.card?.minHeight }}>
      <TableCellWrapper>{message}</TableCellWrapper>
    </CBCard>
  );
};

export { Card as default, LoadingCard, EmptyCard };
